import React, { FunctionComponent } from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { isConfirmType } from '../../../../interfaces'

type CloseSessionMenuItemProps = {
    id: number,
    name: string,
    setIsSession: (isConfirm: isConfirmType) => void
};

function cmp(prevProps: CloseSessionMenuItemProps, nextProps: CloseSessionMenuItemProps) {
    return prevProps.id === nextProps.id;
};

const CloseSessionMenuItem: FunctionComponent<CloseSessionMenuItemProps> = React.memo(({ id, name, setIsSession }) => {
    return <MenuItem onClick={() => setIsSession({ id: id, name: name, open: true })} >
        <Action bg={'#919191'}>
            <IconButton>
                <DoneAllIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default CloseSessionMenuItem;