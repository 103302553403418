import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  padding: 0 5px;
  overflow-x: auto;
  overflow-y: hidden;

  h2 {
    margin: 1vh 0;
    font-size: 130%;
    font-family: 'Montserrat', sans-serif;
  }

  .list {
    padding-bottom: 15px;
  }

  /* .list > div:nth-last-child(2) > div {
    border-radius: 7px 7px 0 0;
  }

  .list > .list > div {
    border-radius: 7px 7px 0 0;
    border: 1px solid red;
  } */

  .pagination {
    font-size: 70%;
    font-weight: bold;
  }

  .balance-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin: 3px;
    background: #456bea;
    border-radius: 20px;
    font-size: 95%;
    font-weight: 300;
    text-transform: capitalize;
    color: #fff;
    border: none;
    min-height: 17px;
    cursor: pointer;
  }
  .balance-btn:hover {
    opacity: 0.6;
  }
`
export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  span {
    width: 20%;
    height: 35px;
    color: #010e2a;
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 68%;
  }
`
type ListItemProps = {
  bg: string
  open: boolean
  nesting: number
}
export const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${(props: ListItemProps) => props.bg};
  border: 1px solid #b6b6b6;
  margin: -0.5px 0;
  box-shadow: ${(props: ListItemProps) =>
    props.open ? '0px 2.5px 3px 0px rgba(34, 60, 80, 0.2)' : ''};
  z-index: ${(props: ListItemProps) => props.nesting === 1 ? 100 : 100-props.nesting};
  div {
    position: relative;
    width: 20%;
    min-width: max-content;
    height: 45px;
    color: #010e2a;
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.5vh;
      cursor: pointer;
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3vh;
    height: 3vh;
    background: #7467ec;
    position: absolute;
    left: 10px;
    border-radius: 50%;
    img {
      width: 1vh;
      filter: brightness(5);
      cursor: default;
    }
  }
`
type StatusProps = {
  active: boolean
}
export const Status = styled.span`
  max-height: 18px;
  background: ${(props: StatusProps) => (props.active ? '#87b03b' : '#b03c3c')};
  color: #fff;
  border-radius: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh 3vh;
`
type ActionPropsType = {
  disabled?: boolean
  bg: string
}
export const Action = styled.span`
  width: 3vh;
  height: 3vh;
  max-width: 25px;
  max-height: 25px;
  margin: 0.3vh;
  background: ${(props: ActionPropsType) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  opacity: ${(props: ActionPropsType) => (props.disabled ? '0.4' : '1')};

  img {
    width: 13px;
    height: auto;
  }
`
