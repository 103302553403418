import React from 'react'

import { Modal } from './styles'
import { Box, Input, Button, ButtonGroup, IconButton } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
// import { Alert } from '@material-ui/lab'
import {
  handlePutTableHighscore,
  tableSelector,
  handleTakeTableHighscore,
} from '../../../redux/tableReducer'
import { useForm } from '../../../hooks/useForm'
import Preloader from '../../Common/Preloader'
import { useStyles } from './styles'
import { prettyNum } from '../../../utils/prettyNum'
import { useTranslation } from 'react-i18next'

interface HighscoreProps {
  id?: any
  currency_id?: string
  highscore?: number
  setTableId: (id: number) => void
  hideHighscore: () => void
}

const Highscore: React.FC<HighscoreProps> = ({
  id,
  currency_id,
  highscore,
  setTableId,
  hideHighscore,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation()

  React.useEffect(() => {
    return () => {
      setTableId(-1)
    }
    // eslint-disable-next-line
  }, [])

  const {
    form: {
      //  error,
        loading },
  } = useSelector(tableSelector)

  const { form, setFormItem } = useForm({
    amount: !!highscore ? prettyNum(highscore) : 0, 
  })

  return (
    <Modal>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>{t('ru.common.balance.highscore')}</h3>
        <IconButton onClick={hideHighscore}>
          <CloseIcon />
        </IconButton>
      </Box>

      {loading ? (
        <Preloader absolute={false} />
      ) : (
        <Box>
          <ButtonGroup variant="contained">
            <Input
              className={classes.input}
              endAdornment={`(${currency_id})`}
              value={form.amount}
              onChange={(e) => {
                // eslint-disable-next-line
                setFormItem('amount', e.target.value.replace(/[^0-9\.]/g, ''))
              }}
              readOnly={!!highscore}
            />
            {highscore && highscore > 0 ? (
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  dispatch(handleTakeTableHighscore(id, hideHighscore))
                }}
              >
                Вывести
              </Button>
            ) : (
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  dispatch(handlePutTableHighscore(id, form.amount * 100, hideHighscore))
                }}
              >
                {t('ru.common.balance.change')}
              </Button>
            )}
          </ButtonGroup>
        </Box>
      )}
    </Modal>
  )
}

export default Highscore
