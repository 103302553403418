import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 0.05);
`
export const useStyles = makeStyles({
  widget: {
    width: '100%',
    padding: '2vh 3vh',
  },
  label: {
    fontSize: '90%'
  }
})
