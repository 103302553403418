import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../Halls/List/styles';
import HallBox from '../Tables/List/hall-box';
import { hallSelector } from '../../redux/hallReducer';
import { useSelector } from 'react-redux';
import Preloader from '../Common/Preloader';
import { IJackpot, isConfirmType } from '../../interfaces';
import { findJackpots } from '../../redux/jackpotReducer';
import CurrentTable from './current-table';
import { Link } from "react-router-dom";
import Overlay from '../Common/Overlay/index';
import Jackpot from './View';
import Confirm from '../Common/Confirm';
import { deleteJackpot } from '../../services/Jackpot';

interface CurrentJackpotsProps {
    isModal?: boolean;
    hallId: number;
    setHallId: (hallId: number) => void;
}

const CurrentJackpots: React.FC<CurrentJackpotsProps> = React.memo(({ isModal, hallId, setHallId }) => {
    const [t] = useTranslation();
    const [jackpotId, setJackpotId] = React.useState<number>(0);
    const { halls: { data: halls } } = useSelector(hallSelector);
    const [jackpots, setJackpots] = React.useState<IJackpot[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (!hallId && halls.length) {
            console.log(halls[0].id)
            setHallId(halls[0].id);
        }
    }, [halls, hallId, setHallId]);
    React.useEffect(() => {
        if (hallId) {
            findJackpots([0, 1], [hallId], [], 1, 4, setLoading, setJackpots, () => console.log('error'));
        }
    }, [hallId]);
    const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false });
    const deleteJackpotConfirm = (id: number, name: string) => setIsConfirm({ id, name, open: true });
    const deleteJackpotComplete = (id: number, ok: boolean) => {
        if (ok) {
            setJackpots(jackpots.filter(e => e.id !== id));
        }
        setIsConfirm({ open: false });
        setLoading(false);
    };
    const deleteJackpotStart = (id?: number) => {
        if (!id) {
            return;
        }
        setLoading(true);
        deleteJackpot(id).then((res) => deleteJackpotComplete(id, res.data.code === 200)).catch(() => deleteJackpotComplete(id, false));
    };
    //Jackpot::STATUS_ACTIVE, Jackpot::STATUS_ACCUMULATION_ONLY, Jackpot::STATUS_PAUSED, Jackpot::STATUS_COMPLETED
    return <Wrapper isModal={isModal}>
        <Box style={{ minWidth: '968px' }}>
            {!isModal && <Box display="flex" justifyContent="space-between">
                <h2>{t('ru.jackpots.title')} <Link to="/jackpots/history"><Button variant="contained" color="primary" size="small">посмотреть завершённые</Button></Link></h2>
            </Box>}
            {hallId > 0 && <div style={{ position: 'relative' }}>
                <HallBox hallId={hallId} setHallId={setHallId} halls={halls} />
                {loading ? <Preloader /> : <CurrentTable jackpots={jackpots} hall_id={hallId} setJackpotId={setJackpotId} deleteJackpot={deleteJackpotConfirm} />}
            </div>}
            {jackpotId > 0 && <Overlay>
                <Jackpot table_id={null} hall_id={hallId} isChangeMode={false} jackpotId={jackpotId} hideJackpot={() => setJackpotId(0)} />
            </Overlay>}
            {isConfirm.open && <Overlay>
                <Confirm text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`} loading={false} cancel={() => setIsConfirm({ open: false })} submit={() => deleteJackpotStart(isConfirm.id)} />
            </Overlay>}
        </Box>
    </Wrapper>;
});

export default CurrentJackpots;