import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import BlockIcon from '@material-ui/icons/Block';
import { isConfirmType } from '../../../../interfaces';
import { useTranslation } from 'react-i18next';

type BlockBtnProps = {
    id: number,
    name: string,
    status: boolean,
    setIsBlock: (isConfirm: isConfirmType) => void
};

function cmp(prevProps: BlockBtnProps, nextProps: BlockBtnProps) {
    return prevProps.id === nextProps.id;
};

const BlockBtn: FunctionComponent<BlockBtnProps> = React.memo(({ id, name, status, setIsBlock }) => {
    const [t] = useTranslation();
    const title = status ? 'block' : 'unblock';
    return <Tooltip title={String(t('ru.common.confirm.' + title))} placement="top" >
        <Action
            bg={'#919191'}
            onClick={() => { setIsBlock({ id: id, name: name, open: true }) }}
        >
            <IconButton>
                <BlockIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default BlockBtn;