import { Grid, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorType } from '../../../interfaces';
import { useStyles } from './styles';
import { trowError, trowErrorMessage } from '../../../utils/errors';

type ApiProps = {
    form: any;
    error: ErrorType[];
    setFormItem: (name: string, value: any) => void;
};

const Api: FunctionComponent<ApiProps> = React.memo(({ form, error, setFormItem }: ApiProps) => {
    const [t] = useTranslation();
    const classes = useStyles();
    return <>
        <Grid item xs={12}>
            <TextField className={classes.textFields} label={t('ru.tables.fields.remote_url')} type="text" value={form.remote_url} name="remote_url" onChange={(e) => setFormItem('remote_url', e.target.value)} error={!!trowError(error, 'remote_url')} helperText={trowErrorMessage(error, 'remote_url')} />
        </Grid>
    </>;
});

export default Api;