import React from 'react'
import { useForm } from '../../../../hooks/useForm'

import IconButton from '@material-ui/core/IconButton'
import { SubForm } from '../styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Input, Button, InputLabel } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  addManufacturerItem,
  manufacturerSelector,
  formIsError,
} from '../../../../redux/manufacturerReducer'
import Preloader from '../../../Common/Preloader'
import { trowErrorMessage, trowError } from '../../../../utils/errors'
import ErrorText from '../../../Common/ErrorText'

interface ManufacturerFormProps {
  hideManufacturer: () => void
}

const ManufacturerForm: React.FC<ManufacturerFormProps> = ({ hideManufacturer }) => {
  const dispatch = useDispatch()

  const {
    form: { error, loading },
  } = useSelector(manufacturerSelector)

  const { form, handleInputChange } = useForm({
    name: '',
  })

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <SubForm>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h4>Добавить производителя</h4>
        {!!loading && (
          <IconButton onClick={hideManufacturer}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {loading ? (
        <Preloader absolute={false}/>
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Input
            type="text"
            value={form.city}
            name="name"
            onChange={handleInputChange}
            placeholder="Название"
            fullWidth
            error={!!error && !!trowError(error, 'name')}
          />
          <InputLabel style={{ margin: '1vh', color: '#f44336' }}>
            {!!error && trowErrorMessage(error, 'name')}
          </InputLabel>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              dispatch(addManufacturerItem(form, hideManufacturer))
            }}
          >
            Добавить
            <AddCircleIcon />
          </Button>
        </Box>
      )}
      <ErrorText error={error} />
    </SubForm>
  )
}

export default ManufacturerForm
