import React from 'react'

import List from '@material-ui/core/List'
import { Box, Grid, makeStyles, IconButton, Chip, Tooltip, Divider } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { Wrapper } from './styled'
import { getRole, handlerChangeRole, roleSelector, roleSuccess } from '../../../redux/roleReducer'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../../Common/Preloader'
import { permissionSelector } from '../../../redux/permissionReducer'
import { IPermission, RoleData } from '../../../interfaces'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import RoleForm from '../Form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface RoleProps {
  roleId: string
  hideRole: () => void
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
}

const Role: React.FC<RoleProps> = ({ roleId, hideRole, isChangeMode, setIsChangeMode }) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  const { role, loading } = useSelector(roleSelector)
  const { items: permissionsList } = useSelector(permissionSelector)

  React.useEffect(() => {
    roleId && dispatch(getRole(roleId))
  }, [dispatch, roleId])
  console.log(role)
  React.useEffect(() => {
    return () => {
      dispatch(roleSuccess(null))
    }
    // eslint-disable-next-line
  }, [])
  const [isChange, setIsChange] = React.useState(isChangeMode)

  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  const rootRender = loading ? (
    <Preloader absolute={false} />
  ) : (
    <>
      <Box className={classes.closeBtn}>
        {
          <Tooltip
            title={
              isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))
            }
            placement="top"
          >
            <IconButton onClick={() => setIsChange(!isChange)}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: '#b6b6b6b6',
                  width: '30px',
                  minHeight: '30px',
                  borderRadius: '50%',
                  padding: '6px',
                }}
              >
                <img
                  style={{
                    width: '20px',
                    minHeight: 'auto',
                  }}
                  src={!isChange ? editPng : visionPng}
                  alt="icon"
                />
              </Box>
            </IconButton>
          </Tooltip>
        }
        <IconButton onClick={() => history.goBack()}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <AccountCircleIcon fontSize="large" />
        <h2>{role && role.name}</h2>
      </Box>
      <br />
      <Divider />

      {isChange ? (
        <RoleForm
          onSubmit={(data: RoleData, hideForm: () => void, name: string) =>
            dispatch(handlerChangeRole(data, hideForm, name))
          }
          hideForm={hideRole}
          cancelBtnEvent={() => setIsChange(false)}
          cancelBtnText={t('ru.common.back_btn')}
          submitBtnText={t('ru.common.submit_btn')}
          name={role?.name}
          description={role?.description}
          permissions={role?.permissions}
        />
      ) : (
        <>
          <List>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <b>{t('ru.roles.fields.description')}:</b>
                    {''}
                    {role && role.description}
                  </Box>
                </Grid>
                {!!role && (
                  <Grid item xs={12} sm={12}>
                    <b>{t('ru.roles.fields.permissions')}:</b>
                    <br />
                    <Box>
                      {role &&
                        role.permissions.map((permission: string) => (
                          <Chip
                            key={permission}
                            label={
                              !!permissionsList.find((i: IPermission) => i.name === permission)
                                ? permissionsList?.find((i: IPermission) => i.name === permission)
                                    ?.description
                                : (permission as string)
                            }
                            className={classes.permission}
                          />
                        ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </List>
        </>
      )}
    </>
  )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Role
