import { IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import { JackpotData } from '../../../interfaces'
import JackpotForm from '../Form'
import CloseIcon from '@material-ui/icons/Close'
import { Wrapper } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { getJackpot, jackpotSelector, jackpotSuccess } from '../../../redux/jackpotReducer'
import Preloader from '../../Common/Preloader'
import { authSelector } from '../../../redux/authReducer'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface changeJackpotProps {
  jackpotId: number
  hall_id?: null | number
  table_id?: number
  hideChangeJackpot: () => void
  changeJackpotItem: (data: JackpotData, hideForm: () => void) => void
}

const ChangeJackpot: React.FC<changeJackpotProps> = ({
  jackpotId,
  hall_id,
  table_id,
  hideChangeJackpot,
  changeJackpotItem,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  
  const { jackpot, loading } = useSelector(jackpotSelector)
  const { role: { item: userRole } } = useSelector(authSelector);

  React.useEffect(() => {
    jackpotId && dispatch(getJackpot(jackpotId))
    return () => {
      dispatch(jackpotSuccess(null))
    }
  }, [dispatch, jackpotId])

  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideChangeJackpot}>
        <CloseIcon />
      </IconButton>
      <h3>Изменить джекпот</h3>
      {loading || !jackpot ? (
        <Preloader />
      ) : (
        <JackpotForm
          onSubmit={changeJackpotItem}
          cancelBtnEvent={hideChangeJackpot}
          cancelBtnText={'Отмена'}
          submitBtnText={'Изменить'}
          hideForm={hideChangeJackpot}
          jackpotHalls={jackpot.halls}
          jackpotTables={jackpot.gambling_tables}
          id={jackpot.id}
          type={jackpot.type}
          min_bet={jackpot.min_bet}
          restart={jackpot.restart}
          min_players={jackpot.min_players}
          initial_balance={jackpot.initial_balance}
          end_balance={jackpot.end_balance}
          percent={jackpot.percent}
          parent_id={jackpot.parent_id}
          status={jackpot.status}
          hall_id={hall_id}
          table_id={table_id}
          showHall={userRole==='administrator'}
        />
      )}
    </Wrapper>
  )
}

export default ChangeJackpot
