import React, { FunctionComponent } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Action } from '../../styles';
import BlockIcon from '@material-ui/icons/Block';

type isConfirmType = {
    id: number | undefined;
    name: string;
    open: boolean;
};

type BlockButtonProps = {
    id: number;
    login: string;
    status: boolean;
    setIsBlock: (item: isConfirmType) => void;
    isMe: boolean;
};

function cmp(prev: BlockButtonProps, next: BlockButtonProps): boolean {
    return prev.id === next.id && prev.status === next.status && prev.login === next.login && prev.isMe === next.isMe;
}

const BlockButton: FunctionComponent<BlockButtonProps> = React.memo(({ id, setIsBlock, login, status, isMe }) => {
    const [t] = useTranslation();
    return <Tooltip title={status ? String(t('ru.common.confirm.block')) : String(t('ru.common.confirm.unblock'))} placement="top" >
        <Action bg={'#919191'} onClick={() => !isMe && setIsBlock({ id: id, name: login, open: true })} disabled={isMe} >
            <IconButton>
                <BlockIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default BlockButton;