import { Box, Input, ListItem, makeStyles } from "@material-ui/core";
import { TableHeader } from "./styles";

const useStyles = makeStyles(() => ({
    inputRoot: {
        '& .MuiInput-underline.Mui-disabled:before': {
            border: 'none',
        },
    },
}));

type SearchHeaderProps = {
    id: string;
    name: string;
    setId: (id: string) => void;
    setName: (name: string) => void;
};

const SearchHeader: React.FC<SearchHeaderProps> = ({ id, name, setId, setName }) => {
    const classes = useStyles();
    return <Box style={{ background: '#fff', padding: '5px 0' }}>
        <TableHeader>
            <ListItem component="span">
                <Input style={{ background: '#efeff0' }} value={id} onChange={(e) => setId(e.target.value)} />
            </ListItem>
            <ListItem component="span">
                <Input style={{ background: '#efeff0' }} value={name} onChange={(e) => setName(e.target.value)} />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span" />
        </TableHeader>
    </Box>;
}

export default SearchHeader;