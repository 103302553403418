import React from 'react'
import PeopleIcon from '@material-ui/icons/People'
import { Box } from '@material-ui/core'
import { authSelector } from '../../../redux/authReducer'
import { userSelector } from '../../../redux/userReducer'
import { useSelector } from 'react-redux'
import Widget from '../Item'
import { useTranslation } from 'react-i18next'

const Partners = () => {
  const [t] = useTranslation()

  const { data: user } = useSelector(authSelector)
  const {
    users: { data: users, loading },
  } = useSelector(userSelector)

  const rootParent = (user_id: number): any => {
    if (!!users) {
      const currentUser = users.find((u) => u.id === user_id)
      if (!!currentUser) {
        if (currentUser.parent_id === null) {
          return currentUser.id
        } else {
          return currentUser && currentUser.parent_id && rootParent(currentUser.parent_id)
        }
      }
    }
  }

  const getPartners = () => {
    let items: number[] = []
    users.forEach((item) => {
      if (user && user.id === rootParent(item.id) && item.id !== user.id) {
        items = [...items, item.id]
      }
    })
    return items
  }

  return (
    user && (
      <Widget
        loading={loading}
        title={t('ru.widgets.partners')}
        icon={<PeopleIcon fontSize="large" />}
        value={
          <Box display="flex" alignItems="center" margin="2vh 0">
            <h2>{getPartners().length}</h2>
          </Box>
        }
      />
    )
  )
}

export default Partners
