import React from 'react'

import PaymentErrorsList from './List'


const PaymentErrors: React.FC = () => {

  return (
    <>
      <PaymentErrorsList />
    </>
  )
}

export default PaymentErrors
