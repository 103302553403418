import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const JackpotsWrapper = styled.div`
  width: 100%;
  position: relative;
  height: auto;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 0.05);
  padding: 3vh;
  margin: 8px 0 4px 0;
  h3 {
    font-size: 95%;
    margin: 5px 0;
  }

  b {
    font-size: 90%;
    line-height: 25x;
  }

  span {
    font-size: 84%;
  }
`
export const useStyles = makeStyles({

})
