import { Box } from '@material-ui/core'
import React from 'react'
import Terminals from '../components/Terminals'

const TerminalsPage = () => {
    return (
        <Box style={{width: '100%'}} display='flex' justifyContent='flex-start' alignItems='flex-start' flexDirection='column'>
            <h3>Терминалы</h3>
            <Terminals />
        </Box>
    )
}

export default TerminalsPage
