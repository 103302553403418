import { createSlice } from '@reduxjs/toolkit'
import {
  fetchJackpotsList,
  addJackpot,
  changeJackpot,
  fetchJackpot,
  deleteJackpot,
} from '../services/Jackpot'
import { IJackpot, ErrorType, JackpotData, isConfirmType } from '../interfaces'
import { AppThunk } from './store'
import { changeArr } from '../utils/changeArr'

interface jackpotInitialState {
  jackpot: IJackpot | null
  loading: boolean
  error: ErrorType[]
  jackpots: {
    items: IJackpot[]
    loading: boolean
    error: ErrorType[]
  }
  form: {
    loading: boolean
    error: ErrorType[]
  }
}
export const initialState: jackpotInitialState = {
  jackpot: null,
  loading: false,
  error: [],
  jackpots: {
    items: [],
    loading: false,
    error: [],
  },
  form: {
    loading: false,
    error: [],
  },
}

export const slice = createSlice({
  name: 'jackpot',
  initialState,
  reducers: {
    jackpotIsLoading: (state) => {
      state.loading = true
    },
    jackpotSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.jackpot = payload
    },
    jackpotFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    jackpotListIsLoading: (state) => {
      state.jackpots.loading = true
    },
    jackpotListSuccess: (state, { payload }) => {
      state.jackpots.loading = false
      state.jackpots.error = []
      state.jackpots.items = payload
    },
    jackpotListFailure: (state, { payload }) => {
      state.jackpots.loading = false
      state.jackpots.error = payload
    },
    jackpotItemDelete: (state, { payload }) => {
      const index = state.jackpots.items.findIndex((i) => i.id === payload)
      state.jackpots.items = [
        ...state.jackpots.items.slice(0, index),
        ...state.jackpots.items.slice(index + 1, state.jackpots.items.length),
      ]
    },
    jackpotItemAdd: (state, { payload }) => {
      state.jackpots.items = [...state.jackpots.items, payload]
    },
    jackpotItemChange: (state, { payload }) => {
      state.jackpots.items = [...changeArr(state.jackpots.items, payload.id, payload)]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getJackpot = (id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(jackpotIsLoading())

    return (
      id &&
      fetchJackpot(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(jackpotSuccess(res.data.data))
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(jackpotFailure([res.data.data]))
            } else {
              dispatch(jackpotFailure(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(jackpotFailure(err)))
    )
  }
}

export const getJackpotList = (page: number = 1, limit: number = 20): AppThunk => {
  return async (dispatch) => {
    dispatch(jackpotListIsLoading())

    return fetchJackpotsList(page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(jackpotListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(jackpotListFailure([res.data.data]))
          } else {
            dispatch(jackpotListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(jackpotListFailure(err)))
  }
}

export const findJackpots = (status: number[] = [0, 1], halls: number[] = [], tables: number[] = [], page: number = 1, limit: number = 10, setLoading: (loading: boolean) => void, setSuccess: (data: any, lastPage: number) => void, setError: () => void) => {
  setLoading(true);
  fetchJackpotsList(page, limit, status, halls, tables).then((res) => {
    if (res.data.code === 200) {
      setSuccess(res.data.data, +res.headers['x-pagination-page-count']);
    } else {
      setError();
    }
    setLoading(false);
  }).catch(() => setError());
}

let jackpots: any = []
export const getAllJackpots = (page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(jackpotListIsLoading())

    return fetchJackpotsList(page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          jackpots = [...jackpots, ...res.data.data]
          if (res.headers['x-pagination-current-page'] < res.headers['x-pagination-page-count']) {
            dispatch(getAllJackpots(Number(res.headers['x-pagination-current-page']) + 1, limit))
          } else {
            dispatch(jackpotListSuccess(jackpots))
            jackpots = []
          }
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(jackpotListFailure([res.data.data]))
          } else {
            dispatch(jackpotListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(jackpotListFailure(err)))
  }
}

export const addJackpotItem = (data: JackpotData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return addJackpot(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(jackpotItemAdd(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const deleteJackpotItem = (
  id: number,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return deleteJackpot(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(jackpotItemDelete(id))
          dispatch(formSucces())
          setIsConfirm({ open: false })
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const changeJackpotItem = (
  data: JackpotData,
  hideForm: () => void,
  id: number
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    const dataToSend = data.gambling_tables.length > 0 ? { ...data, halls: [] } : data;
    return changeJackpot(dataToSend, id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(jackpotItemChange(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  jackpotIsLoading,
  jackpotSuccess,
  jackpotFailure,
  jackpotListIsLoading,
  jackpotListSuccess,
  jackpotListFailure,
  jackpotItemDelete,
  jackpotItemAdd,
  jackpotItemChange,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  jackpot: jackpotInitialState
}

export const jackpotSelector = (state: IState) => state.jackpot

export default slice.reducer
