import axios from 'axios'

export const addCashbox = async (user_id: number, hall_id: number) => {
  return axios.get(`cashbox/add/${user_id}/${hall_id}`)
}

export const removeCashbox = async (user_id: number, hall_id: number) => {
  return axios.get(`cashbox/remove/${user_id}/${hall_id}`)
}

export const openCashbox = async (hall_id: number) => {
  return axios.get(`cashbox/open/${hall_id}`)
}

export const closeCashbox = async () => {
  return axios.get(`cashbox/close`)
}

export const fetchCashbox = async (page: number, limit: number) => {
  return axios.get(`cashbox`, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}
