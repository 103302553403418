import React, { FunctionComponent } from 'react';
import { Button, FormControl, FormHelperText, Grid, GridSize, NativeSelect, Tooltip } from '@material-ui/core';
import { ICompany } from '../../../../../interfaces';
import { useSelector } from 'react-redux';
import { companySelector } from '../../../../../redux/companyReducer';
import AddCircleIcon from '@material-ui/icons/AddCircle';

type FieldCompanyProps = {
    sm: GridSize;
    error: boolean;
    title: string;
    className: string;
    onClick: () => void;
    buttonText: string;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    companyId: number;
    formHelperText: string;
};

function cmp(prev: FieldCompanyProps, cur: FieldCompanyProps): boolean {
    return prev.sm === cur.sm && prev.error === cur.error && prev.title === cur.title && prev.className === cur.className && prev.buttonText === cur.buttonText && prev.formHelperText === cur.formHelperText && prev.companyId === cur.companyId;
}

const FieldCompany: FunctionComponent<FieldCompanyProps> = React.memo(({ sm, error, title, className, onClick, buttonText, onChange, companyId, formHelperText }) => {
    const { items: companyList } = useSelector(companySelector);
    return <Grid item xs={12} sm={sm}>
        <FormControl error={error} fullWidth size="small">
            <Tooltip title={title} arrow>
                <Button className={className} onClick={onClick}> {buttonText} <AddCircleIcon /> </Button>
            </Tooltip>
            <NativeSelect onChange={onChange} value={companyId} >
                <option aria-label="None" value="" />
                {companyList && companyList.map((item: ICompany) => <option value={item.id} key={item.id}> {item.name} </option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldCompany;