import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { trowError, trowErrorMessage } from "../../../utils/errors";
import { playerSelector } from "../../../redux/playerReducer";
import { useSelector } from "react-redux";

type RenderTextFieldProps = {
    form: any;
    handleInputChange: (object: any) => void;
    field: string;
};

const RenderTextField: React.FC<RenderTextFieldProps> = ({ field, form, handleInputChange }) => {
    const [t] = useTranslation();
    const { form: { error } } = useSelector(playerSelector);
    return <TextField
        value={form[field]}
        name={field}
        label={t("ru.players.fields." + field)}
        onChange={handleInputChange}
        size="small"
        error={!!trowError(error, field)}
        helperText={trowErrorMessage(error, field)}
    />;
}

export default RenderTextField;