import React, { ReactNode } from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import Preloader from '../../../Common/Preloader'

import { ListItem, Box, FormLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface PaymentErrorsListProps {}

const PaymentErrorsList: React.FC<PaymentErrorsListProps> = () => {
  const [t] = useTranslation()


  const renderList = () => {
    return [{id: 1},{id: 2},{id: 3},{id: 4}].map((item: any, id: number) => {
      return (
        <React.Fragment key={id}>
          <Item item={item} />
        </React.Fragment>
      )
    })
  }
  interface TcProps {
    children: ReactNode
  }
  const Tc: React.FC<TcProps> = ({ children }) => {
    return (
      <ListItem component="span" style={{ justifyContent: 'center', textAlign: 'center' }}>
        {children}
      </ListItem>
    )
  }

  // const [page, setPage] = React.useState(1)
  // React.useEffect(() => {
  //   getPaymentErrorsList(page, 20)
  //   // eslint-disable-next-line
  // }, [page])

  return (
    <>
      <Wrapper>
        <div style={{ minWidth: '550px' }}>
          <TableHeader style={{ fontSize: '75%' }}>
          <Tc>#</Tc>
            <Tc>{t('Дата')}</Tc>
            <Tc>{t('Сумма инкасации')}</Tc>
            <Tc>{t('Номиналы')}</Tc>
            <Tc>{t('Название')}</Tc>
          </TableHeader>
          <Box display="flex" justifyContent="center">
              <FormLabel>
                {`admin`}
              </FormLabel>
            </Box>
          <ul className={'Terminal'}>{false ? <Preloader absolute={false} /> : renderList()}</ul>
         {/* {pageCount && <Box
            display="flex"
            justifyContent="center"
            style={{ margin: '10px auto', width: '100%' }}
          >
            <Pagination
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              count={pageCount}
              page={page}
              onChange={(e, value) => {
                setPage(value)
              }}
            />
          </Box>} */}
        </div>
      </Wrapper>
    </>
  )
}

export default PaymentErrorsList
