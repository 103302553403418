import ReactDOM from 'react-dom'
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import './i18n'
import App from './routes'

import GlobalStyle from './theme/GlobalStyles'
import store from './redux/store'
import { theme } from './theme/theme'

document.title = process.env.TITLE ?? '';
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)
