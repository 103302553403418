import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type FieldIsSchoolProps = {
    error: boolean;
    onChange: (object: any) => void;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldIsSchoolProps, cur: FieldIsSchoolProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldIsSchool: FunctionComponent<FieldIsSchoolProps> = React.memo(({ error, onChange, value, formHelperText }) => {
    const [t] = useTranslation();
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <NativeSelect onChange={onChange} value={value}>
                <option value={0}>{t('Отключен')}</option>
                <option value={1}>{t('Включен')}</option>
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldIsSchool;