import styled from 'styled-components'

type WrapperProps = {
  isModal?: boolean
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${(props: WrapperProps) => (props.isModal ? '#efeff0' : '')};
  padding: 0 5px;
  overflow-x: auto;
  overflow-y: hidden;

  h2 {
    margin: 1vh 0;
    font-size: 2.9vh;
  }
`
export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  span {
    width: 20%;
    height: 35px;
    color: #010e2a;
    font-size: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  margin: 0.5vh auto;

  div {
    position: relative;
    width: 20%;
    height: 35px;
    color: #010e2a;
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      width: 1.5vh;
      cursor: pointer;
    }
  }
  div:nth-child(2) {
    display: block;
    white-space: nowrap; /* Отменяем перенос текста */
    overflow: hidden; /* Обрезаем содержимое */
    padding: 5px; /* Поля */
    text-overflow: ellipsis; /* Многоточие */
  }
`

type StatusProps = {
  active: boolean
}

export const Status = styled.span`
  max-height: 18px;
  max-width: max-content;
  background: ${(props: StatusProps) => (props.active ? '#87b03b' : '#b03c3c')};
  color: #fff;
  border-radius: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh 3vh;
  width: 15vh;
`
type ActionProps = {
  bg: string
}

export const Action = styled.span`
  width: 3vh;
  height: 3vh;
  max-width: 25px;
  max-height: 25px;
  margin: 0.3vh;
  background: ${(props: ActionProps) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 13px;
    height: auto;
  }
`
