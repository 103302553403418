import React from 'react';
import { ListItem, Status } from '../styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { IconButton, makeStyles, Collapse, Menu, MenuItem, Tooltip, Box } from '@material-ui/core';
import { StatusMobile } from '../../../Common/Status/styles';
import { prettyNum } from '../../../../utils/prettyNum';
import { UserBalance, IUser } from '../../../../interfaces';
import { authSelector } from '../../../../redux/authReducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { convertUTCDateToLocalDate } from '../../../../utils/date';
import NestingLine from './nesting-line';
import Buttons from './buttons/';
import MobileButtons from './mobile-buttons/';

const useStyles = makeStyles(() => ({
  subClients: {
    width: '100%',
    display: 'block',
    margin: '0 auto'
  }
}));
type isConfirmType = {
  id: number | undefined;
  name: string;
  open: boolean;
};
interface UsersItemProps {
  bg?: string;
  balance: UserBalance | undefined;
  users: IUser[];
  setUserId: (id: number) => void;
  setIsPaymentShown: (show: boolean) => void;
  setIsConfirm: (item: isConfirmType) => void;
  setIsBlock: (item: isConfirmType) => void;
  setIsReLogin: (item: isConfirmType) => void;
  setChange: (change: boolean) => void;
  nesting: number;
  user: any;
  viewUser: boolean;
  updateUser: boolean;
  deleteUser: boolean;
};

const Item: React.FC<UsersItemProps> = ({ bg, balance, users = [], user, setUserId, setIsPaymentShown, setIsConfirm, setIsBlock, setIsReLogin, nesting, setChange, viewUser, updateUser, deleteUser }) => {
  //console.log("item", user.u.id);
  const classes = useStyles();
  const [t] = useTranslation();

  const { data } = useSelector(authSelector);

  const [subClientsOpen, setSubClientsOpen] = React.useState(data!.id === user.u.id || user.children.has(data!.id) ? true : false);

  const getUserCurrencyList = (balance: UserBalance | undefined) => {
    let availableCurrency: string[] = [];
    for (let currency in balance) {
      availableCurrency = [...availableCurrency, currency];
    }
    return availableCurrency;
  };

  const getDefaultBalance = (balance: UserBalance) => {
    let maxBalance: number
    type BalanceListType = {
      currency: string;
      balance: number;
    };
    let balanceList: BalanceListType[] = [];
    let currencyList: number[] = [];
    for (let currency in balance) {
      balanceList = [...balanceList, { currency: currency, balance: balance[currency] }]
      currencyList = [...currencyList, balance[currency]]
    }
    if (balanceList) maxBalance = Math.max.apply(Math, [...currencyList])

    return balanceList.find((i) => i.balance === maxBalance)?.currency
  };

  const [currency, setCurrency] = React.useState<string | undefined>('')
  React.useEffect(() => balance && setCurrency(getDefaultBalance(balance)), [balance]);

  const subClients = user.children.size;

  const selectedBalance = balance && currency && balance[currency] ? prettyNum(balance[currency]) : '-';
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  type AnchorElType = { currentTarget: Element | null };
  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef(null);

  const handleToggle = () => { setOpen((prevOpen) => !prevOpen) };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return <>
    <Box display="flex">
      <ListItem bg={bg ? bg : ''} open={subClientsOpen} nesting={nesting ? nesting : 1} style={{ marginLeft: `${nesting * 7}px` }}>
        <NestingLine nesting={nesting || 0} />
        <div>{user.u.id}</div>
        <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => subClients && setSubClientsOpen(!subClientsOpen)}>
          {user.users}
          <Box style={{ background: subClients && (subClientsOpen ? '#919191' : '#3039ea'), borderRadius: '50%', width: '30px', height: '30px', marginLeft: '20px', color: '#fff' }}>
            {subClients && (subClientsOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
          </Box>
        </div>
        <div>
          <span style={{ opacity: 0.6 }}>{user.u.login}</span>
        </div>
        <div>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Box display="flex">
              <Tooltip title={String(selectedBalance)} placement="top">
                <span style={{ fontSize: 'innerhit', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {selectedBalance}
                </span>
              </Tooltip>
              {selectedBalance !== '-' && <IconButton style={{ fontSize: 'x-small', width: 'max-content', color: '#3039ea' }} size="small" onClick={(e: AnchorElType) => setAnchorEl(e.currentTarget)}> {currency} </IconButton>}
              <Menu id="simple-menu" keepMounted open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} >
                {balance && getUserCurrencyList(balance).map((item: string, id: number) => <MenuItem key={id} onClick={() => { setCurrency(item); setAnchorEl(null) }} > {item} </MenuItem>)}
              </Menu>
            </Box>
            <button className="balance-btn" onClick={() => { setIsPaymentShown(true); setUserId(user.u.id) }} >
              <span>{t('ru.users.fields.balance')}</span>
              <AddCircleIcon style={{ width: '13px', marginLeft: '5px' }} />
            </button>
          </Box>
        </div>
        <div>{user.halls}</div>
        <div>
          {global.window?.innerWidth < 1200 ? <StatusMobile status={user.u.status} /> : <Status active={user.u.status}>
            {user.u.status ? t('ru.common.status.active') : t('ru.common.status.inactive')}
          </Status>}
        </div>
        <div>
          {user.u.created_at && <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <span>{convertUTCDateToLocalDate(user.u.created_at)}</span>
          </Box>}
        </div>
        <div>
          {global.window?.innerWidth < 1200 ?
            <MobileButtons anchorRef={anchorRef} open={open} handleToggle={handleToggle} handleClose={handleClose} handleListKeyDown={handleListKeyDown}
              viewUser={viewUser} updateUser={updateUser} deleteUser={deleteUser} isMe={user.u.id === data?.id} id={user.u.id} status={user.u.status}
              setChange={setChange} setIsConfirm={setIsConfirm} login={user.u.login} setIsReLogin={setIsReLogin} setIsBlock={setIsBlock} /> :
            <Buttons viewUser={viewUser} updateUser={updateUser} deleteUser={deleteUser} isMe={user.u.id === data?.id} id={user.u.id} status={user.u.status}
              setChange={setChange} setIsConfirm={setIsConfirm} login={user.u.login} setIsReLogin={setIsReLogin} setIsBlock={setIsBlock} />}
        </div>
      </ListItem>
    </Box>
    {/* users trees  v*/}
    <Collapse className={classes.subClients} in={subClientsOpen} component="section">
      {[...user.children.values()].map((item: any) => <Item user={user.children.get(item.u.id)} key={item.u.id} bg={'#eee'} balance={item.u.balance} users={users} setUserId={setUserId} setIsPaymentShown={setIsPaymentShown} setIsConfirm={setIsConfirm} setIsBlock={setIsBlock} setIsReLogin={setIsReLogin} nesting={!!nesting ? nesting + 1 : 0} setChange={setChange} viewUser={viewUser} updateUser={updateUser} deleteUser={deleteUser} />)}
    </Collapse>
  </>
};

export default Item;
