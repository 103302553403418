import React from 'react';
import { Box, Modal, Typography } from '@material-ui/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35em',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: '#000',
    textAlign: 'center'
};

interface HotkeyModalProps {
    isOpen: boolean;
    onKeyDown: (e: React.KeyboardEvent) => void;
    showDialog: (v: React.SetStateAction<number>) => void;
};

const HotkeyModal: React.FC<HotkeyModalProps> = ({ isOpen, onKeyDown, showDialog }) => {
    return <Modal open={isOpen} onClose={() => showDialog(0)} onKeyDown={onKeyDown} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h2" component="h1">
                Нажмите нужную клавишу на клавиатуре...
            </Typography>
        </Box>
    </Modal>;
};

export default HotkeyModal;
