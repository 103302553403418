import React, { ReactElement } from 'react'
import { Wrapper, useStyles } from './styles'
import { Box } from '@material-ui/core'
import Preloader from '../../Common/Preloader'

interface WidgetProps {
  title: string
  icon: ReactElement
  value: ReactElement
  loading: boolean
}

const Widget: React.FC<WidgetProps> = ({ title, icon, value, loading }) => {
  const classes = useStyles()
  return (
    <Wrapper>
      <Box
        className={classes.widget}
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span className={classes.label}>{title}</span>
          {icon}
        </Box>
        {loading ? <Preloader absolute={false} /> : value}
      </Box>
    </Wrapper>
  )
}

export default Widget
