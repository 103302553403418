import { createSlice } from '@reduxjs/toolkit'
import { fetchCountryList, addCountry } from '../services/Country'
import { ICountry, ErrorType, CountryData } from '../interfaces'
import { AppThunk } from './store'

interface countryInitialState {
  items: ICountry[]
  loading: boolean
  error: ErrorType[] | null
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: countryInitialState = {
  items: [],
  loading: false,
  error: null,
  form: {
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    countryListIsLoading: (state) => {
      state.loading = true
    },
    countryListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    countryListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    countryItemDelete: (state, { payload }) => {
      const index = state.items.findIndex((i) => i.id === payload)
      state.items = [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1, state.items.length),
      ]
    },
    countryItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getCountryList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(countryListIsLoading())

    return fetchCountryList()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(countryListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(countryListFailure([res.data.data]))
          } else {
            dispatch(countryListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(countryListFailure(err)))
  }
}

export const addCountryItem = (data: CountryData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return addCountry(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(countryItemAdd(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  countryListIsLoading,
  countryListSuccess,
  countryListFailure,
  countryItemAdd,
  countryItemDelete,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  country: countryInitialState
}

export const countrySelector = (state: IState) => state.country

export default slice.reducer
