import React from 'react'
import { Typography, Button, Box } from '@material-ui/core'
import { Wrapper } from './styles'
import Preloader from '../Preloader'
import { useTranslation } from 'react-i18next'

interface ConfirmProps {
  text: string
  loading: boolean
  cancel: () => void
  submit: () => void
}

const Confirm: React.FC<ConfirmProps> = ({ text, loading, cancel, submit }) => {
  const [t] = useTranslation()

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      cancel()
    }
    if (e.code === 'Enter') {
      submit()
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Typography>{text}</Typography>
          <Box>
            <Button onClick={() => cancel()}>{t('ru.common.cancel_btn')}</Button>
            <Button onClick={() => submit()}>{t('ru.common.submit_btn')}</Button>
          </Box>
        </>
      )}
    </Wrapper>
  )
}

export default Confirm
