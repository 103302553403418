import React, { FunctionComponent } from 'react';
import { ListItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

type SortButtonProps = {
    name: string,
    label: string,
    selectedSort: string,
    sortDir: boolean,
    setSelectedSort: (name: string) => void,
    setSortDir: (asc: boolean) => void
};

const SortButton: FunctionComponent<SortButtonProps> = ({ name, label, selectedSort, sortDir, setSelectedSort, setSortDir }) => {
    return <ListItem button component="span" selected={name === selectedSort} style={{ cursor: 'pointer', justifyContent: 'center' }} onClick={() => selectedSort === name ? setSortDir(!sortDir) : setSelectedSort(name)}>
        {label}
        {selectedSort === `${name}` && (sortDir ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
    </ListItem>;
}

export default SortButton;