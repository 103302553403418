import React from 'react'
import TablesList from './List'


const Tables: React.FC = () => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <TablesList height={'55vh'} />
    </div>
  )
}
export default Tables
