import React from 'react'
import { BtnWrapper } from './styles'
interface BurgerIconProps {
    onClick: () => void
}
export const Burger: React.FC<BurgerIconProps> = ({ onClick }) => {
    return (
        <BtnWrapper onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 27" width="3.5vh" height="24">
                <g id="Top Bar">
                    <g id="Nav">
                        <path
                            id="Bg copy 9"
                            className="shp0"
                            d="M0.04 22.05L38.42 22.05L38.42 26.32L0.04 26.32L0.04 22.05Z"
                        />
                        <path
                            id="Bg copy 10"
                            className="shp0"
                            d="M0.04 11.36L27.76 11.36L27.76 15.64L0.04 15.64L0.04 11.36Z"
                        />
                        <path
                            id="Bg copy 11"
                            className="shp0"
                            d="M0.04 0.67L46.95 0.67L46.95 4.95L0.04 4.95L0.04 0.67Z"
                        />
                    </g>
                </g>
            </svg>
        </BtnWrapper>
    )
}

export default Burger
