import React from 'react'
import { Wrapper, useStyles } from './styled'
import UserForm from '../Form'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { UserData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface AddUserProps {
  hideAddUser: () => void
  handlerAddUser: (
    data: UserData,
    assignmentsData:
      | string[]
      | {
        prev: string[]
        new: string[]
      },
    hideForm: () => void,
    id: number | undefined
  ) => void
}

const AddUser: React.FC<AddUserProps> = ({ hideAddUser, handlerAddUser }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const history = useHistory()
  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddUser}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.users.add_form')}</h3>
      <UserForm
        onSubmit={handlerAddUser}
        cancelBtnEvent={hideAddUser}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
        hideForm={(user_id?: number) => {
          hideAddUser()
          history.push(`users?id=${user_id}`)
        }}
      />
    </Wrapper>
  )
}

export default AddUser
