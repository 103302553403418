import React from 'react'
import { useForm } from '../../../../hooks/useForm'

import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Input, Button, InputLabel } from '@material-ui/core'
import { addCountryItem, countrySelector, formIsError } from '../../../../redux/countryReducer'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../../../Common/Preloader'
import { trowErrorMessage, trowError } from '../../../../utils/errors'
import ErrorText from '../../../Common/ErrorText'
import { SubForm } from '../../../Halls/Form/styles'

interface CountryFormProps {
  hideCountry: () => void
}

const CountryForm: React.FC<CountryFormProps> = ({ hideCountry }) => {
  const dispatch = useDispatch()

  const {
    form: { loading, error },
  } = useSelector(countrySelector)

  const { form, handleInputChange } = useForm({
    country: '',
  })

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <SubForm>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h4>Добавить страну</h4>
        <IconButton onClick={hideCountry}>
          <CloseIcon />
        </IconButton>
      </Box>
      {loading ? (
        <Preloader absolute={false} />
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Input
            type="text"
            value={form.country}
            name="country"
            onChange={handleInputChange}
            placeholder="Название"
            fullWidth
            error={!!error && !!trowError(error, 'name')}
          />
          <InputLabel style={{ margin: '1vh', color: '#f44336' }}>
            {!!error && trowErrorMessage(error, 'name')}
          </InputLabel>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              dispatch(
                addCountryItem(
                  {
                    name: form.country,
                  },
                  hideCountry
                )
              )
            }}
          >
            Добавить
            <AddCircleIcon />
          </Button>
        </Box>
      )}
      <ErrorText error={error} />
    </SubForm>
  )
}

export default CountryForm
