import { createSlice } from '@reduxjs/toolkit'
import {
  fetchGamblingTableGamesReport,
  fetchGamblingTableReport,
  fetchGamblingTableReportsList,
  fetchHallGamesReport,
  fetchHallReport,
  fetchHallReportsList,
  fetchUserReport,
} from '../services/Report'
import { ErrorType } from '../interfaces'
import { AppThunk } from './store'
import { distributionOfDepositsAndPayments } from '../services/Halls'

interface reportInitialState {
  data: any
  loading: boolean
  error: ErrorType[]
  reports: {
    info: {
      data: any
      loading: boolean
      error: ErrorType[]
    }
    halls: {
      data: any
      loading: boolean
      error: ErrorType[]
    }
    tables: {
      data: any
      loading: boolean
      error: ErrorType[]
    }
    games: {
      data: any
      loading: boolean
      error: ErrorType[]
    }
    user: {
      data: any
      loading: boolean
      error: ErrorType[]
    }
    distribution: {
      data: any
      loading: boolean
      error: ErrorType[]
    }
  }
}

export const initialState: reportInitialState = {
  data: null,
  loading: false,
  error: [],
  reports: {
    info: {
      data: null,
      loading: false,
      error: [],
    },
    halls: {
      data: null,
      loading: false,
      error: [],
    },
    tables: {
      data: null,
      loading: false,
      error: [],
    },
    games: {
      data: null,
      loading: false,
      error: [],
    },
    user: {
      data: null,
      loading: false,
      error: [],
    },
    distribution: {
      data: null,
      loading: false,
      error: [],
    },
  },
}

export const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    reportsIsLoading: (state) => {
      state.loading = true
    },
    reportsSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.data = payload
    },
    reportsFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    reportsInfoIsLoading: (state) => {
      state.reports.info.loading = true
    },
    reportsInfoSuccess: (state, { payload }) => {
      state.reports.info.loading = false
      state.reports.info.error = []
      state.reports.info.data = payload
    },
    reportsInfoFailure: (state, { payload }) => {
      state.reports.info.loading = false
      state.reports.info.error = payload
    },
    reportsHallsListIsLoading: (state) => {
      state.reports.halls.loading = true
    },
    reportsHallsListSuccess: (state, { payload }) => {
      state.reports.halls.loading = false
      state.reports.halls.error = []
      state.reports.halls.data = payload
    },
    reportsHallsListFailure: (state, { payload }) => {
      state.reports.halls.loading = false
      state.reports.halls.error = payload
    },
    reportsTablesListIsLoading: (state) => {
      state.reports.tables.loading = true
    },
    reportsTablesListSuccess: (state, { payload }) => {
      state.reports.tables.loading = false
      state.reports.tables.error = []
      state.reports.tables.data = payload
    },
    reportsTablesListFailure: (state, { payload }) => {
      state.reports.tables.loading = false
      state.reports.tables.error = payload
    },
    reportsGamesListIsLoading: (state) => {
      state.reports.games.loading = true
    },
    reportsGamesListSuccess: (state, { payload }) => {
      const getTransformData = () => {
        let data: any = []
        for (let i in payload.result) {
          let payout = Number(
            ((payload.result[i].bet - payload.result[i].win) / payload.result[i].bet) * 100
          )
          data = [
            ...data,
            {
              ...payload.result[i],
              ggr: Number(payload.result[i].bet) - Number(payload.result[i].win),
              payout: !!payout ? payout : 0,
            },
          ]
        }
        return data
      }

      state.reports.games.loading = false
      state.reports.games.error = []
      state.reports.games.data = getTransformData()
    },
    reportsGamesListFailure: (state, { payload }) => {
      state.reports.games.loading = false
      state.reports.games.error = payload
    },
    reportsUserIsLoading: (state) => {
      state.reports.user.loading = true
    },
    reportsUserSuccess: (state, { payload }) => {
      state.reports.user.loading = false
      state.reports.user.error = []
      state.reports.user.data = payload
    },
    reportsUserFailure: (state, { payload }) => {
      state.reports.user.loading = false
      state.reports.user.error = payload
    },
    reportsDistributionIsLoading: (state) => {
      state.reports.distribution.loading = true
    },
    reportsDistributionSuccess: (state, { payload }) => {
      state.reports.distribution.loading = false
      state.reports.distribution.error = []
      state.reports.distribution.data = payload
    },
    reportsDistributionFailure: (state, { payload }) => {
      state.reports.distribution.loading = false
      state.reports.distribution.error = payload
    },
  },
})

export const getInfoReports = (
  component: string,
  elements: any,
  from: string,
  to: string
): AppThunk => {
  const fetch = async (component: string) => {
    switch (component) {
      case 'user':
        return fetchHallReportsList(elements, from, to)
      case 'hall':
        return fetchHallReport(elements[0], from, to)
      case 'table':
        return fetchGamblingTableReportsList(elements, from, to)
      default:
        console.error('component name undefined')
    }
  }
  return async (dispatch) => {
    dispatch(reportsInfoIsLoading())
    return (
      !!component &&
      fetch(component)
        .then((res) => {
          if (res!.data.code === 200) {
            dispatch(reportsInfoSuccess(res!.data.data))
          } else {
            if (typeof res!.data.data.length !== 'number') {
              dispatch(reportsInfoFailure([res!.data.data]))
            } else {
              dispatch(reportsInfoFailure(res!.data.data))
            }
          }
        })
        .catch((err) => dispatch(reportsFailure(err)))
    )
  }
}

export const getHallReports = (id: number, from: string, to: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsIsLoading())

    return fetchHallReport(id, from, to)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(reportsSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsFailure([res.data.data]))
          } else {
            dispatch(reportsFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsFailure(err)))
  }
}

export const getTableReports = (id: number, from: string, to: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsIsLoading())

    return fetchGamblingTableReport(id, from, to)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(reportsSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsFailure([res.data.data]))
          } else {
            dispatch(reportsFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsFailure(err)))
  }
}

export const getHallReportsList = (halls: number[], from: string, to: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsHallsListIsLoading())
    return fetchHallReportsList(halls, from, to)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(reportsHallsListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsHallsListFailure([res.data.data]))
          } else {
            dispatch(reportsHallsListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsFailure(err)))
  }
}

export const getTableReportsList = (tables: number[], from: string, to: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsTablesListIsLoading())
    return fetchGamblingTableReportsList(tables, from, to)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(reportsTablesListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsTablesListFailure([res.data.data]))
          } else {
            dispatch(reportsTablesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsTablesListFailure(err)))
  }
}

export const getTableGameReportsList = (games: number, from: string, to: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsGamesListIsLoading())
    return fetchGamblingTableGamesReport(games, from, to)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(reportsGamesListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsGamesListFailure([res.data.data]))
          } else {
            dispatch(reportsGamesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsTablesListFailure(err)))
  }
}

export const getHallGameReportsList = (games: any, from: string, to: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsGamesListIsLoading())
    return fetchHallGamesReport(games, from, to)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(reportsGamesListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsGamesListFailure([res.data.data]))
          } else {
            dispatch(reportsGamesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsTablesListFailure(err)))
  }
}

export const getUserReport = (from: string, to: string): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsUserIsLoading())
    return fetchUserReport(from, to)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data.data.result)
          dispatch(reportsUserSuccess(res.data.data.result))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsUserFailure([res.data.data]))
          } else {
            dispatch(reportsUserFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsUserFailure(err)))
  }
}
export const getDistributionReport = (hall_id: number, table_id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(reportsDistributionIsLoading())
    return distributionOfDepositsAndPayments(hall_id, table_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(reportsDistributionSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(reportsDistributionFailure([res.data.data]))
          } else {
            dispatch(reportsDistributionFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(reportsDistributionFailure(err)))
  }
}

export const {
  reportsIsLoading,
  reportsSuccess,
  reportsFailure,
  reportsInfoIsLoading,
  reportsInfoSuccess,
  reportsInfoFailure,
  reportsHallsListIsLoading,
  reportsHallsListSuccess,
  reportsHallsListFailure,
  reportsTablesListIsLoading,
  reportsTablesListSuccess,
  reportsTablesListFailure,
  reportsGamesListIsLoading,
  reportsGamesListSuccess,
  reportsGamesListFailure,
  reportsUserIsLoading,
  reportsUserSuccess,
  reportsUserFailure,
  reportsDistributionIsLoading,
  reportsDistributionSuccess,
  reportsDistributionFailure,
} = slice.actions

interface IState {
  report: reportInitialState
}
export const reportSelector = (state: IState) => state.report

export default slice.reducer
