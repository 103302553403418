import { IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import { JackpotData } from '../../../interfaces'
import JackpotForm from '../Form'
import CloseIcon from '@material-ui/icons/Close'
import { Wrapper } from './styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddJackpotProps {
  hall_id?: null | number
  table_id?: null | number
  hideAddJackpot: () => void
  addJackpotItem: (data: JackpotData, hideForm: () => void) => void
}

const AddJackpot: React.FC<AddJackpotProps> = ({
  hideAddJackpot,
  addJackpotItem,
  hall_id,
  table_id,
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddJackpot}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.jackpots.add_form')}</h3>
      <JackpotForm
        onSubmit={addJackpotItem}
        cancelBtnEvent={hideAddJackpot}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
        hideForm={hideAddJackpot}
        hall_id={hall_id}
        table_id={table_id}
      />
    </Wrapper>
  )
}

export default AddJackpot
