import { Table, TableBody, TableRow } from '@material-ui/core';
import React from 'react';

type PokerCombinationProps = {
  variables: any;
};

type Card = {
  type: number;
  id: number;
  win?: boolean;
  hold?: boolean;
};

function cardToString(number: number): string {
  const suit = ["♣", "♦", "♥", "♠", " "];
  const face = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A", "&#x1F0CF"];
  return `${face[number < 52 ? number % 13 : 13].padStart(2)}${suit[Math.floor(number / 13)]}`;
}

const PokerCombination: React.FC<PokerCombinationProps> = React.memo(({ variables }) => {
  return <Table style={{width: "100%", textAlign: "center", fontSize: "2em"}}>
    <TableBody>
      <TableRow style={{height: "3em", verticalAlign: "middle"}}>
        {variables.cards.map((card: Card) => <td style={{backgroundColor: card.win ? "yellow" : (card.hold ? "lightgreen" : "white")}} dangerouslySetInnerHTML={{ __html: cardToString(card.type - 1) }}></td>)}
      </TableRow>
      {variables?.bonus_win > 0 && <TableRow style={{height: "3em", verticalAlign: "middle"}}>
        <td colSpan={3}>minibonus</td>
        <td colSpan={2}>+{variables.bonus_win}</td>
      </TableRow>}
    </TableBody>
  </Table>;
});

export default PokerCombination;
