import React from 'react'
import { Wrapper, TableHeader } from './styles'
import Item from './Item'
import Preloader from '../../Common/Preloader'

import Button from '@material-ui/core/Button'
import { ListItem, Box, Input, makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import {
  defaultPackageSelector,
  deleteDefaultPackageItem,
  handlerAddDefaultPackage,
} from '../../../redux/defaultPackageReducer'
import Overlay from '../../Common/Overlay/index'
import Confirm from '../../Common/Confirm'
import { DefaultPackageData, IDefaultPackage, isConfirmType } from '../../../interfaces'
import { useShowBlock } from '../../../hooks/useShowBlock'
import AddDefaultPackage from '../Add'
import DefaultPackage from '../View'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none',
    },
  },
}))

const DefaultPackagesList: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })
  const [isChangeMode, setIsChangeMode] = React.useState(false)

  const {
    defaultPackages: { items: defaultPackages, loading },
    form: formLoading,
  } = useSelector(defaultPackageSelector)

  const [defaultPackageId, setDefaultPackageId] = React.useState<null | number>(null)

  const {
    showBlock: showDefaultPackage,
    // isBlockShown: isDefaultPackage,
    hideBlock: hideDefaultPackage,
  } = useShowBlock()

  const {
    showBlock: showChangeDefaultPackage,
    // isBlockShown: isChangeDefaultPackage,
    // hideBlock: hideChangeDefaultPackage,
  } = useShowBlock()

  const {
    showBlock: showAddDefaultPackage,
    isBlockShown: isAddDefaultPackage,
    hideBlock: hideAddDefaultPackage,
  } = useShowBlock()

  const [search, setSearch] = React.useState<any>(null)
  const filteredDefaultPackage = () => {
    if (search) {
      return defaultPackages.filter((defaultPackage) => {
        const idFilter = search?.id ? defaultPackage.id === Number(search?.id) : true
        return idFilter
      })
    } else {
      return defaultPackages
    }
  }

  const location = useLocation()
  const id = new URLSearchParams(location.search).get('default_package_id')
  React.useEffect(() => {
    if (id) {
      setDefaultPackageId(Number(id))
    } else {
      setDefaultPackageId(null)
    }
  }, [id])

  return (
    <Wrapper>
      <Box component="div" style={{ minWidth: '550px' }}>
        <Box display="flex" justifyContent="space-between">
          <h2>{t('ru.default_packages.title')}</h2>
          <Button onClick={showAddDefaultPackage} variant="contained">
          {t('ru.common.add_btn')}
          </Button>
        </Box>
        <div>
          <TableHeader>
            <ListItem component="span">id</ListItem>
            <ListItem component="span">{t('ru.default_packages.fields.package')}</ListItem>
            <ListItem component="span">{t('ru.default_packages.fields.games')}</ListItem>
            <ListItem component="span"></ListItem>
          </TableHeader>
          <Box style={{ background: '#fff', padding: '5px 0' }}>
            <TableHeader>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      id: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem component="span">

              </ListItem>
            </TableHeader>
          </Box>
          <ul>
            {!!loading ? (
              <Preloader absolute={false} />
            ) : (

              filteredDefaultPackage().map((defaultPackage) => {
                return (
                  <Item
                    key={defaultPackage.id}
                    id={defaultPackage.id}
                    packages={defaultPackage.package}
                    games={defaultPackage.games}
                    setDefaultPackageId={setDefaultPackageId}
                    showDefaultPackage={showDefaultPackage}
                    showChangeDefaultPackage={showChangeDefaultPackage}
                    setIsConfirm={setIsConfirm}
                    setIsChangeMode={setIsChangeMode}
                  />
                )
              })
            )}
          </ul>
        </div>
      </Box>
      {!!isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.id}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              dispatch(deleteDefaultPackageItem(isConfirm?.id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
      {!!isAddDefaultPackage && (
        <Overlay>
          <AddDefaultPackage
            hideAddDefaultPackage={hideAddDefaultPackage}
            handlerAddDefaultPackage={(data: DefaultPackageData, hideForm: () => void) =>
              dispatch(handlerAddDefaultPackage(data, hideForm))
            }
          />
        </Overlay>
      )}
      {/* {isChangeDefaultPackage && (
        <Overlay>
          <ChangeDefaultPackage
            hideChangeDefaultPackage={hideChangeDefaultPackage}
            defaultPackage={defaultPackages?.find(
              (i: IDefaultPackage) => i.id === defaultPackageId
            )}
            handlerChangeDefaultPackage={(
              data: DefaultPackageData,
              hideForm: () => void,
              id: number | undefined
            ) => dispatch(handlerChangeDefaultPackage(data, hideForm, id))}
          />
        </Overlay>
      )} */}
      {!!id && !!defaultPackageId && (
        <Overlay>
          <DefaultPackage
            hideDefaultPackage={hideDefaultPackage}
            defaultPackage={defaultPackages?.find(
              (i: IDefaultPackage) => i.id === defaultPackageId
            )}
            isChangeMode={isChangeMode}
            setIsChangeMode={setIsChangeMode}
          />
        </Overlay>
      )}
    </Wrapper>
  )
}

export default DefaultPackagesList
