import React from 'react'

import { useForm } from '../../../hooks/useForm'

import { Box, Button, Grid, TextField, makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { profileSelector, formIsError } from '../../../redux/profileReducer'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import Preloader from '../../Common/Preloader'
import { ProfileData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  textFields: {
    width: '100%',
  },
  submitBtn: {
    margin: '1vh',
  },
}))

interface ProfileFormProps {
  cancelBtnText: string
  cancelBtnEvent: () => void
  submitBtnText: string
  onSubmit: (data: ProfileData, hideForm: () => void, id?: number | undefined) => void
  id?: number
  name?: string
  patronymic?: string
  surname?: string
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  cancelBtnText,
  cancelBtnEvent,
  submitBtnText,
  onSubmit,
  id,
  name = '',
  patronymic = '',
  surname = '',
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading },
  } = useSelector(profileSelector)

  const { form, handleInputChange } = useForm({
    name: name,
    patronymic: patronymic,
    surname: surname,
  })

  const data: ProfileData = {
    name: form.name,
    patronymic: form.patronymic,
    surname: form.surname,
  }

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      cancelBtnEvent()
    }
    if (e.code === 'Enter') {
      onSubmit(data, cancelBtnEvent, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  return loading ? (
    <Preloader absolute={false} />
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(data, cancelBtnEvent, id)
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            value={form.name}
            name={'name'}
            label={t('ru.profiles.fields.first_name')}
            onChange={handleInputChange}
            size="small"
            className={classes.textFields}
            error={!!trowError(error, 'name')}
            helperText={trowErrorMessage(error, 'name')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={form.surname}
            name="surname"
            label={t('ru.profiles.fields.sure_name')}
            onChange={handleInputChange}
            size="small"
            className={classes.textFields}
            error={!!trowError(error, 'surname')}
            helperText={trowErrorMessage(error, 'surname')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            value={form.patronymic}
            name="patronymic"
            label={t('ru.profiles.fields.last_name')}
            onChange={handleInputChange}
            size="small"
            className={classes.textFields}
            error={!!trowError(error, 'patronymic')}
            helperText={trowErrorMessage(error, 'patronymic')}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              className={classes.submitBtn}
              onClick={cancelBtnEvent}
              variant="contained"
            >
              {cancelBtnText}
            </Button>
            <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default ProfileForm
