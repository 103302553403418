import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { useTranslation } from 'react-i18next';

type BindBtnProps = {
    id: number,
    playerConnection: any,
    showBind: () => void,
    setTableId: (id: number) => void
};

function cmp(prevProps: BindBtnProps, nextProps: BindBtnProps) {
    const isPlayerConnection = (prevProps.playerConnection === null && nextProps.playerConnection === null) || (prevProps.playerConnection !== null && nextProps.playerConnection !== null && prevProps.playerConnection.player_id === nextProps.playerConnection.player_id);
    return prevProps.id === nextProps.id && isPlayerConnection;
};

const BindBtn: FunctionComponent<BindBtnProps> = React.memo(({ id, playerConnection, showBind, setTableId }) => {
    const [t] = useTranslation();
    return <Tooltip title={String(t('Привязка'))} placement="top">
        <Action bg={'#919191'} onClick={() => { showBind(); setTableId(id); }} >
            <IconButton>
                {playerConnection ? <LinkOffIcon fontSize="small" style={{ color: '#fff' }} /> : <InsertLinkIcon fontSize="small" style={{ color: '#fff' }} />} {' '}
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default BindBtn;