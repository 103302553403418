import React from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import { ListItem, IconButton, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Preloader from '../../Common/Preloader'
import { ITransfer } from '../../../interfaces'
import { Pagination } from '@material-ui/lab'
import {
  handlerHallTransfers,
  handlerTableTransfers,
  handlerUserTransfers,
  transfersSelector,
} from '../../../redux/transfersReducer'
import { useDispatch, useSelector } from 'react-redux'
import { hallSelector } from '../../../redux/hallReducer'
import { userSelector } from '../../../redux/userReducer'
import { tableSelector } from '../../../redux/tableReducer'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EnhanceLink from '../../Common/EnhanceLink'

interface OperationsListProps {
  hideOperations: () => void
  transfers: ITransfer[]
  loading: boolean
  user_id: number | undefined
  hall_id: number | undefined
  table_id: number | undefined
}

const OperationsList: React.FC<OperationsListProps> = ({
  hideOperations,
  transfers,
  loading,
  user_id,
  hall_id,
  table_id,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [t] = useTranslation()

  const { pageCount } = useSelector(transfersSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const {
    users: { data: users },
  } = useSelector(userSelector)
  const {
    tables: { data: tables },
  } = useSelector(tableSelector)

  const typeStyle: any = {
    textAlign: '-webkit-center',
  }

  const getType = (type: string, id: number) => {
    const getItem = (arr: any, id: number) => {
      const item = arr.find((i: any) => i.id === id)
      if (!!item) {
        return !!item.name ? item.name : item.login
      } else {
        return !!id ? id : ''
      }
    }
    if (hall_id) {
      switch (type) {
        case 'hall_put':
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.hall.hall_put')}
              <EnhanceLink pathname={`/users`} search={`?id=${id}`}>
                {getItem(users, id)}
              </EnhanceLink>
            </span>
          )
        case 'hall_take':
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.hall.hall_take')}
              <EnhanceLink pathname={`/users`} search={`?id=${id}`}>
                {getItem(users, id)}
              </EnhanceLink>
            </span>
          )
        case `gambling_table_put`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.hall.gambling_table_put')}
              <EnhanceLink pathname={`/tables`} search={`?table_id=${id}`}>
                {getItem(tables, id)}
              </EnhanceLink>
            </span>
          )
        case `gambling_table_take`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.hall.gambling_table_take')}
              <EnhanceLink pathname={`/tables`} search={`?table_id=${id}`}>
                {getItem(tables, id)}
              </EnhanceLink>
            </span>
          )
        case `highscore_put`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.hall.highscore_put')}
              <EnhanceLink pathname={`/tables`} search={`?table_id=${id}`}>
                {getItem(tables, id)}
              </EnhanceLink>
            </span>
          )
        case `highscore_take`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.hall.highscore_take')}
              <EnhanceLink pathname={`/tables`} search={`?table_id=${id}`}>
                {getItem(tables, id)}
              </EnhanceLink>
            </span>
          )
        case `jackpot_init`:
          return <span style={typeStyle}>
            {t('Создание джекпота')}
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
              {getItem(halls, id)}
            </EnhanceLink>
          </span>
        case `jackpot_deleted`:
          return <span style={typeStyle}>
            {t('Удаление джекпота')}
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
              {getItem(halls, id)}
            </EnhanceLink>
          </span>
        default:
          return type
      }
    } else if (user_id) {
      switch (type) {
        case 'admin_put':
          return t('ru.operations.types.user.admin_put')
        case `user_transfer`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.user.user_transfer')}
              <EnhanceLink pathname={`/users`} search={`?id=${id}`}>
                {getItem(users, id)}
              </EnhanceLink>
            </span>
          )
        case `hall_put`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.user.hall_put')}
              <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
                {getItem(halls, id)}
              </EnhanceLink>
            </span>
          )
        case `hall_take`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.user.hall_take')}
              <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
                {getItem(halls, id)}
              </EnhanceLink>
            </span>
          )
        case `jackpot_init`:
          return (
            <span style={typeStyle}>
              {t('Создание джекпота')}
              <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
                {getItem(halls, id)}
              </EnhanceLink>
            </span>
          )
        case `jackpot_deleted`:
          return <span style={typeStyle}>
            {t('Удаление джекпота')}
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
              {getItem(halls, id)}
            </EnhanceLink>
          </span>
        default:
          return type
      }
    } else if (table_id) {
      switch (type) {
        case `gambling_table_put`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.table.gambling_table_put')}
              <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
                {getItem(halls, id)}
              </EnhanceLink>
            </span>
          )
        case `gambling_table_take`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.table.gambling_table_take')}
              <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
                {getItem(halls, id)}
              </EnhanceLink>
            </span>
          )
        case `hall_put`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.table.hall_put')}
              <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
                {getItem(halls, id)}
              </EnhanceLink>
            </span>
          )
        case `hall_take`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.table.hall_take')}
              <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
                {getItem(halls, id)}
              </EnhanceLink>
            </span>
          )
        case `highscore_put`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.table.highscore_put')}
              <EnhanceLink pathname={`/tables`} search={`?table_id=${id}`}>
                {getItem(tables, id)}
              </EnhanceLink>
            </span>
          )

        case `highscore_save`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.table.highscore_save')}
            </span>
          )
        case `highscore_take`:
          return (
            <span style={typeStyle}>
              {t('ru.operations.types.table.highscore_take')}
              <EnhanceLink pathname={`/tables`} search={`?table_id=${id}`}>
                {getItem(tables, id)}
              </EnhanceLink>
            </span>
          )
        case 'session_put':
          return <span style={typeStyle}> {t('ru.operations.types.table.session_put')} </span>;
        case 'session_take':
          return <span style={typeStyle}> {t('ru.operations.types.table.session_take')} </span>;
        case `jackpot_init`:
          return (
            <span style={typeStyle}>
              {t('Создание джекпота')}
              <EnhanceLink pathname={`/tables`} search={`?table_id=${id}`}>
                {getItem(tables, id)}
              </EnhanceLink>
            </span>
          )
        case `jackpot_deleted`:
          return <span style={typeStyle}>
            {t('Удаление джекпота')}
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${id}`}>
              {getItem(halls, id)}
            </EnhanceLink>
          </span>
        default:
          return type
      }
    }
  }

  const query = new URLSearchParams(location.search)
  const operations_page = Number(query.get('operations_page'))
  const [page, setPage] = React.useState(operations_page ? operations_page : 1)
  React.useEffect(() => {
    user_id && dispatch(handlerUserTransfers(user_id, page, 20))
    hall_id && dispatch(handlerHallTransfers(hall_id, page, 20))
    table_id && dispatch(handlerTableTransfers(table_id, page, 20))
    // eslint-disable-next-line
  }, [page])

  React.useEffect(() => {
    setPage(Number(operations_page))
    // eslint-disable-next-line
  }, [operations_page])

  return (
    <>
      <Wrapper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <h3>{t('ru.operations.title')}</h3>
          <IconButton onClick={hideOperations}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div style={{ overflow: 'auto' }}>
          <div style={{ minWidth: '765px' }}>
            <TableHeader>
              <ListItem component="span">#</ListItem>
              {/* <ListItem component="span">Пополнение</ListItem> */}
              {/* <ListItem component="span">Снятие</ListItem> */}
              <ListItem component="span">{t('ru.operations.fields.benefit')}</ListItem>
              <ListItem component="span">{t('ru.operations.fields.balance_start')}</ListItem>
              <ListItem component="span">{t('ru.operations.fields.balance_end')}</ListItem>
              <ListItem component="span">{t('ru.operations.fields.operation')}</ListItem>
              <ListItem component="span">{t('ru.operations.fields.created_at')}</ListItem>
            </TableHeader>
            <ul className="operations">
              {loading ? (
                <Preloader absolute={false} />
              ) : transfers.length ? (
                transfers.map((item, id) => {
                  return (
                    <Item
                      key={id}
                      index={id}
                      id={item.id}
                      credit={item.credit}
                      debit={item.debit}
                      before={item.balance_start}
                      after={item.balance_end}
                      type={getType(item.type, Number(item.target))}
                      created_at={item.created_at}
                    />
                  )
                })
              ) : (
                t('ru.common.is_absent')
              )}
            </ul>
          </div>
        </div>
      </Wrapper>
      <Box display="flex" justifyContent="center" style={{ margin: '5px 0' }}>
        {pageCount && (
          <Pagination
            variant="outlined"
            shape="rounded"
            count={pageCount}
            page={page}
            siblingCount={0}
            onChange={(e, value) => {
              query.set('operations_page', String(value))
              history.push({
                pathname: location.pathname,
                search: query.toString(),
              })
              // !loading && setPage(value)
            }}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" style={{ margin: '5px 0', fontSize: '85%' }}>
        <span>
          Зеленым цветом выделены операции пополнения, красным - изъятия, а серым не изменившие
          баланс
        </span>
      </Box>
    </>
  )
}

export default OperationsList
