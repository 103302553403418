import React from 'react'
import { Icon } from './styles'

export const Business = () => {
    return (
        <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" width="45" height="45">
                <defs>
                    <image
                        width="45"
                        height="45"
                        id="img2"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAE4ElEQVRYhc2ZaWxWRRSGH1qhaiRxQdq6IEpwITSiVFQUDKAGEWhFRUUDGpsmGhPBhaBRcYkrAY0kIGr8YUwIVAkISJSCigb/qREQRFA2o0YNWAzWuFx/nDPOcDv3ztzvE9o3Oflu57x37jv7mVMI4zLgeWAxcD/QL4dbDzwJtACPAufmcM8CHlDuLGBYhJYovAskHpvp4b6WwX3Zw308g/t2uYI/1orWAGOAIUATcEDL73G4r2vZRuA64ALgRmC7ls93uA9p2c/AbcodD6zT8vdKFTxRK1ju8VUDbeoHOMURnEYl8JX6q7UsAfYBPT38teqfUIpoMy1Oy/BPV/9A4FZ9vjKDe5X6JwGD6DhKLk5X/4pSRH+N7UkfLlf/9djhzmqgETIDuEGfR2RwK9X/ZXHJtqf7ZPh9PX1FBtf09M3Ynp6WwTUNXFmK6GvIntO9gb3Ab/r3Scrd4OF2A7aov7eWHQB+AY728N9X7rhSRANs1QrWAlcDFwLN2EU4x+GaBbQRWcRDkDm8jY6Nn6dlPwK3K7cR+EjLPytV8Iv491HX2pCD56YI7t/IfB6F3TLzzO2QKEzVF7ciJ1o98DRyeEwFzsFuiW4DhgN1wCPKfRCZ8yOAP1L8a4EBwL3KfQIYDJwP7FLOLbGCK4G/gD/xzzkXZidIkCM5D4McbkOAezwyMr8DFQEuAH0pdiolwJ5I7g/kb6MuzII8NYZ8tpLfjOBWYKdRDL4hXvRS5faPIfcnPnCpUu72SCE7ld89gvuOcs+IqdiIXhrBNafXtpiKgR3K7xbBXUEB0QOU3BIpJEGO/Bh8S/HpEVrgAJyAXeWtwGr9Tdtq7GFgwtc87hqHuy7AbXW4x0Y2kiZgv/Ninn2KPTlDtgX4PJLbhmyphVAF1AI1GVaLjY/J4blmUB2ouwbokdITtV93FcxEgqsEWI9EjF0aS7DTZZfzPLYzReWhBRH4AXYxjgL+AX4tp+JeyHDdhQQ+U5Aws6qcSpGDzQhOw4S6hXEm8Ab5q38DkieZC8xG8iauvaA2nYOP6uXYbTGNSyl2Av+HaY6wn4BnkFRBA3AHsAh7SShio5E0Q4Ls1Wn0QSK/BBnNTPRDYmeDufpSO+FYtwaJxS8GLvHYUP1tTolf5anrCOB79TfnfbSvU1Ed0osmojsxILgo5pAfDpskzqy8Smqxe2P69Ct3kfmwUOs/2eMzmavcULknMlcTYDKSgDGiY68/FwEj9bkCuYY1Ijds18YjayJBIsA0Hlbf5pBgs6U85ZSbdEI7smBCMI0Em8fIs/3I5cPFBPXtRa5fXvRAWpQAz3n8Dc5HQidSI5JYBDgSCcDuy7DJSGTpYrDzrYFZH+mO5BoS4JUcMWOcyiYGhJeKXsiIBjtnk5Kejah0JFb4pDIFpjEUu7XNCJG/UOL8EFExHCu8CdmPxwJ3Ig1fgCTSFwTsJWRk3wJ2O3W66ykXq/SFDyP59YQXWFFbSdxCPwgmFFwPHBXBr8Nuj5uRgGk0kiobhoxInhneecAxRcW6MJv5JvyZep9w01Mxt+xDhldVxE7guADXxL1LKLO3/g/MRsTswX+8gkRjCbDscImKwWOIqH10TJqYuLfsf6EdCpjzvx3b48u0rLWzRMXgbkTkd8htJEESNV0ebrD+SSdrKYQp5Mcmhw3/AtaQ+11QeEI4AAAAAElFTkSuQmCC"
                    />
                </defs>
                <g id="Left Menu">
                    <g id="Menu">
                        <use id="business" href="#img2" transform="matrix(1,0,0,1,0,0)" />
                    </g>
                </g>
            </svg>
        </Icon>
    )
}

export default Business
