import React from 'react';
import { Table, TableHead, TableRow, withStyles, TableCell, makeStyles, TableBody, TableFooter, Box, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { prettyNum } from '../../utils/prettyNum';
import { IJackpot } from '../../interfaces';
import visionPng from '../../assets/images/vision.png';
import trashPng from '../../assets/images/trash.png';
import { Action } from '../Users/List/styles';

interface CurrentTableProps {
    jackpots: IJackpot[],
    hall_id: number,
    setJackpotId: (id: number) => void
    deleteJackpot: (id: number, name: string) => void
}

const useStyles = makeStyles({
    list: {
        overflow: 'auto',
        height: '10vh',
        width: '100%',
        scrollbarWidth: 'none',
        '& ::-webkit-scrollbar': {
            width: '0 !important',
        },
    },
    container: {
        maxHeight: 320,
    },
    item: {
        width: '100%',
        padding: '1vh 1vh',
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 0,
        },
        fontSize: '90%',
    },
    span: {
        color: '#555',
        minHeight: '100%',
        background: '#eee',
        padding: '0 3%',
    },
    reload: {
        color: 'white',
        background: '#a9c477',
        borderRadius: 0,
        width: 'max-content',
    }
});

const StyledTableCell = withStyles(() => ({
    head: {
        fontSize: '75%',
        lineHeight: 1.6,
        padding: '10px',
    },
    body: {
        fontSize: '75%',
        lineHeight: 1.6,
        padding: '5px',
        textAlign: 'center',
    },
}))(TableCell);

function statusConvert(status: number): string {
    return 'ru.jackpots.selectors.' + ['active', 'accumulation', 'inactive', 'disabled'][status];
}

function typeConvert(type: number): string {
    return 'ru.jackpots.selectors.' + ['bronze', 'silver', 'gold', 'diamond'][type];
}

function cmp(prev: CurrentTableProps, next: CurrentTableProps) {
    return prev.hall_id === next.hall_id;
}

const CurrentTable: React.FC<CurrentTableProps> = React.memo(({ jackpots, setJackpotId, deleteJackpot }) => {
    const [t] = useTranslation();
    const classes = useStyles();
    //Jackpot::STATUS_ACTIVE, Jackpot::STATUS_ACCUMULATION_ONLY, Jackpot::STATUS_PAUSED, Jackpot::STATUS_COMPLETED
    return <Table size="small" stickyHeader>
        <TableHead>
            <TableRow>
                <StyledTableCell align="center">#</StyledTableCell>
                <StyledTableCell align="center">{t('ru.jackpots.fields.type')}</StyledTableCell>
                <StyledTableCell align="center">{t('ru.jackpots.fields.status')}</StyledTableCell>
                <StyledTableCell align="center">{t('ru.jackpots.fields.balance')}</StyledTableCell>
                <StyledTableCell align="right">Операции</StyledTableCell>
            </TableRow>
        </TableHead>
        {jackpots.length ? <TableBody className={classes.list}>
            {jackpots.map(j =>
                <TableRow key={j.id}>
                    <StyledTableCell align="center">{j.id}</StyledTableCell>
                    <StyledTableCell align="center">{t(typeConvert(j.type))}</StyledTableCell>
                    <StyledTableCell align="center">{t(statusConvert(j.status))}</StyledTableCell>
                    <StyledTableCell align="center">{prettyNum(j.balance)}</StyledTableCell>
                    <StyledTableCell align="right">
                        <Box display="flex" justifyContent="flex-end">
                            <Action bg={'#7467ec'} onClick={() => setJackpotId(j.id)} >
                                <IconButton>
                                    <img src={visionPng} alt="icon" style={{ width: '30%' }} />
                                </IconButton>
                            </Action>
                            <Action bg={'#919191'} onClick={() => deleteJackpot(j.id, 'джекпот #' + j.id)} >
                                <IconButton>
                                    <img src={trashPng} alt="icon" style={{ width: '35%' }} />
                                </IconButton>
                            </Action>
                        </Box>
                    </StyledTableCell>
                </TableRow>)}
        </TableBody> : <TableFooter>
            <TableRow>
                <StyledTableCell align="center" colSpan={5}>Нет джекпотов</StyledTableCell>
            </TableRow>
        </TableFooter>}
    </Table>;
}, cmp);

export default CurrentTable;