import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';

type FieldCashbackProps = {
    error: boolean;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldCashbackProps, cur: FieldCashbackProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldCashback: FunctionComponent<FieldCashbackProps> = React.memo(({ error, onChange, value, formHelperText }) => {
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <NativeSelect onChange={onChange} value={value}> {[0, 10, 20, 30, 40, 50].map((item) => <option key={item} value={item}> {item} </option>)} </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldCashback;