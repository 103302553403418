import axios from 'axios'
import { JackpotData } from '../interfaces';

interface AssocArray {
  [key: string]: number;
}

export const fetchJackpotsList = async (page: number, limit: number, status: number[] = [], halls: number[] = [], tables: number[] = []) => {
  let req: AssocArray = { page: page, 'per-page': limit };
  status.forEach((e, i) => req[`f[status][${i}]`] = e);
  halls.forEach((e, i) => req[`f[halls][${i}]`] = e);
  tables.forEach((e, i) => req[`f[tables][${i}]`] = e);
  return axios.get(`jackpot`, { params: req });
}

export const addJackpot = async (data: JackpotData) => {
  return axios.post(`jackpot`, data)
}

export const changeJackpot = async (data: JackpotData, id: number) => {
  return axios.put(`jackpot/${id}`, data)
}

export const deleteJackpot = async (id: number) => {
  return axios.delete(`jackpot/${id}`)
}

export const fetchJackpot = async (id: number) => {
  return axios.get(`jackpot/${id}`, {
    params: { expand: 'halls,gambling_tables' },
  })
}
