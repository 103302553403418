import { createSlice } from '@reduxjs/toolkit'
import {
  fetchGamePackageList,
  addGamePackage,
  deleteGamePackage,
  deleteGamePackageImage,
  addGamePackageImage,
  changeGamePackage,
} from '../services/GamePackage'
import { changeArr } from '../utils/changeArr'
import { IPackage, ErrorType, PackageData, isConfirmType, ImagesData } from '../interfaces'
import { AppThunk } from './store'
interface packageInitialState {
  items: IPackage | null
  loading: boolean
  error: ErrorType[] | null
  packages: {
    items: IPackage[]
    loading: boolean
    error: ErrorType[] | null
  }
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
  imageUpload: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: packageInitialState = {
  items: null,
  loading: false,
  error: null,
  packages: {
    items: [],
    loading: false,
    error: null,
  },
  form: {
    loading: false,
    error: null,
  },
  imageUpload: {
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    packageListIsLoading: (state) => {
      state.packages.loading = true
    },
    packageListSuccess: (state, { payload }) => {
      state.packages.loading = false
      state.packages.error = null
      state.packages.items = payload
    },
    packageListFailure: (state, { payload }) => {
      state.packages.loading = false
      state.packages.error = payload
    },
    packageItemDelete: (state, { payload }) => {
      state.packages.items = [...state.packages.items.filter((i) => i.id !== payload)]
    },
    packageItemChange: (state, { payload }) => {
      state.packages.items = [...changeArr(state.packages.items, payload.id, payload)]
    },
    packageItemAdd: (state, { payload }) => {
      state.packages.items = [...state.packages.items, payload]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
    packageAddImage: (state, { payload }) => {
      const itemId = state.packages.items.findIndex((i) => i.id === payload.id)
      state.packages.items = [
        ...state.packages.items.slice(0, itemId),
        {
          ...state.packages.items.find((i) => i.id === payload.id),
          images: [payload.images],
        },
        ...state.packages.items.slice(itemId + 1, state.packages.items.length),
      ] as IPackage[]
    },
    packageDeleteImage: (state, { payload }) => {
      const itemId = state.packages.items.findIndex((i) => i.id === payload)
      state.packages.items = [
        ...state.packages.items.slice(0, itemId),
        {
          ...state.packages.items.find((i) => i.id === payload),
          images: [],
        },
        ...state.packages.items.slice(itemId + 1, state.packages.items.length),
      ] as IPackage[]
    },
    imageUploadIsLoading: (state) => {
      state.imageUpload.loading = true
    },
    imageUploadSucces: (state) => {
      state.imageUpload.loading = false
      state.imageUpload.error = null
    },
    imageUploadIsError: (state, { payload }) => {
      state.imageUpload.loading = false
      state.imageUpload.error = payload
    },
  },
})

export const getPackageList = (search?: any): AppThunk => {
  return async (dispatch) => {
    dispatch(packageListIsLoading())

    return fetchGamePackageList(search)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(packageListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(packageListFailure([res.data.data]))
          } else {
            dispatch(packageListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(packageListFailure(err)))
  }
}

export const handlerAddPackage = (data: PackageData, hideForm: (package_id: number) => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addGamePackage(data).then((res) => {
      if (res.data.code === 200) {
        dispatch(packageItemAdd(res.data.data))
        hideForm(res.data.data.id)
        dispatch(formSucces())
      } else {
        if (typeof res.data.data.length !== 'number') {
          dispatch(formIsError([res.data.data]))
        } else {
          dispatch(formIsError(res.data.data))
        }
      }
    }).catch(err => dispatch(formIsError(err)))
  }
}
export const handlerChangePackage = (
  data: PackageData,
  hideForm: () => void,
  packageId: number | undefined
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      packageId &&
      changeGamePackage(data, packageId).then((res) => {
        if (res.data.code === 200) {
          dispatch(packageItemChange(res.data.data))
          hideForm()
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      }).catch(err => dispatch(formIsError(err)))
    )
  }
}

export const deletePackageListItem = (
  id: number | undefined,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      id &&
      deleteGamePackage(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(packageItemDelete(id))
            dispatch(formSucces())
            setIsConfirm({ open: false })
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

export const handlerAddImage = (
  data: ImagesData,
  package_id: number | undefined,
  setImg: (image: string) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(imageUploadIsLoading())
    return (
      package_id &&
      addGamePackageImage(data, package_id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(
              packageAddImage({
                id: package_id,
                images: res.data.data[0],
              })
            )
            setImg('')
            dispatch(imageUploadSucces())
          } else {
            dispatch(imageUploadIsError(res.data.data))
          }
        })
        .catch((err) => dispatch(imageUploadIsError(err)))
    )
  }
}

export const handlerDeleteImage = (package_id: number, image_id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(imageUploadIsLoading())
    return deleteGamePackageImage(package_id, image_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(packageDeleteImage(package_id))
          dispatch(imageUploadSucces())
        } else {
          dispatch(imageUploadIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(imageUploadIsError(err)))
  }
}

export const {
  packageListIsLoading,
  packageListSuccess,
  packageListFailure,
  packageItemAdd,
  packageItemChange,
  packageItemDelete,
  formIsLoading,
  formSucces,
  formIsError,
  packageAddImage,
  packageDeleteImage,
  imageUploadIsLoading,
  imageUploadSucces,
  imageUploadIsError,
} = slice.actions

interface IState {
  package: packageInitialState
}
export const packageSelector = (state: IState) => state.package

export default slice.reducer
