import { createSlice } from '@reduxjs/toolkit'
import { fetchUser } from '../services/User'
import { fetchHall } from '../services/Halls'
import { fetchGamblingTable } from '../services/GamblingTable'
import { ErrorType } from '../interfaces'
import { AppThunk } from './store'

interface InfoInitialState {
  data: any
  loading: boolean
  error: ErrorType | null
  component: string | null
  form: {
    loading: boolean
    error: ErrorType | null
  }
}

export const initialState: InfoInitialState = {
  data: null,
  loading: false,
  error: null,
  component: null,
  form: {
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'treeInfo',
  initialState,
  reducers: {
    treeInfoIsLoading: (state) => {
      state.loading = true
    },
    treeInfoSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.data = payload
    },
    treeInfoFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    setComponentName: (state, { payload }) => {
      state.component = payload
    },
    treeInfoChangeBalance: (state, { payload }) => {
      if (state.data) {
        state.data.balance = payload
      }
    },
    treeInfoChangeCurrentGame: (state, { payload }) => {
      if (state.data) {
        state.data.currentGame = payload
      }
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getTreeInfo = (id: number, name: string): AppThunk => {
  const fetch = async (id: number, name: string) => {
    switch (name) {
      case 'user':
        return fetchUser(id)
      case 'hall':
        return fetchHall(id)
      case 'table':
        return fetchGamblingTable(id)
      default:
        console.error('component name undefined')
    }
  }
  return async (dispatch) => {
    dispatch(setComponentName(name))
    dispatch(treeInfoIsLoading())

    return fetch(id, name)
      .then((res) => {
        if (res) {
          if (res.data.code === 200) {
            dispatch(treeInfoSuccess(res.data.data))
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(treeInfoFailure([res.data.data]))
            } else {
              dispatch(treeInfoFailure(res.data.data))
            }
          }
        }
      })
      .catch((err) => dispatch(treeInfoFailure(err)))
  }
}

export const {
  treeInfoIsLoading,
  treeInfoSuccess,
  treeInfoFailure,
  setComponentName,
  treeInfoChangeBalance,
  treeInfoChangeCurrentGame,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions
interface IState {
  treeInfo: InfoInitialState
}
export const treeInfoSelector = (state: IState) => state.treeInfo

export default slice.reducer
