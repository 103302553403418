import React from 'react';
import AllJackpots from '../components/Jackpots/all';
import { Switch, Route } from 'react-router-dom';

const JackpotsPage = () => <Switch>
    <Route exact path="/jackpots" component={AllJackpots} />
    <Route exact path="/jackpots/history" component={AllJackpots} />
</Switch>;


export default JackpotsPage;
