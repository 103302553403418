import { createSlice } from '@reduxjs/toolkit'
import { fetchPermissionsList, addPermission, deletePermission } from '../services/Permission'
import { changeArr } from '../utils/changeArr'
import { ErrorType, IPermission } from '../interfaces'
import { AppThunk } from './store'

interface PermissionInitialState {
  currentPage: number | null
  pages: number | null
  count: number | null
  items: IPermission[]
  loading: boolean
  error: ErrorType[]
}

export const initialState: PermissionInitialState = {
  currentPage: null,
  pages: null,
  count: null,
  items: [],
  loading: false,
  error: [],
}

export const slice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    permissionsListIsLoading: (state) => {
      state.loading = true
    },
    permissionsListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.items = state.items ? [...state.items, ...payload] : payload
    },
    permissionsListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    permissionItemDelete: (state, { payload }) => {
      state.items = [...state.items.filter((i) => i.name !== payload)]
    },
    permissionItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    permissionItemChange: (state, { payload }) => {
      state.items = [...changeArr(state.items, payload.id, payload)]
    },
    permissionsPagination: (state, { payload }) => {
      state.currentPage = payload['x-pagination-current-page']
      state.pages = payload['x-pagination-page-count']
      state.count = payload['x-pagination-total-count']
    },
  },
})

export const getPermissionsList = (page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(permissionsListIsLoading())

    return fetchPermissionsList(page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(permissionsPagination(res.headers))
          dispatch(permissionsListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(permissionsListFailure([res.data.data]))
          } else {
            dispatch(permissionsListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(permissionsListFailure(err)))
  }
}

export const getAllPermissions = (page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(permissionsListIsLoading())

    return fetchPermissionsList(page, limit)
      .then((res) => {
        if(res.data.code === 200) {
          if (res.headers['x-pagination-current-page'] < res.headers['x-pagination-page-count']) {
            dispatch(getAllPermissions(Number(res.headers['x-pagination-current-page']) + 1, limit))
          }
          dispatch(permissionsListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(permissionsListFailure([res.data.data]))
          } else {
            dispatch(permissionsListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(permissionsListFailure(err)))
  }
}

export const addPermissionItem = (): AppThunk => {
  return async (dispatch) => {
    return addPermission()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(permissionItemAdd(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(permissionsListFailure([res.data.data]))
          } else {
            dispatch(permissionsListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(permissionsListFailure(err)))
  }
}

export const deletePermissionItem = (id: number): AppThunk => {
  return async (dispatch) => {
    return deletePermission(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(permissionItemDelete(id))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(permissionsListFailure([res.data.data]))
          } else {
            dispatch(permissionsListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(permissionsListFailure(err)))
  }
}

export const {
  permissionsListIsLoading,
  permissionsListSuccess,
  permissionsListFailure,
  permissionItemAdd,
  permissionItemDelete,
  permissionItemChange,
  permissionsPagination,
} = slice.actions

interface IState {
  permission: PermissionInitialState
}

export const permissionSelector = (state: IState) => state.permission

export default slice.reducer
