import React from 'react'
import { Action } from '../styles'
import { Box, IconButton, TableCell, TableRow } from '@material-ui/core'

import visionPng from '../../../../assets/images/vision.png'
import editPng from '../../../../assets/images/edit.png'
import trashPng from '../../../../assets/images/trash.png'
import { handlerDeleteProfile } from '../../../../redux/profileReducer'
import { useDispatch } from 'react-redux'
import { IProfile } from '../../../../interfaces'

interface ProfileListItemProps {
    profile: IProfile
    selectedProfile: number
    setSelectedProfile: (id: number) => void
    setProfileId: (id: number) => void
    showProfile: () => void
    showChangeProfile: () => void
}

const Item: React.FC<ProfileListItemProps> = ({
    profile,
    selectedProfile,
    setSelectedProfile,
    setProfileId,
    showProfile,
    showChangeProfile,
}) => {
    const dispatch = useDispatch()
    return (
        <TableRow
            key={profile.id}
            style={{ cursor: 'pointer' }}
            selected={profile.id === selectedProfile}
            onClick={() => {
                setSelectedProfile(profile.id)
            }}
        >
            <TableCell component="th" scope="row">
                {profile.id}
            </TableCell>
            <TableCell align="right">{profile.name}</TableCell>
            <TableCell align="right">{profile.surname}</TableCell>
            <TableCell align="right">{profile.patronymic}</TableCell>
            <TableCell>
                <Box display="flex" justifyContent="flex-end">
                    <Action
                        bg={'#7467ec'}
                        onClick={() => {
                            showProfile()
                            setProfileId(profile.id)
                        }}
                    >
                        <IconButton>
                            <img src={visionPng} alt="icon" />
                        </IconButton>
                    </Action>
                    <Action
                        bg={'#919191'}
                        onClick={() => {
                            showChangeProfile()
                            setProfileId(profile.id)
                        }}
                    >
                        <IconButton>
                            <img src={editPng} alt="icon" />
                        </IconButton>
                    </Action>
                    <Action
                        bg={'#919191'}
                        onClick={() => {
                            dispatch(handlerDeleteProfile(profile.id))
                        }}
                    >
                        <IconButton>
                            <img src={trashPng} alt="icon" />
                        </IconButton>
                    </Action>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default Item
