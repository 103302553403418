import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { findPermission } from '../../../utils/find';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../redux/authReducer';
import { Box, Button } from '@material-ui/core';

type AddButtonProps = {
    showAddUser: () => void;
};

const style: React.CSSProperties = {
    fontSize: '70%',
    background: '#3039ea',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '5px 30px',
    borderRadius: '3px',
};

function cmp(prev: AddButtonProps, next: AddButtonProps): boolean {
    return true;
}

const AddButton: FunctionComponent<AddButtonProps> = React.memo(({ showAddUser }) => {
    const [t] = useTranslation();
    const { assigments: { items } } = useSelector(authSelector);
    return <Box display="flex" justifyContent="flex-end" alignItems="center">
        {findPermission(items, 'createUser') && <Button onClick={showAddUser} variant="contained" style={style}>{t('ru.common.add_btn')}</Button>}
    </Box>;
}, cmp);

export default AddButton;