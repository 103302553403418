import React, { ReactElement } from 'react'
import LeftPanel from '../../LeftPanel'
import MobileNav from '../../MobileNav'
import Header from '../../Header'
import { Wrapper } from './styles'
import { Grid } from '@material-ui/core'

interface PageProps {
  children: ReactElement
}

const Page: React.FC<PageProps> = ({ children }) => {
  const [isMobileNav, setIsMobileNav] = React.useState(false)
  return (
    <Grid container>
        <Header setIsMobileNav={() => setIsMobileNav(!isMobileNav)} />


      <Grid item xs={2} md={2}>
        <LeftPanel />
      </Grid>
      <Grid item xs={12} md={10}>
      <Wrapper>

        {children}
      </Wrapper>

      </Grid>
      <MobileNav isMobileNav={isMobileNav} setIsMobileNav={() => setIsMobileNav(!isMobileNav)} />
      {/* <div id='bg'><LogoSvg /></div> */}
    </Grid>
  )
}

export default Page
