import React from 'react'
import { ListItem } from '../styles'
import { prettyNum } from '../../../../utils/prettyNum'
import { ISession } from '../../../../interfaces'
import { convertUTCDateToLocalDate } from '../../../../utils/date'
import { Box } from '@material-ui/core'

interface ApplicationSessionProps {
  item: ISession
  bg: string
  striped: boolean | undefined
  onClick: (id: number) => void
}

const Item: React.FC<ApplicationSessionProps> = ({ item, bg, striped, onClick }) => {
  // const rowStyle = {
  //   color: item.balanceAtCreated > item.balanceAtClose ? '#b03c3c' : '#87b03b',
  //   cursor: 'pointer',
  // }

  return (
    item && (
      <>
        <ListItem bg={bg} onClick={() => onClick(item?.id)}>
          <div>{item.id}</div>
          <div>{item.game.name}</div>
          <div>{prettyNum(item.input)}</div>
          <div>{prettyNum(item.balance)}</div>
          <div>{item.count_spins}</div>
          <div>{item.denomination}</div>
          <div>
            {item.opened_at && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                component='section'
              >
                <span>
                  {convertUTCDateToLocalDate(item.opened_at)}
                </span>
              </Box>
            )}
          </div>

          <div>
            {item.closed_at && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                component='section'
              >
                <span>
                  {convertUTCDateToLocalDate(item.closed_at)}
                </span>

              </Box>
            )}
          </div>
        </ListItem>
      </>
    )
  )
}

export default Item
