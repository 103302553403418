import { Grid, Box, Button } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ITable } from '../../../interfaces';
import { fetchEnableApi } from '../../../redux/tableReducer';
import { useDispatch } from 'react-redux';

type ApiProps = {
    table: ITable;
};

function cmp(prevProps: ApiProps, nextProps: ApiProps) {
    return prevProps.table.id === nextProps.table.id && false;
};

const Api: FunctionComponent<ApiProps> = React.memo(({ table: { id, api } }) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    return api ? <>
        <Grid item xs={12} md={6}>
            <Box>
                <b>{t('ru.tables.fields.billing')}:</b> {api.billing}
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box>
                <b>{t('ru.tables.fields.secret')}:</b> {api.secret}
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box>
                <b>{t('ru.tables.fields.remote_url')}:</b> {api.remote_url}
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box>
                <b>{t('ru.tables.fields.local_url')}:</b> {api.local_url}
            </Box>
        </Grid>
    </> : <Grid item xs={12} md={12}>
        <Box textAlign="center">
            <Button color="default" variant="contained" onClick={() => dispatch(fetchEnableApi(id))} >
                {t('ru.tables.fields.activate_api')}
            </Button>
        </Box>
    </Grid>;
}, cmp);

export default Api;