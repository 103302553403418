import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchPlayersList, addPlayer, deletePlayer, changePlayer } from '../services/Player'
import { changeArr } from '../utils/changeArr'
import { IPlayer, PlayerData, isConfirmType, ErrorType } from '../interfaces'
import { AppThunk } from './store'

interface PlayerState {
  items: IPlayer[]
  loading: boolean
  error: ErrorType[]
  form: {
    loading: boolean
    error: ErrorType[]
  }
}

export const initialState: PlayerState = {
  items: [],
  loading: false,
  error: [],
  form: {
    loading: false,
    error: [],
  },
}

export const slice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    playerListIsLoading: (state: PlayerState) => {
      state.loading = true
    },
    playerListSuccess: (state: PlayerState, action: PayloadAction<IPlayer[]>) => {
      state.loading = false
      state.error = []
      state.items = action.payload
    },
    playerListFailure: (state: PlayerState, { payload }) => {
      state.loading = false
      state.error = payload
    },
    playerItemDelete: (state, { payload }) => {
      state.items = [...state.items.filter((i: IPlayer) => i.id !== payload)]
    },
    playerItemAdd: (state, action: PayloadAction<IPlayer>) => {
      state.items = [...state.items, action.payload]
    },
    playerItemChange: (state, { payload }) => {
      state.items = [...changeArr(state.items, payload.id, payload)]
    },
    playerBindChange: (state, { payload }) => {
        const array = state.items
        const id = array.findIndex((i) => i.id === payload.id)
        state.items = [
          ...array.slice(0, id),
          {
            ...array[id],
            tableConnection: payload.table_id,
          },
          ...array.slice(id + 1, array.length),
        ]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getPlayersList = (search?: any, sort?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(playerListIsLoading())

    return fetchPlayersList(search, sort)
      .then((res) => {
        if(res.data.code === 200) {
          dispatch(playerListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(playerListFailure([res.data.data]))
          } else {
            dispatch(playerListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(playerListFailure(err)))
  }
}

export const handlerAddPlayer = (data: PlayerData, hideForm: (player_id: number) => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addPlayer(data).then((res) => {
      if (res.data.code === 200) {
        dispatch(playerItemAdd(res.data.data))
        hideForm(res.data.data.id)
        dispatch(formSucces())
      } else {
        if (typeof res.data.data.length !== 'number') {
          dispatch(formIsError([res.data.data]))
        } else {
          dispatch(formIsError(res.data.data))
        }
      }
    }).catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerChangePlayer = (
  data: PlayerData,
  hideForm: () => void,
  id: number | undefined
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return id &&
      changePlayer(data, id).then((res) => {
        if (res.data.code === 200) {
          dispatch(playerItemChange(res.data.data))
          hideForm()
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      }).catch((err) => dispatch(formIsError(err)))
  }
}

export const deletePlayerItem = (
  id: number | undefined,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return id &&
      deletePlayer(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(playerItemDelete(id))
            dispatch(formSucces())
            setIsConfirm({ open: false })
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
  }
}



export const {
  playerListIsLoading,
  playerListSuccess,
  playerListFailure,
  playerItemAdd,
  playerItemDelete,
  playerItemChange,
  playerBindChange,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  player: PlayerState
}

export const playerSelector = (state: IState) => state.player

export default slice.reducer
