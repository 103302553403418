import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Wrapper = styled.div`
  position: relative;
  margin: 7vh 0;
  border-radius: 1vh;
  max-width: 900px;
  min-width: 75%;
  height: max-content;
  background: #fff;
  padding: 5vh;
  z-index: 999;

  .tabs {
    position: relative;
  }

  .tabs:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ddd;
    z-index: 1;
  }

  ${down('sm')} {
    width: 100%;
  }

  ${down('md')} {
    width: 100%;
  }

  ${down('lg')} {
    min-width: 85%;
  }
`

type TabItemType = {
  active: boolean
}
export const TabItem = styled.span`
  background: #fff;
  color: ${(props: TabItemType) => (props.active ? '#666' : '#ddd')};
  border: ${(props: TabItemType) => (props.active ? '1px solid #ddd' : 'none')};
  border-bottom: ${(props: TabItemType) => (props.active ? 'none' : '1px solid #ddd')};
  cursor: pointer;
  padding: 10px;
  z-index: 10;
`
