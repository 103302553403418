import styled from 'styled-components'
import { up } from 'styled-breakpoints'

type WrapperType = {
  isMobileNav: boolean
}
export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${(props: WrapperType) => (props.isMobileNav ? '0' : '-100%')};
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 19vh 0 2vh 0;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.06));
  z-index: 998;
  transition: left 0.6s ease-in;
  ${up('lg')} {
    display: none;
  }
`
type MenuItemType = {
  active: boolean
}
export const MenuItem = styled.div`
  height: 7.5vh;
  color: ${(props: MenuItemType) => (props.active ? 'rgb(116, 103, 236)' : '#666')};
  font-size: 2.2vh;
  background: rgb(245, 246, 249);
  background-color: ${(props: MenuItemType) => (props.active ? 'rgb(245,246,249)' : 'white')};
  padding: 2vh 4vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-left: ${(props: MenuItemType) => (props.active ? '.7vh solid #7467ec' : '0')};
`
