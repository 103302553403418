import React from 'react'
import { Wrapper, TableHeader } from './styles'
import Item from './Item'
import Preloader from '../../Common/Preloader'

import Button from '@material-ui/core/Button'
import { ListItem, Box, Input, makeStyles } from '@material-ui/core'
import {
  packageSelector,
  deletePackageListItem,
  handlerAddPackage,
} from '../../../redux/packageReducer'
import { useDispatch, useSelector } from 'react-redux'
import Confirm from '../../Common/Confirm'
import Overlay from '../../Common/Overlay/index'
import { IPackage, isConfirmType, PackageData } from '../../../interfaces'
import { useShowBlock } from '../../../hooks/useShowBlock'
import AddPackage from '../Add'
import Package from '../View'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none',
    },
  },
}))

const PackagesList: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    packages: { items: packages, loading },
    form: formLoading,
  } = useSelector(packageSelector)

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })

  const [packageId, setPackageId] = React.useState<null | number>(null)

  const [isChangeMode, setIsChangeMode] = React.useState(false)

  const { showBlock: showPackage, hideBlock: hidePackage } = useShowBlock()

  const {
    showBlock: showAddPackage,
    isBlockShown: isAddPackage,
    hideBlock: hideAddPackage,
  } = useShowBlock()


  const [search, setSearch] = React.useState<any>(null)
  const filteredPackage = () => {
    if (search) {
      return packages.filter((pack) => {
        const idFilter = search?.id ? pack.id === Number(search?.id) : true
        const nameFilter = !!search?.name
          ? pack.name.toLowerCase().indexOf(search.name.toLowerCase()) > -1
          : true

        return idFilter && nameFilter
      })
    } else {
      return packages
    }
  }

  const location = useLocation()
  const id = new URLSearchParams(location.search).get('package_id')
  React.useEffect(() => {
    if (id) {
      setPackageId(Number(id))
    } else {
      setPackageId(null)
    }
  }, [id])

  return (
    <Wrapper>
      <Box component="div" style={{ minWidth: '550px' }}>
        <Box display="flex" justifyContent="space-between">
          <h2>{t('ru.packages.title')}</h2>
          <Button onClick={showAddPackage} variant="contained">
          {t('ru.common.add_btn')}
          </Button>
        </Box>
        <div>
          <TableHeader>
            <ListItem component="span">id</ListItem>
            <ListItem component="span">{t('ru.packages.fields.name')}</ListItem>
            <ListItem component="span">{t('ru.packages.fields.games')}</ListItem>
            <ListItem component="span"></ListItem>
            <ListItem component="span"></ListItem>
          </TableHeader>
          <Box style={{ background: '#fff', padding: '5px 0' }}>
            <TableHeader>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      id: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      name: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem component="span">

              </ListItem>
            </TableHeader>
          </Box>
          <ul>
            {loading ? (
              <Preloader />
            ) : (
              filteredPackage().map((item: IPackage) => {
                return (
                  <Item
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    games={item.games}
                    setPackageId={setPackageId}
                    showPackage={showPackage}
                    // showChangePackage={showChangePackage}
                    setIsConfirm={setIsConfirm}
                    setIsChangeMode={setIsChangeMode}
                  />
                )
              })
            )}
          </ul>
        </div>
      </Box>
      {isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              dispatch(deletePackageListItem(isConfirm.id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
      {isAddPackage && (
        <Overlay>
          <AddPackage
            hideAddPackage={hideAddPackage}
            handlerAddPackage={(data: PackageData, hideForm: () => void) =>
              dispatch(handlerAddPackage(data, hideForm))
            }
          />
        </Overlay>
      )}
      {id && packageId && (
        <Overlay>
          <Package
            hidePackage={hidePackage}
            item={packages?.find((i: IPackage) => i.id === packageId)}
            isChangeMode={isChangeMode}
            setIsChangeMode={setIsChangeMode}
          />
        </Overlay>
      )}
    </Wrapper>
  )
}

export default PackagesList
