import { createSlice } from '@reduxjs/toolkit'
import { fetchFaqList, addFaq, changeFaq, deleteFaq } from '../services/Faq'
import { ErrorType, faqData, IFaq, isConfirmType } from '../interfaces'
import { AppThunk } from './store'
import { changeArr } from '../utils/changeArr'

interface faqInitialState {
  faqs: {
    items: IFaq[]
    loading: boolean
    error: ErrorType[]
  }
  form: {
    loading: boolean
    error: ErrorType[]
  }
}
export const initialState: faqInitialState = {
  faqs: {
    items: [],
    loading: false,
    error: [],
  },
  form: {
    loading: false,
    error: [],
  },
}

export const slice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    faqListIsLoading: (state) => {
      state.faqs.loading = true
    },
    faqListSuccess: (state, { payload }) => {
      state.faqs.loading = false
      state.faqs.error = []
      state.faqs.items = payload
    },
    faqListFailure: (state, { payload }) => {
      state.faqs.loading = false
      state.faqs.error = payload
    },
    faqItemDelete: (state, { payload }) => {
      const index = state.faqs.items.findIndex((i) => i.id === payload)
      state.faqs.items = [
        ...state.faqs.items.slice(0, index),
        ...state.faqs.items.slice(index + 1, state.faqs.items.length),
      ]
    },
    faqItemAdd: (state, { payload }) => {
      state.faqs.items = [...state.faqs.items, payload]
    },
    faqItemChange: (state, { payload }) => {
      state.faqs.items = [...changeArr(state.faqs.items, payload.id, payload)]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getFaqList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(faqListIsLoading())

    return fetchFaqList(1, 20)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(faqListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(faqListFailure([res.data.data]))
          } else {
            dispatch(faqListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(faqListFailure(err)))
  }
}

export const addFaqItem = (data: faqData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return addFaq(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(faqItemAdd(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const deleteFaqItem = (
  id: number,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return deleteFaq(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(faqItemDelete(id))
          dispatch(formSucces())
          setIsConfirm({ open: false })
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const changeFaqItem = (data: faqData, id: number, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return changeFaq(data, id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(faqItemChange(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  faqListIsLoading,
  faqListSuccess,
  faqListFailure,
  faqItemDelete,
  faqItemAdd,
  faqItemChange,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  faq: faqInitialState
}

export const faqSelector = (state: IState) => state.faq

export default slice.reducer
