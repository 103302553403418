import styled from 'styled-components'
import bg_login from '../../assets/images/bg_login.png'

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: url(${bg_login}) no-repeat center / cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`

export const LoginForm = styled.form`
    margin-top: 9.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #9a9fab;
`
type LoginInputType = {
    spacing?: number
}
export const LoginInput = styled.div`
    width: 44vh;
    max-width: 300px;
    height: 6vh;
    max-height: 45px;
    margin: 1vh;
    padding-left: 0 10px;
    box-shadow: 0 0 1vh 0.2vh rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    input {
        letter-spacing: ${(props: LoginInputType) => (props.spacing ? props.spacing + 'vh' : 0)};
        border: 0;
        font-size: 80%;
        width: 100%;
        height: 100%;
        padding-left: 35px;
    }
    input:focus {
        outline: none;
    }
    img {
        width: 2vh;
        height: auto;
        margin: 2vh;
        margin-right: 2vh;
    }
`
export const LoginSubmit = styled.button`
    max-width: 300px;
    max-height: 35px;
    width: 44vh;
    height: 4vh;
    margin: 1vh;
    background: #009160;
    font-size: 85%;
    color: #ffffff;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }
`

export const LoginCheckbox = styled.label`
    border: 0;
    background: transparent;
    font-size: 1.9vh;
    color: #1c1c1c;
    vertical-align: middle;
    position: relative;
    padding-left: 35px;
    margin: 1vh 3vh;
    cursor: pointer;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #9a9fab;
    }
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
`

export const Row = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
`

export const Forgot = styled.span`
    font-size: 1.9vh;
    color: #1c1c1c;
    font-weight: 500;
    font-style: italic;
    margin: 1vh 3vh;
    cursor: pointer;
`
