import React from 'react';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Item from '../Item';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { tableSelector } from '../../../redux/tableReducer';
import { useTranslation } from 'react-i18next';

const TerminalsStatistic = () => {
  const [t] = useTranslation();
  const { tables: { count }, loading } = useSelector(tableSelector);
  return <Item
      title={t('ru.widgets.statistic')}
      icon={<DesktopWindowsIcon fontSize="large" />}
      value={
        <Box display="flex" alignItems="center">
          <PowerSettingsNewIcon fontSize="large" />
          <h2>{count.online}/{count.total}</h2>
        </Box>
      }
      loading={loading}
    />
};

export default TerminalsStatistic;
