import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector } from '../../redux/authReducer'
import { gameSelector, getAllGames } from '../../redux/gameReducer'
import GamesList from './List'
import { getManufacturerList, manufacturerSelector } from '../../redux/manufacturerReducer'
import { packageSelector, getPackageList } from '../../redux/packageReducer'

const Games: React.FC = () => {
  const dispatch = useDispatch()

  const { games: {items: games} } = useSelector(gameSelector)
  const { items: manufacturerList } = useSelector(manufacturerSelector)
  const { packages: {items: packageList} } = useSelector(packageSelector)
  const { status } = useSelector(authSelector)

  React.useEffect(() => {
    if (status === 200) {
      !games.length && dispatch(getAllGames(1, 50))
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (status === 200) {
      !manufacturerList.length && dispatch(getManufacturerList())
    }
  }, [status, manufacturerList, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !packageList.length && dispatch(getPackageList())
    }
  }, [status, packageList, dispatch])

  return (
    <>
      <GamesList />
    </>
  )
}

export default Games
