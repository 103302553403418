import { FormControl, Grid, InputLabel, NativeSelect, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { IHallMathForm } from '../../../../../interfaces';

type MathTabProps = {
    hallMathForm: IHallMathForm;
    setHallMathForm: (f: IHallMathForm) => void;
};

const MathTab: FunctionComponent<MathTabProps> = ({ hallMathForm, setHallMathForm }) => <>
    <Grid item xs={12} sm={12}>
        <TextField style={{ width: "100%" }} label="Пэйаут:" type="text" value={hallMathForm.payout} name="payout" size="small" onChange={v => setHallMathForm({ ...hallMathForm, payout: v.target.value })} />
    </Grid>
    <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
            <InputLabel>Тип адаптива:</InputLabel>
            <NativeSelect style={{ width: "100%" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => setHallMathForm({ ...hallMathForm, adaptive_type: +e.target.value })} value={hallMathForm.adaptive_type}>
                <option value={0} key={0}>Без адаптива</option>
                <option value={1} key={1}>Простой</option>
                <option value={2} key={2}>С задержкой</option>
                <option value={3} key={3}>One Reel</option>
            </NativeSelect>
        </FormControl>
    </Grid>
    {hallMathForm.adaptive_type === 0 ? null : <>
        <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
                <InputLabel>Параметр адаптива:</InputLabel>
                <NativeSelect style={{ width: "100%" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => setHallMathForm({ ...hallMathForm, adaptive_param: +e.target.value })} value={hallMathForm.adaptive_param}>
                    <option value={0} key={0}>Зал</option>
                    <option value={1} key={1}>Стол</option>
                    <option value={2} key={2}>Игры в зале</option>
                    <option value={3} key={3}>Игры в столе</option>
                </NativeSelect>
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField style={{ width: "100%" }} label="Максимальный RTP:" type="text" value={hallMathForm.max_rtp} name="max_rtp" size="small" onChange={v => setHallMathForm({ ...hallMathForm, max_rtp: v.target.value })} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField style={{ width: "100%" }} label="Минимальный RTP:" type="text" value={hallMathForm.min_rtp} name="min_rtp" size="small" onChange={v => setHallMathForm({ ...hallMathForm, min_rtp: v.target.value })} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField style={{ width: "100%" }} label="Овердрафт:" type="text" value={hallMathForm.overdraft} name="name" size="small" onChange={v => setHallMathForm({ ...hallMathForm, overdraft: v.target.value })} />
        </Grid>
    </>}
    <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
            <InputLabel>Набор лент:</InputLabel>
            <NativeSelect style={{ width: "100%" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => setHallMathForm({ ...hallMathForm, reels_path: e.target.value })} value={hallMathForm.reels_path}>
                <option value="reels" key="reels">Стандартный</option>
                <option value="reels2" key="reels2">Сафрошкин</option>
            </NativeSelect>
        </FormControl>
    </Grid>
</>;

export default MathTab;