import React from 'react'
import { Wrapper } from './styles'
import { Box, IconButton } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { treeInfoSelector } from '../../redux/treeInfoReducer'
import Preloader from '../Common/Preloader'
import BusinessIcon from '@material-ui/icons/Business'
import PersonIcon from '@material-ui/icons/Person'
import CreateIcon from '@material-ui/icons/Create'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import UserInfo from './User'
import TableInfo from './Table'
import HallInfo from './Hall'
import { IUser } from '../../interfaces'
import { authSelector } from '../../redux/authReducer'

interface UserInfoProps {
  isPayment: boolean
  showChangeUser: () => any
  showChangeHall: () => void
  showChangeTable: () => void
  showPayment: () => void
  hidePayment: () => void
}

type AllProps = UserInfoProps

const Info: React.FC<AllProps> = ({
  showChangeUser,
  showChangeHall,
  showChangeTable,
  showPayment,
  isPayment,
  hidePayment,
}) => {
  const { data: item, loading, component } = useSelector(treeInfoSelector)
  const {
    role: { item: userRole },
  } = useSelector(authSelector)

  const renderIcon = (name: string | null) => {
    switch (name) {
      case 'user':
        return <PersonIcon />
      case 'hall':
        return <BusinessIcon />
      case 'table':
        return <DesktopWindowsIcon />
      default:
        break
    }
  }

  const showChange = (name: string | null) => {
    switch (name) {
      case 'user':
        return showChangeUser()
      case 'hall':
        return showChangeHall()
      case 'table':
        return showChangeTable()
      default:
        break
    }
  }

  return (
    <Wrapper>
      {!item || loading ? (
        <Preloader />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {renderIcon(component)}
              <h3>
                #{item.id} {component === 'user' ? (item?.login as IUser) : item?.name}
              </h3>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {userRole && userRole !== 'cashier' && (
                <IconButton style={{ padding: '1px' }} onClick={() => showPayment()}>
                  <MonetizationOnIcon />
                </IconButton>
              )}

              {userRole && userRole !== 'cashier' && (
                <IconButton style={{ padding: '1px' }} onClick={() => showChange(component)}>
                  <CreateIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          <br />
          <div>
            <Box display="flex" flexDirection="column">
              {component === 'user' && (
                <UserInfo item={item} isPayment={isPayment} hidePayment={hidePayment} />
              )}
              {component === 'hall' && (
                <HallInfo item={item} isPayment={isPayment} hidePayment={hidePayment} />
              )}
              {component === 'table' && (
                <TableInfo item={item} isPayment={isPayment} hidePayment={hidePayment} />
              )}
            </Box>
          </div>
        </>
      )}
    </Wrapper>
  )
}
export default Info
