import { createSlice } from '@reduxjs/toolkit'
import { fetchCompanyList, addCompany } from '../services/Company'
import { ICompany, ErrorType, CompanyData } from '../interfaces'
import { AppThunk } from './store'

interface companyInitialState {
  items: ICompany[]
  loading: boolean
  error: ErrorType[] | null
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: companyInitialState = {
  items: [],
  loading: false,
  error: null,
  form: {
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyListIsLoading: (state) => {
      state.loading = true
    },
    companyListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    companyListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    companyItemDelete: (state, { payload }) => {
      const index = state.items.findIndex((i) => i.id === payload)
      state.items = [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1, state.items.length),
      ]
    },
    companyItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getCompanyList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(companyListIsLoading())

    return fetchCompanyList()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(companyListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(companyListFailure([res.data.data]))
          } else {
            dispatch(companyListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(companyListFailure(err)))
  }
}

export const addCompanyItem = (data: CompanyData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return addCompany(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(companyItemAdd(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  companyListIsLoading,
  companyListSuccess,
  companyListFailure,
  companyItemAdd,
  companyItemDelete,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  company: companyInitialState
}

export const companySelector = (state: IState) => state.company

export default slice.reducer
