import { Box, Grid, List, ListItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

type WinLinesProps = {
  setLineId: (lineId: number | null) => void;
  linesPosition: any;
  parseWins: any;
};

const WinLines: React.FC<WinLinesProps> = React.memo(({ setLineId, linesPosition, parseWins }) => {
  const [t] = useTranslation();
  return <Grid item xs={12} sm={12}>
    <b>{t('ru.spins.fields.combo')}:</b>
    <List component="nav">
      {parseWins?.lines.map((item: any, id: number) => <ListItem key={id} button style={{ border: '1px solid #d9d9d9' }}
        onMouseEnter={() => linesPosition[String(parseWins.lines[id]?.line + 1)] && setLineId(id)}
        onMouseLeave={() => setLineId(null)}
      >
        <Box style={{ cursor: 'pointer', width: '100%' }} display="flex" justifyContent="space-between" onClick={() => console.log(parseWins?.lines[id], linesPosition)}>
          <span>{id + 1}.</span>
          <span>
            {t('ru.spins.combo.line')}: {item.line + 1}
          </span>
          <span>
            {t('ru.spins.combo.size')}: {item.size}
          </span>
          <span>
            {t('ru.spins.combo.score')}: {item.points}
          </span>
        </Box>
      </ListItem>
      )}
    </List>
  </Grid>;
});

export default WinLines;
