import * as React from 'react'
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation, ArgumentScale, ValueScale } from '@devexpress/dx-react-chart'
import { scaleBand } from 'd3-scale'
import { withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const legendStyles = {
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
}
const legendLabelStyles = (theme) => ({
  label: {
    paddingTop: theme.spacing(1),
  },
})
const legendItemStyles = {
  item: {
    flexDirection: 'column',
  },
}

const LegendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
)
const LegendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label {...restProps} className={classes.label} />
)
const LegendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item {...restProps} className={classes.item} />
)
const LegendRoot = withStyles(legendStyles, { name: 'LegendRoot' })(LegendRootBase)
const LegendLabel = withStyles(legendLabelStyles, { name: 'LegendLabel' })(LegendLabelBase)
const LegendItem = withStyles(legendItemStyles, { name: 'LegendItem' })(LegendItemBase)

const Label = ({ text, ...props }) => <ValueAxis.Label {...props} text={text} />
const modifyDomain = ([start, end]) => {
  const threshold = Math.ceil(Math.max(Math.abs(start), Math.abs(end)))
  return [-threshold, threshold]
}

const DualBarChart = ({ data }) => {
  return (
    <Box style={{ width: '100%' }}>
      <Chart data={data} rotated>
        <ArgumentScale factory={scaleBand} />
        <ArgumentAxis />
        <ValueScale modifyDomain={modifyDomain} />
        <ValueAxis labelComponent={Label} />

        <BarSeries name="Снятие" valueField="profit" argumentField="name" color="#e27575" />
        <BarSeries name="Пополнение" valueField="sum" argumentField="name" color="#87b384" />
        <Animation />
        <Legend
          position="bottom"
          rootComponent={LegendRoot}
          itemComponent={LegendItem}
          labelComponent={LegendLabel}
        />
      </Chart>
    </Box>
  )
}

export default DualBarChart
