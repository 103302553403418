import styled from 'styled-components'

type StatusMobileProps = {
    status: boolean
}
export const StatusMobile = styled.figure`
    width: 2vh;
    height: 2vh;
    margin: 0 auto;
    border-radius: 50%;
    background: ${(props: StatusMobileProps) => props.status ? '#87b03b' : '#b03c3c'};
    box-shadow: inset 0 0 0.5vh ${(props: StatusMobileProps) => props.status ? 'rgb(114 137 109)' : 'rgb(127 106 106)'};
`