import React from 'react'
import { Icon } from './styles'

export const Admin = () => {
    return (
        <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44" height="44">
                <defs>
                    <image
                        width="44"
                        height="44"
                        id="img11"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAFDklEQVRYhcWZfWyfUxTHP9V2MsyECGGxTjQZkoU/mNAonSxmLxipt3ibxUuIIItoTWydqMWWjelQlXQrm5ege2uNeeli8RpsGLFYgm2MYSFR1nL9cc7tc57H8/x+z+/X59ed5CT3nnvu+X3vc88959z7AzgJ+BpwBfK7wDHsB9qtAB4Dlig/CjQDjcC9wHwztgRo0Tmf7ge8OAVUKL2ic4ecHLA4ImsE/iXY/j+BmRGdNUB/ydHFkHcHC8QBbwG3A3cCH6vscaO3DugbIowhsoAbtX9DjF6Tjk3WfhfZAy4HzgEmAufH8LkecLMB35PD4E/ATm13lgDwoeSPTjjgHgP4qhwGFxMctGUlAOwxvIx80eOUTwamAVu8QoNRjnMHT60EgJeTLeDxwB1qv15ltcpV2u/2IH1Yc8DmHEZ7ga3aXkV2gG8jvO0TgArT/0D1Xvcg/aG7VvtNMUaf0bEztJ/loXMKplrb1yXodXllG9aWquwXoA1oB/YR9nXINqw54C7T/grJqA3Kc5GQuscrLIoYqAc+I1w3TIjorAL+yRCwj1TfE3YPy7955YVF/IhPMFmQA57U9og8zGoE+TrDa5FaYTnQgXxNO96FHMCnMwTcllbZ+2xfAdyvc+4zdsYDXxL4ez7ehmRWD7i1kNW1p1U21EPYJS7U/ofAi8jXj2O/Yz4yeAxPFQL4oSIAv5QA+IiU838ENhgML6T9YYeEkEIpGtamqq1y4CKSXeF91f8c2Y0rVH63ymuRtDw1hus8YBuHBwu4GhgFzEjgiarvo4wPmxDObjmLnxadcCrwDfCDMQySAfciKfJolb2BHDBP4whnwny0EykDKiJyB6wATgOGKx8F1ACfeIV5qtyLbNU7Kh8OjNH2GgW9TXVbkcRxv/IK1asEJpH8hbp1/mbgWyQNz1Ibzapzqepco+w/wkDx4/3HAVOQm7QDjkSKZg9kPsFBm50AqAwpUR1yY5lpeI9Z8OocizqPsHts0jkbPMgFKug0Su+p7GDC6fJho9sHjFS+jGBXZpiFWdqEXAIAdhEcQJDifbTOi94fPQ0UP0uNcBaSEA4wshHAAxFD6wkfuloD2Fd91gbAR8jiQSLEz4grjDY6DtiOuNyDyguQGP67V8gyrJUBV2q7H/jbsAcDwa3b71y1yrcAfxHvKtuzBDxNbY1Ewtoi5KrTabgFuFr1v0MiTSVFZLq5RQB+nrCf+i9ck3L+buBNg6Ej7Q/vQg7YJOKv1nF8ARKvtxo7F5Ou6LH8mgGcuvjxsa8YvtXYqQLmIFmzNQU36eILBpz0hWuQ0zsGedzI94UHQ44CautcPjwOGJswFvXhwZA9+NORsHhjDNdHlS35mtUBT8SMZ30JnWPaeYufKOA6lZ9OkMGOLTHg2abdBhxvxsuAE9H3iTjAYwlCTbe2Dysh4B0EVaMDLlG5v+ZP0f76JMDoJIe8E58ZM54l4BOALwi2vQ65CPi+rzkGXn5sLTGZYIXlRn45cLbpl+K5dZTiuTlh/FVUwT+kLCRY1bNGcaOR36KytZTu9fJXtb9MeaWC7fcK9rn1euSVxwGHA2dpuwrJ9z6UdZQA8EHIbXwH/39a2IdUezikxPPtlcAj2j6EoJhvQP416lXd50oAOBU5gkuov6o74CajM09le4FTVNbNfgRczN9e0XeJISOHOPgwpDb1VKGyYYSjRSVwIEEYGnJqR7a6B3g7JW8E/qC4F6NB0X/vKUrv1WcgVgAAAABJRU5ErkJggg=="
                    />
                </defs>
                <g id="Left Menu">
                    <g id="Menu">
                        <use id="networking" href="#img11" transform="matrix(1,0,0,1,0,0)" />
                    </g>
                </g>
            </svg>
        </Icon>
    )
}

export default Admin
