import { Box, ListItemText, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { reportSelector } from '../../../redux/reportReducer'
import { Wrapper } from './styles'
import { prettyNum } from '../../../utils/prettyNum'
import Preloader from '../../Common/Preloader'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import { hallSelector } from '../../../redux/hallReducer'
import { GreenCheckbox } from '../../Common/GreenCheckBox/styles'
import { Chart } from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import { convertToUtc } from '../../../utils/date'
import DateTimeRangePicker from '../../Common/DateTimeRangePicker'

interface HallReportsProps {
  halls: number[]
  fetch: (halls: number[], from: string, to: string) => void
  currency_id: string | undefined
  form: any
  setFormItem: any
}
const HallsReports: React.FC<HallReportsProps> = ({ halls, fetch, form, setFormItem }) => {
  const [t] = useTranslation()

  const [switchPie, setSwitchPie] = React.useState<boolean>(false)

  const {
    reports: {
      halls: { data: report, loading },
    },
  } = useSelector(reportSelector)

  const {
    halls: { data: hallsList },
  } = useSelector(hallSelector)

  React.useEffect(() => {
    !report &&
      !!halls.length &&
      fetch(halls, form.from, form.to)
    // eslint-disable-next-line
  }, [halls, report])

  React.useEffect(() => {
    if (Date.parse(form.to) < Date.parse(form.from)) {
      setFormItem('date_for_drawing', form.from)
    }
    // eslint-disable-next-line
  }, [form.date_end_draw])

  React.useEffect(() => {
    if (Date.parse(form.from) > Date.parse(form.date_end_draw)) {
      setFormItem('date_end_draw', form.from)
    }
    // eslint-disable-next-line
  }, [form.date_for_drawing])

  const returnZero = (num: string | undefined) => {
    return num ? num : '0.00'
  }

  const getRandomColors = useCallback(() => {
    const randomBetween = (min: number, max: number) =>
      min + Math.floor(Math.random() * (max - min + 1))
    let colors: string[] = []
    for (let i = 0; i < report?.halls?.length; i++) {
      const r = randomBetween(0, 255)
      const g = randomBetween(0, 255)
      const b = randomBetween(0, 255)
      colors = [...colors, `rgb(${r},${g},${b})`]
    }
    return colors
    // eslint-disable-next-line
  }, [])

  const colors = [
    '#856ff7',
    '#64b0a6',
    '#704a34',
    '#c55656',
    '#86c380',
    '#f0d965',
    ...getRandomColors(),
  ]
  interface SwitchStatusProps {
    checked: boolean
    onClick: () => void
  }
  const SwitchStatus: React.FC<SwitchStatusProps> = ({ checked, onClick }) => {
    return (
      <Box
        onClick={onClick}
        style={{ fontSize: '80%' }}
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
      >
        <span style={{ color: !checked ? '#666' : '#6666666b' }}>
          {t('ru.reports.switch.total')}
        </span>
        <GreenCheckbox checked={!!checked} />
        <span style={{ color: checked ? '#666' : '#6666666b' }}>
          {t('ru.reports.switch.benefit')}
        </span>
      </Box>
    )
  }

  const getBenefitForTheHalls = () => {
    let pieItems: any = []
    if (!!report && !!report.halls) {
      report.halls.forEach((item: any, id: number) => {
        const hallName: any = hallsList && hallsList.find((i: any) => i.id === item.hall_id)
        const pieItem: any = [hallName?.name, (item.debit - item.credit) / 100, colors[id]]
        pieItems = [...pieItems, pieItem]
      })
    }
    return pieItems
  }

  const getHallsTotalBenefit = () => {
    let totalBenefit = 0
    report?.halls?.forEach((item: any) => {
      totalBenefit += item.debit - item.credit
    })
    return totalBenefit
  }

  const benefit = report?.total_debit - report?.total_credit
  const outPercent = ((report?.total_debit / 100 / (report?.total_credit / 100)) * 100).toFixed(2)
  const totalPie = [
    [t('ru.reports.fields.balance'), report?.end_balance / 100],
    [t('ru.reports.fields.credit'), report && report?.total_credit / 100],
    [t('ru.reports.fields.debit'), report && report?.total_debit / 100],
    [t('ru.reports.fields.credit_table'), report && report?.total_gamble_table_balance / 100],
  ]

  const totalList = () => {
    return (
      <Box className="report-list">
        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
          <Brightness1Icon style={{ color: colors[0], marginRight: '5px' }} />
          <ListItemText>
            <b>{t('ru.reports.fields.balance')}:</b>
          </ListItemText>
          {returnZero(prettyNum(report?.end_balance))}
        </Box>

        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
          <Brightness1Icon style={{ color: colors[1], marginRight: '5px' }} />
          <ListItemText>
            <b>{t('ru.reports.fields.credit')}:</b>
          </ListItemText>
          {returnZero(prettyNum(report?.total_credit))}
        </Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
          <Brightness1Icon style={{ color: colors[2], marginRight: '5px' }} />
          <ListItemText>
            <b>{t('ru.reports.fields.debit')}:</b>
          </ListItemText>{' '}
          {returnZero(prettyNum(report?.total_debit))}
        </Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
          <Brightness1Icon style={{ color: colors[3], marginRight: '5px' }} />
          <ListItemText>
            <b>{t('ru.reports.fields.credit_table')}:</b>
          </ListItemText>{' '}
          {returnZero(prettyNum(report?.total_gamble_table_balance))}
        </Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
          <Brightness1Icon style={{ color: colors[4], marginRight: '5px' }} />
          <ListItemText>
            <b>{t('ru.reports.fields.benefit')}:</b>
          </ListItemText>
          {returnZero(prettyNum(benefit))}
        </Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
          <Brightness1Icon style={{ color: colors[5], marginRight: '5px' }} />
          <ListItemText>
            <b>{t('ru.reports.fields.percent')}:</b>
          </ListItemText>
          {benefit < 0 && '-'}
          {outPercent !== 'NaN' ? outPercent : '0.00'}%
        </Box>
      </Box>
    )
  }

  // const transformNum = (num: number) => {
  //   if ((num ^ 0) === num) {
  //     return String(num + '.00')
  //   } else {
  //     return String(num.toFixed(2))
  //   }
  // }

  // const benefitList = () => {
  //   return (
  //     <Box className="report-list">
  //       {getBenefitForTheHalls().map((item: any, id: number) => {
  //         return (
  //           <Box
  //             key={id}
  //             display="flex"
  //             justifyContent="center"
  //             alignItems="center"
  //             flexWrap="wrap"
  //           >
  //             <Brightness1Icon style={{ color: colors[id], marginRight: '5px' }} />
  //             <ListItemText>
  //               <b>{item[0]}:</b>
  //             </ListItemText>
  //             {transformNum(item[1])}
  //           </Box>
  //         )
  //       })}
  //     </Box>
  //   )
  // }

  const hallsBenefitChart = () => {
    return (
      <div style={{ display: 'flex', maxWidth: 900 }}>
        <Chart
          width={'100%'}
          height={'auto'}
          chartType="ColumnChart"
          loader={<Preloader />}
          data={[['Зал', '', { role: 'style' }], ...getBenefitForTheHalls()]}
          options={{ legend: 'none' }}
        />
      </div>
    )
  }

  return (
    <Wrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <DateTimeRangePicker
          onApply={(e: any, picker: any) => {
            fetch(halls, convertToUtc(picker.startDate._d), convertToUtc(picker.endDate._d))
            setFormItem('from', convertToUtc(picker.startDate._d))
            setFormItem('to', convertToUtc(picker.endDate._d))
          }}
        />
        <SwitchStatus onClick={() => setSwitchPie(!switchPie)} checked={!switchPie} />
      </Box>

      <Box display="flex" justifyContent="space-around" alignItems="center" flexWrap="wrap">
        {loading ? (
          <Preloader />
        ) : (
          <>
            {switchPie && totalList()}
            {(switchPie
              ? !!(
                report?.total_debit +
                report?.total_credit +
                report?.total_gamble_table_balance +
                report?.end_balance
              )
              : !!getHallsTotalBenefit()) &&
              (switchPie ? (
                <Chart
                  width={'50vh'}
                  height={'50vh'}
                  chartType="PieChart"
                  loader={<Preloader />}
                  data={[
                    ['Balance', 'transfers balance statistic'],
                    ...(switchPie ? totalPie : getBenefitForTheHalls()),
                  ]}
                  options={{
                    legend: 'none',
                    is3D: true,
                    colors: colors,
                  }}
                />
              ) : (
                hallsBenefitChart()
              ))}
            {switchPie
              ? !(
                report?.total_debit +
                report?.total_credit +
                report?.total_gamble_table_balance +
                report?.end_balance
              )
              : !getHallsTotalBenefit() && (
                <Typography style={{ margin: '0 20px' }}>{t('ru.common.is_absent')}</Typography>
              )}
          </>
        )}
      </Box>
    </Wrapper>
  )
}

export default HallsReports
