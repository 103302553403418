import React from 'react'
import { Wrapper } from './styles'
import { makeStyles, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { IProfile, PlayerData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import Form from './form'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddPlayerProps {
  hideAddPlayer: () => void
  handlerAddPlayer: (data: PlayerData, hideForm: () => void) => void
  showProfiles: () => void
  profile: IProfile | undefined
}

const AddPlayer: React.FC<AddPlayerProps> = ({
  hideAddPlayer,
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddPlayer}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.players.add_form')}</h3>
      <Form close={hideAddPlayer} />
    </Wrapper>
  )
}

export default AddPlayer
