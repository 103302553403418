import axios from 'axios'

export type QueryType = {
  [query: string]: string
}

export const fetchHallReportsList = async (halls: number[], from: string, to: string) => {
  return axios.post(`hall/report/amounts`, {
    id: [...halls],
    from: from,
    to: to,
  })
}

export const fetchHallReport = async (id: number, from: string, to: string) => {
  return axios.post(`hall/report/amounts`, {
    id: [id],
    from: from,
    to: to,
  })
}

export const fetchGamblingTableReportsList = async (tables: number[], from: string, to: string) => {
  return axios.post(`gambling-table/report/amounts`, {
    id: [...tables],
    from: from,
    to: to,
  })
}

export const fetchGamblingTableReport = async (id: number, from: string, to: string) => {
  return axios.post(`gambling-table/report/amounts`, {
    id: [id],
    from: from,
    to: to,
  })
}

export const fetchHallGamesReport = async (id: any, from: string, to: string) => {
  return axios.post(`hall/report/games/amounts`, {
    id: !!id.length ? [...id] : id,
    from: from,
    to: to,
  })
}

export const fetchGamblingTableGamesReport = async (id: number, from: string, to: string) => {
  return axios.post(`gambling-table/report/games/amounts`, {
    id: [id],
    from: from,
    to: to,
  })
}
export const fetchUserReport = async (from: string, to: string) => {
  return axios.post(`user/report`, {
    from: from,
    to: to,
  })
}
// https://admin-api.test-millenium.tk/v1/user/report

// на выходе массив result с операциями 
// (HallTake - вывод из зала, HallPut - перевод на зал, Transfer - перевод между пользователями)
// from - инициатор операции (id)
// to - второй участник операции