import React, { FunctionComponent } from 'react';
import { Tooltip } from '@material-ui/core';
import offlineIcon from '../../../../assets/images/offline.png';
import onlineIcon from '../../../../assets/images/online.png';
import onPlayIcon from '../../../../assets/images/client_on-play.png';
import clientOnIcon from '../../../../assets/images/client_on.png';

function getIcon(online: boolean, currentGame: boolean) {
    if (!online && !currentGame) {
        return offlineIcon;
    }
    if (online && !currentGame) {
        return onlineIcon;
    }
    if (!online && currentGame) {
        return clientOnIcon;
    }
    if (online && currentGame) {
        return onPlayIcon;
    }
}

function getText(online: boolean, currentGame: boolean): string {
    if (!online && !currentGame) {
        return 'Не в сети';
    } else if (online && !currentGame) {
        return 'В сети';
    }
    return '';
}

type CurrentGameBtnProps = {
    currentGame?: any,
    online: number
}

const CurrentGameBtn: FunctionComponent<CurrentGameBtnProps> = React.memo(({ currentGame, online }) => {
    return <Tooltip title={currentGame ? currentGame?.name : getText(Boolean(online), Boolean(currentGame))} placement="top" >
        <img style={{ width: '3vh', minWidth: '30px', maxWidth: '25px' }} src={getIcon(Boolean(online), Boolean(currentGame))} alt="" />
    </Tooltip>;
});

export default CurrentGameBtn;