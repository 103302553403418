import React from 'react'

import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  ListItem,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import AvTimerIcon from '@material-ui/icons/AvTimer'
import BorderOuterIcon from '@material-ui/icons/BorderOuter'

import editPng from '../../../../assets/images/edit.png'
import visionPng from '../../../../assets/images/vision.png'

import { Wrapper } from '../../../Tables/View/styles'
import { useTranslation } from 'react-i18next'
import { TabItem } from '../../../Halls/View/styles'
import JackpotsList from '../../../Jackpots/List'
import { IGame } from '../../../../interfaces'
import TerminalForm from '../Form'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface TerminalProps {
  terminal: any
  hideTerminal: () => void
}

const Terminal: React.FC<TerminalProps> = ({ terminal, hideTerminal }) => {
  // const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const [tab, setTab] = React.useState<number>(0)

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return main()
      case 1:
        return packets()
      case 2:
        return <JackpotsList change table_id={235} />
      default:
        break
    }
  }

  const main = () => {
    return (
      <>
        {' '}
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('Логин')}:</b> {'1001001'}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('Пароль')}:</b> {'11231123'}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('Типы устройств')}:</b> {'Терминал'}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('Баланс')}:</b> {'1000.00'}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('Процент выдачи')}:</b> {'75%'}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('Номер телефона')}:</b> {'7-999-909-00-00'}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="center">
            <Button
              color="default"
              variant="contained"
              onClick={() => {}}
              style={{ margin: '10px' }}
            >
              {t('ru.common.history_sessions_btn')} <AvTimerIcon style={{ margin: '0 10px' }} />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="center">
            <Button
              color="default"
              variant="contained"
              onClick={() => {}}
              style={{ margin: '10px' }}
            >
              {t('ru.common.history_operations_btn')} <MenuOpenIcon style={{ margin: '0 10px' }} />
            </Button>
          </Box>
        </Grid>
      </>
    )
  }

  const mockTablePackages = [
    {
      package: {
        id: 1,
        name: 'Novomatic',
        image: 'https://api.millenium.link/images/providers/1.png',
        images: [],
        status: 10,
      },
      games: [
        {
          id: 1,
          name: 'Book Of Ra',
          image: 'https://api.millenium.link/images/games/book_of_ra_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/book_of_ra_inbet',
        },
        {
          id: 2,
          name: 'Book Of Ra Deluxe',
          image: 'https://api.millenium.link/images/games/book_of_ra_deluxe_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/book_of_ra_deluxe_inbet',
        },
        {
          id: 3,
          name: 'Lucky Ladies Charm',
          image: 'https://api.millenium.link/images/games/lucky_ladies_charm_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/lucky_ladies_charm_inbet',
        },
        {
          id: 4,
          name: 'Lucky Ladies Charm Deluxe',
          image: 'https://api.millenium.link/images/games/lucky_ladies_charm_deluxe_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/lucky_ladies_charm_deluxe_inbet',
        },
        {
          id: 5,
          name: 'Lord Of Ocean',
          image: 'https://api.millenium.link/images/games/lord_of_ocean_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/lord_of_ocean_inbet',
        },
        {
          id: 6,
          name: 'Sizzling Hot',
          image: 'https://api.millenium.link/images/games/sizzling_hot_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/sizzling_hot_inbet',
        },
        {
          id: 7,
          name: 'Sizzling Hot Deluxe',
          image: 'https://api.millenium.link/images/games/sizzling_hot_deluxe_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/sizzling_hot_deluxe_inbet',
        },
        {
          id: 8,
          name: 'Bananas Go Bahamas',
          image: 'https://api.millenium.link/images/games/bananas_go_bahamas_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/bananas_go_bahamas_inbet',
        },
        {
          id: 9,
          name: 'The Money Game',
          image: 'https://api.millenium.link/images/games/the_money_game_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/the_money_game_inbet',
        },
        {
          id: 10,
          name: 'Pharaohs Gold',
          image: 'https://api.millenium.link/images/games/pharaohs_gold_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/pharaohs_gold_inbet',
        },
        {
          id: 11,
          name: 'Columbus',
          image: 'https://api.millenium.link/images/games/columbus_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/columbus_inbet',
        },
        {
          id: 12,
          name: 'Columbus Deluxe',
          image: 'https://api.millenium.link/images/games/columbus_deluxe_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/columbus_deluxe_inbet',
        },
        {
          id: 13,
          name: 'Dolphins Pearl',
          image: 'https://api.millenium.link/images/games/dolphins_pearl_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/dolphins_pearl_inbet',
        },
        {
          id: 14,
          name: 'Dolphins Pearl Deluxe',
          image: 'https://api.millenium.link/images/games/dolphins_pearl_deluxe_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/dolphins_pearl_deluxe_inbet',
        },
        {
          id: 15,
          name: 'Just Jewels',
          image: 'https://api.millenium.link/images/games/just_jewels_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/just_jewels_inbet',
        },
        {
          id: 16,
          name: 'Banana Splash',
          image: 'https://api.millenium.link/images/games/banana_splash_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/banana_splash_inbet',
        },
        {
          id: 17,
          name: 'Just Jewels Deluxe',
          image: 'https://api.millenium.link/images/games/just_jewels_deluxe_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/just_jewels_deluxe_inbet',
        },
        {
          id: 18,
          name: 'Pharaohs Gold Deluxe',
          image: 'https://api.millenium.link/images/games/pharaohs_gold_deluxe_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/pharaohs_gold_deluxe_inbet',
        },
        {
          id: 19,
          name: 'Hat Trick',
          image: 'https://api.millenium.link/images/games/hat_trick_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/hat_trick_inbet',
        },
        {
          id: 20,
          name: 'Book Of Dead',
          image: 'https://api.millenium.link/images/games/book_of_dead_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/book_of_dead_inbet',
        },
        {
          id: 21,
          name: 'Unicorn Magic',
          image: 'https://api.millenium.link/images/games/unicorn_magic_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/unicorn_magic_inbet',
        },
        {
          id: 23,
          name: 'The Euro Game',
          image: 'https://api.millenium.link/images/games/the_euro_game_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/the_euro_game_inbet',
        },
        {
          id: 26,
          name: 'Inferno',
          image: 'https://api.millenium.link/images/games/inferno_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/inferno_inbet',
        },
        {
          id: 41,
          name: 'Dazzling Diamonds',
          image: 'https://api.millenium.link/images/games/dazzling_diamonds_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/dazzling_diamonds_inbet',
        },
        {
          id: 42,
          name: 'The Real King Aloha Hawaii',
          image: 'https://api.millenium.link/images/games/the_real_king_aloha_hawaii_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/the_real_king_aloha_hawaii_inbet',
        },
        {
          id: 47,
          name: 'Hannibal Of Carthago',
          image: 'https://api.millenium.link/images/games/hannibal_of_carthago_inbet.png',
          images: [],
          manufacturer_id: 1,
          status: 10,
          link: 'https://api.millenium.link/game/hannibal_of_carthago_inbet',
        },
      ],
    },
    {
      package: {
        id: 2,
        name: 'Millenium',
        image: 'https://api.millenium.link/images/providers/2.png',
        images: [],
        status: 10,
      },
      games: [
        {
          id: 63,
          name: 'Book of Strike',
          image: 'https://api.millenium.link/images/games/millenium_book_of_strike.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_book_of_strike',
        },
        {
          id: 68,
          name: 'Crazy Hockey',
          image: 'https://api.millenium.link/images/games/millenium_crazy_hockey.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_crazy_hockey',
        },
        {
          id: 70,
          name: 'Gold Basketball',
          image: 'https://api.millenium.link/images/games/millenium_gold_basketball.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_gold_basketball',
        },
        {
          id: 71,
          name: 'Bloody Garage',
          image: 'https://api.millenium.link/images/games/millenium_bloody_garage.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_bloody_garage',
        },
        {
          id: 72,
          name: 'Cocktail Football',
          image: 'https://api.millenium.link/images/games/millenium_cocktail_football.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_cocktail_football',
        },
        {
          id: 73,
          name: 'Shark Tanks',
          image: 'https://api.millenium.link/images/games/millenium_shark_tanks.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_shark_tanks',
        },
        {
          id: 76,
          name: 'Lord of Warcraft',
          image: 'https://api.millenium.link/images/games/millenium_lord_of_warcraft.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_lord_of_warcraft',
        },
        {
          id: 79,
          name: 'Lucky Dota',
          image: 'https://api.millenium.link/images/games/millenium_lucky_dota.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_lucky_dota',
        },
        {
          id: 91,
          name: 'Dirty Money',
          image: 'https://api.millenium.link/images/games/millenium_dirty_money.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_dirty_money',
        },
        {
          id: 97,
          name: 'Columbus Warship',
          image: 'https://api.millenium.link/images/games/millenium_columbus_warship.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_columbus_warship',
        },
        {
          id: 100,
          name: 'Starcraft Pearl',
          image: 'https://api.millenium.link/images/games/millenium_starcraft_pearl.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_starcraft_pearl',
        },
      ],
    },
    {
      package: {
        id: 3,
        name: 'Igrosoft',
        image: 'https://api.millenium.link/images/providers/3.png',
        images: [],
        status: 10,
      },
      games: [
        {
          id: 124,
          name: 'Crazy Monkey',
          image: 'https://api.millenium.link/images/games/crazy_monkey.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/crazy_monkey',
        },
        {
          id: 125,
          name: 'Crazy Monkey Deluxe',
          image: 'https://api.millenium.link/images/games/crazy_monkey_deluxe.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/crazy_monkey_deluxe',
        },
        {
          id: 126,
          name: 'Fruit Cocktail',
          image: 'https://api.millenium.link/images/games/fruit_cocktail.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/fruit_cocktail',
        },
        {
          id: 127,
          name: 'Fruit Cocktail Deluxe',
          image: 'https://api.millenium.link/images/games/fruit_cocktail_deluxe.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/fruit_cocktail_deluxe',
        },
        {
          id: 128,
          name: 'Garage',
          image: 'https://api.millenium.link/images/games/garage.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/garage',
        },
        {
          id: 129,
          name: 'Gnome',
          image: 'https://api.millenium.link/images/games/gnome.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/gnome',
        },
        {
          id: 130,
          name: 'Island',
          image: 'https://api.millenium.link/images/games/island.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/island',
        },
        {
          id: 131,
          name: 'Keks',
          image: 'https://api.millenium.link/images/games/keks.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/keks',
        },
        {
          id: 132,
          name: 'Lucky Haunter',
          image: 'https://api.millenium.link/images/games/lucky_haunter.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/lucky_haunter',
        },
        {
          id: 133,
          name: 'Pirate',
          image: 'https://api.millenium.link/images/games/pirate.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/pirate',
        },
        {
          id: 134,
          name: 'Resident',
          image: 'https://api.millenium.link/images/games/resident.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/resident',
        },
        {
          id: 135,
          name: 'Rock Climber',
          image: 'https://api.millenium.link/images/games/rock_climber.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/rock_climber',
        },
        {
          id: 136,
          name: 'Sweet Life',
          image: 'https://api.millenium.link/images/games/sweet_life.png',
          images: [],
          manufacturer_id: 3,
          status: 10,
          link: 'https://api.millenium.link/game/sweet_life',
        },
      ],
    },
    {
      package: {
        id: 4,
        name: 'Novomatic (millenium)',
        image: 'https://api.millenium.link/images/providers/4.png',
        images: [],
        status: 10,
      },
      games: [
        {
          id: 57,
          name: 'Age of Heroes Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_age_of_heroes_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_age_of_heroes_deluxe',
        },
        {
          id: 58,
          name: 'Always American Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_always_american_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_always_american_deluxe',
        },
        {
          id: 59,
          name: 'Always Hot',
          image: 'https://api.millenium.link/images/games/millenium_always_hot.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_always_hot',
        },
        {
          id: 60,
          name: 'Always Hot Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_always_hot_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_always_hot_deluxe',
        },
        {
          id: 61,
          name: 'Amazon Treasures Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_amazon_treasures_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_amazon_treasures_deluxe',
        },
        {
          id: 62,
          name: 'Book of Ra Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_book_of_ra_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_book_of_ra_deluxe',
        },
        {
          id: 64,
          name: 'Faust Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_faust_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_faust_deluxe',
        },
        {
          id: 65,
          name: 'Gate of Ra Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_gate_of_ra_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_gate_of_ra_deluxe',
        },
        {
          id: 66,
          name: 'Golden Ark Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_golden_ark_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_golden_ark_deluxe',
        },
        {
          id: 67,
          name: 'Lord of the Ocean Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_lord_of_the_ocean_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_lord_of_the_ocean_deluxe',
        },
        {
          id: 75,
          name: 'Book of Ra',
          image: 'https://api.millenium.link/images/games/millenium_book_of_ra.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_book_of_ra',
        },
        {
          id: 77,
          name: 'Lucky Lady Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_lucky_lady_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_lucky_lady_deluxe',
        },
        {
          id: 78,
          name: 'Lucky Lady Charm',
          image: 'https://api.millenium.link/images/games/millenium_lucky_lady_charm.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_lucky_lady_charm',
        },
        {
          id: 80,
          name: 'Sizzling Hot',
          image: 'https://api.millenium.link/images/games/millenium_sizzling_hot.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_sizzling_hot',
        },
        {
          id: 81,
          name: 'Sizzling Hot Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_sizzling_hot_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_sizzling_hot_deluxe',
        },
        {
          id: 82,
          name: 'Sizzling Hot 7 Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_sizzling_hot7_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_sizzling_hot7_deluxe',
        },
        {
          id: 83,
          name: 'Sizzling Gems Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_sizzling_gems_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_sizzling_gems_deluxe',
        },
        {
          id: 84,
          name: 'Sizzling Eight Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_sizzling_eight_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_sizzling_eight_deluxe',
        },
        {
          id: 87,
          name: 'Fruits and Royals Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_fruits_n_royals_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_fruits_n_royals_deluxe',
        },
        {
          id: 88,
          name: 'Bananas Go Bahamas',
          image: 'https://api.millenium.link/images/games/millenium_bananas.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_bananas',
        },
        {
          id: 89,
          name: 'Money Game',
          image: 'https://api.millenium.link/images/games/millenium_money_game.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_money_game',
        },
        {
          id: 90,
          name: 'Money Game Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_money_game_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_money_game_deluxe',
        },
        {
          id: 92,
          name: 'Columbus',
          image: 'https://api.millenium.link/images/games/millenium_columbus.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_columbus',
        },
        {
          id: 93,
          name: 'Columbus Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_columbus_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_columbus_deluxe',
        },
        {
          id: 94,
          name: 'Holmes Watson Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_holmes_watson_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_holmes_watson_deluxe',
        },
        {
          id: 95,
          name: 'Secret Of Egypt Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_secret_of_egypt_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_secret_of_egypt_deluxe',
        },
        {
          id: 96,
          name: 'Haute Couture',
          image: 'https://api.millenium.link/images/games/millenium_haute_couture.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_haute_couture',
        },
        {
          id: 98,
          name: 'Dolphins Pearl',
          image: 'https://api.millenium.link/images/games/millenium_dolphins_pearl.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_dolphins_pearl',
        },
        {
          id: 99,
          name: 'Dolphins Pearl Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_dolphins_pearl_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_dolphins_pearl_deluxe',
        },
        {
          id: 102,
          name: 'Just Jewels',
          image: 'https://api.millenium.link/images/games/millenium_just_jewels.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_just_jewels',
        },
        {
          id: 103,
          name: 'Just Jewels Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_just_jewels_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_just_jewels_deluxe',
        },
        {
          id: 104,
          name: 'Just Fruits Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_just_fruits_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_just_fruits_deluxe',
        },
        {
          id: 105,
          name: 'Banana Splash',
          image: 'https://api.millenium.link/images/games/millenium_banana_splash.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_banana_splash',
        },
        {
          id: 106,
          name: 'Emperors China',
          image: 'https://api.millenium.link/images/games/millenium_emperors_china.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_emperors_china',
        },
        {
          id: 107,
          name: 'Royal Treasures',
          image: 'https://api.millenium.link/images/games/millenium_royal_treasures.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_royal_treasures',
        },
        {
          id: 108,
          name: 'Royal Treasures Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_royal_treasures_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_royal_treasures_deluxe',
        },
        {
          id: 109,
          name: 'Egyptian Experience Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_egyptian_experience_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_egyptian_experience_deluxe',
        },
        {
          id: 110,
          name: 'The Ming Dynasty Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_the_ming_dynasty_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_the_ming_dynasty_deluxe',
        },
        {
          id: 111,
          name: 'Silver Fox Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_silver_fox_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_silver_fox_deluxe',
        },
        {
          id: 112,
          name: 'Hannibal Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_hannibal_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_hannibal_deluxe',
        },
        {
          id: 113,
          name: 'Gryphons Gold Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_gryphons_gold_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_gryphons_gold_deluxe',
        },
        {
          id: 114,
          name: 'Gold Craze Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_gold_craze_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_gold_craze_deluxe',
        },
        {
          id: 115,
          name: 'Cold Spell Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_cold_spell_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_cold_spell_deluxe',
        },
        {
          id: 116,
          name: 'Gryphons Gold',
          image: 'https://api.millenium.link/images/games/millenium_gryphons_gold.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_gryphons_gold',
        },
        {
          id: 117,
          name: 'Unicorn Magic',
          image: 'https://api.millenium.link/images/games/millenium_unicorn_magic.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_unicorn_magic',
        },
        {
          id: 121,
          name: 'Hat Trick',
          image: 'https://api.millenium.link/images/games/millenium_hat_trick.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_hat_trick',
        },
        {
          id: 122,
          name: 'Marco Polo Deluxe',
          image: 'https://api.millenium.link/images/games/millenium_marco_polo_deluxe.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_marco_polo_deluxe',
        },
        {
          id: 123,
          name: 'Illusionist',
          image: 'https://api.millenium.link/images/games/millenium_illusionist.png',
          images: [],
          manufacturer_id: 2,
          status: 10,
          link: 'https://api.millenium.link/game/millenium_illusionist',
        },
      ],
    },
    {
      package: {
        id: 5,
        name: 'Belatra',
        image: 'https://api.millenium.link/images/providers/5.png',
        images: [],
        status: 10,
      },
      games: [
        {
          id: 137,
          name: 'Alcatraz',
          image: 'https://api.millenium.link/images/games/alcatraz.png',
          images: [],
          manufacturer_id: 4,
          status: 10,
          link: 'https://api.millenium.link/game/alcatraz',
        },
        {
          id: 138,
          name: 'Fairy Land',
          image: 'https://api.millenium.link/images/games/fairy_land.png',
          images: [],
          manufacturer_id: 4,
          status: 10,
          link: 'https://api.millenium.link/game/fairy_land',
        },
        {
          id: 139,
          name: 'Lucky Drink',
          image: 'https://api.millenium.link/images/games/lucky_drink.png',
          images: [],
          manufacturer_id: 4,
          status: 10,
          link: 'https://api.millenium.link/game/lucky_drink',
        },
      ],
    },
    {
      package: {
        id: 6,
        name: 'Playtech',
        image: 'https://api.millenium.link/images/providers/6.png',
        images: [],
        status: 10,
      },
      games: [
        {
          id: 140,
          name: 'Arctic Treasure',
          image: 'https://api.millenium.link/images/games/arctic_treasure.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/arctic_treasure',
        },
        {
          id: 141,
          name: 'Azteca',
          image: 'https://api.millenium.link/images/games/azteca.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/azteca',
        },
        {
          id: 142,
          name: 'Captains Treasure',
          image: 'https://api.millenium.link/images/games/captains_treasure.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/captains_treasure',
        },
        {
          id: 143,
          name: 'Cherry Love',
          image: 'https://api.millenium.link/images/games/cherry_love.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/cherry_love',
        },
        {
          id: 145,
          name: 'Discovery',
          image: 'https://api.millenium.link/images/games/discovery.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/discovery',
        },
        {
          id: 146,
          name: 'Golden Games',
          image: 'https://api.millenium.link/images/games/golden_games.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/golden_games',
        },
        {
          id: 147,
          name: 'Great Blue',
          image: 'https://api.millenium.link/images/games/great_blue.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/great_blue',
        },
        {
          id: 148,
          name: 'Hulk',
          image: 'https://api.millenium.link/images/games/hulk.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/hulk',
        },
        {
          id: 149,
          name: 'Hulk 50',
          image: 'https://api.millenium.link/images/games/hulk50.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/hulk50',
        },
        {
          id: 150,
          name: 'Irish Luck',
          image: 'https://api.millenium.link/images/games/irish_luck.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/irish_luck',
        },
        {
          id: 151,
          name: 'Iron Man',
          image: 'https://api.millenium.link/images/games/iron_man.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/iron_man',
        },
        {
          id: 152,
          name: 'Iron Man 2',
          image: 'https://api.millenium.link/images/games/iron_man2.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/iron_man2',
        },
        {
          id: 153,
          name: 'Iron Man 2 (50 lines)',
          image: 'https://api.millenium.link/images/games/iron_man2_50_lines.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/iron_man2_50_lines',
        },
        {
          id: 154,
          name: 'Panther Moon',
          image: 'https://api.millenium.link/images/games/panther_moon.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/panther_moon',
        },
        {
          id: 155,
          name: 'Penguin Vacation',
          image: 'https://api.millenium.link/images/games/penguin_vacation.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/penguin_vacation',
        },
        {
          id: 156,
          name: 'Rome And Glory',
          image: 'https://api.millenium.link/images/games/rome_and_glory.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/rome_and_glory',
        },
        {
          id: 157,
          name: 'Russian Love',
          image: 'https://api.millenium.link/images/games/russian_love.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/russian_love',
        },
        {
          id: 158,
          name: 'Safari Heat',
          image: 'https://api.millenium.link/images/games/safari_heat.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/safari_heat',
        },
        {
          id: 159,
          name: 'Skazka',
          image: 'https://api.millenium.link/images/games/skazka.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/skazka',
        },
        {
          id: 160,
          name: 'Sparta',
          image: 'https://api.millenium.link/images/games/sparta.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/sparta',
        },
        {
          id: 161,
          name: 'Thai Paradise',
          image: 'https://api.millenium.link/images/games/thai_paradise.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/thai_paradise',
        },
        {
          id: 162,
          name: 'True Love',
          image: 'https://api.millenium.link/images/games/true_love.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/true_love',
        },
        {
          id: 163,
          name: 'Wild Spirit',
          image: 'https://api.millenium.link/images/games/wild_spirit.png',
          images: [],
          manufacturer_id: 5,
          status: 10,
          link: 'https://api.millenium.link/game/wild_spirit',
        },
      ],
    },
  ]

  const packets = () => {
    return (
      <Grid item xs={12}>
        <Box>
          <b>{t('ru.tables.tabs.packets')}:</b>
          <br />
        </Box>
        {mockTablePackages.map((item: any, id: number) => {
          return (
            <React.Fragment key={id}>
              <ListItem>
                <Box display="flex" alignItems="center">
                  <BorderOuterIcon />
                  {item.package.name}
                </Box>
              </ListItem>
              <Collapse in={true}>
                <Box>
                  {item.games.map((game: IGame) => {
                    return (
                      <Chip
                        key={game.id}
                        label={
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <span>{game.name}</span>
                          </Box>
                        }
                      />
                    )
                  })}
                </Box>
              </Collapse>
            </React.Fragment>
          )
        })}
      </Grid>
    )
  }

  const [isChange, setIsChange] = React.useState(false)

  const rootRender = (
    <>
            <Box className={classes.closeBtn}>

    {
            <Tooltip
              title={
                isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))
              }
              placement="top"
            >
              <IconButton onClick={() => setIsChange(!isChange)}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    background: '#b6b6b6b6',
                    width: '30px',
                    minHeight: '30px',
                    borderRadius: '50%',
                    padding: '6px',
                  }}
                >
                  <img
                    style={{
                      width: '20px',
                      minHeight: 'auto',
                    }}
                    src={!isChange ? editPng : visionPng}
                    alt="icon"
                  />
                </Box>
              </IconButton>
            </Tooltip>
          }
      <IconButton onClick={hideTerminal}>
        <CloseIcon />
      </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <h2>Терминал 123</h2>
      </Box>
      {isChange ? (
        <TerminalForm terminal cancelBtnEvent={() => setIsChange(false)} />
      ) : (
        <>
          <Box className="tabs" display="flex" justifyContent="flex-start">
            <TabItem
              active={tab === 0}
              onClick={() => {
                setTab(0)
              }}
            >
              {t('ru.tables.tabs.info')}
            </TabItem>
            <TabItem
              active={tab === 1}
              onClick={() => {
                setTab(1)
              }}
            >
              {t('ru.tables.tabs.packets')}
            </TabItem>
            <TabItem
              active={tab === 2}
              onClick={() => {
                setTab(2)
              }}
            >
              {t('ru.tables.tabs.jackpots')}
            </TabItem>
          </Box>
          <br />
          <Grid container spacing={2}>
            {returnTab(tab)}
          </Grid>
        </>
      )}
    </>
  )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Terminal
