import React from 'react'
import PlayersList from './List'
import {
  getPlayersList,
  playerSelector,
} from '../../redux/playerReducer'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector } from '../../redux/authReducer'
import { getAlltables, tableSelector } from '../../redux/tableReducer'

const Players: React.FC = () => {
  const dispatch = useDispatch()
  const { items: players } = useSelector(playerSelector)

  const { status } = useSelector(authSelector)

  const {
    tables: { data: tables },
  } = useSelector(tableSelector)

  React.useEffect(() => {
    if (status === 200) {
      !players.length && dispatch(getPlayersList())
    }
    // eslint-disable-next-line
  }, [dispatch, status])

  React.useEffect(() => {
    if (status === 200) {
      !tables.length && dispatch(getAlltables(1, 50))
    }
    // eslint-disable-next-line
  }, [status, dispatch])




  return (
    <div style={{ width: '100%' }}>
      <PlayersList />
    </div>
  )
}

export default Players
