import axios from 'axios'
import { CountryData } from '../interfaces'

export const fetchCountryList = async () => {
    return axios.get('country')
}

export const addCountry = async (data: CountryData) => {
    return axios.post('country', data)
}

// export const fetchCountry = async (id: number) => {
//     return axios.get(`Country/${id}`)
// }

// export const changeCountry = async (id: number) => {
//     return axios.put(`Country/${id}`)
// }

// export const deleteCountry = async (id: number) => {
//     return axios.delete(`Country/${id}`)
// }
