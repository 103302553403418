import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    color: '#666',
  },
  textFields: {
    width: '100%',
  },
  submitBtn: {
    margin: '1vh',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%',
  },
  chip: {
    margin: '.5vh',
    padding: '0 1vh',
  },
  icon: {
    margin: '.5vh',
  },
  packet: {
    background: '#f3f7f9',
    padding: '0 1vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
  },
  tables: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tablesCount: {
    width: '75px',
  },
})

export const FormWrapper = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const SubForm = styled.div`
  position: absolute;
  background: #fff;
  padding: 5vh;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 999;
  border-radius: 1vh;

  ${down('sm')} {
    width: 90%;
  }
`
