import { createSlice } from '@reduxjs/toolkit'
import { getUserTransfers } from '../services/User'
import { AppThunk } from './store'
import { ITransfer } from '../interfaces'
import { getHallsOperations } from '../services/Halls'
import { getTableOperations } from '../services/GamblingTable'

interface TransfersInitialState {
  pageCount: null | number
  items: ITransfer[]
  loading: boolean
  error: TypeError | null
}

export const initialState: TransfersInitialState = {
  pageCount: null,
  items: [],
  loading: false,
  error: null,
}

export const slice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    userTransfersListIsLoading: (state) => {
      state.loading = true
    },
    userTransfersListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    userTransfersListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    setTransfersPage: (state, { payload }) => {
      state.pageCount = payload
    },
  },
})

export const handlerUserTransfers = (id: number | undefined, page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(userTransfersListIsLoading())

    return (
      id && 
      getUserTransfers(id, page, limit)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(userTransfersListSuccess(res.data.data))
            dispatch(setTransfersPage(res.headers['x-pagination-page-count']))
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(userTransfersListFailure([res.data.data]))
            } else {
              dispatch(userTransfersListFailure(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(userTransfersListFailure(err)))
    )
  }
}

export const handlerHallTransfers = (id: number | undefined, page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(userTransfersListIsLoading())

    return (
      id &&
      getHallsOperations(id, page, limit)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(userTransfersListSuccess(res.data.data))
            dispatch(setTransfersPage(res.headers['x-pagination-page-count']))
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(userTransfersListFailure([res.data.data]))
            } else {
              dispatch(userTransfersListFailure(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(userTransfersListFailure(err)))
    )
  }
}

export const handlerTableTransfers = (id: number | undefined, page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(userTransfersListIsLoading())

    return (
      id &&
      getTableOperations(id, page, limit)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(userTransfersListSuccess(res.data.data))
            dispatch(setTransfersPage(res.headers['x-pagination-page-count']))
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(userTransfersListFailure([res.data.data]))
            } else {
              dispatch(userTransfersListFailure(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(userTransfersListFailure(err)))
    )
  }
}

export const {
  userTransfersListIsLoading,
  userTransfersListSuccess,
  userTransfersListFailure,
  setTransfersPage
} = slice.actions

interface IState {
  transfers: TransfersInitialState
}

export const transfersSelector = (state: IState) => state.transfers

export default slice.reducer
