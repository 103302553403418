import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  span {
    width: 20%;
    height: 35px;
    color: #010e2a;
    font-size: 80%;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span:first-child {
    width: 15%;
  }
`

type ListItemType = {
  bg: string
  cursor?: string
}

export const ListItem = styled.div`
  cursor: ${(props: ListItemType) => (props.cursor ? props.cursor : 'default')};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${(props: ListItemType) => props.bg};
  margin: 0.3vh auto;

  &:hover {
    background: ${(props: ListItemType) => (props.cursor ? '#f3f3f3' : props.bg)};
  }
  div {
    position: relative;
    width: 20%;
    height: 3.6em;
    color: #010e2a;
    font-size: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  div:first-child {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`
