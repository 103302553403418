import api from "./index";

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        findPlayers: builder.query<Player[], WithPagination<PlayerFormFind | void>>({
            query: (form) => {
                const params: Record<string, string> = { expand: "profile" };
                if (form.page) {
                    params.page = form.page?.toString();
                }
                if (form.data) {
                    let k: keyof PlayerFormFind;
                    for (k in form.data) {
                        if (k && form.data[k]) {
                            params[`f[${k}]`] = form.data[k]!.toString();
                        }
                    }
                }
                return { url: `player?${new URLSearchParams(params).toString()}`, method: "get" };
            },
            transformResponse: (response: any) => response.data,
            providesTags: ["players"],
        }),
        addPlayer: builder.mutation<DefaultResponse<Player>, PlayerRequestCreate>({
            query: (form) => ({ url: "player", method: "post", data: form }),
            invalidatesTags: ["players"],
        }),
        deletePlayer: builder.mutation<DefaultResponse<boolean>, number>({
            query: (playerId) => ({ url: `player/${playerId}`, method: "delete" }),
            invalidatesTags: ["players"],
        }),
    }),
});

export const { useFindPlayersQuery, useAddPlayerMutation, useDeletePlayerMutation } = authApi;