import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Wrapper = styled.div`
  position: relative;
  margin: 7vh 0;
  border-radius: 1vh;
  max-width: 700px;
  height: max-content;
  background: #fff;
  padding: 5vh;
  z-index: 9999;

  ${down('sm')} {
    width: 100%;
  }
`
type GameItemType = {
  highlights?: boolean
  showline?: boolean
  isLineSelected?: boolean
  isScatterSelected?: boolean
  bg?: string
}
export const GameItem = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 170%;
  transition: box-shadow 0.2s ease;
    ${(props: GameItemType) =>
    props.showline && `border: 2px solid ${props.highlights ? '#7aff4f' : ''};`}
     ${(props: GameItemType) => props.highlights && `border: 2px solid #7aff4f;`}


  ${(props: GameItemType) =>
    props.isLineSelected &&
    `filter: ${props.showline ? 'blur(0)' : 'blur(3px)'};`}

  ${(props: GameItemType) =>
  props.isScatterSelected &&
    `filter: ${props.highlights ? 'blur(0)' : 'blur(3px)'};`}

`
