import { Box } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Outstandings from './Outstandings'
import Reports from './Reports'
import State from './State'
import { TabItem } from './styles'
import Alerts from './Alerts'
import Collections from './Collections'
import PaymentErrors from './PaymentErrors'

const Terminals: React.FC = () => {
  const [t] = useTranslation()

  const [tab, setTab] = React.useState<number>(0)

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return <State />
      case 1:
        return <Reports />
      case 2:
        return <Collections />
      case 3:
        return <PaymentErrors />
      case 4:
        return <Outstandings />
      case 5:
        return <Alerts />
      default:
        break
    }
  }

  return (
    <>
      <Box className="tabs" display="flex" justifyContent="flex-start">
        <TabItem
          active={tab === 0}
          onClick={() => {
            setTab(0)
          }}
        >
          {t('ru.terminals.tabs.state')}
        </TabItem>
        <TabItem
          active={tab === 1}
          onClick={() => {
            setTab(1)
          }}
        >
          {t('Отчеты')}
        </TabItem>
        <TabItem
          active={tab === 2}
          onClick={() => {
            setTab(2)
          }}
        >
          {t('Инкасации')}
        </TabItem>
        <TabItem
          active={tab === 3}
          onClick={() => {
            setTab(3)
          }}
        >
          {t('Ошибки выплат')}
        </TabItem>
        <TabItem
          active={tab === 4}
          onClick={() => {
            setTab(4)
          }}
        >
          {t('ru.terminals.tabs.outstandings')}
        </TabItem>
        <TabItem
          active={tab === 5}
          onClick={() => {
            setTab(5)
          }}
        >
          {t('Оповещения')}
        </TabItem>
      </Box>
      {returnTab(tab)}
    </>
  )
}

export default Terminals
