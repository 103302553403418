import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  h2 {
    margin: 1vh 0;
    font-size: 2.9vh;
  }

  .cashbox {
    overflow-y: auto;
    height: 56vh;
    width: 100%;
    overflow-x: hidden;
  }
`
export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: stretch;
  span {
    width: 20%;
    height: max-content;
    color: #010e2a;
    font-size: 1.6vh;
    text-align: center;
    white-space: pre-line;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
    text-align: center;
  }
  ${down('md')} {
    span:nth-child(7),
    span:nth-child(8),
    span:nth-child(9) {
      display: none;
    }
  }

  ${down('sm')} {
    span:nth-child(5) {
      display: none;
    }
`
type ListItemType = {
  bg: string
  disabled: boolean
}
export const ListItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: ${(props: ListItemType) => props.bg};
    margin: 0.5vh;
    opacity: ${(props: ListItemType) => props.disabled ? .6 : 1};

    div {
        position: relative;
        width: 20%;
        height: max-content;
        min-height: 4.9vh;
        color: #010e2a;
        font-size: 1.6vh;
        white-space: pre-line;
        overflow: hidden;
        padding: 5px;
        text-overflow: ellipsis;
        text-align: center;
    }
        img {
            width: 1.5vh;
            cursor: pointer;
        }
    }

    ${down('md')} {
    div:nth-child(7),
    div:nth-child(8),
    div:nth-child(9) {
      display: none;
    }

    ${down('sm')} {
    div:nth-child(5) {
      display: none;
    }



`
type StatusType = {
  active: boolean
}
export const Status = styled.span`
  height: 3vh;
  background: ${(props: StatusType) => (props.active ? '#87b03b' : '#b03c3c')};
  color: #fff;
  border-radius: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh 3vh;
  width: 15vh;
`
type ActionType = {
  bg: string
}
export const Action = styled.span`
  width: 3vh;
  height: 3vh;
  margin: 0.3vh;
  background: ${(props: ActionType) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
`
