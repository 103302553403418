import axios from 'axios'
import { PackageData, ImagesData } from '../interfaces'

export const fetchGamePackageList = async (search = {
    id: '',
    name: ''
}) => {
    return axios.get(`game-package`, {
        params: { expand: 'games', 'f[id]': search.id, 'f[name]': search.name },
    })
}

export const addGamePackage = async (data: PackageData) => {
    return axios.post(`game-package`, data, {
        params: { expand: 'games' },
    })
}

export const fetchGamePackage = async (id: number) => {
    return axios.get(`game-package/${id}`)
}

export const changeGamePackage = async (data: PackageData, id: number) => {
    return axios.put(`game-package/${id}`, data, {
        params: { expand: 'games' },
    })
}

export const deleteGamePackage = async (id: number) => {
    return axios.delete(`game-package/${id}`)
}

export const addGamePackageImage = async (data: ImagesData, id: number) => {
    return axios.post(`game-package/${id}/image/attach`, data)
}

export const deleteGamePackageImage = async (id: number, image_id: number) => {
    return axios.post(`game-package/${id}/image/${image_id}/delete`)
}