import React, { FunctionComponent } from 'react';
import { ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SortButton from './sort-button';
import { TableHeader } from './styles';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../redux/authReducer';
import { findPermissionOr } from '../../../utils/find';

type HeaderProps = {
    isModal: boolean,
    selectedSort: string,
    sortDir: boolean,
    setSelectedSort: (name: string) => void,
    setSortDir: (asc: boolean) => void
};

const Header: FunctionComponent<HeaderProps> = React.memo(({ isModal, selectedSort, sortDir, setSelectedSort, setSortDir }) => {
    const { assigments: { items } } = useSelector(authSelector);
    const [t] = useTranslation();
    return <TableHeader isModal={isModal}>
        <SortButton name='id' label='id' selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <SortButton name='login' label={t('ru.tables.fields.login')} selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <SortButton name='name' label={t('ru.tables.fields.name')} selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <SortButton name='denomination' label={t('ru.tables.fields.denomination')} selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <ListItem component="span">{t('ru.tables.fields.balance')}</ListItem>
        <SortButton name='highscore' label={t('ru.tables.fields.highscore')} selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <SortButton name='pollscore' label={t('ru.tables.fields.poll')} selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <ListItem component="span">{t('ru.tables.fields.payout')}</ListItem>
        {findPermissionOr(items, ['viewTableMath', 'viewTableMathChildren']) && <ListItem component="span">{t('ru.tables.fields.math')}</ListItem>}
        <SortButton name='online' label={t('ru.tables.fields.status')} selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <ListItem component="span"></ListItem>
    </TableHeader>;
});

export default Header;