import { createSlice } from '@reduxjs/toolkit'
interface PaymentInitialState {
    form: {
        payment: any,
        loading: boolean
        error: TypeError[]
    }
}
export const initialState: PaymentInitialState = {
    form: {
        payment: null,
        loading: false,
        error: [],
    },
}
export const slice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        paymentFormIsLoading: (state) => {
            state.form.loading = true
        },
        paymentFormSucces: (state, { payload }) => {
            state.form.payment = payload
            state.form.loading = false
            state.form.error = []
        },
        paymentFormIsError: (state, { payload }) => {
            state.form.loading = false
            state.form.error = payload
        },
    },
})

export const {
    paymentFormIsLoading,
    paymentFormSucces,
    paymentFormIsError,
} = slice.actions

interface IState {
    payment: PaymentInitialState
}

export const paymentSelector = (state: IState) => state.payment

export default slice.reducer
