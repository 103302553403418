import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Wrapper = styled.div`
  position: relative;
  margin: 7vh 0;
  border-radius: 1vh;
  max-width: 800px;
  height: max-content;
  background: #fff;
  padding: 5vh;
  z-index: 9999;

  ${down('sm')} {
    width: 100%;
  }
`
