import axios from 'axios'
import { CurrencyData } from '../interfaces'

export const fetchCurrencyList = async () => {
    return axios.get('currency')
}

export const addCurrency = async (data: CurrencyData) => {
    return axios.post('currency', data)
}

// export const fetchCurrency = async (id: number) => {
//     return axios.get(`currency/${id}`)
// }

// export const changeCurrency = async (id: number) => {
//     return axios.put(`currency/${id}`)
// }

// export const deleteCurrency = async (id: number) => {
//     return axios.delete(`currency/${id}`)
// }
