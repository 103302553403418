import React, { FunctionComponent } from 'react';
import { Grid, TextField } from '@material-ui/core';

type FieldAddressProps = {
    className: string;
    label: string;
    value: string;
    onChange: (obj: any) => void;
};

function cmp(prev: FieldAddressProps, cur: FieldAddressProps): boolean {
    return prev.className === cur.className && prev.label === cur.label && prev.value === cur.value;
}

const FieldAddress: FunctionComponent<FieldAddressProps> = React.memo(({ className, label, value, onChange }) => {
    return <Grid item xs={12} sm={8}>
        <TextField className={className} label={label} type="search" name="address" value={value} onChange={onChange} size="small" />
    </Grid>;
}, cmp);

export default FieldAddress;