import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  ListItemText,
  NativeSelect,
} from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isConfirmType, IUser } from '../../../../interfaces'
import {
  hallSelector,
  handleBindingToHall,
  handleRemoveToHall,
} from '../../../../redux/hallReducer'
import { userSelector } from '../../../../redux/userReducer'
import ClearIcon from '@material-ui/icons/Clear'
import Overlay from '../../../Common/Overlay'
import Confirm from '../../../Common/Confirm'
import { convertUTCDateToLocalDate } from '../../../../utils/date'
import { useTranslation } from 'react-i18next'

interface BindingProps {
  hall_id: null | number
  change?: boolean
}
const Binding: React.FC<BindingProps> = ({ hall_id, change }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const {
    users: { data: users },
  } = useSelector(userSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const [selectedParentId, setSelectedParentId] = React.useState<number | null>(null)
  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({
    open: false,
  })

  const getCashboxUsers = () => {
    let arr: number[] = []
    halls
      .find((i) => i.id === hall_id)
      ?.cashbox?.forEach((item: any) => {
        arr = [...arr, item.user_id]
      })
    return arr
  }

  return (
    <Box
      style={{ width: '100%' }}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      flexDirection="column"
    >
      <Box style={{ width: '100%', background: '#efeff0', padding: '5px' }}>
        {halls &&
          halls
            .find((i) => i.id === hall_id)!
            .cashbox?.map((item: any) => {
              return (
                <Box
                  key={item.id}
                  style={{
                    width: '100%',
                    background: '#fff',
                    margin: '5px auto',
                    padding: '1px 6px',
                  }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span style={{ marginRight: '15px' }}>{item.id}.</span>
                  <ListItemText primary={`${users.find((i) => i.id === item.user_id) ? users.find((i) => i.id === item.user_id)!.login : ''}`} />
                  <span style={{ fontSize: '70%' }}>
                    {item.created_at && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="row"
                        component="section"
                      >
                        ({' '}
                        <span style={{ margin: '3px' }}>
                          {convertUTCDateToLocalDate(item.created_at)}
                        </span>
                        )
                      </Box>
                    )}
                  </span>
                  {change && (
                    <IconButton onClick={() => setIsConfirm({ id: item.user_id, open: true })}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </Box>
              )
            })}
        {!!halls && !halls.find((i) => i.id === hall_id)!.cashbox.length && <span>Нет данных</span>}
      </Box>
      {change && (
        <Box
          style={{ width: '100%' }}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <FormControl style={{ width: '200px' }} fullWidth size="small">
            <FormHelperText>{t('ru.halls.form_helpers.users')}</FormHelperText>
            <NativeSelect
              onChange={(e) => {
                setSelectedParentId(Number(e.target.value))
              }}
              value={selectedParentId}
            >
              <option aria-label="None" value="" />
              {users &&
                // eslint-disable-next-line
                users.map((item: IUser) => {
                  for (let role in item.roles) {
                    if (item.roles[role].name === 'cashier') {
                      return (
                        !getCashboxUsers().find((i) => i === item.id) && (
                          <option value={item.id} key={item.id}>
                            {item.login}
                          </option>
                        )
                      )
                    }
                  }
                })}
            </NativeSelect>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() =>
              hall_id &&
              selectedParentId &&
              dispatch(handleBindingToHall(selectedParentId, hall_id))
            }
          >
            {t('ru.common.bind_btn')}
          </Button>
        </Box>
      )}
      {isConfirm.open && (
        <Overlay>
          <Confirm
            text={`Удалить смену?`}
            loading={false}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              hall_id &&
                isConfirm.id &&
                dispatch(handleRemoveToHall(isConfirm.id, hall_id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
    </Box>
  )
}

export default Binding
