import React from 'react'

import List from '@material-ui/core/List'
import { Box, Grid, makeStyles, IconButton, ListItem, Tooltip, Divider } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { Wrapper } from './styled'
import { DefaultPackageData, IDefaultPackage } from '../../../interfaces'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import { handlerChangeDefaultPackage } from '../../../redux/defaultPackageReducer'
import DefaultPackageForm from '../Form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface DefaultPackageProps {
  defaultPackage: IDefaultPackage | undefined
  hideDefaultPackage: () => void
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
}

const DefaultPackage: React.FC<DefaultPackageProps> = ({
  defaultPackage,
  hideDefaultPackage,
  isChangeMode,
  setIsChangeMode,
}) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  // const { items: ManufacturerList } = useSelector(manufacturerSelector)
  // const { items: PackageList } = useSelector(packageSelector)

  const [isChange, setIsChange] = React.useState(isChangeMode)
  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  const rootRender = defaultPackage && (
    <>
      <Box className={classes.closeBtn}>
        {
          <Tooltip
            title={
              isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))
            }
            placement="top"
          >
            <IconButton onClick={() => setIsChange(!isChange)}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: '#b6b6b6b6',
                  width: '30px',
                  minHeight: '30px',
                  borderRadius: '50%',
                  padding: '6px',
                }}
              >
                <img
                  style={{
                    width: '20px',
                    minHeight: 'auto',
                  }}
                  src={!isChange ? editPng : visionPng}
                  alt="icon"
                />
              </Box>
            </IconButton>
          </Tooltip>
        }
        <IconButton onClick={() => history.goBack()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center">
        <AccountCircleIcon fontSize="large" />
        <h2>#{defaultPackage.id}</h2>
      </Box>
      <br />
      <Divider />
      {isChange ? (
        <DefaultPackageForm
          onSubmit={(data: DefaultPackageData, hideForm: () => void, id: number | undefined) =>
            dispatch(handlerChangeDefaultPackage(data, hideForm, id))
          }
          cancelBtnEvent={() => setIsChange(false)}
          cancelBtnText={t('ru.common.back_btn')}
          submitBtnText={t('ru.common.submit_btn')}
          id={defaultPackage?.id}
          package_id={defaultPackage?.package.id}
          games={defaultPackage?.games}
          hideForm={() => setIsChange(false)}
        />
      ) : (
        <>
          <List>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box>
                    <b>{t('ru.default_packages.fields.package')}:</b>
                    {defaultPackage.package.name}
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box>
                    <b>{t('ru.default_packages.fields.games')}:</b>
                    {defaultPackage.games.map((item) => {
                      return <ListItem key={item.id}>{item.name}</ListItem>
                    })}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </List>
        </>
      )}
    </>
  )

  return <Wrapper>{rootRender}</Wrapper>
}

export default DefaultPackage
