import React, { FunctionComponent } from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import CachedIcon from '@material-ui/icons/Cached';
import { isConfirmType } from '../../../../interfaces';

type ReloadMenuItemProps = {
    id: number,
    name: string,
    setIsReload: (isConfirm: isConfirmType) => void
}

function cmp(prevProps: ReloadMenuItemProps, nextProps: ReloadMenuItemProps) {
    return prevProps.id === nextProps.id;
};

const ReloadMenuItem: FunctionComponent<ReloadMenuItemProps> = React.memo(({ id, name, setIsReload }) => {
    return <MenuItem onClick={() => { setIsReload({ id: id, name: name, open: true }) }} >
        <Action bg={'#919191'}>
            <IconButton>
                <CachedIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default ReloadMenuItem;