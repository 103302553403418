import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: 7vh 0;
  border-radius: 1vh;
  max-width: 900px;
  min-width: 75%;
  height: max-content;
  background: #fff;
  padding: 5vh;
  z-index: 999;
`
