import React from 'react'

import { authSelector } from '../../redux/authReducer'
import { useDispatch, useSelector } from 'react-redux'
import Overlay from '../Common/Overlay/index'
import Operations from '../Operations'
import { permissionSelector, getAllPermissions } from '../../redux/permissionReducer'
import { roleSelector, getRolesList } from '../../redux/roleReducer'
import User from '../Users/View'
import { useHistory, useLocation } from 'react-router-dom'

const Me: React.FC = () => {
  const dispatch = useDispatch()
  const {
    roles: { items: roles },
  } = useSelector(roleSelector)
  const { items: permissionsList } = useSelector(permissionSelector)
  const { data: user, status } = useSelector(authSelector)

  const [isChange, setIsChange] = React.useState(false)

  React.useEffect(() => {
    if (status === 200) {
      !roles.length && dispatch(getRolesList())
    }
  }, [status, roles, dispatch])
  React.useEffect(() => {
    if (status === 200) {
      !permissionsList.length && dispatch(getAllPermissions(1, 50))
    }
    // eslint-disable-next-line
  }, [dispatch, status, permissionsList])


  const history: any = useHistory()
  const location: any = useLocation()
  const operations_page = Number(new URLSearchParams(location.search).get('operations_page'))

  return (
    <>
      {user && (
        <User
          change={isChange}
          setChange={setIsChange}
          user_id={user?.id}
          setUserId={() => {}}
          me
        />
      )}
      {!!operations_page && (
        <Overlay>
          <Operations
            user_id={user?.id}
            hideOperations={() => {
              const query = new URLSearchParams(location.search)
              query.delete('operations_page')
              history.push({
                pathname: location.pathname,
                search: query.toString(),
                state: {
                  prevPaths:
                    location.state && location.state.prevPaths ? location.state.prevPaths : [],
                },
              })
            }}
          />
        </Overlay>
      )}
    </>
  )
}

export default Me
