import { Box, Checkbox, Chip, Collapse, FormControl, FormHelperText, Grid, IconButton, Input, ListItem, MenuItem, Select } from "@material-ui/core";
import { GamesType, IGame, IPackage } from "../../../interfaces";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import BorderOuterIcon from '@material-ui/icons/BorderOuter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';

type PacketsProps = {
    packagesSelected: number[];
    packages: IPackage[];
    setPackagesSelected: (packagesSelected: number[]) => void;
    gamesSelected: GamesType[];
    setGamesSelected: (packagesSelected: GamesType[]) => void;
    getGamesId: (games: IGame[]) => number[];
    handlerOpenPackage: (arr: GamesType[], package_id: number) => void;
}

const Packets = ({ packagesSelected, packages, setPackagesSelected, gamesSelected, setGamesSelected, getGamesId, handlerOpenPackage }: PacketsProps) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const handlerCheckGame = useCallback((arr: GamesType[], package_id: number, game_id: number) => {
        let itemId = arr.findIndex((i: GamesType) => i.package_id === package_id)
        const changeGamesArray = (games: number[], game: number) => {
            if (games.includes(game)) {
                let index = games.indexOf(game)
                return [...games.slice(0, index), ...games.slice(index + 1, games.length)]
            } else {
                return [...games, game]
            }
        }
        setGamesSelected([
            ...arr.slice(0, itemId),
            {
                package_id: arr[itemId].package_id,
                isOpen: arr[itemId].isOpen,
                games: changeGamesArray(arr[itemId].games, game_id),
            },
            ...arr.slice(itemId + 1, arr.length),
        ])
    }, [setGamesSelected]);
    return <Grid item xs={12} sm={12}>
        <FormControl fullWidth size="small">
            <FormHelperText>{t('ru.tables.form_helpers.packets')}</FormHelperText>
            <Select multiple value={packagesSelected} input={<Input />}>
                {packages &&
                    packages.map((item: IPackage) => (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                            onClick={() => {
                                if (packagesSelected.includes(item.id)) {
                                    let index = packagesSelected.indexOf(item.id)
                                    setPackagesSelected([
                                        ...packagesSelected.slice(0, index),
                                        ...packagesSelected.slice(index + 1, packagesSelected.length),
                                    ])
                                } else {
                                    setPackagesSelected([...packagesSelected, item.id])
                                }
                            }}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
            {packagesSelected.map((item) => {
                const packageItem = packages && packages.find((i: IPackage) => i.id === item)
                !!packageItem &&
                    !gamesSelected.find((i) => i.package_id === packageItem.id) &&
                    setGamesSelected([
                        ...gamesSelected,
                        {
                            package_id: packageItem.id,
                            isOpen: false,
                            games: getGamesId(packageItem.games),
                        },
                    ])

                return (
                    packageItem && (
                        <React.Fragment key={item}>
                            <ListItem
                                button
                                onClick={() => handlerOpenPackage(gamesSelected, packageItem.id)}
                                className={classes.packet}
                            >
                                <Box display="flex" alignItems="center">
                                    <BorderOuterIcon className={classes.icon} />
                                    {packageItem.name}
                                    {gamesSelected.find((i) => i.package_id === packageItem.id) &&
                                        gamesSelected?.find((i) => i.package_id === packageItem.id)?.isOpen ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        let index = packagesSelected.indexOf(packageItem.id)
                                        setPackagesSelected([
                                            ...packagesSelected.slice(0, index),
                                            ...packagesSelected.slice(index + 1, packagesSelected.length),
                                        ])
                                    }}
                                    style={{ padding: 0 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </ListItem>
                            <Collapse
                                in={
                                    gamesSelected.find((i) => i.package_id === packageItem.id) &&
                                    gamesSelected?.find((i) => i.package_id === packageItem.id)?.isOpen
                                }
                            >
                                <Box className={classes.chips}>
                                    {packageItem.games.map((game: IGame) => {
                                        return (
                                            <Chip
                                                className={classes.chip}
                                                key={game.id}
                                                onClick={() => handlerCheckGame(gamesSelected, packageItem.id, game.id)}
                                                label={
                                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                                        <span>{game.name}</span>
                                                        <Checkbox
                                                            checked={
                                                                gamesSelected.find((i) => i.package_id === packageItem.id) &&
                                                                gamesSelected
                                                                    ?.find((i: GamesType) => i.package_id === packageItem.id)
                                                                    ?.games.includes(game.id)
                                                            }
                                                            color="default"
                                                        />
                                                    </Box>
                                                }
                                            />
                                        )
                                    })}
                                </Box>
                            </Collapse>
                        </React.Fragment>
                    )
                )
            })}
        </FormControl>
    </Grid>
}

export default Packets;