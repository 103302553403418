import { createSlice } from '@reduxjs/toolkit'
import { fetchLogList } from '../services/Log'
import { ILog, ErrorType } from '../interfaces'
import { AppThunk } from './store'

interface logInitialState {
  items: ILog[]
  init: boolean
  loading: boolean
  error: ErrorType[] | null
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: logInitialState = {
  items: [],
  init: false,
  loading: false,
  error: null,
  form: {
    loading: false,
    error: null,
  },
}

export const slice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    logListIsLoading: (state) => {
      state.loading = true
    },
    logListSuccess: (state, { payload }) => {
      state.init = true
      state.loading = false
      state.error = null
      state.items = payload
    },
    logListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSuccess: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getLogList = (offset: number = 0, size: number = 10): AppThunk => {
  return async (dispatch) => {
    dispatch(logListIsLoading())

    return fetchLogList(offset, size)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(logListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(logListFailure([res.data.data]))
          } else {
            dispatch(logListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(logListFailure(err)))
  }
}

export const {
  logListIsLoading,
  logListSuccess,
  logListFailure,
  formIsLoading,
  formSuccess,
  formIsError,
} = slice.actions

interface IState {
  log: logInitialState
}

export const logSelector = (state: IState) => state.log

export default slice.reducer
