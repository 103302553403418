import styled from 'styled-components'
import { down } from 'styled-breakpoints'

type WrapperProps = {
  me: boolean
}
export const Wrapper = styled.div`
  position: relative;
  margin: ${(props: WrapperProps) => (props.me ? '0' : '7vh 0')};
  border-radius: 1vh;
  max-width: 800px;
  min-width: 60%;
  height: max-content;
  background: #fff;
  padding: 5vh;
  z-index: ${(props: WrapperProps) => (props.me ? '1' : '999')};;

  .tabs {
    position: relative;
  }

  .tabs:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ddd;
    z-index: 1;
  }

  ${down('sm')} {
    width: 100%;
  }

  ${down('md')} {
    width: 100%;
  }

  ${down('lg')} {
    min-width: 80%;
  }
`
