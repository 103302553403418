import axios from 'axios'
import { CompanyData } from '../interfaces'

export const fetchCompanyList = async () => {
    return axios.get('company')
}

export const addCompany = async (data: CompanyData) => {
    return axios.post('company', data)
}

// export const fetchCompany = async (id: number) => {
//     return axios.get(`company/${id}`)
// }

// export const changeCompany = async (id: number) => {
//     return axios.put(`company/${id}`)
// }

// export const deleteCompany = async (id: number) => {
//     return axios.delete(`company/${id}`)
// }
