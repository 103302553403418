import React from 'react'
import { useForm } from '../../../../hooks/useForm'

import IconButton from '@material-ui/core/IconButton'
import { SubForm } from '../styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Input, Button, InputLabel } from '@material-ui/core'
import { addCityItem, citySelector, formIsError } from '../../../../redux/cityReducer'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../../../Common/Preloader'
import { trowErrorMessage, trowError } from '../../../../utils/errors'
import ErrorText from '../../../Common/ErrorText'

interface CityFormProps {
  hideCity: () => void
}

const CityForm: React.FC<CityFormProps> = ({ hideCity }) => {
  const dispatch = useDispatch()

  const {
    form: { loading, error },
  } = useSelector(citySelector)

  const { form, handleInputChange } = useForm({
    city: '',
  })

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <SubForm>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h4>Добавить город</h4>
        <IconButton onClick={hideCity}>
          <CloseIcon />
        </IconButton>
      </Box>
      {loading ? (
        <Preloader absolute={false} />
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Input
            type="text"
            value={form.city}
            name="city"
            onChange={handleInputChange}
            placeholder="Название"
            fullWidth
            error={!!error && !!trowError(error, 'name')}
          />
          <InputLabel style={{ margin: '1vh', color: '#f44336' }}>
            {!!error && trowErrorMessage(error, 'name')}
          </InputLabel>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              dispatch(
                addCityItem(
                  {
                    name: form.city,
                  },
                  hideCity
                )
              )
            }}
          >
            Добавить
            <AddCircleIcon />
          </Button>
        </Box>
      )}
      <ErrorText error={error} />
    </SubForm>
  )
}

export default CityForm
