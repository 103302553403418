import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeader } from './styles';
import { ListItem } from '@material-ui/core';

const AddButton: FunctionComponent<{}> = React.memo(() => {
    const [t] = useTranslation();
    return <TableHeader>
        <ListItem component="span">id</ListItem>
        <ListItem component="span">{t('ru.users.fields.subclients')}</ListItem>
        <ListItem component="span">
            <div>{t('ru.users.fields.login')}</div>
        </ListItem>
        <ListItem component="span">
            <div>{t('ru.users.fields.balance')}</div>
        </ListItem>
        <ListItem component="span">{t('ru.users.fields.halls_count')}</ListItem>
        <ListItem component="span">{t('ru.users.fields.status')}</ListItem>
        <ListItem component="span">{t('ru.users.fields.created_at')}</ListItem>
        <ListItem component="span">{t('ru.users.fields.action')}</ListItem>
    </TableHeader>;
});

export default AddButton;