import React from 'react'
import { ListItem } from '../styles'
import { Box, IconButton, Tooltip } from '@material-ui/core'
import editPng from '../../../../../assets/images/edit.png'
import visionPng from '../../../../../assets/images/vision.png'
import BlockIcon from '@material-ui/icons/Block'

import { Action } from '../../../../Users/List/styles'
import { useTranslation } from 'react-i18next'

interface TerminalsListItemProps {
  item: any
  bg?: string
  showTerminal: () => void
}

const Item: React.FC<TerminalsListItemProps> = ({ item, bg = '#fff', showTerminal }) => {
  const [t] = useTranslation()

  return (
    item && (
      <>
        <ListItem bg={bg}>
          <div>{item.id}</div>
          <div>{'terminal_123'}</div>
          <div>{'12-07-2021 19:00'}</div>
          <div>{'11-08-2021 14:00'}</div>
          <div>{'норма'}</div>
          <div>{'12000.00'}</div>
          {/* <div>{'1000.00'}</div>
          <div>{'-11000.00'}</div> */}
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              flexWrap="wrap"
              component="section"
              style={{ width: '100%', maxHeight: '75px' }}
            >
              <span style={{ fontSize: '85%' }}>"50": 96</span>
              <span style={{ fontSize: '85%' }}>"100": 35</span>
              <span style={{ fontSize: '85%' }}>"200": 111</span>
              <span style={{ fontSize: '85%' }}>"500": 29</span>
              <span style={{ fontSize: '85%' }}>"1000": 30</span>
              <span style={{ fontSize: '85%' }}>"2000": 33</span>
              <span style={{ fontSize: '85%' }}>"5000": 10</span>
            </Box>
          </div>
          <div>{'10000.00'}</div>
          <div>
            <Box display="flex" style={{ width: '100%' }}>
              <Tooltip title={String(t('ru.common.view_tooltip'))} placement="top">
                <Action bg={'#7467ec'} onClick={showTerminal}>
                  <IconButton>
                    <img src={visionPng} alt="icon" />
                  </IconButton>
                </Action>
              </Tooltip>
              <Tooltip title={String(t('ru.common.change_btn'))} placement="top">
                <Action bg={'#919191'} onClick={() => {}}>
                  <IconButton>
                    <img src={editPng} alt="icon" />
                  </IconButton>
                </Action>
              </Tooltip>
              <Tooltip title={String(t('ru.common.confirm.block_btn'))} placement="top">
                <Action bg={'#919191'} onClick={() => {}}>
                  <IconButton>
                    <BlockIcon fontSize="small" style={{ color: '#fff' }} />
                  </IconButton>
                </Action>
              </Tooltip>
            </Box>
          </div>
        </ListItem>
      </>
    )
  )
}

export default Item
