import styled from 'styled-components'
import { down } from 'styled-breakpoints'
export const Wrapper = styled.div`
  position: relative;
  /* margin: 10% 3% 0 2%; */
  margin-top: 14vh;
  margin-right: 2%;
  margin-left: 1.5%;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  #bg {
    z-index: 0;
    position: fixed;
    width: 58%;
    path {
      fill: #b8b8b80d;
    }
  }

  ${down('lg')} {
    margin-top: 14vh;
    margin-right: 2%;
    margin-left: 2%;
  }

  ${down('md')} {
    margin-top: 14vh;
    margin-right: 2%;
    margin-left: 2%;
  }
  ${down('sm')} {
    margin-top: 19.5vh;
    margin-right: 2%;
    margin-left: 2%;
  }
`
