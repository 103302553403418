import React from 'react'
import TreeView from '@material-ui/lab/TreeView'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { tableSelector, getAlltables } from '../../redux/tableReducer'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector } from '../../redux/authReducer'
import { getUsersList, userSelector } from '../../redux/userReducer'
import LockIcon from '@material-ui/icons/Lock'
import AddBoxIcon from '@material-ui/icons/AddBox'
import admin from '../../assets/images/admin.png'
import owner from '../../assets/images/hall-host.png'
import cashier from '../../assets/images/cashier.png'
import agent from '../../assets/images/agent.png'

import Item from './Item'
import { useStyles, Wrapper } from './styles'
import Preloader from '../Common/Preloader'
import { IUser } from '../../interfaces'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
interface ITree {
  showAddUser: () => void
}
const Tree: React.FC<ITree> = ({ showAddUser }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    users: { data: users, loading },
  } = useSelector(userSelector)
  const {
    tables: { data: tables, loading: tablesLoading },
  } = useSelector(tableSelector)
  const { data: user, status, role } = useSelector(authSelector)

  const [selectedItem, setSelectedItem] = React.useState<string | undefined>(user?.login)

  const getDefaultUserExpanded = (user: IUser | undefined) => {
    let expandArr: any = [user?.login]
    if (user && !!user.parent_id) {
      expandArr = [
        ...expandArr,
        users?.find((i: IUser) => i.id === user.parent_id) &&
        users?.find((i: IUser) => i.id === user.parent_id)?.login,
      ]
      getDefaultUserExpanded(users?.find((i: IUser) => i.id === user.parent_id))
    }
    return expandArr
  }

  React.useEffect(() => {
    if (status === 200) {
      !users.length && dispatch(getUsersList())
      !tablesLoading && !tables.length && dispatch(getAlltables(1, 50))
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  const getUserIcon = (role: string) => {
    switch (role) {
      case 'administrator':
        return admin
      case 'cashier':
        return cashier
      case 'owner':
        return owner
      default:
        return agent
    }
  }

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between">
        <h3>{t('ru.tree.title')}</h3>
        <AddBoxIcon style={{ cursor: 'pointer' }} onClick={showAddUser} />
      </Box>
      {loading ? (
        <Preloader absolute={false} />
      ) : (
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ArrowDropDownIcon style={{ fontSize: '150%', color: '#416def', zIndex: 2 }} />}
          defaultExpandIcon={<ArrowRightIcon style={{ fontSize: '150%', color: '#929292', zIndex: 2 }} />}
          defaultParentIcon={'#'}
          selected={selectedItem}
          defaultExpanded={!!user && getDefaultUserExpanded(user)}
        >
          {users &&
            users.map((item: IUser) => {
              return (
                user &&
                item &&
                user.id === item.id && (
                  <Item
                    key={item.id}
                    icon={
                      item.status ? (
                        <img
                          src={getUserIcon(role.item)}
                          alt=""
                          style={{ width: '25px', height: '25px' }}
                        />
                      ) : (
                        <LockIcon style={{ color: '#de7d55' }} />
                      )
                    }
                    user={item}
                    setSelectedItem={setSelectedItem}
                  />
                )
              )
            })}
        </TreeView>
      )}
    </Wrapper>
  )
}

export default Tree
