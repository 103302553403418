import React from 'react'
import { Wrapper } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, IconButton } from '@material-ui/core'
import PackageForm from '../Form'
import { PackageData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddPackageProps {
  hideAddPackage: () => void
  handlerAddPackage: (data: PackageData, hideForm: () => void) => void
}
const AddPackage: React.FC<AddPackageProps> = ({ hideAddPackage, handlerAddPackage }) => {
  const classes = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddPackage}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.packages.add_form')}</h3>
      <PackageForm
        onSubmit={handlerAddPackage}
        cancelBtnEvent={hideAddPackage}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
        hideForm={(package_id?: number) => {
          hideAddPackage()
          history.push(`/admin/game-packages?package_id=${package_id}`)
        }}
      />
    </Wrapper>
  )
}

export default AddPackage
