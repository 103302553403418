import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 12vh;
  background: #fff;
  z-index: 999;
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .left-part {
    width: 60%;
  }

  .logo {
    width: 50px;
    height: 50px;
  }
  .burger {
    display: none;
    margin: 10px;
  }

  .grid-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }


  ${down('md')} {
    .burger {
      display: block;
    }
    .logo {
      margin: 10px;
    }
    .block > span:nth-child(2) {
      display: none;
    }
  }

  ${down('sm')} {
    padding: 0 10px;
    height: 17vh;
  }
`

export const Logo = styled.span`
  position: relative;
  width: 60%;
  height: auto;
  margin: 5px 15px;
  path {
    fill: #464545;
  }

  ${down('sm')} {
    margin: 0;
  }
`

export const Search = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  input {
    position: relative;
    font-size: 2vh;
    font-weight: 300;
    letter-spacing: 0px;
    padding: 10px 5px;
    color: #777777;
    font-family: 'Myriad Pro', sans-serif;
    border: 0;
    height: 3vh;
    margin: 0 2vh;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  ${down('sm')} {
    margin: 0;
    width: 58%;
  }
`

export const Balance = styled.span`
  cursor: pointer;
  position: relative;
  color: black;
  margin: 0;
  font-size: calc(14px + 70%);
  width: max-content;
  letter-spacing: 1px;
  line-height: 0;
  color: #464545;
  font-weight: 500;
  border-bottom: 0.3vh solid #f7cc7a;
  padding: 0 3vh 1.5vh 3vh;

  ${down('md')} {
    font-size: 115%;
  }

  ${down('sm')} {
    font-size: 2vh;
  }
`

export const Notifications = styled.span`
  position: relative;
  width: 30px;
  height: 30px;
  background: #f3f5f7;
  border-radius: 50%;

  ${down('sm')} {
  }

  &:after {
    content: '';
    width: 1vh;
    height: 1vh;
    background: #ed7a7c;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    right: 0;
  }

  .notification {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 2vh;
    height: auto;
  }
`
export const User = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 1%;
  cursor: pointer;
  .avatar {
    width: 4.5vh;
    height: 4.5vh;
    max-width: 35px;
    max-height: 35px;
    margin: 0 1vh;
    background: #cfcfcf;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 105%;
  }

  .user {
    font-family: 'Myriad Pro', sans-serif;
    color: black;
    font-size: 85%;
  }

  ${down('sm')} {
    margin: 0;
  }
`

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .burger {
    display: none;
    margin: 10px;
  }

  .logo {
    width: 56px;
    margin: 10px;
  }

  ${down('sm')} {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    /* .burger {
      margin-right: 30%;
    } */
  }
`

export const CashboxAlert = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1vh;
  padding: 3vh;
  width: max-content;
  min-width: 30%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
`
