import React, { ReactText } from 'react'
import { Box, ListItem, Collapse, Chip, Typography } from '@material-ui/core'
import BorderOuterIcon from '@material-ui/icons/BorderOuter'
import { prettyNum } from '../../../utils/prettyNum'
import { useStyles, TabItem } from '../styles'
import { useDispatch } from 'react-redux'
import Overlay from '../../Common/Overlay/index'
import PaymentForm from '../../Payment'
import { handlerTakeHallBalance, handlerPutHallBalance } from '../../../redux/hallReducer'
import { IHall, IPackage, IGame } from '../../../interfaces'
import { Status } from '../../Users/List/styles'
import { useTranslation } from 'react-i18next'

interface HallInfoProps {
  item: IHall
  isPayment: Boolean
  hidePayment: () => void
}

type AllProps = HallInfoProps

const HallInfo: React.FC<AllProps> = ({ item, isPayment, hidePayment }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const [tab, setTab] = React.useState<number>(0)

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return <Main />
      case 1:
        return <Packets />
      default:
        break
    }
  }

  const Main: React.FC = () => {
    return (
      <>
        <Box>
          <b>{t('ru.halls.fields.user')}:</b> <span>{item.owner ? item.owner.login : 'Отсуствует'}</span>
        </Box>
        <Box>
          <b>{t('ru.halls.fields.company')}:</b> <span>{item.company && item.company.name}</span>
        </Box>
        <Box>
          <b>{t('ru.halls.fields.city')}:</b> <span>{item.city && item.city.name}</span>
        </Box>
        <Box>
          <b>{t('ru.halls.fields.currency')}:</b> <span>{item.currency && item.currency.id}</span>
        </Box>
        <Box>
          <b>{t('ru.halls.fields.adress')}:</b> <span>{item.address ? item.address : 'Отсутствует'}</span>
        </Box>
        <Box
          className={classes.box}
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
        >
          <b>Статус:</b>
          <Box display="flex">
            <Status active={!!item.status}>{!!item.status ? 'Активен' : 'Неактивен'}</Status>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <b>{t('ru.halls.fields.balance')}:</b>

          <span>
            {item.balance && prettyNum(item.balance)}(
            {item.currency && item.currency.id})
          </span>
        </Box>
        <Box>
          <b>{t('ru.halls.fields.denominations')}:</b>
          <Box className={classes.chips}>
            {!!item.denominations ? (
              item.denominations.map((item: ReactText) => {
                return <Chip className={classes.chip} key={item} label={<span>{Number(item)/100}</span>} />
              })
            ) : (
              <Typography>{t('ru.common.no_data')}</Typography>
            )}
          </Box>
        </Box>
      </>
    )
  }

  const Packets: React.FC = () => {
    return (
      <>
        <Box>
          <b>{t('ru.halls.tabs.packets')}:</b>
          <br />
        </Box>
        {!!item.packages &&
          item.packages.map((item: IPackage, id) => {
            return (
              <React.Fragment key={id}>
                <ListItem>
                  <Box display="flex" alignItems="center">
                    <BorderOuterIcon fontSize="small" />
                    <span>{item.package.name}</span>
                  </Box>
                </ListItem>
                <Collapse in={true}>
                  <Box className={classes.chips}>
                    {item.games.map((game: IGame) => {
                      return (
                        <Chip
                          className={classes.chip}
                          key={game.id}
                          label={
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <span>{game.name}</span>
                            </Box>
                          }
                        />
                      )
                    })}
                  </Box>
                </Collapse>
              </React.Fragment>
            )
          })}
      </>
    )
  }

  return (
    <>
      <Box className="tabs" display="flex" justifyContent="flex-start">
        <TabItem
          active={tab === 0}
          onClick={() => {
            setTab(0)
          }}
        >
          {t('ru.halls.tabs.info')}
        </TabItem>
        <TabItem
          active={tab === 1}
          onClick={() => {
            setTab(1)
          }}
        >
          {t('ru.halls.tabs.packets')}
        </TabItem>
      </Box>
      {returnTab(tab)}

      {isPayment && (
        <Overlay>
          <PaymentForm
            id={item.id}
            hidePayment={hidePayment}
            take={(data, id, info) =>
              dispatch(handlerTakeHallBalance(data, id, info))
            }
            putEvent={(data, id, info) =>
              dispatch(handlerPutHallBalance(data, id, info))
            }
            currency_id={item.currency_id}
            balance={item.balance}
            info
          />
        </Overlay>
      )}
    </>
  )
}

export default HallInfo
