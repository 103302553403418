import axios from 'axios'
import { PlayerData } from '../interfaces'

export const fetchPlayersList = async (search = {
    id: '',
    name: ''
}, sort?: string) => {
    return axios.get(`player`, {
        params: { expand: 'profile,tableConnection', 'f[id]': search.id, 'f[name]': search.name, sort: sort },
    })
}

export const addPlayer = async (data: PlayerData) => {
    return axios.post('player', data, { params: { expand: 'profile,tableConnection' } })
}

export const fetchPlayer = async (id: number) => {
    return axios.get(`player/${id}`)
}

export const changePlayer = async (data: PlayerData, id: number) => {
    return axios.put(`player/${id}`, data, { params: { expand: 'profile,tableConnection' } })
}

export const deletePlayer = async (id: number) => {
    return axios.delete(`player/${id}`)
}

export const connectPlayer = async (player_id: number, table_id: number) => {
    return axios.post(`player/${player_id}/connect/${table_id}`)
}
export const disconnectPlayer = async (player_id: number, table_id: number) => {
    return axios.post(`player/${player_id}/disconnect/${table_id}`)
}


// POST,PATCH,GET v1/player/<playerId:\d+>/connect/<tableId:\d+>
//     /**
//      * Возвращает true в случае успеха и объект с ошибками в случае неудачи
//      * Ошибки:
//      * свойства player_id и table_id говорят о наличии проблем с этим параметром
//      * значение null говорит о некорректности идентификатора
//      * целочисленное значение говорит о том, что переданный идентификатор уже ассоциирован с указанным в ошибке
//      * POST - параметр force=true разорвать мешающие связи
//      */
//     POST,PATCH,GET v1/player/<playerId:\d+>/disconnect/<tableId:\d+>
// убирает связи, выставленные функцией connect. один из параметров может быть нулём(т.е. не указан)