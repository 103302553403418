import React from 'react'

export const useShowBlock = () => {
    const [isBlockShown, setIsBlockShown] = React.useState(false)
    const showBlock = () => setIsBlockShown(true)
    const hideBlock = () => setIsBlockShown(false)
    const toggleShowBlock = () => setIsBlockShown((prev) => !prev)
    return {
        isBlockShown,
        showBlock,
        hideBlock,
        toggleShowBlock,
    }
}
