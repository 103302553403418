import React from 'react'
import { Wrapper } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, IconButton } from '@material-ui/core'
import GameForm from '../Form'
import { GameData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
}))

interface AddGameProps {
    hideAddGame: () => void
    handleAddGame: (data: GameData, hideForm: () => void) => void
}

const AddGame: React.FC<AddGameProps> = ({ hideAddGame, handleAddGame }) => {
    const history = useHistory()
    const classes = useStyles()
    const [t] = useTranslation()

    return (
        <Wrapper>
            <IconButton className={classes.closeBtn} onClick={hideAddGame}>
                <CloseIcon />
            </IconButton>
            <h3>{t('ru.games.add_form')}</h3>
            <GameForm
                onSubmit={handleAddGame}
                cancelBtnEvent={hideAddGame}
                cancelBtnText={t('ru.common.cancel_btn')}
                submitBtnText={t('ru.common.add_btn')}
                hideForm={(game_id?: number) => {
                    hideAddGame()
                    history.push(`games?game_id=${game_id}`)
                }}
                
            />
        </Wrapper>
    )
}

export default AddGame
