import styled from 'styled-components'

type WrapperProps = {
  isModal?: boolean
}

export const ListWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${(props: WrapperProps) => (props.isModal ? '#efeff0' : '')};
  padding: 0 5px;
  overflow-x: auto;
  overflow-y: hidden;
  h2 {
    margin: 1vh 0;
    font-size: 2.9vh;
  }

  .update-btn {
    width: max-content;
  }
`
type TableHeaderType = {
  isModal: boolean
}
export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  span:nth-child(4) {
    display: ${(props: TableHeaderType) => (props.isModal ? 'none' : '')};
  }
  span {
    overflow: hidden;
    width: 20%;
    height: 35px;
    color: #010e2a;
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`
type ListItemType = {
  bg: string
  isModal: boolean
}
export const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${(props: ListItemType) => props.bg};
  margin: 0.5vh auto;

  div:nth-child(4) {
    display: ${(props: ListItemType) => (props.isModal ? 'none' : '')};
  }
  & > div {
    position: relative;
    width: 20%;
    height: 5em;
    color: #010e2a;
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.5vh;
      cursor: pointer;
    }
  }

`
type StatusProps = {
  active: boolean
}
export const Status = styled.span`
  max-height: 18px;
  max-width: max-content;
  background: ${(props: StatusProps) => (props.active ? '#87b03b' : '#b03c3c')};
  color: #fff;
  border-radius: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh 3vh;
  width: 15vh;
`
type ActionType = {
  bg: string
}
export const Action = styled.span`
  width: 3vh;
  height: 3vh;
  max-width: 25px;
  max-height: 25px;
  margin: 0.3vh;
  background: ${(props: ActionType) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 13px;
    height: auto;
  }
`
