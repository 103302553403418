import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Chip, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { hotkeysSelector, removeHotkey } from '../../redux/hotkeysReducer';
import { IHotkeyActionData } from '../../interfaces';

interface HotkeyRowProps {
    action: IHotkeyActionData;
    showDialog: (v: React.SetStateAction<number>) => void;
    edit?: boolean;
};

function getChip(id: number, key: string, onDelete: (() => void) | false) {
    if (onDelete) {
        return <Chip key={id} color="primary" onDelete={onDelete} label={key} />;
    }
    return <Chip key={id} color="primary" label={key} />;
}

const HotkeyRow: React.FC<HotkeyRowProps> = ({ action, showDialog, edit = true }) => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const hotkeys = useSelector(hotkeysSelector);
    return <TableRow>
        <TableCell>{t('ru.hotkey.' + action.action)}</TableCell>
        <TableCell>{t('ru.hotkey.in_' + (action.type ? 'game' : 'menu'))}</TableCell>
        <TableCell>{(hotkeys.actionToIdxs[action.id] || []).map(id => getChip(id, hotkeys.items[id].key, edit ? () => dispatch(removeHotkey(id)) : false))}</TableCell>
        {edit && <TableCell><Button size="small" variant="contained" color="primary" onClick={() => showDialog(action.id)}>{t('ru.common.add_btn')}</Button></TableCell>}
    </TableRow>;
};

export default HotkeyRow;
