import { Grid, Button } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorType } from '../../../interfaces';
import { trowError, trowErrorMessage } from '../../../utils/errors';
import FieldAddDenomination from '../../Halls/Form/tabs/info/field-add-denomination';
import FieldDenomination from '../../Halls/Form/tabs/info/field-denomination';
import FieldDenominationCheckboxes from '../../Halls/Form/tabs/info/field-denomination-checkboxes';
import { useStyles } from './styles';

type DenominationsProps = {
    form: any;
    error: ErrorType[];
    setDefaultDenomination: (x: number) => void;
    hallDenominations: number[];
    setDenominations: (x: number[] | null) => void;
    setDenom: (x: string) => void;
};

const Denominations: FunctionComponent<DenominationsProps> = React.memo(({ form, error, setDefaultDenomination, hallDenominations, setDenominations, setDenom }) => {
    const [t] = useTranslation();
    console.log(form)
    const classes = useStyles();
    const denominationList = [1, 10, 20, 50, 100, 1000, 10000];
    if (form.denominationList) {
        form.denominationList.forEach((e: number) => !denominationList.includes(e) && denominationList.push(e));
    }
    denominationList.sort((a, b) => a - b);
    return <>
        <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
                {form.denominationList ? <>
                    <FieldDenominationCheckboxes setDenominationsSelected={(x) => setDenominations(x.map(e => +e))} denomination={form.denomination} formHelperText={trowErrorMessage(error, 'denomination', 'Выберите деноминацию по умолчанию')} denomenationList={denominationList} denominationsSelected={form.denominationList} />
                    <FieldAddDenomination className={classes.textFields} value={form.denom} onChange={(e) => setDenom(e.target.value)}
                        onClick={() => {
                            if (form.denom && form.denom.match(/^\d+(\.\d{0,2})?$/) && !form.denominationList.find((i: number) => i === form.denom * 100)) {
                                const tmp = [...form.denominationList, form.denom.replace(/[^\d.]/g, '') * 100];
                                tmp.sort((a: number, b: number) => a - b);
                                setDenominations(tmp);
                            }
                        }} />
                    <Button size="small" color="primary" onClick={() => setDenominations(null)}> {t('ru.tables.set_inherit')} </Button>
                </> : <Button size="small" color="primary" onClick={() => setDenominations([form.denomination])}> {t('ru.tables.set_own_denominations')} </Button>}
                <FieldDenomination error={!!trowError(error, 'denomination')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setDefaultDenomination(+e.target.value)}
                    value={form.denomination} values={form.denominationList || hallDenominations} formHelperText={trowErrorMessage(error, 'denomination', t('ru.halls.fields.denomination'))} />
            </Grid>
        </Grid>
    </>;
});

export default Denominations;