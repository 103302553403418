import React from 'react'
import { Action, ListItem } from '../styles'

import { IconButton, makeStyles, Menu, MenuItem, MenuList, Tooltip } from '@material-ui/core'

import visionPng from '../../../../assets/images/vision.png'
import editPng from '../../../../assets/images/edit.png'
import trashPng from '../../../../assets/images/trash.png'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { IGame, isConfirmType } from '../../../../interfaces'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiList-padding': {
      padding: 0,
    },
  },
}))

interface PackageListItemProps {
  id: number
  name: string
  games: IGame[]
  showPackage?: () => void
  showChangePackage?: () => void
  setPackageId?: (id: number) => void
  setIsConfirm: (isConfirm: isConfirmType) => void
  setIsChangeMode: (isChangeMode: boolean) => void
}

const Item: React.FC<PackageListItemProps> = ({
  id,
  name,
  games,
  showPackage,
  showChangePackage,
  setPackageId,
  setIsConfirm,
  setIsChangeMode,
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  const [open, setOpen] = React.useState(false)
  const anchorRef: any = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const itemMenu = () => {
    return (
      <>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className={classes.root}
          autoFocus={false}
          open={open}
          elevation={0}
          anchorEl={anchorRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          <MenuList
            autoFocus={false}
            onKeyDown={handleListKeyDown}
            onClick={handleClose}
            style={{
              background: '#fff',
              border: '1px solid #efeff0',
              display: 'flex',
            }}
          >
            <Link to={`game-packages?package_id=${id}`}>
              <MenuItem
                onClick={() => {
                  // setPackageId(id)
                  // showPackage()
                }}
              >
                <Action bg={'#7467ec'}>
                  <IconButton>
                    <img src={visionPng} alt="icon" />
                  </IconButton>
                </Action>
              </MenuItem>
            </Link>
            <Link to={`game-packages?package_id=${id}`}>
              <MenuItem
                onClick={() => {
                  // setPackageId(id)
                  setIsChangeMode(true)
                  // showPackage()
                }}
              >
                <Action bg={'#919191'}>
                  <IconButton>
                    <img src={editPng} alt="icon" />
                  </IconButton>
                </Action>
              </MenuItem>
            </Link>
            <MenuItem
              onClick={() => {
                setIsConfirm({
                  id: id,
                  name: name,
                  open: true,
                })
              }}
            >
              <Action bg={'#919191'}>
                <IconButton>
                  <img src={trashPng} alt="icon" />
                </IconButton>
              </Action>
            </MenuItem>
          </MenuList>
        </Menu>
      </>
    )
  }

  return (
    <ListItem>
      <div>{id}</div>
      <div>{name}</div>
      <div>{games.length}</div>
      <div></div>
      <div>
        {window.innerWidth < 1200 ? (
          itemMenu()
        ) : (
          <>
            {' '}
            <Tooltip title={String(t('ru.common.view_tooltip'))} placement="top">
            <Link to={`game-packages?package_id=${id}`}>
              <Action
                bg={'#7467ec'}
                onClick={() => {
                  // setPackageId(id)
                  // showPackage()
                }}
              >
                <IconButton>
                  <img src={visionPng} alt="icon" />
                </IconButton>
              </Action>
              </Link>
            </Tooltip>
            <Tooltip title={String(t('ru.common.change_btn'))} placement="top">
            <Link to={`game-packages?package_id=${id}`}>
              <Action
                bg={'#919191'}
                onClick={() => {
                  // setPackageId(id)
                  setIsChangeMode(true)
                  // showPackage()
                }}
              >
                <IconButton>
                  <img src={editPng} alt="icon" />
                </IconButton>
              </Action>
              </Link>
            </Tooltip>
            <Tooltip title={String(t('ru.common.delete_btn'))} placement="top">
              <Action
                bg={'#919191'}
                onClick={() => {
                  setIsConfirm({
                    id: id,
                    name: name,
                    open: true,
                  })
                }}
              >
                <IconButton>
                  <img src={trashPng} alt="icon" />
                </IconButton>
              </Action>
            </Tooltip>
          </>
        )}
      </div>
    </ListItem>
  )
}

export default Item
