import { Box, Button, Checkbox, TextField } from '@material-ui/core'
import React from 'react'
// import { useForm } from '../../../hooks/useForm'

const Alerts: React.FC = () => {
  // const { form, setFormItem } = useForm({
  //   isConnect: false,
  //   isDefective: false,
  //   isFull: false,
  //   isConnectMinuts: 0,
  //   isDefectiveMinuts: 0,
  // })

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Checkbox />
          <span style={{ marginRight: '10px' }}>Терминал не выходит на связь</span>
          <TextField label="более, минут" variant="outlined" size="small" />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Checkbox />
          <span style={{ marginRight: '10px' }}>Купюрник не исправен</span>
          <TextField label="более, минут" variant="outlined" size="small" />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Checkbox />
          <span style={{ marginRight: '10px' }}>Купюрник переполнен</span>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ width: '100%' }}>
        <Button variant='outlined'>Применить</Button>
      </Box>
    </>
  )
}

export default Alerts
