import React, { ReactNode } from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { Box, Typography } from '@material-ui/core'
import { Wrapper } from './styles'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }  
  }

  static getDerivedStateFromError() {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true }
  }

  componentDidCatch() {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    this.setState({
      hasError: true,
    })
  }

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return (
        <Wrapper>
          <Box display="flex" flexDirection="column" alignItems="center">
            <ErrorOutlineIcon />
            <Typography>Что-то пошло не так.</Typography>
          </Box>
        </Wrapper>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
