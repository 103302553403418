import styled from 'styled-components'

export const BindWrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #fff;
  width: max-content;
  padding: 2em;
  border-radius: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
