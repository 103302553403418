import React, { FunctionComponent } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Action } from '../../styles';
import editPng from '../../../../../assets/images/edit.png';

type UpdateButtonProps = {
    id: number;
    setChange: (x: boolean) => void;
};

function cmp(prev: UpdateButtonProps, next: UpdateButtonProps): boolean {
    return prev.id === next.id;
}

const UpdateButton: FunctionComponent<UpdateButtonProps> = React.memo(({ id, setChange }) => {
    const [t] = useTranslation();
    const history = useHistory();
    return <Tooltip title={String(t('ru.common.change_btn'))} placement="top">
        <Action bg={'#919191'} onClick={() => { setChange(true); history.push({ pathname: `users`, search: `?id=${id}`, state: { prevPath: window.location.href } }); }} >
            <IconButton>
                <img src={editPng} alt="icon" />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default UpdateButton;