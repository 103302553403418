import React from 'react'
import OutstandingsList from './List'

const Outstandings: React.FC = () => {
  return (
    <>
      <OutstandingsList />
    </>
  )
}

export default Outstandings
