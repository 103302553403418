import React from 'react'
import { useForm } from '../../../hooks/useForm'

import {
    Box,
    Button,
    Grid,
    makeStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { hallSelector } from '../../../redux/hallReducer'
import { playerSelector } from '../../../redux/playerReducer'
import Preloader from '../../Common/Preloader'
import { IHall } from '../../../interfaces'
import { useAddPlayerMutation, useFindPlayersQuery } from '../../../api/player'
import RenderSelect from './render-select'
import RenderTextField from './render-text-field'

const useStyles = makeStyles(() => ({
    submitBtn: {
        margin: '1vh',
    },
}))
interface FormProps {
    close: () => void;
}

const Form: React.FC<FormProps> = ({ close }) => {
    const classes = useStyles();

    const {
        halls: { data: halls },
    } = useSelector(hallSelector);
    const {
        form: { loading: formLoading },
    } = useSelector(playerSelector);

    const { form, handleInputChange, setFormItem } = useForm({
        hall_id: halls && halls.length ? halls[0].id : "",
        name: "",
        surname: "",
        patronymic: "",
        document_type: 0,
        document_series: "",
        document_number: "",
        citizenship: 0,
        registration_address: "",
    });
    const { data } = useFindPlayersQuery({ data: form });
    const [addPlayer, { isLoading }] = useAddPlayerMutation();
    const players = data?.filter(player => player.profile);

    const convertForm = (form: PlayerProfileRequestFind): PlayerRequestCreate => {
        return {
            hall_id: form.hall_id,
            name: `${form.surname} ${form.name} ${form.patronymic}`.trim(),
            profile: {
                name: form.name.trim(),
                surname: form.surname.trim(),
                patronymic: form.patronymic.trim(),
                document_type: form.document_type,
                document_series: form.document_series.trim(),
                document_number: form.document_number.trim(),
                citizenship: form.citizenship,
                registration_address: form.registration_address.trim(),
            }
        };
    };

    return formLoading ? <Preloader absolute={false} /> : <>
        <form onSubmit={(e) => { e.preventDefault(); }}>
            <Grid container>
                <Grid item md={6}>
                    <RenderSelect field="hall_id" options={(halls ?? []).map((hall: IHall) => ({ id: hall.id, value: hall.name }))} form={form} setFormItem={setFormItem} />
                </Grid>
                <Grid item md={6}>
                    <RenderSelect field="citizenship" options={[{ id: 0, value: "Россия" }]} form={form} setFormItem={setFormItem} />
                </Grid>
                <Grid item md={6}>
                    <RenderTextField form={form} handleInputChange={handleInputChange} field="name" />
                </Grid>
                <Grid item md={6}>
                    <RenderTextField form={form} handleInputChange={handleInputChange} field="surname" />
                </Grid>
                <Grid item md={6}>
                    <RenderTextField form={form} handleInputChange={handleInputChange} field="patronymic" />
                </Grid>
                <Grid item md={6}>
                    <RenderSelect field="document_type" options={[{ "id": 0, "value": "Паспорт" }]} form={form} setFormItem={setFormItem} />
                </Grid>
                <Grid item md={6}>
                    <RenderTextField form={form} handleInputChange={handleInputChange} field="document_series" />
                </Grid>
                <Grid item md={6}>
                    <RenderTextField form={form} handleInputChange={handleInputChange} field="document_number" />
                </Grid>
                <Grid item md={12} lg={12} xs={12} sm={12}>
                    <RenderTextField form={form} handleInputChange={handleInputChange} field="registration_address" />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        fullWidth
                        className={classes.submitBtn}
                        onClick={close}
                        variant="contained">Закрыть</Button>
                    <Button disabled={isLoading} onClick={() => addPlayer(convertForm(form)).unwrap().then(resp => resp.success && close())} fullWidth variant="contained" type="button">Добавить</Button>
                </Box>
            </Grid>
        </form>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Имя</TableCell>
                    <TableCell>Документ</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {players?.map(player => <TableRow key={player.id}>
                    <TableCell>{player.profile!.id}</TableCell>
                    <TableCell>{`${player.profile!.surname} ${player.profile!.name} ${player.profile!.patronymic}`}</TableCell>
                    <TableCell>{`${player.profile!.document_series} ${player.profile!.document_number}`}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </>;
}

export default Form
