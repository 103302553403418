import React from 'react'

import List from '@material-ui/core/List'
import { Box, Grid, makeStyles, IconButton, Divider } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { Wrapper } from './styled'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../../Common/Preloader'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchTableSpin, tableSelector, tableSpinSuccess } from '../../../redux/tableReducer'
import { prettyNum } from '../../../utils/prettyNum'
import { gameSelector, getGame } from '../../../redux/gameReducer'
import { useTranslation } from 'react-i18next'
import { convertUTCDateToLocalDate } from '../../../utils/date'
import Combination from './Combination'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface SpinProps {
  spinId: number
  hide: () => void
}

const Spin: React.FC<SpinProps> = ({ spinId, hide }) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [t] = useTranslation()

  const {
    spin: { data: spin, loading },
    sessions: { data: sessions },
  } = useSelector(tableSelector)
  const { game } = useSelector(gameSelector)
  React.useEffect(() => {
    spinId && dispatch(fetchTableSpin(spinId, 1, 20))
  }, [dispatch, spinId])
  React.useEffect(() => {
    return () => {
      dispatch(tableSpinSuccess(null))
    }
    // eslint-disable-next-line
  }, [])

  const parseWins = spin?.wins && JSON.parse(spin?.wins)
  const session_id = new URLSearchParams(location.search).get('session_id')
  const gameId = sessions?.find((i) => i.id === Number(session_id))?.game.id
  React.useEffect(() => {
    gameId !== game?.id && gameId && dispatch(getGame(gameId))
    // eslint-disable-next-line
  }, [dispatch, gameId])

  const rootRender =
    loading && !!game ? (
      <Preloader absolute={false} />
    ) : (
      <>
        <Box className={classes.closeBtn}>
          <IconButton onClick={() => history.goBack()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCircleIcon fontSize="large" />
          <h2>
            {t('ru.spins.subtitle')} #{spin?.id}
          </h2>
        </Box>
        <br />
        <Divider />
        <>
          <List>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <b>{t('ru.spins.fields.bet')}:</b> {spin?.total_bet}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <b>{t('ru.spins.fields.win')}:</b> {spin?.win}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <b>{t('ru.spins.fields.lines')}:</b> {spin?.lines}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <b>{t('ru.spins.fields.balance_start')}:</b> {prettyNum(spin?.balance_start)}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <b>{t('ru.spins.fields.balance_end')}:</b> {prettyNum(spin?.balance_end)}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row" component="section">
                    <b>{t('ru.spins.fields.created_at')}:</b>{' '}
                    {spin?.created_at && <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" component="section">
                      <span style={{ margin: '3px' }}>
                        {convertUTCDateToLocalDate(spin?.created_at)}
                      </span>
                    </Box>}
                  </Box>
                </Grid>
                {parseWins?.step && <Grid item xs={12} sm={12}>
                  <b>{t('ru.spins.fields.step')}:</b> {parseWins?.step}
                </Grid>}
                <Combination game={game} sessionId={session_id} spin={spin} parseWins={parseWins}/>
              </Grid>
            </div>
          </List>
        </>
      </>
    )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Spin
