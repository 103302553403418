import React from 'react'
import { Icon } from './styles'

export const Terminals: React.FC = () => {
    return (
        <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 41" width="49" height="41">
                <defs>
                    <image
                        width="49"
                        height="41"
                        id="img5"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAApCAYAAACcGcHqAAADMElEQVRYhdWZzUtUURjGfwlBuCgpCEIhokWbIAgpMEgrDatF1Ma+0b6WSTUL+4JaDhT2B2SUlJGZZBgFWWJp2Eb8WERgUGCYhUQQJmXdFuc9zZnpzHjvzPV6fOCBO+97553n4Zxzz3vugMIZYArw5hCngDrRzyUJjgIXgBhQ6zBjonNUdF9ELgaBPOYW8oAhlH48YL8klgDNqKH67SCngLuiE9H9z0S5BF/J52HgjYMcFn09orfcNLFRgh7Qhdt4gQhH6baauB29rkC4RRoTpRL0gNbodQVCKwkTpfg0MQ+4Akzg7/k9BhxOqXES+JBy32fUIgVYC7wjea+aQD01l4dhYp3Ex4E+H9Qi8o0aLyXWb7kPMe2JUZ0fl9i2MExslXg1/tAs9y82Ys+MH9boNGJ75brYyFdLrCwME2USP+HTRJvcX2DEOiwmuozYHrneYOSPz4SJWp8mHmYwsQiYL9R7EiRGYoeRPyWxTa6Y0FPHRoBDGfIVrpjYhZpm3ajdtgd4hGrkAFYALWJW5zuAGySvrVk1ESZmzUQlcBN4gBqRNtTOq2sWAddQo6HzzcBVYKErJvQjNt2aOJghv8UVE/rptEziBcBrQ8w+ud5t5Oskttk1EyZs+0SJkT+KY/vEc4sJcxfX+8QaI39gJkwc82nivsWE7p06gafCP4YY3TsNGXndMIbSO1VKvI7EfM3Ex/zfO50DvpK8YCdRewXAeuBLSt4D3gMrwzCxylJ8On5EtQ4m8i00scCSt728yMoEwE7gHmq+T8dGYLWlRljI2oRLSGvCPGM3Rq8rEBpJmEh7xn6LagOWOsgi0WcdCd3u1hN8Ec8G60VvhWniNAkcAZqAOw6ySfRpxLSJSeAnyefbuYBi4BfwA2A7iWEawN8bjT7UAecJ0BvgOzb2Sp3uAN8ZNDRXalclQDvRzOew2E5yw5gV9HwszLFOodSJ5SooG9TIj8dRiy1bxqVOTbTyFc4S7vQ4H6189RfTd+AbUIU6fWXLKqkzIXUjgT4vaI4Bn3LgWEq9lihMeKg32nHgMtAAXM+BDVInLnU9IoAHjKDeUITNkahMDDCzz//+oIL+AuDiGbVbp7q8AAAAAElFTkSuQmCC"
                    />
                </defs>
                <g id="Left Menu">
                    <g id="Menu">
                        <use id="2305475" href="#img5" transform="matrix(1,0,0,1,0,0)" />
                    </g>
                </g>
            </svg>
        </Icon>
    )
}

export default Terminals
