import React, { FunctionComponent } from 'react';
import { MenuItem, Box, Select, FormLabel } from '@material-ui/core';
import { prettyNum } from '../../../utils/prettyNum';
import { IHall } from '../../../interfaces';

type HallBoxProps = {
    hallId: number,
    setHallId: (id: number) => void,
    halls: IHall[]
};

function cmp(prevProps: HallBoxProps, nextProps: HallBoxProps) {
    return prevProps.hallId === nextProps.hallId;
}

const HallBox: FunctionComponent<HallBoxProps> = React.memo(({ hallId, setHallId, halls }) => {
    const hall = halls.find(e => e.id === hallId);
    return <Box display="flex" justifyContent="center">
        <FormLabel>
            <Select value={hallId} onChange={(e: any) => { setHallId(e.target.value); }}>
                {halls.map(hall => <MenuItem key={hall.id} value={hall.id}>{hall.name}</MenuItem>)}
            </Select>
            {` `}({prettyNum(hall?.balance)}
            {` `}
            {hall?.currency_id})
        </FormLabel>
    </Box>;
}, cmp);

export default HallBox;