import React from 'react'
import { Wrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  userSelector,
  deleteUserListItem,
  handleChangeUserStatus,
  handlerAddUser,
  handlerTranferUserBalance,
  handlerWithdrawTranferUserBalance,
} from '../../../redux/userReducer'
import Overlay from '../../Common/Overlay/index'
import Confirm from '../../Common/Confirm'
import { IUser } from '../../../interfaces'
import {
  authSelector,
  clearState,
  handlerPutAdminBalance,
  handlerTakeAdminBalance,
} from '../../../redux/authReducer'
import { useShowBlock } from '../../../hooks/useShowBlock'
import AddUser from '../Add'
import User from '../View'
import PaymentForm from '../../Payment'
import Operations from '../../Operations'
import Axios from 'axios'
import { reLogin } from '../../../services/Auth'
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isConfirmType } from '../../../interfaces';
import ReloginForm from './relogin-form';
import UserListBox from './user-list-box'

interface UsersListProps extends RouteComponentProps<any> {
  userId: number | null;
  setUserId: (id: number | null) => void;
}

const UsersList: React.FC<UsersListProps> = ({ userId, setUserId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();

  const { data } = useSelector(authSelector);
  const { users: { data: users }, form: formLoading } = useSelector(userSelector);

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false });
  const [isBlock, setIsBlock] = React.useState<isConfirmType>({ open: false });
  const [isReLogin, setIsReLogin] = React.useState<isConfirmType>({ open: false });
  const relogin = () => isReLogin.id && reLogin(isReLogin.id).then((res) => {
    dispatch(clearState());
    Axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.data}`;
    localStorage.setItem('token', res.data.data);
    window.location.href = window.location.origin;
  });

  const [isChange, setIsChange] = React.useState(false)
  const { hideBlock: hideUser } = useShowBlock()
  const [isAddUserShown, setIsAddUserShown] = React.useState(false);
  const [isPaymentShown, setIsPaymentShown] = React.useState(false);

  const location: any = useLocation()
  const id = new URLSearchParams(location.search).get('id')
  const operations_page = Number(new URLSearchParams(location.search).get('operations_page'));

  React.useEffect(() => {
    setUserId(id ? Number(id) : null);
  }, [id, setUserId]);
  return (
    <Wrapper>
      <UserListBox setUserId={setUserId} setIsPaymentShown={setIsPaymentShown} setChange={setIsChange} showAddUser={setIsAddUserShown} setIsConfirm={setIsConfirm} setIsBlock={setIsBlock} setIsReLogin={setIsReLogin} />
      {isAddUserShown && <Overlay>
        <AddUser hideAddUser={() => setIsAddUserShown(false)} handlerAddUser={(data, assignmentsData, hideForm) => dispatch(handlerAddUser(data, assignmentsData, hideForm))} />
      </Overlay>}
      {!!id && !!userId && <Overlay>
        <User hideUser={hideUser} setUserId={setUserId} user_id={userId} change={isChange} setChange={setIsChange} />
      </Overlay>}
      {isPaymentShown && (
        <Overlay>
          <PaymentForm
            id={userId}
            hidePayment={() => setIsPaymentShown(false)}
            take={
              data?.id === userId
                ? (data, id) => {
                  dispatch(handlerTakeAdminBalance(data))
                }
                : (data, id, info) => dispatch(handlerWithdrawTranferUserBalance(data, id, info))
            }
            putEvent={
              data?.id === userId
                ? (data) => {
                  dispatch(handlerPutAdminBalance(data))
                }
                : (data, id, info) => dispatch(handlerTranferUserBalance(data, id, info))
            }
            balance={users?.find((i: IUser) => i.id === userId)?.balance}
          />
        </Overlay>
      )}
      {!!id && !!operations_page && (
        <Overlay>
          <Operations user_id={Number(id)} hideOperations={() => {
            const query = new URLSearchParams(location.search)
            query.delete('operations_page')
            history.push({
              pathname: location.pathname,
              search: query.toString(),
              state: {
                prevPaths:
                  location.state && location.state.prevPaths ? location.state.prevPaths : [],
              },
            })
          }} />
        </Overlay>
      )}
      {!!isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsConfirm({
                open: false,
              })
            }}
            submit={() => {
              dispatch(deleteUserListItem(isConfirm?.id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
      {!!isBlock.open && (
        <Overlay>
          <Confirm
            text={`${!!users && !!users?.find((i) => i.id === isBlock.id)?.status
              ? t('ru.common.confirm.block')
              : t('ru.common.confirm.unblock')
              } ${isBlock.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsBlock({ open: false })
            }}
            submit={() => {
              dispatch(
                handleChangeUserStatus(
                  users?.find((i) => i.id === isBlock.id),
                  setIsBlock
                )
              )
            }}
          />
        </Overlay>
      )}
      {isReLogin.open && <ReloginForm name={isReLogin.name} loading={formLoading.loading} cancel={() => setIsReLogin({ open: false })} relogin={relogin} />}
    </Wrapper>
  )
};

export default withRouter(UsersList)
