import React from 'react'
import { OverlayWrapper } from './styles'

const Overlay: React.FC = ({ children }) => {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return <OverlayWrapper>{children}</OverlayWrapper>
}

export default Overlay
