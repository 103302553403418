import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { BindWrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayersList, playerSelector } from '../../../redux/playerReducer'

import CloseIcon from '@material-ui/icons/Close'
import { tableBindChange, tableSelector } from '../../../redux/tableReducer'
import { connectPlayer, disconnectPlayer } from '../../../services/Player'

interface BindProps {
  hideBind: () => void
  table_id: number
}

const Bind: React.FC<BindProps> = ({ hideBind, table_id }) => {
  const dispatch = useDispatch()
  const { items: players } = useSelector(playerSelector)
  const {
    tables: { data: tables },
  } = useSelector(tableSelector)

  const table = tables.find((t) => t.id === table_id)

  React.useEffect(() => {
    !players.length && dispatch(getPlayersList())
    // eslint-disable-next-line
  }, [dispatch])

  const [player, setPlayer] = React.useState(
    table?.playerConnection ? table?.playerConnection.player_id : 0
  )

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  const handleConnectPlayerToTable = () => {
    setLoading(true)
    connectPlayer(player, table_id).then((res) => {
      if (res?.data?.player_id) {
        setError(res.data.data)
      } else {
        dispatch(tableBindChange({ id: table_id, player_id: { player_id: player } }))
        setLoading(false)
      }
    })
  }

  const handleDisconnectPlayer = () => {
    setLoading(true)
    disconnectPlayer(player, table_id).then((res) => {
      if (res?.data?.code === 200) {
        table && dispatch(tableBindChange({ id: table_id, player_id: null }))
        setLoading(false)
        setPlayer(0)
      } else {
        setError(res.data.data)
      }
    })
  }
  const isConnection = table?.playerConnection;
  return (
    <BindWrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>Привязка игрока</h3>
        <IconButton onClick={hideBind}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Select value={player} disabled={!!table?.playerConnection} onChange={(e: any) => setPlayer(e.target.value)} style={{ margin: '1em', width: '100%' }}>
        <MenuItem key="0" value="0"> - </MenuItem>
        {players.filter((p) => !p.tableConnection).map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
        {(isConnection ? players : players.filter((t) => !t.tableConnection)).map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
      </Select>
      <Button disabled={loading || player === 0} onClick={table?.playerConnection ? handleDisconnectPlayer : handleConnectPlayerToTable} variant="contained" fullWidth >
        {loading ? <CircularProgress style={{ color: '#b1b1b1' }} size={17} /> : !!table?.playerConnection ? 'Отвязать' : 'Привязать'}
      </Button>
      {error && <Typography>{error}</Typography>}
    </BindWrapper>
  )
}
export default Bind
