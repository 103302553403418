import axios from 'axios'
import { GameData, ImagesData } from '../interfaces'

export const fetchGamesList = async (
  page: number,
  limit: number,
  search = {
    id: '',
    name: '',
  },
  sort?: string
) => {
  return axios.get(`game`, {
    params: {
      expand: 'manufacturer,packages,lines',
      'f[id]': search.id,
      'f[name]': search.name,
      sort: sort,
      page: page,
      'per-page': limit,
    },
  })
}

export const addGame = async (data: GameData) => {
  return axios.post(`game`, data, {
    params: { expand: 'manufacturer,packages' },
  })
}

export const fetchGame = async (id: number) => {
  return axios.get(`game/${id}`, {
    params: { expand: 'lines,icons' },
  })
}

export const changeGame = async (data: GameData, id: number) => {
  return axios.put(`game/${id}`, data, {
    params: { expand: 'manufacturer,packages' },
  })
}

export const deleteGame = async (id: number) => {
  return axios.delete(`game/${id}`)
}

export const addGameImage = async (data: ImagesData, id: number) => {
  return axios.post(`game/${id}/image/attach`, data)
}

export const deleteGameImage = async (id: number, image_id: number) => {
  return axios.post(`game/${id}/image/${image_id}/delete`)
}
