import React from 'react'
import { ListItem } from '../styles'
import { prettyNum } from '../../../../utils/prettyNum'
import { Box } from '@material-ui/core'
import { convertUTCDateToLocalDate } from '../../../../utils/date'

interface OperationsListItemProps {
  index: number
  id: number
  credit: number
  debit: number
  before: number
  after: number
  created_at: string
  type: any
}

const Item: React.FC<OperationsListItemProps> = ({
  index,
  id,
  credit,
  debit,
  before,
  after,
  type,
  created_at
}) => {
  const operationColor = (id: number) => {
    if (id % 2 === 0) {
      return Number(after) > Number(before) ? '#a9c477' : '#de7d55'
    } else {
      return Number(after) > Number(before) ? '#87b03b57' : '#ffd8c7'
    }
  }

  const greyColor = (id: number) => {
    if (id % 2 === 0) {
     return '#bebebe'
    } else {
      return'#e9e9e9'
    }
  }
const benefit = debit-credit
  return (
    <>
      <ListItem bg={benefit === 0 ? greyColor(index) : operationColor(index)}>
        <div>{id}</div>
        <div>{prettyNum(benefit)}</div>
        <div>{prettyNum(before)}</div>
        <div>{prettyNum(after)}</div>
        <div>{type}</div>
        <div>{created_at && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                component='section'
              >
                <span>
                  {convertUTCDateToLocalDate(created_at)}
                </span>
              </Box>
            )}</div>
      </ListItem>
    </>
  )
}

export default Item
