import React from 'react'
import HallsList from './List'

const Halls: React.FC = () => {
  return (
    <>
      <HallsList />
    </>
  )
}

export default Halls
