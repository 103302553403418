import React from 'react'

import ReportList from './List'


const Report: React.FC = () => {

  return (
    <>
      <ReportList />
    </>
  )
}

export default Report
