import React from 'react'
import { Box, Chip, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  changeJackpotItem,
  getJackpot,
  jackpotSelector,
  jackpotSuccess,
} from '../../../redux/jackpotReducer'
import { Wrapper } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import Preloader from '../../Common/Preloader'
import { prettyNum } from '../../../utils/prettyNum'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import JackpotForm from '../Form'
import { useTranslation } from 'react-i18next'
import { convertUTCDateToLocalDate } from '../../../utils/date'
import { findPermissionOr } from '../../../utils/find'
import { authSelector } from '../../../redux/authReducer'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  chip: {
    margin: '.5vh',
    padding: '0 1vh',
  },
}))

interface JackpotProps {
  jackpotId: number
  hideJackpot: () => void
  isChangeMode: boolean
  hall_id?: null | number
  table_id?: null | number
}

const Jackpot: React.FC<JackpotProps> = ({
  jackpotId,
  hideJackpot,
  isChangeMode,
  hall_id,
  table_id,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()
  const { assigments: { items: assigments } } = useSelector(authSelector);

  const typeConvert = (type: number) => {
    switch (type) {
      case 0:
        return t('ru.jackpots.selectors.bronze')
      case 1:
        return t('ru.jackpots.selectors.silver')
      case 2:
        return t('ru.jackpots.selectors.gold')
      case 3:
        return t('ru.jackpots.selectors.diamond')
      default:
        break
    }
  }

  const statusConvert = (status: number) => {
    switch (status) {
      case 0:
        return t('ru.jackpots.selectors.active')
      case 1:
        return t('ru.jackpots.selectors.accumulation')
      case 2:
        return t('ru.jackpots.selectors.inactive')
      case 3:
        return t('ru.jackpots.selectors.disabled')
      default:
        break
    }
  }

  const { jackpot, loading } = useSelector(jackpotSelector)

  React.useEffect(() => {
    jackpotId && dispatch(getJackpot(jackpotId))
    return () => {
      dispatch(jackpotSuccess(null))
    }
  }, [dispatch, jackpotId])

  const [isChange, setIsChange] = React.useState(isChangeMode)

  React.useEffect(() => {
    return () => {
      setIsChange(false)
    }
  }, [])


  const rootRender =
    !jackpot || loading ? (
      <Preloader />
    ) : (
      <>
        <Box className={classes.closeBtn}>
          {findPermissionOr(assigments, ['jackpotUpdate', 'jackpotUpdateChildren']) && <Tooltip title={isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))} placement="top">
            <IconButton onClick={() => setIsChange(!isChange)}>
              <Box display="flex" justifyContent="center" alignItems="center" style={{ background: '#b6b6b6b6', width: '30px', minHeight: '30px', borderRadius: '50%', padding: '6px' }}>
                <img style={{ width: '20px', minHeight: 'auto' }} src={!isChange ? editPng : visionPng} alt="icon" />
              </Box>
            </IconButton>
          </Tooltip>}
          <IconButton onClick={hideJackpot}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center">
          <h2>
            {t('ru.jackpots.sub_title')} #{jackpot?.id}
          </h2>
        </Box>
        {isChange ? (
          <Box style={{ width: '100%' }} display="flex" justifyContent="center">
            <JackpotForm
              onSubmit={(data, hideForm) => dispatch(changeJackpotItem(data, hideForm, jackpotId))}
              cancelBtnEvent={() => setIsChange(false)}
              cancelBtnText={t('ru.common.cancel_btn')}
              submitBtnText={t('ru.common.change_btn')}
              hideForm={hideJackpot}
              jackpotHalls={jackpot.halls}
              jackpotTables={jackpot.gambling_tables}
              id={jackpot.id}
              type={jackpot.type}
              min_bet={jackpot.min_bet}
              min_bet_to_win={jackpot.min_bet_to_win}
              restart={jackpot.restart}
              min_players={jackpot.min_players}
              start_balance={jackpot.start_balance}
              initial_balance={jackpot.initial_balance}
              end_balance={jackpot.end_balance}
              percent={jackpot.percent}
              parent_id={jackpot.parent_id}
              status={jackpot.status}
              hall_id={hall_id}
              table_id={table_id}
            />
          </Box>
        ) : (
          <Grid container spacing={1} style={{ marginTop: '15px' }}>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.status')}:</b>
                <Chip className={classes.chip} label={<span>{statusConvert(jackpot?.status)}</span>} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.type')}:</b>
                <Chip className={classes.chip} label={<span>{typeConvert(jackpot?.type)}</span>} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.restart')}:</b>
                <Chip
                  className={classes.chip}
                  label={<span>{!!jackpot?.restart ? 'Вкл' : 'Выкл'}</span>}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.min_bet')}:</b>
                <Chip className={classes.chip} label={<span>{Number(jackpot?.min_bet) / 100}</span>} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.min_bet_to_win')}:</b>
                <Chip className={classes.chip} label={<span>{Number(jackpot?.min_bet_to_win) / 100}</span>} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.initial_balance')}:</b>
                <Chip
                  className={classes.chip}
                  label={
                    <span>
                      {prettyNum(jackpot?.start_balance)
                        ? prettyNum(jackpot?.start_balance)
                        : '0.00'}
                    </span>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.end_balance')}:</b>
                <Chip
                  className={classes.chip}
                  label={
                    <span>
                      {prettyNum(jackpot?.end_balance) ? prettyNum(jackpot?.end_balance) : '0.00'}
                    </span>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.min_players')}:</b>
                <Chip className={classes.chip} label={<span>{jackpot.min_players}</span>} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.balance')}:</b>
                <Chip
                  className={classes.chip}
                  label={
                    <span>
                      {prettyNum(jackpot?.balance) ? prettyNum(jackpot?.balance) : '0.00'}
                    </span>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.start_balance')}:</b>
                <Chip
                  className={classes.chip}
                  label={
                    <span>
                      {prettyNum(jackpot?.initial_balance)
                        ? prettyNum(jackpot?.initial_balance)
                        : '0.00'}
                    </span>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <b>{t('ru.jackpots.fields.created_at')}:</b>
                <span>
                  {jackpot && (
                    <Box
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      flexDirection="row"
                    >
                      <span style={{ margin: '3px' }}>
                        {convertUTCDateToLocalDate(
                          jackpot.created_at
                        )}
                      </span>
                    </Box>
                  )}
                </span>
              </Box>
            </Grid>
            {jackpot?.start_time && (
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <b>{t('ru.jackpots.fields.start_time')}:</b>
                  <span>{jackpot?.start_time}</span>
                </Box>
              </Grid>
            )}
            {jackpot?.end_time && (
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <b>{t('ru.jackpots.fields.end_time')}:</b>
                  <span>{jackpot?.end_time}</span>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </>
    )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Jackpot
