import { down } from 'styled-breakpoints'
import styled from 'styled-components'

export const SubForm = styled.div`
  position: absolute;
  background: #fff;
  color: #666;
  padding: 5vh;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 999;
  border-radius: 1vh;
  width: max-content;
  ${down('sm')} {
    width: 100%;
  }
`
