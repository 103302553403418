import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import editPng from '../../../../assets/images/edit.png';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type UpdateBtnProps = {
    id: number,
    setIsChangeMode: (isChangeMode: boolean) => void
};

function cmp(prevProps: UpdateBtnProps, nextProps: UpdateBtnProps) {
    return prevProps.id === nextProps.id;
};

const UpdateBtn: FunctionComponent<UpdateBtnProps> = React.memo(({ id, setIsChangeMode }) => {
    const history = useHistory();
    const [t] = useTranslation();
    return <Tooltip title={String(t('ru.common.change_btn'))} placement="top">
        <Action bg={'#919191'} onClick={() => { setIsChangeMode(true); history.push({ pathname: `tables`, search: `?table_id=${id}` }) }} >
            <IconButton>
                <img src={editPng} alt="icon" />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default UpdateBtn;