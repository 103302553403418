import React, { ReactElement } from 'react'
import { Box, Grid, Chip } from '@material-ui/core'
import { prettyNum } from '../../../utils/prettyNum'
import { useDispatch, useSelector } from 'react-redux'
import { handlerFetchAssignments } from '../../../redux/assignmentReducer'
import { useStyles } from '../styles'
import PaymentForm from '../../Payment'
import {
  handlerTakeAdminBalance,
  handlerPutAdminBalance,
  authSelector,
} from '../../../redux/authReducer'
import Overlay from '../../Common/Overlay/index'
import { handlerTranferUserBalance, userSelector } from '../../../redux/userReducer'
import { IUser } from '../../../interfaces'
import { IPaymentData } from '../../../interfaces'
import { Status } from '../../Users/List/styles'
import { tableSelector } from '../../../redux/tableReducer'
import { useTranslation } from 'react-i18next'

interface UserInfoProps {
  item: IUser
  isPayment: boolean
  hidePayment: () => void
}

const UserInfo: React.FC<UserInfoProps> = ({ item, isPayment, hidePayment }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  React.useEffect(() => {
    dispatch(handlerFetchAssignments(item.id))
  }, [dispatch, item.id])

  const { data: authInfo } = useSelector(authSelector)
  const {
    users: { data: users },
  } = useSelector(userSelector)
  const {
    tables: { data: tables },
  } = useSelector(tableSelector)

  const usersLength = users.filter((i) => i.parent_id === item.id).length
  const tablesLength = tables.filter((i) => i.hall?.owner_id === item.id).length
  // const userRole = (assignments: AssigmentType) => {
  //   let role = null
  //   for (let assignment in assignments) {
  //     if (assignments[assignment] === 'role') {
  //       role = roles?.find((i: IRole) => i.name === assignment)?.description
  //     }
  //   }
  //   return role
  // }
  // const userPermissions = (assignments: AssigmentType) => {
  //   let permissions: string[] | undefined = []
  //   for (let assignment in assignments) {
  //     if (assignments[assignment] === 'permission') {
  //       if (permissionsList.find((i: IPermission) => i.name === assignment)) {
  //         permissions = [
  //           ...permissions,
  //           permissionsList.find((i: IPermission) => i.name === assignment)
  //             ? permissionsList?.find((i: IPermission) => i.name === assignment)?.description
  //             : assignment,
  //         ] as string[]
  //       }
  //     }
  //   }
  //   return permissions
  // }
  const permissionAndRolesRender = () => {
    let role
    for(let i in item.roles) {
      role = item.roles[i].description
    }
    return (
      <>
        <Grid item xs={12} sm={6}>
          <Box>
            <b>{t('ru.users.fields.role')}:</b>{' '}
            <span>
              {role}
            </span>
          </Box>
        </Grid>
      </>
    )
  }

  const renderBalance = () => {
    let items: ReactElement[] = []
    for (let currency in item.balance) {
      items = [
        ...items,
        <Chip
          key={currency}
          className={classes.chip}
          label={<b>{`${prettyNum(item.balance[currency])} ${currency}`}</b>}
        />,
      ]
    }
    return !!items.length ? items : <span>{t('ru.common.no_data')}</span>
  }
  return (
    <>
      <Box
        className={classes.box}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <b>{t('ru.tree.fields.child_users')}:</b>
        <Box display="flex">
          <span>{usersLength}</span>
        </Box>
      </Box>
      <Box
        className={classes.box}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <b>{t('ru.tree.fields.tables')}:</b>
        <Box display="flex">
          <span>{tablesLength}</span>
        </Box>
      </Box>
      <Box
        className={classes.box}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <b>{t('ru.tree.fields.status')}:</b>
        <Box display="flex">
          <Status active={!!item.status}>{!!item.status ? 'Активен' : 'Неактивен'}</Status>
        </Box>
      </Box>
      <Box
        className={classes.box}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection=""
      >
        <b>{t('ru.tree.fields.balance')}:</b>
        {renderBalance()}
      </Box>
      {permissionAndRolesRender()}
      {isPayment && (
        <Overlay>
          <PaymentForm
            id={item.id}
            hidePayment={hidePayment}
            take={
              authInfo?.id === item.id
                ? (data: IPaymentData, id) => {
                    dispatch(handlerTakeAdminBalance(data))
                  }
                : () => {}
            }
            putEvent={
              authInfo?.id === item.id
                ? (data: IPaymentData) => {
                    dispatch(handlerPutAdminBalance(data))
                  }
                : (data: IPaymentData, id?: number | undefined, info?: boolean) => {
                    id && info && dispatch(handlerTranferUserBalance(data, id, info))
                  }
            }
            balance={item.balance}
            info
          />
        </Overlay>
      )}
    </>
  )
}

export default UserInfo
