import axios from 'axios'
import { DefaultPackageData } from '../interfaces'

export const fetchDefaultGamePackagesList = async (search = {
    id: '',
}) => {
    return axios.get('game-default-package',{
        params: { expand: 'games', 'f[id]': search.id},
    })
}

export const addDefaultGamePackage = async (data: DefaultPackageData) => {
    return axios.post('game-default-package', data)
}

export const fetchDefaultGamePackage = async (id: number) => {
    return axios.get(`game-default-package/${id}`)
}

export const changeDefaultGamePackage = async (data: DefaultPackageData, id: number) => {
    return axios.put(`game-default-package/${id}`, data)
}

export const deleteDefaultGamePackage = async (id: number) => {
    return axios.delete(`game-default-package/${id}`)
}
