import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Wrapper = styled.div`
    position: relative;
    margin: auto;
    border-radius: 1vh;
    max-width: max-content;
    height: 95vh;
    background: #fff;
    padding: 5vh;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    ${down('sm')} {
        width: 100%;
    }
`
