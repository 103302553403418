import React from 'react'
import {
  getProfilesList, profileSelector,
} from '../../redux/profileReducer'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Button, Grid, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Wrapper } from './styles'

import { useForm } from '../../hooks/useForm'

import ProfilesList from './List'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  span: {
    width: '25%',
  },
  gridItem: {
    width: '100%',
  },
  gridWrapper: {
    marginTop: '3vh',
  },
  paper: {
    width: '100%',
    padding: '1.5vh',
    cursor: 'pointer',
    transition: '.6s',
    '&:hover': {
      background: '#efefef',
    },
  },
  selected: {
    width: '100%',
    padding: '1.5vh',
    cursor: 'pointer',
    transition: '.6s',
    background: '#d1d7d9',
  },
}))

interface ProfilesProps {
  setCurrentProfile: (id: number) => void
  hideProfiles: () => void
}

const Profiles: React.FC<ProfilesProps> = ({ setCurrentProfile, hideProfiles }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const { items: profiles } = useSelector(profileSelector)

  React.useEffect(() => {
      !profiles.length && dispatch(getProfilesList())
    // eslint-disable-next-line
  }, [dispatch])


  const { form, handleInputChange } = useForm({
    name: '',
    patronymic: '',
    surname: '',
  })

  return (
    <Wrapper>
      <h3>{t('ru.profiles.title')}</h3>
      <Grid container spacing={1} className={classes.gridWrapper}>
        <Grid item xs={6}>
          <TextField
            label={t('ru.profiles.fields.first_name')}
            name="name"
            variant="outlined"
            size="small"
            value={form.name}
            onChange={handleInputChange}
            className={classes.gridItem}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('ru.profiles.fields.sure_name')}
            name="surname"
            variant="outlined"
            size="small"
            value={form.surname}
            onChange={handleInputChange}
            className={classes.gridItem}
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            label={t('ru.profiles.fields.last_name')}
            name="patronymic"
            variant="outlined"
            size="small"
            value={form.patronymic}
            onChange={handleInputChange}
            className={classes.gridItem}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(
                getProfilesList({
                  name: form.name,
                  surname: form.surname,
                  patronymic: form.patronymic,
                })
              )
            }}
            size="medium"
            className={classes.gridItem}
          >
            <SearchIcon />
            {t('ru.common.search')}
          </Button>
        </Grid>
      </Grid>

      <ProfilesList setCurrentProfile={setCurrentProfile} hideProfiles={hideProfiles}/>
    </Wrapper>
  )
}

export default Profiles
