import { Box, Grid, List, ListItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

type WinScattersProps = {
  setScatterId: (scatterId: number | null) => void;
  parseWins: any;
};

const WinScatters: React.FC<WinScattersProps> = React.memo(({ setScatterId, parseWins }) => {
  const [t] = useTranslation();
  return <Grid item xs={12} sm={12}>
    <b>Scatters:</b>
    <List component="nav">
      {parseWins?.scatters.map((item: any, id: number) => <ListItem key={id} button style={{ background: '#fafafa' }} onMouseEnter={() => setScatterId(id)} onMouseLeave={() => setScatterId(null)}>
        <Box key={id} style={{ cursor: 'pointer', width: '100%', height: '100%' }} display="flex" justifyContent="space-between">
          <span>{id + 1}.</span>
          <span>
            {t('ru.spins.combo.symbol')}: {item.symbol}
          </span>
          <span>
            {t('ru.spins.combo.size')}: {item.size}
          </span>
          <span>
            {t('ru.spins.combo.score')}: {item.points}
          </span>
        </Box>
      </ListItem>
      )}
    </List>
  </Grid>;
});

export default WinScatters;
