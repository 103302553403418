import React from 'react'

import List from '@material-ui/core/List'
import {
  Box,
  Grid,
  makeStyles,
  IconButton,
  ListItem,
  Button,
  Tooltip,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { Wrapper } from './styles'
import {
  gameSelector,
  handlerDeleteImage,
  handlerAddImage,
  handleChangeGame,
} from '../../../redux/gameReducer'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../../Common/Preloader'
import { IGame, IPackage, ImagesData, GameData } from '../../../interfaces'
import { Status } from '../../Users/List/styles'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import GameForm from '../Form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  input: {
    display: 'none',
  },
  image: {
    maxWidth: '60vh',
  },
}))

interface GameProps {
  game: IGame | undefined
  hideGame: () => void
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
}

const Game: React.FC<GameProps> = ({ game, hideGame, isChangeMode, setIsChangeMode }) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  const { imageUpload } = useSelector(gameSelector)
  const [img, setImg] = React.useState<any>('')

  const handlerUploadImage = ({ target }: any) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(target.files[0])
    fileReader.onload = (e: any) => {
      setImg(e.target.result)
    }
  }

  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  const imageData: ImagesData = {
    files: [img],
  }
  const viewImage = game?.images[0] ? (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <img className={classes.image} src={game?.images[0].url} alt={game?.images[0].url} />
      <Button
        disabled={imageUpload.loading}
        onClick={() => dispatch(handlerDeleteImage(game?.id, game?.images[0].id))}
        color="secondary"
      >
        {t('ru.common.delete_btn')}
      </Button>
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handlerUploadImage}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          {t('ru.common.add_btn')}
        </Button>
      </label>
    </Box>
  )

  const renderImage = () => {
    return !img ? (
      viewImage
    ) : (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <img className={classes.image} src={img} alt="img" />
        <Button
          disabled={imageUpload.loading}
          onClick={() => dispatch(handlerAddImage(imageData, game?.id, setImg))}
        >
          {t('ru.common.save_btn')}
        </Button>
      </Box>
    )
  }
  const [isChange, setIsChange] = React.useState(isChangeMode)
  const rootRender = game && (
    <>
      <Box className={classes.closeBtn}>
        {
          <Tooltip
            title={
              isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))
            }
            placement="top"
          >
            <IconButton onClick={() => setIsChange(!isChange)}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: '#b6b6b6b6',
                  width: '30px',
                  minHeight: '30px',
                  borderRadius: '50%',
                  padding: '6px',
                }}
              >
                <img
                  style={{
                    width: '20px',
                    minHeight: 'auto',
                  }}
                  src={!isChange ? editPng : visionPng}
                  alt="icon"
                />
              </Box>
            </IconButton>
          </Tooltip>
        }
        <IconButton onClick={() => history.goBack()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center">
        <AccountCircleIcon fontSize="large" />
        <h2>{game?.name}</h2>
      </Box>
      <br />
      <Divider />
      {isChange ? (
        <GameForm
          onSubmit={(data: GameData, hideForm: () => void, id: number | undefined) =>
            dispatch(handleChangeGame(data, hideForm, id))
          }
          cancelBtnEvent={() => setIsChange(false)}
          cancelBtnText={t('ru.common.back_btn')}
          submitBtnText={t('ru.common.submit_btn')}
          id={game?.id}
          name={game?.name}
          manufacturer_id={game?.manufacturer_id}
          status={game?.status}
          packages={game?.packages}
          hideForm={() => setIsChange(false)}
        />
      ) : (
        <List>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <b>{t('ru.games.fields.manufacturer')}:</b>
                  {''}
                  {game?.manufacturer.name}
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <b>{t('ru.games.fields.packet')}:</b>
                  {!!game?.packages
                    ? game?.packages.map((item: IPackage) => {
                        return <ListItem key={item.id}>{item.name}</ListItem>
                      })
                    : t('ru.common.is_absent')}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="space-between" flexDirection="row">
                  <b>{t('ru.games.fields.status')}:</b>
                  <Box display="flex">
                    <Status active={!!game!.status}>
                      {!!game!.status
                        ? t('ru.common.status.active')
                        : t('ru.common.status.inactive')}
                    </Status>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <span>
                  <b>{t('ru.games.fields.image')}:</b>
                </span>
                <br />
                {renderImage()}
                {imageUpload.loading && <Preloader absolute />}
              </Grid>
            </Grid>
          </div>
        </List>
      )}
    </>
  )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Game
