import React, { FunctionComponent } from 'react';
import { Grid, Box } from '@material-ui/core';
import SwitchStatus from '../../../../Common/SwitchStatus';

type FieldStatusProps = {
    className: string;
    checked: boolean;
    onClick: () => void;
};

function cmp(prev: FieldStatusProps, cur: FieldStatusProps): boolean {
    return prev.className === cur.className && prev.checked === cur.checked;
}

const FieldStatus: FunctionComponent<FieldStatusProps> = React.memo(({ className, checked, onClick }) => {
    return <Grid item xs={12} sm={4}>
        <Box className={className} display="flex" justifyContent="center" alignItems="center" onClick={onClick} style={{ cursor: 'pointer' }} >
            <SwitchStatus checked={checked} />
        </Box>
    </Grid>;
}, cmp);

export default FieldStatus;