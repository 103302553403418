import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { logSelector, getLogList } from '../../redux/logReducer';
import { getUsersList, userSelector } from '../../redux/userReducer';
import { ILog, IUser } from '../../interfaces';
import { convertUTCDateToLocalDate } from '../../utils/date';

const actions = ['ACTION_LOGIN', 'ACTION_LOGOUT', 'ACTION_HALL_CREATE', 'ACTION_HALL_UPDATE', 'ACTION_HALL_DELETE',
    'ACTION_USER_CREATE', 'ACTION_USER_UPDATE', 'ACTION_USER_DELETE', 'ACTION_TABLE_CREATE', 'ACTION_TABLE_UPDATE',
    'ACTION_TABLE_DELETE', 'ACTION_JACKPOT_CREATE', 'ACTION_JACKPOT_UPDATE', 'ACTION_JACKPOT_DELETE', 'ACTION_MATH_CREATE',
    'ACTION_MATH_UPDATE'];



const Logs: React.FC = React.memo(() => {
    const dispatch = useDispatch();
    const [page, setPage] = React.useState<any>(0);
    const { users: { data: usersList } } = useSelector(userSelector);
    React.useEffect(() => {
        !usersList.length && dispatch(getUsersList());
    }, [usersList, dispatch]);
    const userIdToLogin: any = {};
    usersList.forEach(({ id, login }: IUser) => (userIdToLogin[id] = login));
    const getUserNameById = (id: any) => {
        return id ? (id in userIdToLogin ? userIdToLogin[id] : `#${id}`) : ''
    };
    const getUserName = (item: ILog) => {
        if ([1, 2].includes(item.action_id)) {
            if (item.object2_id) {
                if (item.action_id === 1) {
                    return `${getUserNameById(item.object2_id)} => ${getUserNameById(item.object1_id)}`;
                }
                return `${getUserNameById(item.object1_id)} => ${getUserNameById(item.object2_id)}`;
            }
            return getUserNameById(item.object1_id);
        }
        return getUserNameById(item.user_id);
    };

    const parseData = (item: ILog) => {
        if (!item.data) {
            return "";
        }
        const ret: String[] = [];
        try {
            if ([3, 6, 9, 12, 15].includes(item.action_id)) {
                for (let k in item.data) {
                    ret.push(`${k} = ${item.data[k][1]}`);
                }
            } else if ([4, 7, 10, 13, 16].includes(item.action_id)) {
                for (let k in item.data) {
                    ret.push(`${k} = ${item.data[k][0]} -> ${item.data[k][1]}`);
                }
            }
        } catch (e) { ret.push("Ошибка"); };
        return ret.join("\r\n");
    };

    const parseAction = (item: ILog) => {
        switch (item.action_id) {
            case 1:
                return `Вход`;
            case 2:
                return `Выход`;
            case 3:
                return `Создан зал #${item.object1_id}`;
            case 4:
                return `Изменён зал #${item.object1_id}`;
            case 5:
                return `Удалён зал #${item.object1_id}`;
            case 6:
                return `Создан пользователь #${item.object1_id}`;
            case 7:
                return `Изменён пользователь #${item.object1_id}`;
            case 8:
                return `Удалён пользователь #${item.object1_id}`;
            case 9:
                return `Создан стол #${item.object1_id}`;
            case 10:
                return `Изменён стол #${item.object1_id}`;
            case 11:
                return `Удалён стол #${item.object1_id}`;
            case 12:
                return `Создан джекпот #${item.object1_id}`;
            case 13:
                return `Изменён джекпот #${item.object1_id}`;
            case 14:
                return `Удалён джекпот #${item.object1_id}`;
            case 15:
                return `Created math #${item.object1_id}`;
            case 16:
                return `Updated math #${item.object1_id}`;
        }
        return actions[item.action_id - 1];
    };

    const { items: logList, init } = useSelector(logSelector);
    React.useEffect(() => {
        !init && dispatch(getLogList());
    }, [init, dispatch]);
    const nextPage = () => {
        dispatch(getLogList((page + 1) * 10));
        setPage(page + 1);
    };
    const prevPage = () => {
        dispatch(getLogList((page - 1) * 10));
        setPage(page - 1);
    };
    return <>
        <p style={{ textAlign: "left" }}>
        </p>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Пользователь</TableCell>
                    <TableCell>ip</TableCell>
                    <TableCell>Действие</TableCell>
                    <TableCell>Описание</TableCell>
                    <TableCell>Время</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {logList.slice(0, 10).map((item: ILog) => <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{getUserName(item)}</TableCell>
                    <TableCell>{item.ip}<br />{item.agent}</TableCell>
                    <TableCell>{parseAction(item)}</TableCell>
                    <TableCell><pre style={{ maxHeight: "5em", overflowY: "scroll" }}>{parseData(item)}</pre></TableCell>
                    <TableCell>{convertUTCDateToLocalDate(item.created_at)}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
        <Button variant="contained" color="primary" size="small" disabled={page === 0} onClick={prevPage}>Позже</Button>
        <Button style={{ float: "right" }} variant="contained" color="primary" size="small" disabled={logList.length < 11} onClick={nextPage}>Раньше</Button>
    </>;
});

export default Logs;
