import React from 'react'
import { ListItem } from '../styles'

interface TerminalsListItemProps {
  item: any
  bg?: string
}

const Item: React.FC<TerminalsListItemProps> = ({ item, bg = '#fff' }) => {
  return (
    item && (
      <>
        <ListItem bg={bg}>
        <div>{item.id}</div>
          <div>{'Nürnberg'}</div>
          <div>{'12-12-2021'}</div>
          <div>{'19300.10'}</div>
          <div>{'707'}</div>
          <div>{'+7-999-999-00-00'}</div>
          <div>{'user_123'}</div>
        </ListItem>
      </>
    )
  )
}

export default Item
