import { createSlice } from '@reduxjs/toolkit'
import { fetchCashbox } from '../services/Cashbox'
import { ICashbox, ErrorType } from '../interfaces'
import { AppThunk } from './store'

interface CashboxInitialState {
  pageCount: null | number
  items: ICashbox[]
  loading: boolean
  error: ErrorType[] | null
}
export const initialState: CashboxInitialState = {
  pageCount: null,
  items: [],
  loading: false,
  error: null,
}

export const slice = createSlice({
  name: 'cashbox',
  initialState,
  reducers: {
    CashboxListIsLoading: (state) => {
      state.loading = true
    },
    CashboxListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    CashboxListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    setCasboxPage: (state, { payload }) => {
      state.pageCount = payload
    },
  },
})

export const getCashboxList = (page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(CashboxListIsLoading())

    return fetchCashbox(page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(setCasboxPage(res.headers['x-pagination-page-count']))
          dispatch(CashboxListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(CashboxListFailure([res.data.data]))
          } else {
            dispatch(CashboxListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(CashboxListFailure(err)))
  }
}


export const {
  CashboxListIsLoading,
  CashboxListSuccess,
  CashboxListFailure,
  setCasboxPage,
} = slice.actions

interface IState {
  cashbox: CashboxInitialState
}

export const cashboxSelector = (state: IState) => state.cashbox

export default slice.reducer
