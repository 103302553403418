import React, { FunctionComponent } from 'react';
import { MenuItem } from '@material-ui/core';
import { Action } from '../styles';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import InsertLinkIcon from '@material-ui/icons/InsertLink';

type BindMenuItemProps = {
    id: number,
    playerConnection: any,
    showBind: () => void,
    setTableId: (id: number) => void
};

function cmp(prevProps: BindMenuItemProps, nextProps: BindMenuItemProps) {
    const isPlayerConnection = (prevProps.playerConnection === null && nextProps.playerConnection === null) || (prevProps.playerConnection !== null && nextProps.playerConnection !== null && prevProps.playerConnection.player_id === nextProps.playerConnection.player_id);
    return prevProps.id === nextProps.id && isPlayerConnection;
};

const BindMenuItem: FunctionComponent<BindMenuItemProps> = React.memo(({ id, playerConnection, showBind, setTableId }) => {
    return <MenuItem onClick={() => { showBind(); setTableId(id); }} >
        <Action bg={'#919191'}>
            {playerConnection ? <LinkOffIcon fontSize="small" style={{ color: '#fff' }} /> : <InsertLinkIcon fontSize="small" style={{ color: '#fff' }} />}
        </Action>
    </MenuItem>;
}, cmp);

export default BindMenuItem;