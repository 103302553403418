import React from 'react'
import { prettyNum } from '../../../../utils/prettyNum'
import { ListItem } from '../styles'

interface ReportsListItemProps {
  cursor?: string
  id?: number | undefined
  name: string | undefined
  win?: string | undefined
  bet?: string | undefined
  count?: string | undefined
  payout?: string | undefined
  total?: boolean | undefined
  setSelectedHall?: (id: number) => void
}

const Item: React.FC<ReportsListItemProps> = ({
  id,
  name,
  win,
  bet,
  count,
  // payout,
  setSelectedHall,
  cursor
}) => {

  const outPercent =
    bet && win && Number(win) === 0 && Number(bet) > 0
      ? 100
      : ((Number(win) / 100 / (Number(bet) / 100)) * 100)?.toFixed(2)

  const payout =
    outPercent === 'Infinity' ? '∞' : (outPercent !== 'NaN' ? outPercent : '0') + '%'
  return (
      <ListItem bg={'#fff'} onClick={() => id && setSelectedHall && setSelectedHall(id)} cursor={cursor ? cursor : ''}>
        <div>{name}</div>
        <div>{prettyNum(Number(bet))}</div>
        <div>{prettyNum(Number(win))}</div>
        <div>{count}</div>
        <div>{prettyNum(Number(bet)-Number(win))}</div>
        <div>{payout}</div>
      </ListItem>
  )
}

export default Item
