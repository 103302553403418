import React from 'react'

import {
  Box,
  makeStyles,
  IconButton,
  FormControl,
  FormHelperText,
  TextField,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import TablesList from '../../../Tables/List'
import { useForm } from '../../../../hooks/useForm'
import { addGamblingTable } from '../../../../services/GamblingTable'
import { tableSelector, tablesListItemAdd } from '../../../../redux/tableReducer'
import { useDispatch, useSelector } from 'react-redux'
import Overlay from '../../../Common/Overlay/index'
import { Wrapper } from '../../../Common/Confirm/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  tables: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tablesCount: {
    width: '75px',
  },
  scroll: {
    // overflow: 'auto',
  },
}))

interface TablesTabProps {
  hallId: null | number
  change?: boolean
}

const TablesTab: React.FC<TablesTabProps> = ({ hallId, change }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const { form, handleInputChange, setFormItem } = useForm({
    name: '',
    tables_count: 1,
  })

  const {
    tables: { data: tables },
  } = useSelector(tableSelector)
  const [creatingTables, setCreatingTables] = React.useState<any>([])

  const searchTables = (items: any, search: string) => {
    if (search.length === 0) {
      return items
    }

    return items.filter((item: any) => {
      return !!item.name.match(/\w+/g) && item.name.match(/\w+/g)[0] === search
    })
  }
  const getHighIndex = (search: string) => {
    let arr: number[] = []
    searchTables(tables, search).forEach((element: any) => {
      arr = [...arr, Number(element.name.match(/\d+/g)[0])]
    })
    return !!arr.length ? Math.max(...arr) : 0
  }
  const addManyTables = (operatinsLength: number) => {
    let count: number = 0
    let arr: any = []
    const addedGamblingTables = (count: number, operatinsLength: number) => {
      count += 1
      hallId &&
        addGamblingTable({
          name: `${form.name} ${getHighIndex(form.name) + count}`,
          hall_id: hallId,
          status: 10,
        }).then((res) => {
          if (res.data.code === 200) {
            dispatch(tablesListItemAdd(res.data.data))
            arr = [...arr, { login: res.data.data.login, password: res.data.data.password }]
            setCreatingTables(arr)
            if (count < operatinsLength) {
              addedGamblingTables(count, operatinsLength)
            }
          }
        })

      return arr
    }
    addedGamblingTables(count, operatinsLength)
  }

  return (
    <>
      <Box className={classes.tables}>
        {hallId && <TablesList hall_id={hallId} isModal height={'45vh'} />}
        {change && (
          <Box display="flex" justifyContent="flex-end" flexDirection="row" flexWrap="wrap">
            <FormControl>
              <FormHelperText>{t('ru.common.count')}</FormHelperText>
              <Box display="flex" flexDirection="row">
                <TextField
                  className={classes.tablesCount}
                  size="small"
                  variant="outlined"
                  type="number"
                  name="tables_count"
                  onChange={(e) => setFormItem('tables_count', e.target.value.replace(/\D/, ''))}
                  value={form.tables_count}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  type="text"
                  placeholder={t('ru.halls.fields.name')}
                  name="name"
                  onChange={handleInputChange}
                  value={form.name}
                />
                <Button
                  variant="outlined"
                  disabled={Number(form.tables_count) === 0 || !form.name}
                  onClick={() => {
                    addManyTables(form.tables_count)
                    console.log(getHighIndex(form.name))
                    // setFormItem('tables_count', 1)
                  }}
                >
                  {t('ru.common.add_btn')}
                </Button>
              </Box>
            </FormControl>
          </Box>
        )}
      </Box>
      {!!creatingTables.length && (
        <Overlay>
          <Wrapper>
            <Box>
              <h3>{t('ru.halls.form_helpers.creating')}</h3>
              <IconButton className={classes.closeBtn} onClick={() => setCreatingTables([])}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              style={{ width: '100%', margin: '5vh 0' }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {creatingTables.map((item: any, id: number) => {
                return (
                  <span key={id}>
                    <b>Login: </b> {' ' + item.login} <b>Password: </b> {' ' + item.password}
                  </span>
                )
              })}
            </Box>
          </Wrapper>
        </Overlay>
      )}
    </>
  )
}

export default TablesTab
