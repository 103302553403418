import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, TextField } from '@material-ui/core';

type FieldCashbackReelProps = {
    error: boolean;
    className: string;
    onChange: (object: any) => void;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldCashbackReelProps, cur: FieldCashbackReelProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldCashbackReel: FunctionComponent<FieldCashbackReelProps> = React.memo(({ error, className, onChange, value, formHelperText }) => {
    return <Grid item xs={3} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <TextField className={className} placeholder="Введите RTP" type="text" value={value} name="cashback_reel" onChange={onChange} size="small" />
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldCashbackReel;