import { createSlice } from '@reduxjs/toolkit'
import { fetchUserAssignments } from '../services/Assignment'
import { ErrorType, IAssigments } from '../interfaces'
import { AppThunk } from './store'
interface AssigmentInitialState {
  items: IAssigments | null
  loading: boolean
  error: ErrorType[]
}
export const initialState: AssigmentInitialState = {
  items: null,
  loading: false,
  error: [],
}
export const slice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    assignmentListIsLoading: (state) => {
      state.loading = true
    },
    assignmentListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.items = payload
    },
    assignmentListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export const handlerFetchAssignments = (id: number | undefined): AppThunk => {
  return async (dispatch) => {
    dispatch(assignmentListIsLoading())

    return (
      id &&
      fetchUserAssignments(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(assignmentListSuccess(res.data.data.items))
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(assignmentListFailure([res.data.data]))
            } else {
              dispatch(assignmentListFailure(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(assignmentListFailure(err)))
    )
  }
}

export const {
  assignmentListIsLoading,
  assignmentListSuccess,
  assignmentListFailure,
} = slice.actions

interface IState {
  assignment: AssigmentInitialState
}
export const assignmentSelector = (state: IState) => state.assignment

export default slice.reducer
