import React, { FunctionComponent } from 'react';
import { Grid, FormControl, FormHelperText, NativeSelect } from '@material-ui/core';
import { IUser } from '../../../../../interfaces';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../../redux/userReducer';

type FieldOwnerProps = {
    formHelperText: string;
    error: boolean;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    userId: number;
    disabled: boolean;
    formHelperText2: string;
};

function cmp(prev: FieldOwnerProps, cur: FieldOwnerProps): boolean {
    return prev.formHelperText === cur.formHelperText && prev.error === cur.error && prev.userId === cur.userId
        && prev.disabled === cur.disabled && prev.formHelperText2 === cur.formHelperText2;
}

const FieldOwner: FunctionComponent<FieldOwnerProps> = React.memo(({ formHelperText, error, onChange, userId, disabled, formHelperText2 }) => {
    const { users: { data: usersList } } = useSelector(userSelector);
    return <Grid item xs={12} sm={4}>
        <FormControl error={error} fullWidth size="small">
            <FormHelperText>{formHelperText}</FormHelperText>
            <NativeSelect onChange={onChange} value={userId} disabled={disabled} >
                <option aria-label="None" value="" />
                {usersList && usersList.map((item: IUser) => <option value={item.id} key={item.id}>{item.login}</option>)}
            </NativeSelect>
            <FormHelperText>{formHelperText2}</FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldOwner;