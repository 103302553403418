import styled from 'styled-components'

type ActionType = {
  bg: string
}

export const Action = styled.span`
  width: 3vh;
  height: 3vh;
  max-width: 25px;
  max-height: 25px;
  margin: 0.3vh;
  background: ${(props: ActionType) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  img {
    width: 1.5vh;
    height: 1.5vh;
  }
`
