import { createGlobalStyle } from 'styled-components'
// import '/assets/fonts/fonts.css';

type GlobalStylePropsTypr = {
  theme: any
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    background: #f9f9f9;
    font-size: ${(props: GlobalStylePropsTypr) => props.theme.fontSizes.text};
    line-height: ${(props: GlobalStylePropsTypr) => props.theme.lineHeights.text};
    color: ${(props: GlobalStylePropsTypr) => props.theme.colors.white};
    ul {
      padding: 0;
    }
    div {
      font-family: 'Montserrat', sans-serif;
    }

  *,
  *::after,
  *::before { 
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  a{
    color: inherit;
    text-decoration: none;
    font-family: inherit;
  }

`

export default GlobalStyle
