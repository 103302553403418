import { Box, IconButton, makeStyles, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from '../../hooks/useForm'
import { authSelector } from '../../redux/authReducer'
import { getHallReportsList } from '../../redux/reportReducer'
import { convertToUtc, date } from '../../utils/date'
import Overlay from '../Common/Overlay'
import ReportsList from './List'
import HallsReports from './Report'
import TablesReport from './TablesReport'
import CloseIcon from '@material-ui/icons/Close'
import { Wrapper } from '../Halls/View/styles'
import { useTranslation } from 'react-i18next'
import { hallSelector } from '../../redux/hallReducer'
import GamesReportsList from './GamesReportsList'
import { useCallback } from 'react'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

const Reports: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    data,
    role: { item: userRole },
  } = useSelector(authSelector)

  const {
    halls: { data: halls },
  } = useSelector(hallSelector)

  const getUserHalls = useCallback(() => {
    let hallsId: number[] = []
    halls &&
      halls.forEach((item) => {
        hallsId.push(item.id)
      })

    return hallsId
  }, [halls])

  const { form, setFormItem } = useForm({
    from: convertToUtc(date() + ' 00:00:00'),
    to: convertToUtc(date() + ' 23:59:59'),
  })

  const [selectedHall, setSelectedHall] = React.useState<number | null>(null)
  const [selectedTable, setSelectedTable] = React.useState<number | null>(null)

  const [tab, setTab] = React.useState<number>(0)


  const returnTab = useCallback(
    (tab: number) => {
      switch (tab) {
        case 0:
          return (
            selectedHall && (
              <TablesReport
                hall_id={selectedHall}
                from={form.from}
                to={form.to}
                setSelectedTable={setSelectedTable}
              />
            )
          )
        case 1:
          return (
            selectedHall && (
              <div style={{ overflowX: 'auto', background: '#f9f9f9', padding: '2px' }}>
                <GamesReportsList
                  hall_id={selectedHall}
                  from={form.from}
                  to={form.to}
                  setTab={setTab}
                />
              </div>
            )
          )
        default:
          break
      }
    },
    // eslint-disable-next-line
    [tab, selectedHall]
  )
  const currency = data && data.halls && data.halls.find((i) => i.id === selectedHall)?.currency_id
  return (
    <div style={{ width: '100%' }}>
      <h2>{t('ru.reports.halls_title')}</h2>
      <br />
      <HallsReports
        halls={getUserHalls()}
        fetch={(halls: number[], from: string, to: string) =>
          dispatch(getHallReportsList(halls, from, to))
        }
        currency_id={String(currency)}
        form={form}
        setFormItem={setFormItem}
      />
      <ReportsList setSelectedHall={setSelectedHall} />
      {!!selectedHall && (
        <Overlay>
          <Wrapper>
            <Box display="flex">
              <h3>
                {!tab ? t('ru.reports.table_subtitle') : t('ru.reports.hall_subtitle')} ID{' '}
                {selectedHall}
              </h3>
              <IconButton className={classes.closeBtn} onClick={() => setSelectedHall(null)}>
                <CloseIcon />
              </IconButton>
            </Box>

            {userRole && userRole !== 'cashier' && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ width: '100%' }}
              >
                <Select value={tab} onChange={(e: any) => setTab(e.target.value)}>
                  <MenuItem value={0}>{t('ru.reports.selector.tables')}</MenuItem>
                  <MenuItem value={1}>{t('ru.reports.selector.games')}</MenuItem>
                </Select>
              </Box>
            )}

            {returnTab(tab)}
          </Wrapper>
        </Overlay>
      )}
      {!!selectedTable && (
        <Overlay>
          <Wrapper>
            <Box display="flex">
              <h3>
                {t('Отчет по играм стола')} ID {selectedTable}
              </h3>
              <IconButton className={classes.closeBtn} onClick={() => setSelectedTable(null)}>
                <CloseIcon />
              </IconButton>
            </Box>
            {selectedTable && (
              <div style={{ overflowX: 'auto', background: '#f9f9f9', padding: '2px' }}>
                <GamesReportsList
                  table_id={selectedTable}
                  from={form.from}
                  to={form.to}
                  setTab={setTab}
                />
              </div>
            )}
          </Wrapper>
        </Overlay>
      )}
    </div>
  )
}

export default Reports
