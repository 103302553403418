import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import PagesIcon from '@material-ui/icons/Pages';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import LimitIcon from '@material-ui/icons/MoneyOff';
import { useTranslation } from 'react-i18next';
import MenuItem from './menu-item';
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '7vh'
  },
  grid: {
    padding: '4vh'
  }
}));

const AdminMenu: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  return <div style={{ width: '100%', zIndex: 1 }}>
    <h2>{t('ru.admin_menu.title')}</h2>
    <div style={{ margin: '0 auto' }}>
      <Grid container>
        <Grid className={classes.grid} item xs={12} sm={6}>
          <MenuItem icon={<VideogameAssetIcon className={classes.icon} />} label={t('ru.admin_menu.games')} link="/admin/games" />
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6}>
          <MenuItem icon={<AccessibilityNewIcon className={classes.icon} />} label={t('ru.admin_menu.roles')} link="/admin/roles" />
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6}>
          <MenuItem icon={<BorderOuterIcon className={classes.icon} />} label={t('ru.admin_menu.packages')} link="/admin/game-packages" />
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6}>
          <MenuItem icon={<PagesIcon className={classes.icon} />} label={t('ru.admin_menu.default_packages')} link="/admin/game-default-packages" />
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6}>
          <MenuItem icon={<KeyboardIcon className={classes.icon} />} label={t('ru.admin_menu.hotkeys')} link="/admin/hotkeys" />
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6}>
          <MenuItem icon={<LimitIcon className={classes.icon} />} label={t('ru.admin_menu.limits')} link="/admin/limits" />
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6}>
          <MenuItem icon={<HistoryIcon className={classes.icon} />} label={t('ru.admin_menu.log')} link="/admin/log" />
        </Grid>
        {/* <Grid className={classes.grid} item xs={12} sm={6}>
            <MenuItem
              icon={<AvTimerIcon className={classes.icon} />}
              label="Сессии приложения"
              link="/admin/sessions"
            />
          </Grid> */}
        {/* <Grid className={classes.grid} item xs={12} sm={6}>
            <MenuItem
              icon={<ReportProblemIcon className={classes.icon} />}
              label="Обратная связь"
              link="/admin/issues"
            />
          </Grid> */}
      </Grid>
    </div>
  </div>;
};
export default AdminMenu;
