import { createSlice } from '@reduxjs/toolkit'
import {
  fetchGamesList,
  addGame,
  deleteGame,
  addGameImage,
  deleteGameImage,
  changeGame,
  fetchGame,
} from '../services/Game'
import { changeArr } from '../utils/changeArr'
import { IGame, GameData, isConfirmType, ImagesData, ErrorType, IPackage } from '../interfaces'
import { AppThunk } from './store'

interface GameinitialState {
  game: null | IGame
  loading: boolean
  error: ErrorType[]
  games: {
    items: IGame[],
    loading: boolean,
    error: ErrorType[],
  },
  form: {
    loading: boolean
    error: ErrorType[]
  }
  imageUpload: {
    loading: boolean
    error: ErrorType[]
  }
}

export const initialState: GameinitialState = {
  game: null,
  loading: false,
  error: [],
  games: {
    items: [],
    loading: false,
    error: [],
  },
  form: {
    loading: false,
    error: [],
  },
  imageUpload: {
    loading: false,
    error: [],
  },
}
export const slice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    gameIsLoading: (state) => {
      state.loading = true
    },
    gameSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.game = payload
    },
    gameFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    gamesListIsLoading: (state) => {
      state.loading = true
    },
    gamesListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.games.items = payload
    },
    gamesListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    gameItemDelete: (state, { payload }) => {
      state.games.items = [...state.games.items.filter((i) => i.id !== payload)]
    },
    gameItemAdd: (state, { payload }) => {
      state.games.items = [...state.games.items, payload]
    },
    gameItemChange: (state, { payload }) => {
      state.games.items = [...changeArr(state.games.items, payload.id, payload)]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
    gameAddImage: (state, { payload }) => {
      const itemId = state.games.items.findIndex((i) => i.id === payload.id)
      state.games.items = [
        ...state.games.items.slice(0, itemId),
        {
          ...state.games.items.find((i) => i.id === payload.id),
          images: [payload.images],
        },
        ...state.games.items.slice(itemId + 1, state.games.items.length),
      ] as IGame[]
    },
    gameDeleteImage: (state, { payload }) => {
      const itemId = state.games.items.findIndex((i) => i.id === payload)
      state.games.items = [
        ...state.games.items.slice(0, itemId),
        {
          ...state.games.items.find((i) => i.id === payload),
          images: [],
        },
        ...state.games.items.slice(itemId + 1, state.games.items.length),
      ] as IGame[]
    },
    imageUploadIsLoading: (state) => {
      state.imageUpload.loading = true
    },
    imageUploadSucces: (state) => {
      state.imageUpload.loading = false
      state.imageUpload.error = []
    },
    imageUploadIsError: (state, { payload }) => {
      state.imageUpload.loading = false
      state.imageUpload.error = payload
    },
  },
})
export const getGame = (id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(gameIsLoading())

    return fetchGame(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(gameSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(gameFailure([res.data.data]))
          } else {
            dispatch(gameFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(gamesListFailure(err)))
  }
}

export const getGamesList = (
  page: number,
  limit: number,
  search?: any,
  sort?: string
): AppThunk => {
  return async (dispatch) => {
    dispatch(gamesListIsLoading())

    return fetchGamesList(page, limit, search, sort)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(gamesListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(gamesListFailure([res.data.data]))
          } else {
            dispatch(gamesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(gamesListFailure(err)))
  }
}
let games: any = []
export const getAllGames = (page: number, limit: number, search?: any, sort?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(gamesListIsLoading())

    return fetchGamesList(page, limit, search, sort)
      .then((res) => {
        if (res.data.code === 200) {
          games = [...games, ...res.data.data]
          if (res.headers['x-pagination-current-page'] < res.headers['x-pagination-page-count']) {
            dispatch(getAllGames(Number(res.headers['x-pagination-current-page']) + 1, limit))
          } else {
            dispatch(gamesListSuccess(games))
            games = []
          }
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(gamesListFailure([res.data.data]))
          } else {
            dispatch(gamesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(gamesListFailure(err)))
  }
}

export const addGameItem = (data: GameData): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    addGame(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(gameItemAdd(res.data.data))
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const deleteGameItem = (
  id: number | undefined,
  setIsCinfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      id &&
      deleteGame(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(gameItemDelete(id))
            dispatch(formSucces())
            setIsCinfirm({ open: false })
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

export const handleAddGame = (data: GameData, hideForm: (game_id: number) => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addGame(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(gameItemAdd(res.data.data))
          dispatch(formSucces())
          hideForm(res.data.data.id)
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleChangeGame = (
  data: GameData,
  hideForm: () => void,
  id: number | undefined
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      id &&
      changeGame(data, id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(gameItemChange(res.data.data))
            hideForm()
            dispatch(formSucces())
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

export const handleChangeStatusGame = (
  game: any,
  setIsBlock: (isBlock: isConfirmType) => void
): AppThunk => {
  const packages = (packages: IPackage[]) => {
    let arr: number[] = []
    packages.forEach((item) => {
      arr = [...arr, item.id]
    })

    return arr
  }
  const data = {
    name: game.name,
    package: packages(game.packages),
    manufacturer_id: game.manufacturer_id,
    status: !!game?.status ? 0 : 10,
  }
  return async (dispatch) => {
    dispatch(formIsLoading())
    return changeGame(data, game.id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(gameItemChange(res.data.data))
          dispatch(formSucces())
          setIsBlock({ open: false })
        } else if (res.data.code !== 200) {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => console.log(err))
  }
}

export const handlerAddImage = (
  data: ImagesData,
  game_id: number | undefined,
  setImg: (img: string) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(imageUploadIsLoading())
    return (
      game_id &&
      addGameImage(data, game_id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(
              gameAddImage({
                id: game_id,
                images: res.data.data[0],
              })
            )
            setImg('')
            dispatch(imageUploadSucces())
          } else if (res.data.code !== 200) {
            dispatch(imageUploadIsError(res.data.data))
          }
        })
        .catch((err) => dispatch(imageUploadIsError(err)))
    )
  }
}

export const handlerDeleteImage = (profile_id: number, image_id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(imageUploadIsLoading())
    return deleteGameImage(profile_id, image_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(gameDeleteImage(profile_id))
          dispatch(imageUploadSucces())
        } else if (res.data.code !== 200) {
          dispatch(imageUploadIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(imageUploadIsError(err)))
  }
}

export const {
  gameIsLoading,
  gameSuccess,
  gameFailure,
  gamesListIsLoading,
  gamesListSuccess,
  gamesListFailure,
  gameItemAdd,
  gameItemDelete,
  gameItemChange,
  formIsLoading,
  formSucces,
  formIsError,
  gameAddImage,
  gameDeleteImage,
  imageUploadIsLoading,
  imageUploadSucces,
  imageUploadIsError,
} = slice.actions

interface IState {
  game: GameinitialState
}

export const gameSelector = (state: IState) => state.game

export default slice.reducer
