import axios from 'axios'
import { RoleData } from '../interfaces'

export const fetchRolesList = async (
  search = {
    name: '',
    description: '',
  }
) => {
  return axios.get('auth/role', {
    params: {
      expand: '',
      'f[name]': search.name,
      'f[description]': search.description,
    },
  })
}

export const addRole = async (data: RoleData) => {
  return axios.post('auth/role', data)
}

export const fetchRole = async (name: string) => {
  return axios.get(`auth/role/${name}`)
}

export const changeRole = async (data: RoleData, name: string) => {
  return axios.put(`auth/role/${name}`, data)
}

export const deleteRole = async (name: string) => {
  return axios.delete(`auth/role/${name}`)
}
