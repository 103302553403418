import React from 'react';
import { useLocation } from 'react-router-dom';
import CurrentJackpots from './current';
import CompleteJackpots from './complete';

interface AllProps {
    isModal?: boolean;
}

const AllJackpots: React.FC<AllProps> = React.memo(({ isModal }) => {
    const { pathname } = useLocation();
    const [hallId, setHallId] = React.useState<number>(0);
    if (pathname === "/jackpots") {
        return <CurrentJackpots isModal={isModal} hallId={hallId} setHallId={setHallId} />
    }
    return <CompleteJackpots isModal={isModal} hallId={hallId} setHallId={setHallId} />
});

export default AllJackpots;