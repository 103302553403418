import { createSlice } from '@reduxjs/toolkit'
import { fetchCityList, addCity } from '../services/City'
import { ICity, ErrorType, CityData } from '../interfaces'
import { AppThunk } from './store'

interface cityInitialState {
  items: ICity[]
  loading: boolean
  error: ErrorType[] | null
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: cityInitialState = {
  items: [],
  loading: false,
  error: null,
  form: {
    loading: false,
    error: null,
  },
}

export const slice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    cityListIsLoading: (state) => {
      state.loading = true
    },
    cityListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    cityListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    cityItemDelete: (state, { payload }) => {
      const index = state.items.findIndex((i) => i.id === payload)
      state.items = [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1, state.items.length),
      ]
    },
    cityItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getCityList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(cityListIsLoading())

    return fetchCityList()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(cityListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(cityListFailure([res.data.data]))
          } else {
            dispatch(cityListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(cityListFailure(err)))
  }
}

export const addCityItem = (data: CityData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return addCity(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(cityItemAdd(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  cityListIsLoading,
  cityListSuccess,
  cityListFailure,
  cityItemDelete,
  cityItemAdd,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  city: cityInitialState
}

export const citySelector = (state: IState) => state.city

export default slice.reducer
