import React from 'react'

import List from '@material-ui/core/List'
import {
  Box,
  Grid,
  makeStyles,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { Wrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  handlerAddImage,
  handlerChangePackage,
  handlerDeleteImage,
  packageSelector,
} from '../../../redux/packageReducer'
import Preloader from '../../Common/Preloader'
import { IPackage, PackageData } from '../../../interfaces'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import PackageForm from '../Form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  input: {
    display: 'none',
  },
  image: {
    maxWidth: '60vh',
  },
}))

interface PackageProps {
  item: IPackage | undefined
  hidePackage: () => void
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
}

const Package: React.FC<PackageProps> = ({ item, hidePackage, isChangeMode, setIsChangeMode }) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  const { imageUpload } = useSelector(packageSelector)
  const [img, setImg] = React.useState('')

  const handlerUploadImage = ({ target }: any) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(target.files[0])
    fileReader.onload = (e: any) => {
      setImg(e.target.result)
    }
  }

  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  const imageData = {
    files: [img],
  }
  const viewImage = item?.image ? (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <img className={classes.image} src={item?.image} alt={item?.image} />
      <Button
        disabled={imageUpload.loading}
        onClick={() => dispatch(handlerDeleteImage(item?.id, item?.images[0].id))}
        color="secondary"
      >
        {t('ru.common.delete_btn')}
      </Button>
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handlerUploadImage}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          {t('ru.common.add_btn')}
        </Button>
      </label>
    </Box>
  )

  const renderImage = () => {
    return !img ? (
      viewImage
    ) : (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <img className={classes.image} src={img} alt="img" />
        <Button
          disabled={imageUpload.loading}
          onClick={() => dispatch(handlerAddImage(imageData, item?.id, setImg))}
        >
          {t('ru.common.save_btn')}
        </Button>
      </Box>
    )
  }
  const [isChange, setIsChange] = React.useState(isChangeMode)
  // const { items: games } = useSelector(gameSelector)
  const rootRender =  (
    <>
      <Box className={classes.closeBtn}>
        {
          <Tooltip
            title={
              isChange
              ? String(t('ru.common.view_tooltip'))
              : String(t('ru.common.change_btn'))
            }
            placement="top"
          >
            <IconButton onClick={() => setIsChange(!isChange)}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: '#b6b6b6b6',
                  width: '30px',
                  minHeight: '30px',
                  borderRadius: '50%',
                  padding: '6px',
                }}
              >
                <img
                  style={{
                    width: '20px',
                    minHeight: 'auto',
                  }}
                  src={!isChange ? editPng : visionPng}
                  alt="icon"
                />
              </Box>
            </IconButton>
          </Tooltip>
        }
        <IconButton onClick={() => history.goBack()}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <AccountCircleIcon fontSize="large" />
        <h2>#{item?.id}</h2>
      </Box>
      <br />
      <Divider />
      {isChange ? (
        <PackageForm
          onSubmit={(data: PackageData, hideForm: () => void, packageId: number | undefined) =>
            dispatch(handlerChangePackage(data, hideForm, packageId))
          }
          hideForm={hidePackage}
          cancelBtnEvent={() => setIsChange(false)}
          cancelBtnText={t('ru.common.back_btn')}
          submitBtnText={t('ru.common.submit_btn')}
          id={item?.id}
          name={item?.name}
          games={item?.games}
        />
      ) : (
        <>
          <List>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box>
                    <b>Пакет:</b>
                    {''}
                    {item?.name}
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box>
                    <b>{t('ru.packages.fields.games')}:</b>
                    <Grid container>
                      {item?.games.map((game) => (
                        <Grid key={game.name} item xs={12} sm={6} md={4}>
                          <FormControlLabel
                            control={<Checkbox checked={true} color="default" name={game.name} />}
                            label={
                              <span style={{ fontSize: '70%', lineHeight: '1' }}>{game.name}</span>
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <span>
                    <b>{t('ru.packages.fields.image')}:</b>
                  </span>
                  <br />
                  {renderImage()}
                  {imageUpload.loading && <Preloader absolute />}
                </Grid>
              </Grid>
            </div>
          </List>
        </>
      )}
    </>
  )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Package
