import React from 'react'
import { useForm } from '../../../../hooks/useForm'

import IconButton from '@material-ui/core/IconButton'
import { SubForm } from '../styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Input, Button, InputLabel } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { addCurrencyItem, currencySelector, formIsError } from '../../../../redux/currencyReducer'
import Preloader from '../../../Common/Preloader'
import { trowErrorMessage, trowError } from '../../../../utils/errors'
import ErrorText from '../../../Common/ErrorText'

interface CurrencyFormProps {
  hideCurrency: () => void
}

const CurrencyForm: React.FC<CurrencyFormProps> = ({ hideCurrency }) => {
  const dispatch = useDispatch()

  const {
    form: { loading, error },
  } = useSelector(currencySelector)

  const { form, handleInputChange } = useForm({
    currency: '',
    code: '',
    alfa: '',
  })

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <SubForm>
      {loading ? (
        <Preloader absolute={false} />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h4>Добавить валюту</h4>
            <IconButton onClick={hideCurrency}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <Input
              type="text"
              value={form.currency}
              name="currency"
              onChange={handleInputChange}
              placeholder="Валюта"
              fullWidth
              error={!!error && !!trowError(error, 'id')}
            />
            <InputLabel style={{ margin: '1vh', color: '#f44336' }}>
              {!!error && trowErrorMessage(error, 'id')}
            </InputLabel>
            <Input
              type="text"
              value={form.code}
              name="code"
              onChange={handleInputChange}
              placeholder="Код"
              fullWidth
              error={!!error && !!trowError(error, 'code')}
            />
            <InputLabel style={{ margin: '1vh', color: '#f44336' }}>
              {!!error && trowErrorMessage(error, 'code')}
            </InputLabel>
            <Input
              type="text"
              value={form.alfa}
              name="alfa"
              onChange={handleInputChange}
              placeholder="Наименование"
              fullWidth
              error={!!error && !!trowError(error, 'name')}
            />
            <InputLabel style={{ margin: '1vh', color: '#f44336' }}>
              {!!error && trowErrorMessage(error, 'name')}
            </InputLabel>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                dispatch(
                  addCurrencyItem(
                    {
                      id: form.currency,
                      code: form.code,
                      name: form.alfa,
                    },
                    hideCurrency
                  )
                )
              }}
            >
              Добавить
              <AddCircleIcon />
            </Button>
          </Box>
        </>
      )}
      <ErrorText error={error} />
    </SubForm>
  )
}

export default CurrencyForm
