import React, { ReactElement } from 'react'
import { tableSelector } from '../../../redux/tableReducer'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from '../../../redux/userReducer'
import LockIcon from '@material-ui/icons/Lock'

import admin from '../../../assets/images/admin.png'
import owner from '../../../assets/images/hall-host.png'
import cashier from '../../../assets/images/cashier.png'
import agent from '../../../assets/images/agent.png'
import hallIcon from '../../../assets/images/hall.png'
import offlineIcon from '../../../assets/images/offline.png'
import onlineIcon from '../../../assets/images/online.png'
import onPlayIcon from '../../../assets/images/client_on-play.png'
import clientOnIcon from '../../../assets/images/client_on.png'

import { Box } from '@material-ui/core'
import { prettyNum } from '../../../utils/prettyNum'
import { StyledTreeItem, useStyles } from '../styles'
import { getTreeInfo } from '../../../redux/treeInfoReducer'
import { IUser, IHall, ITable, UserBalance } from '../../../interfaces'
import { authSelector } from '../../../redux/authReducer'
import { hallSelector } from '../../../redux/hallReducer'

interface ItemProps {
  icon: ReactElement
  user: IUser
  setSelectedItem: (item: any) => void
  selectedTreeItem?: (component: string, user_id: number | undefined, hall_id?: number) => void
}

const Item: React.FC<ItemProps> = ({ icon, user, setSelectedItem, selectedTreeItem }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    users: { data: users },
  } = useSelector(userSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const {
    tables: { data: tables },
  } = useSelector(tableSelector)
  const { data } = useSelector(authSelector)



  const handleClickOnUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    selectedTreeItem && selectedTreeItem('user', user.id)
    dispatch(getTreeInfo(user.id, 'user'))
    event.stopPropagation()
    event.preventDefault()
    setSelectedItem(user.login)
    // scrollToLocation(document.querySelector('#info'))
  }
  const getDefaultBalance = (balance: UserBalance) => {
    let maxBalance: number
    type BalanceListType = {
      currency: string
      balance: number
    }
    let balanceList: BalanceListType[] = []
    let currencyList: number[] = []
    for (let currency in balance) {
      balanceList = [...balanceList, { currency: currency, balance: balance[currency] }]
      currencyList = [...currencyList, balance[currency]]
    }
    if (balanceList) maxBalance = Math.max.apply(Math, [...currencyList])

    return balanceList.find((i) => i.balance === maxBalance)?.currency
  }

  const getStateIcon = (online: number, currentGame: any) => {
    if (online === 0 && !currentGame) {
      return offlineIcon
    } else if (online === 1 && !currentGame) {
      return onlineIcon
    } else if (online === 0 && currentGame) {
      return clientOnIcon
    } else if (online === 1 && currentGame) {
      return onPlayIcon
    }
  }

  const getUserIcon = (role: string) => {
    switch (role) {
      case 'administrator':
        return admin
      case 'cashier':
        return cashier
      case 'owner':
        return owner
      default:
        return agent
    }
  }

  const user_balance = data?.id === user.id ? data?.balance : user?.balance
  const currency = user_balance && getDefaultBalance(user_balance)
  if (!halls.find(h => h.owner_id === user.id)) {
    return (
      <StyledTreeItem
        key={user.login}
        nodeId={user.login}
        showline={data?.id === user.id ? ' ' : ''}
        label={
          <Box
            className={classes.treeItem}
            display="flex"
            alignItems="center"
            onClick={handleClickOnUser}
          >
            {icon}
            <span>{user.login}</span>
            {
              <span
                style={{
                  color: '#3f3f3f',
                  fontSize: '70%',
                }}
              >
                <b>
                  {!!user_balance && !!currency
                    ? `(${prettyNum(user_balance[currency])} ${currency})`
                    : ''}
                </b>
              </span>
            }
          </Box>
        }
      >
        {users.filter((i: IUser) => i.parent_id === user.id) &&
          users
            .filter((i: IUser) => i.parent_id === user.id)
            .map((user: IUser) => {
              let userRole = ''
              for(let role in user!.roles) {
                  userRole = role
              }
              return (
                <Item
                  key={user.id}
                  icon={<img src={getUserIcon(userRole)} alt="" style={{ width: '25px', height: '25px' }} />}
                  user={user}
                  setSelectedItem={setSelectedItem}
                  selectedTreeItem={selectedTreeItem}
                />
              )
            })}
      </StyledTreeItem>
    )
  } else {
    return (
      user && (
        <StyledTreeItem
          key={user.login}
          nodeId={user.login}
          showline={data?.id === user.id ? ' ' : ''}
          label={
            <Box
              className={classes.treeItem}
              display="flex"
              alignItems="center"
              onClick={handleClickOnUser}
            >
              {icon}
              <span>{user.login}</span>
              {
                <span
                  style={{
                    color: '#3f3f3f',
                    fontSize: '70%',
                  }}
                >
                  <b>
                    {!!user_balance && !!currency
                      ? `(${prettyNum(user_balance[currency])} ${currency})`
                      : ''}
                  </b>
                </span>
              }
            </Box>
          }
        >
          {users.filter((i: IUser) => i.parent_id === user.id) &&
            users
              .filter((i: IUser) => i.parent_id === user.id)
              .map((user: IUser) => {
                let userRole = ''
                for(let role in user!.roles) {
                    userRole = role
                }
                return (
                  <Item
                    key={user.id}
                    icon={
                      user.status ? (
                        <img src={getUserIcon(String(userRole))} alt="" style={{ width: '25px', height: '25px' }} />
                      ) : (
                        <LockIcon style={{ color: '#de7d55' }} />
                      )
                    }
                    user={user}
                    setSelectedItem={setSelectedItem}
                    selectedTreeItem={selectedTreeItem}
                  />
                )
              })}
          {halls &&
            halls.map((hall: IHall) => {
              if (tables && !tables.find((i: ITable) => i.hall_id === hall.id)) {
                return (
                  hall.owner_id === user.id && (
                    <StyledTreeItem
                      key={hall.id}
                      nodeId={hall.name + user.login}
                      label={
                        <Box
                          className={classes.treeItem}
                          display="flex"
                          alignItems="center"
                          onClick={(event) => {
                            selectedTreeItem && selectedTreeItem('hall', user.id, hall.id)
                            dispatch(getTreeInfo(hall.id, 'hall'))
                            event.stopPropagation()
                            event.preventDefault()
                            setSelectedItem(hall.name + user.login)
                            // scrollToLocation(document.querySelector('#info'))
                          }}
                        >
                          {hall.status ? (
                            <img src={hallIcon} alt="" style={{ width: '25px', height: '25px' }} />
                          ) : (
                            <LockIcon style={{ color: '#de7d55' }} />
                          )}
                          <span>{hall.name}</span>
                          <span style={{ color: '#3d3d3d', fontSize: '70%' }}>
                            <b>
                              (
                              {`${!!prettyNum(hall.balance) ? prettyNum(hall.balance) : '0.00'} ${
                                hall.currency_id
                              }`}
                              )
                            </b>
                          </span>
                        </Box>
                      }
                    />
                  )
                )
              }
              return (
                hall.owner_id === user.id && (
                  <StyledTreeItem
                    key={hall.id}
                    nodeId={hall.name + user.login}
                    selected
                    label={
                      <Box
                        className={classes.treeItem}
                        display="flex"
                        alignItems="center"
                        onClick={(event) => {
                          selectedTreeItem && selectedTreeItem('hall', user.id, hall.id)
                          dispatch(getTreeInfo(hall.id, 'hall'))
                          event.stopPropagation()
                          event.preventDefault()
                          setSelectedItem(hall.name + user.login)
                          // scrollToLocation(document.querySelector('#info'))
                        }}
                      >
                        {hall.status ? (
                          <img src={hallIcon} alt="" style={{ width: '25px', height: '23px' }} />
                        ) : (
                          <LockIcon style={{ color: '#de7d55' }} />
                        )}
                        <span>{hall.name}</span>
                        <span style={{ color: '#3d3d3d', fontSize: '70%' }}>
                          <b>
                            (
                            {`${!!prettyNum(hall.balance) ? prettyNum(hall.balance) : '0.00'} ${
                              hall.currency_id
                            }`}
                            )
                          </b>
                        </span>
                      </Box>
                    }
                  >
                    {tables &&
                      tables.map((table: ITable) => {
                        const tableCurrency: string | undefined = hall?.currency_id
                        const checkBalance = (balance: UserBalance) => {
                          let count = 0
                          for (let i in balance) {
                            if (balance[i] === 0) {
                              return false
                            } else {
                              count += 1
                              return count
                            }
                          }
                        }
                        return (
                          table.hall_id === hall.id && (
                            <StyledTreeItem
                              key={table.id}
                              nodeId={table.name + user.login}
                              label={
                                <Box className={classes.treeItem} display="flex">
                                  {table.status ? (
                                    <img
                                      style={{ width: '25px', height: '25px' }}
                                      src={getStateIcon(table.online, table.currentGame)}
                                      alt=""
                                    />
                                  ) : (
                                    <LockIcon style={{ color: '#de7d55' }} />
                                  )}
                                  <span>{table.name}</span>
                                  <span
                                    style={{
                                      color: '#3f3f3f',
                                      fontSize: '70%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <b>
                                      ({!!checkBalance(table.balance) && !!tableCurrency
                                        ? `${prettyNum(table.balance[tableCurrency])} ${
                                            hall.currency_id
                                          }`
                                        : `0.00 ${tableCurrency}`})
                                    </b>
                                  </span>
                                </Box>
                              }
                              onLabelClick={() => {
                                dispatch(getTreeInfo(table.id, 'table'))
                                setSelectedItem(table.name + user.login)
                                // scrollToLocation(document.querySelector('#info'))
                              }}
                            />
                          )
                        )
                      })}
                  </StyledTreeItem>
                )
              )
            })}
        </StyledTreeItem>
      )
    )
  }
}

export default Item
