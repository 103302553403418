import React, { ChangeEvent } from 'react'

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  NativeSelect,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { useSelector, useDispatch } from 'react-redux'

import { FormWrapper } from './styles'
import { gameSelector } from '../../../redux/gameReducer'
import { packageSelector } from '../../../redux/packageReducer'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import { defaultPackageSelector, formIsError } from '../../../redux/defaultPackageReducer'
import Preloader from '../../Common/Preloader'
import ErrorText from '../../Common/ErrorText'
import { IGame, DefaultPackageData, IPackage } from '../../../interfaces'
import { useTranslation } from 'react-i18next'

export const useStyles = makeStyles({
  submitBtn: {
    margin: '1vh',
  },
})

interface DefaultPackageFormProps {
  onSubmit: (data: DefaultPackageData, hideForm: () => void, id?: number | undefined) => void
  hideForm: () => void
  cancelBtnEvent: () => void
  cancelBtnText: string
  submitBtnText: string
  id?: number | undefined
  package_id?: number | undefined
  games?: IGame[] | undefined
}

const DefaultPackageForm: React.FC<DefaultPackageFormProps> = ({
  onSubmit,
  hideForm,
  cancelBtnEvent,
  cancelBtnText,
  submitBtnText,
  id,
  package_id = -1,
  games = [],
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading: formLoading },
  } = useSelector(defaultPackageSelector)
  const {
    games: { items: gamesList },
  } = useSelector(gameSelector)
  const {
    packages: { items: PackageList },
  } = useSelector(packageSelector)

  const getGamesId = (arr: IGame[]) => {
    let newArr: number[] = []
    arr.forEach((item: IGame) => {
      newArr = [...newArr, item.id]
    })
    return newArr
  }

  const [packageId, setPackageId] = React.useState<number>(
    !!package_id ? package_id : PackageList[0].id
  )
  const [gamesSelected, setGamesSelected] = React.useState<number[]>(getGamesId(games))

  const data = {
    package_id: packageId,
    games: gamesSelected,
  }

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      cancelBtnEvent()
    }
    if (e.code === 'Enter') {
      onSubmit(data, hideForm, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])
  // console.log(gamesList.filter(g => g.manufacturer_id === packageId))
  return formLoading ? (
    <Preloader absolute={false} />
  ) : (
    <FormWrapper
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(data, hideForm, id)
      }}
    >
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl error={!!error && !!trowError(error, 'package_id')} fullWidth size="small">
            <FormHelperText>{t('ru.default_packages.form_helpers.package')}</FormHelperText>

            <NativeSelect
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setPackageId(Number(e.target.value))
              }}
              value={packageId}
            >
              <option aria-label="None" value="" />
              {PackageList &&
                PackageList.map((item: IPackage) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  )
                })}
            </NativeSelect>
            <FormHelperText>{!!error && trowErrorMessage(error, 'package_id')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth size="small">
            <FormHelperText>{t('ru.default_packages.form_helpers.games')}</FormHelperText>
            <Grid container>

            {gamesList &&
              gamesList.filter(g => g.manufacturer_id === packageId).map((game) => (
                <Grid key={game.name} item xs={12} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!gamesSelected.find((i: number) => i === game.id)}
                        color="default"
                        onChange={() => {
                          if (gamesSelected.includes(game.id)) {
                            let index = gamesSelected.indexOf(game.id)
                            setGamesSelected([
                              ...gamesSelected.slice(0, index),
                              ...gamesSelected.slice(index + 1, gamesSelected.length),
                            ])
                          } else {
                            setGamesSelected([...gamesSelected, game.id])
                          }
                        }}
                        name={game.name}
                      />
                    }
                    label={
                      <span style={{ fontSize: '70%', lineHeight: '1' }}>
                        {gamesList.find((i: IGame) => i.name === game.name)?.name}
                      </span>
                    }
                  />
                </Grid>
              ))}
              </Grid>
              {!gamesList.filter(g => g.manufacturer_id === packageId).length && <span>Нет данных</span>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              className={classes.submitBtn}
              variant="contained"
              onClick={cancelBtnEvent}
            >
              {cancelBtnText}
            </Button>
            <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>
    </FormWrapper>
  )
}

export default DefaultPackageForm
