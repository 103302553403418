import React from 'react';
import { IGame, ISpin } from '../../../interfaces';
import Preloader from '../../Common/Preloader';
import PokerCombination from './PokerCombination';
import Reels from './Reels';

type CombinationProps = {
  spin: ISpin | null;
  game: IGame | null;
  parseWins: any;
  sessionId: string | null;
};

const Combination: React.FC<CombinationProps> = React.memo(({ spin, game, parseWins, sessionId }) => {
  const [linesPosition, setLinesPosition] = React.useState<any>(null);
  React.useEffect(() => { game && setLinesPosition(game?.lines) }, [game, sessionId]);
  if (spin && spin?.reels) {
    if (!linesPosition) {
      return <Preloader />;
    }
    return <Reels spin={spin} game={game} linesPosition={linesPosition} parseWins={parseWins} />;
  }
  const variables = JSON.parse(spin?.variables ?? '{}');
  if("cards" in variables) {
    return <PokerCombination variables={variables} />;
  }
  return null;
});

export default Combination;
