import { Box, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { IHallMath } from '../../../../interfaces';
import { prettyNum } from '../../../../utils/prettyNum';

type MathTabProps = {
    hallMath?: IHallMath;
};

function getAdaptiveType(type: number): String {
    return ['нет', 'обычный', 'с задержкой'][type] || type.toString();
}

function getAdaptiveParam(type: number): String {
    return ['зал', 'стол', 'игры зала', 'игры стола'][type] || type.toString();
}

function getReelsPath(type: String): String {
    if(type==='reels2') {
        return 'Сафрошкин';
    }
    return 'Стандартный';
}

const MathTab: FunctionComponent<MathTabProps> = ({ hallMath }) => {
    if (!hallMath) {
        return <></>;
    }
    return <>
        <Grid item xs={12} md={6}>
            <Box>
                <b>Пэйаут:</b> <span>{hallMath.payout}%</span>
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <Box>
                <b>Тип адаптива:</b> <span>{getAdaptiveType(hallMath.adaptive_type)}</span>
            </Box>
        </Grid>
        {hallMath.adaptive_type === 0 ? null : <>
            <Grid item xs={12} md={6}>
                <Box>
                    <b>Параметр адаптива:</b> <span>{getAdaptiveParam(hallMath.adaptive_param)}</span>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box>
                    <b>Максимальный RTP:</b> {prettyNum(100 * hallMath.max_rtp)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box>
                    <b>Минимальный RTP:</b> {prettyNum(100 * hallMath.min_rtp)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Box>
                    <b>Овердрафт:</b> {hallMath.overdraft}
                </Box>
            </Grid>
        </>}
        <Grid item xs={12} sm={12}>
            <Box>
                <b>Набор лент:</b> {getReelsPath(hallMath.reels_path)}
            </Box>
        </Grid>
    </>;
};

export default MathTab;