import axios from 'axios'
import { ManufacturerData } from '../interfaces'

export const fetchManufacturerList = async () => {
    return axios.get(`manufacturer`)
}

export const addManufacturer = async (data: ManufacturerData) => {
    return axios.post(`manufacturer`, data)
}

export const fetchManufacturer = async (id: number) => {
    return axios.get(`manufacturer/${id}`)
}

export const changeManufacturer = async (data: ManufacturerData, id: number) => {
    return axios.put(`manufacturer/${id}`, data)
}

export const deleteManufacturer = async (id: number) => {
    return axios.delete(`manufacturer/${id}`)
}
