import React, { FunctionComponent } from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import trashPng from '../../../../assets/images/trash.png';
import { isConfirmType } from '../../../../interfaces'

type DeleteMenuItemProps = {
    id: number,
    name: string,
    setIsConfirm: (isConfirm: isConfirmType) => void
}

function cmp(prevProps: DeleteMenuItemProps, nextProps: DeleteMenuItemProps) {
    return prevProps.id === nextProps.id;
}

const DeleteMenuItem: FunctionComponent<DeleteMenuItemProps> = React.memo(({ id, name, setIsConfirm }) => {
    return <MenuItem onClick={() => { setIsConfirm({ id: id, name: name, open: true }) }} >
        <Action bg={'#919191'}>
            <IconButton>
                <img src={trashPng} alt="icon" />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default DeleteMenuItem;