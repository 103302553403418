import React from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import Preloader from '../../Common/Preloader'

import { useDispatch, useSelector } from 'react-redux'
import { Box, ListItem } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import {
  getHallGameReportsList,
  getTableGameReportsList,
  reportSelector,
  reportsGamesListSuccess,
} from '../../../redux/reportReducer'
import { gameSelector, getAllGames } from '../../../redux/gameReducer'
import { useTranslation } from 'react-i18next'

interface ReportsListProps {
  setTab: (tab: number) => void
  from: string
  to: string
  hall_id?: number | number[] | undefined
  table_id?: number | undefined
}

const GamesReportsList: React.FC<ReportsListProps> = ({ setTab, from, to, hall_id, table_id }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const {
    reports: {
      games: { data: gameReports, loading },
    },
  } = useSelector(reportSelector)
  const {
    games: { items: games },
  } = useSelector(gameSelector)

  const [selectedSort, setSelectedSort] = React.useState('game_id')
  const [sortDir, setSortDir] = React.useState<boolean>(true)

  React.useEffect(() => {
    !games.length && dispatch(getAllGames(1, 49))
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    table_id &&
      dispatch(
        getTableGameReportsList(
          Number(table_id),
          from,
          to
        )
      )
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    hall_id &&
      dispatch(
        getHallGameReportsList([hall_id], from, to)
      )
    // eslint-disable-next-line
  }, [])

  const sortedList = (sort: string, sortDir: boolean, list: any[]) => {
    const sortedArr = [...list]

    return sortedArr.sort((a: any, b: any) => {
      if (sortDir ? Number(a[sort]) > Number(b[sort]) : Number(a[sort]) < Number(b[sort])) {
        return 1
      }
      if (sortDir ? Number(a[sort]) < Number(b[sort]) : Number(a[sort]) > Number(b[sort])) {
        return -1
      }
      return 0
    })
  }

  const sortButton = (name: string, label: string) => {
    return (
      <ListItem
        component="span"
        selected={name === selectedSort || `-${name}` === selectedSort}
        style={{ cursor: 'pointer', justifyContent: 'center' }}
        onClick={() => {
          if (selectedSort === name) {
            setSortDir(!sortDir)
          } else {
            setSelectedSort(name)
          }
        }}
      >
        {label}
        {selectedSort === `${name}` && (sortDir ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItem>
    )
  }

  React.useEffect(() => {
    return () => {
      setTab(0)
      dispatch(reportsGamesListSuccess([]))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper>
      <div>
        <TableHeader>
          {sortButton('game_id', t('ru.reports.fields.game'))}
          {sortButton('bet', t('ru.reports.fields.bet'))}
          {sortButton('win', t('ru.reports.fields.win'))}
          {sortButton('count', t('Кол-во спинов'))}
          {sortButton('ggr', t('GGR'))}
          {sortButton('payout', t('Payout'))}
        </TableHeader>
        <ul>
          {loading ? (
            <Preloader absolute={false} />
          ) : (
            games &&
            gameReports &&
            gameReports &&
            sortedList(selectedSort, sortDir, gameReports).map((game: any) => {
              return (
                <Item
                  key={game.game_id}
                  id={game.game_id}
                  name={games.find((g) => g.id === Number(game.game_id))?.name}
                  bet={game.bet}
                  win={game.win}
                  count={game.count}
                  payout={(((game.bet - game.win) / game.bet) * 100).toFixed(2)}
                  total={false}
                  // setSelectedgame={setSelectedgame}
                />
              )
            })
          )}
          {gameReports && gameReports.result && !gameReports.result.length && (
            <Box
              style={{ width: '100%' }}
              display="flex"
              justifyContent="center"
              alignContent="flex"
            >
              {t('ru.common.no_data')}
            </Box>
          )}
        </ul>
      </div>
    </Wrapper>
  )
}

export default GamesReportsList
