import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, TextField } from '@material-ui/core';

type FieldMinCashbackDepositProps = {
    error: boolean;
    className: string;
    onChange: (object: any) => void;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldMinCashbackDepositProps, cur: FieldMinCashbackDepositProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value && prev.className === cur.className;
}

const FieldMinCashbackDeposit: FunctionComponent<FieldMinCashbackDepositProps> = React.memo(({ error, className, onChange, value, formHelperText }) => {
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <TextField className={className} type="search" name="min_cashback_deposit" value={value} onChange={onChange} size="small" />
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldMinCashbackDeposit;