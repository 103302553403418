import axios from 'axios'
import { AssigmentsData } from '../interfaces'

export const fetchUserAssignments = async (id: number) => {
    return axios.get(`auth/assignment/${id}`)
}

export const addAssignments = async (data: AssigmentsData, id: number) => {
    return axios.post(`auth/assignment/${id}/assign`, data)
}

export const revokeAssignments = async (data: AssigmentsData, id: number) => {
    return axios.post(`auth/assignment/${id}/revoke`, data)
}
