import React from 'react'
import { FormHelperText, Box } from '@material-ui/core'
interface ErrorTextProps {
  error: any
}
const ErrorText: React.FC<ErrorTextProps> = ({ error }) => {
  return (
    <Box display="flex" justifyContent="center">
      {error && <FormHelperText style={{color: '#f44336'}}>{error.message}</FormHelperText>}
    </Box>
  )
}

export default ErrorText