import React, { FunctionComponent } from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import { Action } from '../../styles';
import trashPng from '../../../../../assets/images/trash.png';

type isConfirmType = {
    id: number | undefined;
    name: string;
    open: boolean;
};

type DeleteButtonProps = {
    id: number;
    setIsConfirm: (item: isConfirmType) => void;
    login: string;
};

function cmp(prev: DeleteButtonProps, next: DeleteButtonProps): boolean {
    return prev.id === next.id;
}

const DeleteButton: FunctionComponent<DeleteButtonProps> = React.memo(({ id, setIsConfirm, login }) => {
    return <MenuItem onClick={() => { setIsConfirm({ id: id, name: login, open: true }) }} >
        <Action bg={'#919191'}>
            <IconButton>
                <img src={trashPng} alt="icon" />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default DeleteButton;