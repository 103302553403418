import React from 'react'

import { useForm } from '../../../hooks/useForm'

import {
  Box,
  FormControl,
  NativeSelect,
  FormHelperText,
  Grid,
  Select,
  MenuItem,
  Input,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { useSelector, useDispatch } from 'react-redux'

import { FormWrapper, useStyles } from './styles'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import { formIsError, jackpotSelector } from '../../../redux/jackpotReducer'
import Preloader from '../../Common/Preloader'
import { IHall, ITable, IUser, JackpotData } from '../../../interfaces'
import ErrorText from '../../Common/ErrorText'
import { enhanceDateItem } from '../../../utils/date'
import { hallSelector } from '../../../redux/hallReducer'
import { tableSelector } from '../../../redux/tableReducer'
import { userSelector } from '../../../redux/userReducer'
import { authSelector } from '../../../redux/authReducer'
import { useTranslation } from 'react-i18next'

if (global.window) {
  require('react-datepicker/dist/react-datepicker.css')
}

interface JackpotFormProps {
  onSubmit: (data: JackpotData, hideForm: () => void, id?: number | undefined) => void
  cancelBtnEvent: () => void
  cancelBtnText: string
  submitBtnText: string
  hall_id?: null | number
  table_id?: null | number
  id?: number
  type?: number
  min_bet?: number
  min_bet_to_win?: number
  restart?: number
  min_players?: number
  balance?: number
  start_balance?: number
  initial_balance?: number
  end_balance?: number
  percent?: number
  status?: number
  date_end_draw?: string
  date_for_drawing?: string
  jackpotHalls?: number[]
  jackpotTables?: number[]
  parent_id?: number
  hideForm: () => void
  showHall?: boolean;
}

const JackpotForm: React.FC<JackpotFormProps> = ({
  onSubmit,
  cancelBtnEvent,
  cancelBtnText,
  submitBtnText,
  hall_id,
  table_id,
  id,
  type,
  min_bet = 1,
  min_bet_to_win = 1,
  restart = 0,
  min_players = 1,
  start_balance = 0,
  initial_balance = 0,
  end_balance = 100,
  percent = 100,
  status,
  date_end_draw,
  date_for_drawing,
  jackpotHalls,
  jackpotTables,
  parent_id,
  hideForm,
  showHall = true
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading: formLoading },
  } = useSelector(jackpotSelector)
  const { halls } = useSelector(hallSelector)
  const { tables } = useSelector(tableSelector)
  const {
    users: { data: users },
  } = useSelector(userSelector)
  const { data: authInfo } = useSelector(authSelector)

  const transformDate = (date: any) => {
    return `${date.getFullYear()}-${enhanceDateItem(date.getMonth() + 1)}-${enhanceDateItem(
      date.getDate()
    )}T${enhanceDateItem(date.getHours())}:${enhanceDateItem(date.getMinutes())}:${enhanceDateItem(
      date.getSeconds()
    )}`
  }
  const date = new Date()
  const { form, clearForm, setFormItem } = useForm({
    parent_id: parent_id,
    min_bet: min_bet ? min_bet / 100 : '0.00',
    min_bet_to_win: min_bet_to_win ? min_bet_to_win / 100 : '0.00',
    restart: restart,
    min_players: min_players,
    start_balance: start_balance ? start_balance / 100 : '0.00',
    end_balance: end_balance ? end_balance / 100 : '0.00',
    initial_balance: initial_balance ? initial_balance / 100 : '0.00',
    percent: percent / 1000,
    date_end_draw: date_end_draw ? date_end_draw : transformDate(date),
    date_for_drawing: date_for_drawing
      ? date_for_drawing
      : transformDate(new Date(date.getTime() + 60000)),
  })

  const [statusSelected, setStatusSelected] = React.useState<number>(0)
  const [typeSelected, setTypeSelected] = React.useState<number>(0)

  const [selectedTables, setSelectedTables] = React.useState<number[]>([])
  const [selectedHalls, setSelectedHalls] = React.useState<number[]>([])

  React.useEffect(() => {
    status && setStatusSelected(status)
    type && setTypeSelected(type)
  }, [status, type])

  React.useEffect(() => {
    jackpotHalls && hall_id && setSelectedHalls(jackpotHalls)
    jackpotTables && table_id && setSelectedTables(jackpotTables)
    hall_id && setSelectedHalls([...selectedHalls, hall_id])
    table_id && setSelectedTables([...selectedHalls, table_id])
    jackpotHalls && setSelectedHalls(jackpotHalls)
    jackpotTables && setSelectedTables(jackpotTables)
    // eslint-disable-next-line
  }, [jackpotHalls, jackpotTables, hall_id])

  React.useEffect(() => {
    const newDate = new Date(Date.parse(form.date_end_draw) - 600000)
    if (Date.parse(form.date_end_draw) < Date.parse(form.date_for_drawing)) {
      setFormItem('date_for_drawing', transformDate(newDate))
    }
    // eslint-disable-next-line
  }, [form.date_end_draw])
  React.useEffect(() => {
    const newDate = new Date(Date.parse(form.date_for_drawing) + 600000)
    if (Date.parse(form.date_for_drawing) > Date.parse(form.date_end_draw)) {
      setFormItem('date_end_draw', transformDate(newDate))
    }
    // eslint-disable-next-line
  }, [form.date_for_drawing])

  const data: JackpotData = {
    type: typeSelected,
    min_bet: form.min_bet * 100,
    min_bet_to_win: form.min_bet_to_win * 100,
    restart: +!!form.restart,
    min_players: form.min_players,
    start_balance: form.start_balance * 100,
    end_balance: form.end_balance * 100,
    initial_balance: form.initial_balance * 100,
    percent: form.percent * 1000,
    status: statusSelected,
    date_end_draw: '',
    date_for_drawing: '',
    halls: selectedHalls,
    gambling_tables: selectedTables,
    parent_id: form.parent_id
  }

  React.useEffect(() => {
    return () => clearForm()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    parent_id && setFormItem('parent_id', parent_id)
    authInfo && !parent_id && setFormItem('parent_id', authInfo.id)
    // eslint-disable-next-line
  }, [parent_id])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      hideForm()
    }
    if (e.code === 'Enter') {
      onSubmit(data, hideForm, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  return formLoading ? (
    <Preloader absolute={false} />
  ) : (
    <FormWrapper>
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <FormHelperText>{t('ru.jackpots.form_helpers.type')}</FormHelperText>

            <NativeSelect
              onChange={(e: any) => {
                setTypeSelected(e.target.value)
              }}
              value={typeSelected}
            >
              <option value={0}>Бронза</option>
              <option value={1}>Серебро</option>
              <option value={2}>Золото</option>
              <option value={3}>Бриллиант</option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <FormHelperText>{t('ru.jackpots.form_helpers.status')}</FormHelperText>

            <NativeSelect
              onChange={(e: any) => {
                setStatusSelected(e.target.value)
              }}
              value={statusSelected}
            >
              <option value={0}>{t('ru.jackpots.selectors.active')}</option>
              <option value={1}>{t('ru.jackpots.selectors.accumulation')}</option>
              <option value={2}>{t('ru.jackpots.selectors.inactive')}</option>
              <option value={3}>{t('ru.jackpots.selectors.disabled')}</option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <FormHelperText>{t('ru.jackpots.fields.restart')}</FormHelperText>

            <NativeSelect
              onChange={(e: any) => {
                setFormItem('restart', +e.target.value)
              }}
              value={form.restart}
            >
              <option value={1}>{t('ru.jackpots.selectors.on')}</option>
              <option value={0}>{t('ru.jackpots.selectors.off')}</option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <FormHelperText>{t('ru.jackpots.fields.parent')}</FormHelperText>
            <NativeSelect
              onChange={(e) => setFormItem('parent_id', Number(e.target.value))}
              value={form.parent_id ?? ''}
            >
              <option aria-label="None" value="" />
              {users &&
                users.map((item: IUser) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.login}
                    </option>
                  )
                })}
            </NativeSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormHelperText>{t('ru.jackpots.fields.initial_balance')}</FormHelperText>
          <TextField
            className={classes.textFields}
            variant="outlined"
            value={form.start_balance}
            onInput={(e: any) => {
              // eslint-disable-next-line
              setFormItem('start_balance', e.target.value.replace(/[^0-9\.]/g, ''))
            }}
            size="small"
            error={
              !!trowError(error, 'start_balance') ||
              Number(form.start_balance) * 100 > Number(form.end_balance) * 100
            }
            helperText={
              Number(form.start_balance) * 100 > Number(form.end_balance) * 100
                ? 'Баланс начала должен быть меньше окончания'
                : trowErrorMessage(error, 'start_balance')
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormHelperText>{t('ru.jackpots.fields.end_balance')}</FormHelperText>
          <TextField
            className={classes.textFields}
            variant="outlined"
            value={form.end_balance}
            onInput={(e: any) => {
              // eslint-disable-next-line
              setFormItem('end_balance', e.target.value.replace(/[^0-9\.]/g, ''))
            }}
            size="small"
            error={
              !!trowError(error, 'end_balance') ||
              Number(form.start_balance) * 100 > Number(form.end_balance) * 100
            }
            helperText={
              Number(form.start_balance) * 100 > Number(form.end_balance) * 100
                ? 'Баланс начала должен быть меньше окончания'
                : trowErrorMessage(error, 'end_balance')
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormHelperText>{t('ru.jackpots.fields.start_balance')}</FormHelperText>
          <TextField
            className={classes.textFields}
            variant="outlined"
            value={form.initial_balance}
            onInput={(e: any) => {
              // eslint-disable-next-line
              setFormItem('initial_balance', e.target.value.replace(/[^0-9\.]/g, ''))
            }}
            size="small"
            error={!!trowError(error, 'initial_balance')}
            helperText={trowErrorMessage(error, 'initial_balance')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormHelperText>{t('ru.jackpots.fields.min_bet')}</FormHelperText>
          <TextField className={classes.textFields} variant="outlined" value={form.min_bet} name="min_bet" onChange={(e) => setFormItem('min_bet', e.target.value.replace(/\D\./, ''))} size="small" error={!!trowError(error, 'min_bet')}helperText={trowErrorMessage(error, 'min_bet')}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormHelperText>{t('ru.jackpots.fields.min_bet_to_win')}</FormHelperText>
          <TextField className={classes.textFields} variant="outlined" value={form.min_bet_to_win} name="min_bet_to_win" onChange={(e) => setFormItem('min_bet_to_win', e.target.value.replace(/\D\./, ''))} size="small" error={!!trowError(error, 'min_bet_to_win')}helperText={trowErrorMessage(error, 'min_bet_to_win')}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormHelperText>{t('ru.jackpots.fields.min_players')}</FormHelperText>
          <TextField
            className={classes.textFields}
            variant="outlined"
            value={form.min_players}
            name="min_players"
            onChange={(e) => {
              setFormItem('min_players', e.target.value.replace(/\D/, ''))
            }}
            size="small"
            error={!!trowError(error, 'min_players')}
            helperText={trowErrorMessage(error, 'min_players')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormHelperText>{t('ru.jackpots.fields.percent')}</FormHelperText>
          <TextField
            className={classes.textFields}
            variant="outlined"
            value={form.percent}
            name="percent"
            onChange={(e) => {
              // eslint-disable-next-line
              setFormItem('percent', e.target.value.replace(/[^0-9\.]/g, ''))
            }}
            size="small"
            error={!!trowError(error, 'percent')}
            helperText={trowErrorMessage(error, 'percent')}
          />
        </Grid>
        {(!hall_id || showHall) && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <FormHelperText>{t('ru.jackpots.form_helpers.hall')}</FormHelperText>

                <Select
                  disabled={!!selectedTables.length && !!hall_id}
                  className={classes.textFields}
                  multiple
                  value={selectedHalls ? selectedHalls : ''}
                  input={<Input />}
                  error={!!trowError(error, 'halls')}
                >
                  {halls.data.map((item: IHall) => {
                    return (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        onClick={() => {
                          if (selectedHalls.includes(item.id)) {
                            let index = selectedHalls.indexOf(item.id)
                            hall_id !== item.id &&
                              setSelectedHalls([
                                ...selectedHalls.slice(0, index),
                                ...selectedHalls.slice(index + 1, selectedHalls.length),
                              ])
                          } else {
                            setSelectedHalls([...selectedHalls, item.id])
                          }
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {trowErrorMessage(error, 'halls') ? (
                  <FormHelperText style={{ color: '#ed4534' }}>
                    {trowErrorMessage(error, 'halls')}
                  </FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <FormHelperText>{t('ru.jackpots.form_helpers.table')}</FormHelperText>

                <Select
                  disabled={!!selectedHalls.length && !!table_id}
                  className={classes.textFields}
                  multiple
                  value={selectedTables}
                  input={<Input />}
                >
                  {tables.data.map((item: ITable) => {
                    return (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        onClick={() => {
                          if (selectedTables.includes(item.id)) {
                            let index = selectedTables.indexOf(item.id)
                            table_id !== item.id &&
                              setSelectedTables([
                                ...selectedTables.slice(0, index),
                                ...selectedTables.slice(index + 1, selectedTables.length),
                              ])
                          } else {
                            setSelectedTables([...selectedTables, item.id])
                          }
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
                {trowErrorMessage(error, 'gambling_tables') ? (
                  <FormHelperText style={{ color: '#ed4534' }}>
                    {trowErrorMessage(error, 'gambling_tables')}
                  </FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              className={classes.submitBtn}
              variant="contained"
              onClick={cancelBtnEvent}
            >
              {cancelBtnText}
            </Button>
            <Button
              fullWidth
              className={classes.submitBtn}
              disabled={Number(form.start_balance) * 100 > Number(form.end_balance) * 100}
              variant="contained"
              onClick={(e: any) => {
                e.preventDefault()
                onSubmit(data, hideForm, id)
              }}
            >
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
          {hall_id &&
            (trowErrorMessage(error, 'halls') ? (
              <FormHelperText style={{ color: '#ed4534', textAlign: 'center' }}>
                {trowErrorMessage(error, 'halls')}
              </FormHelperText>
            ) : (
              ''
            ))}

          {hall_id &&
            (trowErrorMessage(error, 'gambling_tables') ? (
              <FormHelperText style={{ color: '#ed4534' }}>
                {trowErrorMessage(error, 'gambling_tables')}
              </FormHelperText>
            ) : (
              ''
            ))}
        </Grid>
      </Grid>
    </FormWrapper>
  )
}

export default JackpotForm
