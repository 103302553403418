import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector } from '../../redux/authReducer'
import {
  roleSelector,
  getRolesList,
} from '../../redux/roleReducer'
import RolesList from './List'
import { permissionSelector, getAllPermissions } from '../../redux/permissionReducer'

const Roles: React.FC = () => {
  const dispatch = useDispatch()

  const {
    roles: { items: roles },
  } = useSelector(roleSelector)
  const { items: permissionsList } = useSelector(permissionSelector)

  const { status } = useSelector(authSelector)

  React.useEffect(() => {
    if (status === 200) {
      !roles.length && dispatch(getRolesList())
    }
  }, [status, roles, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !permissionsList.length && dispatch(getAllPermissions(1, 50))
    }
    // eslint-disable-next-line
  }, [dispatch, status, permissionsList])



  return (
    <>
      <RolesList />
    </>
  )
}

export default Roles
