import React, { FunctionComponent, ChangeEvent } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../styles';
import { trowError, trowErrorMessage } from '../../../../../utils/errors';
import FieldName from './field-name';
import FieldStatus from './field-status';
import FieldAddress from './field-address';
import FieldOwner from './field-owner';
import FieldCity from './field-city';
import FieldLimit from './field-limit';
import FieldCurrency from './field-currency';
import FieldCompany from './field-company';
import FieldCashback from './field-cashback';
import FieldIsSocialPoll from './field-is-social-poll';
import FieldShowLogo from './field-show-logo';
import FieldMinCashbackDeposit from './field-min-cashback-deposit';
import FieldMathType from './field-math-type';
import FieldHallTemplate from './field-hall-template';
import FieldIsSchool from './field-is-school';
import FieldTickerText from './field-ticker-text';
import FieldAddDenomination from './field-add-denomination';
import FieldDenominationCheckboxes from './field-denomination-checkboxes';
import { ErrorType } from '../../../../../interfaces';
import FieldKeyboardType from './field-keyboard-type';
import FieldShowCurrency from './field-show-currency';
import FieldLanguage from './field-language';
import FieldDenomination from './field-denomination';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../../redux/authReducer';
import FieldJackpotMigration from './field-jackpot-migration';
import FieldReelAnimation from './field-reel-animation';
import { findPermissionOr } from '../../../../../utils/find';
import FieldPollInc from './field-poll-inc';
import FieldOferta from './field-oferta';
import FieldCashbackReel from './field-cashback-reel';

type InfoProps = {
    form: any;
    handleInputChange: (object: any) => void;
    error: ErrorType[];
    change: boolean;
    setFormItem: (name: string, value: any) => void;
    user_id: number;
    ownerId: number;
    currency: string;
    setCurrency: (x: string) => void;
    companyId: number;
    setCompany: (x: number) => void;
    showCity: () => void;
    showCompany: () => void;
    showCurrency: () => void;
    outPercent: number;
    setOutPercent: (x: number) => void;
    pollInc: number;
    setPollInc: (x: number) => void;
    selectedCashback: number;
    setSelectedCashback: (x: number) => void;
    cityId: number;
    setCity: (x: number) => void;
    limitId: number;
    setLimit: (x: number | null) => void;
    setUser: (x: number) => void;
    denomination: number;
    denominations: React.ReactText[];
    setDenominationsSelected: (x: React.ReactText[]) => void;
    setDefaultDenomination: (x: number) => void
    setOferta: (x: number) => void;
    oferta: number
};

const Info: FunctionComponent<InfoProps> = React.memo(({ form, handleInputChange, error, change, setFormItem, cityId, setCity, limitId, setLimit, user_id, ownerId, currency, setCurrency, denomination, setDefaultDenomination,
    companyId, showCity, showCompany, showCurrency, pollInc, setPollInc, selectedCashback, setSelectedCashback, setCompany, setUser, denominations, setDenominationsSelected, oferta, setOferta }) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const { role: { item: userRole }, assigments: { items } } = useSelector(authSelector);
    const denomenationList = [1, 10, 20, 50, 100, 1000, 10000];
    const denominationsSelected = denominations.map(e => +e);
    denominationsSelected.forEach(e => {
        e = +e;
        if (denomenationList.indexOf(e) === -1) {
            denomenationList.push(e);
        }
    });
    denomenationList.sort((a: number, b: number) => a - b);
    const isAdmin = userRole && userRole === 'administrator';
    return <>
        <FieldName className={classes.textFields} label={t('ru.halls.fields.name')} value={form.name} onChange={handleInputChange} error={!!trowError(error, 'name')} helperText={trowErrorMessage(error, 'name')} />
        <FieldStatus className={classes.textFields} onClick={() => setFormItem('status', !form.status)} checked={!!form.status} />
        <FieldOwner error={!!trowError(error, 'owner_id')} formHelperText={t('ru.halls.fields.user')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setUser(+e.target.value)} userId={ownerId} disabled={!!user_id} formHelperText2={trowErrorMessage(error, 'owner_id')} />
        <FieldAddress className={classes.textFields} label={t('ru.halls.fields.adress')} value={form.address} onChange={handleInputChange} />
        <FieldCity sm={change ? 6 : 4} error={!!trowError(error, 'city_id')} title={t('ru.common.add_btn')} className={classes.buttons} onClick={showCity}
            buttonText={t('ru.halls.fields.city')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setCity(+e.target.value)} cityId={cityId}
            formHelperText={trowErrorMessage(error, 'city_id', t('ru.halls.form_helpers.city'))} />
        {!change && <FieldCurrency error={!!trowError(error, 'currency_id')} title={t('ru.common.add_btn')} className={classes.buttons} onClick={showCurrency}
            buttonText={t('ru.halls.fields.currency')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setCurrency(e.target.value)} currencyId={currency}
            formHelperText={trowErrorMessage(error, 'company_id', t('ru.halls.form_helpers.currency'))} />}
        <FieldCompany sm={change ? 6 : 4} error={!!trowError(error, 'company_id')} title={t('ru.common.add_btn')} className={classes.buttons} onClick={showCompany}
            buttonText={t('ru.halls.fields.company')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setCompany(+e.target.value)} companyId={companyId}
            formHelperText={trowErrorMessage(error, 'company_id', t('ru.halls.form_helpers.company'))} />
        <FieldCashback error={!!trowError(error, 'selectedCashback')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedCashback(Number(e.target.value))} value={selectedCashback || 0}
            formHelperText={trowErrorMessage(error, 'selectedCashback', t('ru.halls.fields.cashback'))} />
        {isAdmin && <FieldPollInc error={!!trowError(error, 'poll_inc')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setPollInc(Number(e.target.value))} value={pollInc}
            formHelperText={trowErrorMessage(error, 'poll_inc', t('ru.halls.fields.poll_inc'))} />}
        <FieldIsSocialPoll error={!!trowError(error, 'is_social_poll')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('is_social_poll', e.target.value)} value={form.is_social_poll}
            formHelperText={trowErrorMessage(error, 'is_social_poll', t('ru.halls.fields.is_social_poll'))} />
        <FieldShowLogo error={!!trowError(error, 'show_logo')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('show_logo', e.target.value)} value={form.show_logo}
            formHelperText={trowErrorMessage(error, 'show_logo', t('ru.halls.fields.logo'))} />
        <FieldMinCashbackDeposit error={!!trowError(error, 'min_cashback_deposit')} onChange={handleInputChange} value={form.min_cashback_deposit}
            formHelperText={trowErrorMessage(error, 'min_cashback_deposit', t('ru.halls.fields.min_cashback_deposit'))} className={classes.textFields} />
        {isAdmin && <FieldMathType error={!!trowError(error, 'math_type')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('math_type', e.target.value)}
            value={form.math_type} formHelperText={trowErrorMessage(error, 'math_type', t('ru.halls.fields.math_type'))} />}
        <FieldHallTemplate error={!!trowError(error, 'hall_template')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('hall_template', e.target.value)}
            value={form.hall_template} formHelperText={trowErrorMessage(error, 'hall_template', t('ru.halls.fields.template'))} />
        <FieldIsSchool error={!!trowError(error, 'is_school')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('is_school', e.target.value)}
            value={form.is_school} formHelperText={trowErrorMessage(error, 'is_school', t('Школа крупье'))} />
        <FieldKeyboardType error={!!trowError(error, 'keyboard_type')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('keyboard_type', e.target.value)}
            value={form.keyboard_type} formHelperText={trowErrorMessage(error, 'keyboard_type', t('ru.halls.fields.keyboard_type'))} />
        <FieldReelAnimation error={!!trowError(error, 'reel_animation')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('reel_animation', e.target.value)}
            value={form.reel_animation} formHelperText={trowErrorMessage(error, 'reel_animation', t('ru.halls.fields.reel_animation'))} />
        <FieldShowCurrency error={!!trowError(error, 'show_currency')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('show_currency', e.target.value)}
            value={form.show_currency} formHelperText={trowErrorMessage(error, 'show_currency', t('ru.halls.fields.show_currency'))} />
        <FieldOferta error={!!trowError(error, 'oferta')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('oferta', e.target.value)}
            value={form.oferta} formHelperText={trowErrorMessage(error, 'oferta', t('ru.halls.fields.oferta'))} />
        <FieldLanguage error={!!trowError(error, 'language')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('language', e.target.value)}
            value={form.language} formHelperText={trowErrorMessage(error, 'language', t('ru.halls.fields.language'))} />
        <FieldJackpotMigration error={!!trowError(error, 'jackpot_migration')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('jackpot_migration', e.target.value)}
            value={form.jackpot_migration} formHelperText={trowErrorMessage(error, 'jackpot_migration', t('ru.halls.fields.jackpot_migration'))} />
        {findPermissionOr(items, ['hallChildrenUpdateLimit', 'updateHallLimit']) && <FieldLimit error={!!trowError(error, 'limit_id')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setLimit(+e.target.value || null)} limitId={limitId}
            formHelperText={trowErrorMessage(error, 'limit_id', t('ru.halls.form_helpers.limit'))} />}
        {isAdmin && <FieldCashbackReel error={!!trowError(error, 'cashback_reel')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormItem('cashback_reel', e.target.value)}
            formHelperText={trowErrorMessage(error, 'cashback_reel', t('ru.halls.form_helpers.cashback_reel'))} value={form.cashback_reel} className={classes.textFields} />}
        <FieldTickerText error={!!trowError(error, 'text')} className={classes.textFields} onChange={e => setFormItem('text', e.target.value)}
            value={form.text} formHelperText={trowErrorMessage(error, 'text', t('ru.halls.fields.ticker_text'))} />
        <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
                <FieldDenominationCheckboxes setDenominationsSelected={setDenominationsSelected} denomination={denomination} formHelperText={trowErrorMessage(error, 'denomination', 'Выберите деноминацию по умолчанию')} denomenationList={denomenationList} denominationsSelected={denominationsSelected.map(e => +e)} />
                <FieldAddDenomination className={classes.textFields} value={form.denom} onChange={(e) => setFormItem('denom', e.target.value.replace(/[^\d.]/g, ''))}
                    onClick={() => {
                        if (!!form.denom && !denominationsSelected.find((i) => i === form.denom * 100)) {
                            const tmp = [...denominationsSelected, form.denom.replace(/[^\d.]/g, '') * 100];
                            tmp.sort((a: number, b: number) => a - b);
                            setDenominationsSelected(tmp);
                        }
                    }} />
                <FieldDenomination error={!!trowError(error, 'denomination')} onChange={(e: ChangeEvent<HTMLSelectElement>) => setDefaultDenomination(+e.target.value)}
                    value={denomination} values={denominationsSelected} formHelperText={trowErrorMessage(error, 'denomination', t('ru.halls.fields.denomination'))} />
            </Grid>
        </Grid>
    </>;
});

export default Info;