import { Box, ListItem } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { reportSelector } from '../../../../redux/reportReducer'
import { Wrapper } from './styles'
import { prettyNum } from '../../../../utils/prettyNum'
import Preloader from '../../../Common/Preloader'
import { convertToUtc, date } from '../../../../utils/date'
import { useForm } from '../../../../hooks/useForm'
import { useTranslation } from 'react-i18next'
import { TableHeader } from '../../../Reports/List/styles'
import Item from '../../../Reports/List/Item'
import { tableSelector } from '../../../../redux/tableReducer'
import DateTimeRangePicker from '../../../Common/DateTimeRangePicker'

interface TableReportsProps {
  tableId: number
  fetch: (id: number, from: string, to: string) => void
  currency_id: string | undefined
}
const TableReports: React.FC<TableReportsProps> = ({ tableId, fetch }) => {
  const [t] = useTranslation()

  const { form, setFormItem } = useForm({
    from: date(),
    to: date(),
  })

  React.useEffect(() => {
    fetch(
      tableId,
      convertToUtc(`${form.from} 00:00:00`),
      convertToUtc(`${form.to} 23:59:59`)
    )
    // eslint-disable-next-line
  }, [])

  const { data: report, loading } = useSelector(reportSelector)

  const {
    tables: { data: tables },
  } = useSelector(tableSelector)

  React.useEffect(() => {
    if (Date.parse(form.to) < Date.parse(form.from)) {
      setFormItem('date_for_drawing', form.from)
    }
    // eslint-disable-next-line
  }, [form.date_end_draw])
  React.useEffect(() => {
    if (Date.parse(form.from) > Date.parse(form.date_end_draw)) {
      setFormItem('date_end_idraw', form.from)
    }
    // eslint-disable-next-line
  }, [form.date_for_drawing])
  const getReportList = () => {
    if (report && report.gambling_tables && !!report.gambling_tables.length) {
      return report.gambling_tables.map((table: any) => {
        return (
          <Item
            key={table.gambling_table_id}
            id={table.gambling_table_id}
            name={tables?.find((i) => i.id === table.gambling_table_id)?.name}
            table_id={table.gambling_table_id}
            balance={prettyNum(table.end_balance)}
            credit={prettyNum(table.debit)}
            debit={prettyNum(table.credit)}
            setSelectedHall={() => {}}
            // income={prettyNum(report.credit - report.debit)}
            // payout={(
            //   ((report.credit - report.debit) / report.credit) *
            //   100
            // ).toFixed(2)}
            total={false}
            isModal
          />
        )
      })
    }
  }

  return (
    <Wrapper>
      <h3>{t('ru.reports.main_title')}</h3>
      <br />
      <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
        <DateTimeRangePicker
          onApply={(e: any, picker: any) => {
            fetch(tableId, convertToUtc(picker.startDate._d), convertToUtc(picker.endDate._d))
          }}
        />
      </Box>
      <Box
        style={{ width: '100%', paddingTop: '25px' }}
        display="flex"
        flexDirection="column"
        alignContent="center"
        flexWrap="wrap"
      >
        <Box style={{ width: '100%', overflowX: 'auto' }}>
          <div style={{ minWidth: '700px', background: '#f9f9f9' }}>
            <TableHeader>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                id
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.hall')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.balance')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.debit')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.credit')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.benefit')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.percent')}
              </ListItem>
            </TableHeader>
            <ul>
              {loading ? <Preloader absolute={false} /> : getReportList()}
              {/* {report && report.gambling_tables && !report.gambling_tables.length && (
              <Box
                style={{ width: '100%' }}
                display="flex"
                justifyContent="center"
                alignContent="flex"
              >
                {t('ru.common.no_data')}
              </Box>
            )} */}
              <Item
                name={'Итог:'}
                balance={prettyNum(report?.end_balance)}
                credit={prettyNum(report?.total_debit)}
                debit={prettyNum(report?.total_credit)}
                total
                isModal
              />
            </ul>
          </div>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default TableReports
