import React, { useCallback } from 'react'
import { Wrapper, TableHeader } from './styles'
import Item from './Item'
import Preloader from '../../Common/Preloader'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import Button from '@material-ui/core/Button'
import {
  deleteHallListItem,
  hallSelector,
  handleChangeHallStatus,
  handlerAddHall,
  handlerTakeHallBalance,
  handlerPutHallBalance,
  handleResetMath
} from '../../../redux/hallReducer'
import { ListItem, Box, Input, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Confirm from '../../Common/Confirm'
import Overlay from '../../Common/Overlay/index'
import { IHall, isConfirmType } from '../../../interfaces'
import { authSelector } from '../../../redux/authReducer'
import { useShowBlock } from '../../../hooks/useShowBlock'
import AddHall from '../Add'
import Hall from '../View'
import PaymentForm from '../../Payment'
import Operations from '../../Operations'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { sortedList } from '../../../utils/sort'
import { getUsersList, userSelector } from '../../../redux/userReducer'
import { getPackageList, packageSelector } from '../../../redux/packageReducer'
import { currencySelector, getCurrencyList } from '../../../redux/currencyReducer'
import { citySelector, getCityList } from '../../../redux/cityReducer'
import { companySelector, getCompanyList } from '../../../redux/companyReducer'
import { limitSelector, getLimitList } from '../../../redux/limitReducer'
import Distribution from '../../Distribution'

interface HallsListProps {
  user_id?: number
  isModal?: boolean
}

const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none',
    },
  },
}))

const HallsList: React.FC<HallsListProps> = ({ user_id, isModal }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()
  const history = useHistory()

  const [selectedSort, setSelectedSort] = React.useState('id')
  const [sortDir, setSortDir] = React.useState<boolean>(true)

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })
  const [isBlock, setIsBlock] = React.useState<isConfirmType>({ open: false })
  const [isCancel, setIsCancel] = React.useState<isConfirmType>({ open: false })

  const {
    halls: { data: halls, loading },
    form: formLoading,
    mathReset
  } = useSelector(hallSelector)

  const {
    data: user,
    assigments: { items },
    role: { item: userRole },
  } = useSelector(authSelector)

  const {
    users: { data: usersList },
  } = useSelector(userSelector)
  const {
    packages: { items: packages },
  } = useSelector(packageSelector)

  const { items: currencyList } = useSelector(currencySelector)
  const { items: cityList } = useSelector(citySelector)
  const { items: companyList } = useSelector(companySelector)
  const { items: limitList } = useSelector(limitSelector)

  React.useEffect(() => {
    !usersList.length && dispatch(getUsersList())
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    !currencyList.length && dispatch(getCurrencyList())
    !cityList.length && dispatch(getCityList())
    !companyList.length && dispatch(getCompanyList())
    !limitList.length && dispatch(getLimitList())
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    !packages.length && dispatch(getPackageList())
    // eslint-disable-next-line
  }, [])

  const [hallId, setHallId] = React.useState<null | number>(null)

  const { showBlock: showHall, hideBlock: hideHall } = useShowBlock()

  const { showBlock: showAddHall, isBlockShown: isAddHall, hideBlock: hideAddHall } = useShowBlock()

  const { showBlock: showPayment, isBlockShown: isPayment, hideBlock: hidePayment } = useShowBlock()

  const {
    showBlock: showDistribution,
    isBlockShown: isDistribution,
    hideBlock: hideDistribution,
  } = useShowBlock()

  const sortButton = (name: string, label: string) => {
    return <ListItem component="span" selected={name === selectedSort || `-${name}` === selectedSort} style={{ cursor: 'pointer', justifyContent: 'center' }}
      onClick={() => {
        if (selectedSort === name) {
          setSortDir(!sortDir);
        } else {
          setSelectedSort(name);
        }
      }}
    >
      {label}
      {selectedSort === `${name}` && (sortDir ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
    </ListItem>
  }

  const [search, setSearch] = React.useState<any>(null);
  const userHalls = user_id ? halls.filter((i) => i.owner_id === user_id) : halls;
  const filteredHalls = useCallback(() => {
    if (search) {
      return userHalls.filter((hall) => {
        const idFilter = search?.id ? hall.id === Number(search?.id) : true;
        const nameFilter = !!search?.name ? hall.name.toLowerCase().indexOf(search.name.toLowerCase()) > -1 : true;
        const userNameFilter = search?.user_login ? hall.owner.login.toLowerCase().indexOf(search.user_login.toLowerCase()) > -1 : true;
        return idFilter && nameFilter && userNameFilter;
      });
    } else {
      return userHalls;
    }
    // eslint-disable-next-line
  }, [userHalls, search]);
  const [isChangeMode, setIsChangeMode] = React.useState(false);

  const location: any = useLocation();
  const id = new URLSearchParams(location.search).get('hall_id');
  const operations_page = Number(new URLSearchParams(location.search).get('operations_page'));

  const currentHall = halls?.find((i: IHall) => i.id === hallId);
  const hallOwnerBalance = !!currentHall && !!currentHall.currency_id && !!user && !!user.balance && user.balance[currentHall.currency_id] ? user.balance[currentHall.currency_id] : 0;

  React.useEffect(() => {
    if (id) {
      setHallId(Number(id))
    } else {
      setHallId(null)
    }
  }, [id])

  const sortedHalls = !!filteredHalls() && sortedList(selectedSort, sortDir, filteredHalls())

  const list = React.useMemo(
    () => sortedHalls.map((item) => <Item key={item.id} item={item} setHallId={setHallId} showHall={showHall} showPayment={showPayment} setIsConfirm={setIsConfirm} setIsBlock={setIsBlock} setIsChangeMode={setIsChangeMode} isModal={!!isModal} />),
    // eslint-disable-next-line
    [halls]
  );

  return (
    <Wrapper isModal={isModal}>
      <Box style={{ minWidth: '968px' }}>
        {!isModal && <Box display="flex" justifyContent="space-between">
          <h2>{t('ru.halls.title')}</h2>
          {!!items.find((i: string) => i === 'createHall') && <Button onClick={showAddHall} variant="contained">
            {t('ru.common.add_btn')}
          </Button>}
        </Box>}
        <div>
          <TableHeader>
            {sortButton('id', 'id')}
            {sortButton('name', t('ru.halls.fields.name'))}
            {sortButton('owner_id', t('ru.halls.fields.user'))}
            <ListItem component="span">{t('ru.halls.fields.balance')}</ListItem>
            {/* {sortButton('balance', t('ru.halls.fields.balance'))} */}
            {sortButton('created_at', t('ru.halls.fields.created_at'))}
            {userRole !== 'cashier' && sortButton('out_percent', t('ru.halls.fields.out_percent'))}
            {sortButton('status', t('ru.halls.fields.status'))}
            <ListItem component="span"></ListItem>
          </TableHeader>
          <Box style={{ background: '#fff', padding: '5px 0' }}>
            <TableHeader>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      id: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      name: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      user_login: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem component="span"></ListItem>
            </TableHeader>
          </Box>
          <ul>{!!loading ? <Preloader absolute={false} /> : list}</ul>
        </div>
      </Box>
      {!!isAddHall && <Overlay>
        <AddHall hideAddHall={hideAddHall} handlerAddHall={(data, packages, math, hideForm) => dispatch(handlerAddHall(data, packages, math, hideForm))} />
      </Overlay>}
      {!!id && !!hallId && <Overlay>
        <Hall
          hideHall={hideHall}
          hallId={hallId}
          isChangeMode={isChangeMode}
          setIsChangeMode={setIsChangeMode}
          setIsCancel={setIsCancel}
          showPayment={showPayment}
          showDistribution={showDistribution}
        />
      </Overlay>}
      {!!isPayment && <Overlay>
        <PaymentForm
          id={hallId}
          hidePayment={hidePayment}
          take={(data, hallId) => dispatch(handlerTakeHallBalance(data, hallId))}
          putEvent={(data, hallId) => dispatch(handlerPutHallBalance(data, hallId))}
          currency_id={currentHall?.currency_id}
          balance={currentHall?.balance}
          ownerBalance={hallOwnerBalance}
          info={false}
        />
      </Overlay>}
      {!!id && !!operations_page && <Overlay>
        <Operations hall_id={Number(id)}
          hideOperations={() => {
            let query = new URLSearchParams(location.search)
            query.delete('operations_page')
            history.push({
              pathname: location.pathname,
              search: query.toString(),
              state: {
                prevPaths:
                  location.state && location.state.prevPaths ? location.state.prevPaths : [],
              },
            })
          }}
        />
      </Overlay>}
      {!!isConfirm.open && <Overlay>
        <Confirm
          text={`Удалить зал ${isConfirm.name}?`}
          loading={formLoading.loading}
          cancel={() => setIsConfirm({ open: false })}
          submit={() => isConfirm.id && dispatch(deleteHallListItem(isConfirm.id, setIsConfirm))}
        />
      </Overlay>}
      {!!isBlock.open && <Overlay>
        <Confirm
          text={`${!!halls && !!halls?.find((i) => i.id === isBlock.id)?.status ? t('ru.common.confirm.block') : t('ru.common.confirm.unblock')} ${isBlock.name}?`}
          loading={formLoading.loading}
          cancel={() => setIsBlock({ open: false })}
          submit={() => dispatch(handleChangeHallStatus(halls?.find((i) => i.id === isBlock.id), setIsBlock))}
        />
      </Overlay>}
      {!!isCancel.open && <Overlay>
        <Confirm text={`Вы уверены, что хотите сбросить все значения?`} loading={mathReset.loading} cancel={() => setIsCancel({ open: false })} submit={() => isCancel.id && dispatch(handleResetMath(isCancel.id, setIsCancel))} />
      </Overlay>}
      {hallId && isDistribution && <Overlay>
        <Distribution hallId={hallId} hideDistribution={hideDistribution} />
      </Overlay>}
    </Wrapper>
  )
}

export default HallsList
