import React from 'react'
import UsersList from './List'
import { getUsersList, userSelector } from '../../redux/userReducer'
import { authSelector } from '../../redux/authReducer'
import { useDispatch, useSelector } from 'react-redux'
import { roleSelector, getRolesList } from '../../redux/roleReducer'
import { permissionSelector, getAllPermissions } from '../../redux/permissionReducer'
import { getTablesList, tableSelector } from '../../redux/tableReducer'
import { getAllJackpots, jackpotSelector } from '../../redux/jackpotReducer'
import { citySelector, getCityList } from '../../redux/cityReducer'
import { countrySelector, getCountryList } from '../../redux/countryReducer'

const Users: React.FC = () => {
  const dispatch = useDispatch()
  const {
    users: { data: users },
  } = useSelector(userSelector)
  const { status } = useSelector(authSelector)
  const {
    roles: { items: roles },
  } = useSelector(roleSelector)

  const { items: permissionsList } = useSelector(permissionSelector)
  const {
    tables: { data: tables },
  } = useSelector(tableSelector)
  const {
    jackpots: { items: jackpots },
  } = useSelector(jackpotSelector)

  const { items: cityList } = useSelector(citySelector)

  const { items: countryList } = useSelector(countrySelector)

  React.useEffect(() => {
    if (status === 200) {
      !users.length && dispatch(getUsersList())
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (status === 200) {
      !roles.length && dispatch(getRolesList())
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (status === 200) {
      !permissionsList.length && dispatch(getAllPermissions(1, 50))
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (status === 200) {
      !tables.length && dispatch(getTablesList())
    }
    // eslint-disable-next-line
  }, [dispatch, status])

  React.useEffect(() => {
    if (status === 200) {
      !jackpots.length && dispatch(getAllJackpots(1, 49))
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !cityList.length && dispatch(getCityList())
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !countryList.length && dispatch(getCountryList())
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  const [userId, setUserId] = React.useState<null | number>(null);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <UsersList userId={userId} setUserId={setUserId} />
    </div>
  )
}
export default Users
