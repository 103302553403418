import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import trashPng from '../../../../assets/images/trash.png';
import { useTranslation } from 'react-i18next';
import { isConfirmType } from '../../../../interfaces'

type DeleteBtnProps = {
    id: number,
    name: string,
    setIsConfirm: (isConfirm: isConfirmType) => void
}

function cmp(prevProps: DeleteBtnProps, nextProps: DeleteBtnProps) {
    return prevProps.id === nextProps.id;
}

const DeleteBtn: FunctionComponent<DeleteBtnProps> = React.memo(({ id, name, setIsConfirm }) => {
    const [t] = useTranslation();
    return <Tooltip title={String(t('ru.common.delete_btn'))} placement="top">
        <Action bg={'#919191'} onClick={() => { setIsConfirm({ id: id, name: name, open: true }) }}>
            <IconButton>
                <img src={trashPng} alt="icon" />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default DeleteBtn;