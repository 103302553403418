import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector } from '../../redux/authReducer'
import { defaultPackageSelector, getDefaultPackagesList } from '../../redux/defaultPackageReducer'
import DefaultPackagesList from './List'
import { packageSelector, getPackageList } from '../../redux/packageReducer'
import { gameSelector, getAllGames } from '../../redux/gameReducer'

const DefaultPackages: React.FC = () => {
  const dispatch = useDispatch()

  const {
    defaultPackages: { items: defaultPackages },
  } = useSelector(defaultPackageSelector)
  const {
    packages: { items: packageList },
  } = useSelector(packageSelector)
  const { status } = useSelector(authSelector)
  const { games: {items: games} } = useSelector(gameSelector)

  React.useEffect(() => {
    if (status === 200) {
      !packageList.length && dispatch(getPackageList())
    }
  }, [status, packageList, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !defaultPackages.length && dispatch(getDefaultPackagesList())
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !games.length && dispatch(getAllGames(1, 50))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <DefaultPackagesList />
    </>
  )
}

export default DefaultPackages
