import { createSlice } from '@reduxjs/toolkit';
import { fetchAddHotkey, fetchHotkeys, fetchRemoveHotkey } from '../services/Hotkey';
import { ErrorType, IHotkeyData, IntToIntsArray } from '../interfaces';
import { AppThunk } from './store';

interface hotkeyState {
  hallId: number,
  tableId: number,
  items: IHotkeyData[];
  loading: boolean;
  error: ErrorType[] | null;
  actionToIdxs: IntToIntsArray;
};

export const initialState: hotkeyState = {
  hallId: -1,
  tableId: -1,
  items: [],
  loading: false,
  error: null,
  actionToIdxs: []
};

export const slice = createSlice({
  name: 'hotkeys',
  initialState,
  reducers: {
    hotkeyLoading: (state) => {
      state.loading = true;
    },
    hotkeysSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.items = [];
      payload.forEach((e: IHotkeyData) => state.items[e.id] = e);
      payload.forEach((value: IHotkeyData) => {
        if (value.action_id in state.actionToIdxs) {
          state.actionToIdxs[value.action_id].push(value.id);
        } else {
          state.actionToIdxs[value.action_id] = [value.id];
        }
      });
    },
    hotkeysFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.items = [];
      state.actionToIdxs = [];
    },
    addHotkeySuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.items[payload.id] = payload;
      if (payload.action_id in state.actionToIdxs) {
        state.actionToIdxs[payload.action_id].push(payload.id);
      } else {
        state.actionToIdxs[payload.action_id] = [payload.id];
      }
    },
    addHotkeyFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    removeHotkeySuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.actionToIdxs[payload.action_id] = state.actionToIdxs[payload.action_id].filter((idx: number) => idx !== payload.id);
      delete state.items[payload.id];
    },
    removeHotkeyFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    clearHotkeys: (state, { payload }) => {
      state.actionToIdxs = [];
      state.items = [];
      state.hallId = payload.hallId;
      state.tableId = payload.tableId;
    }
  }
});

export const addHotkey = (key: string, actionId: number, hallId: number = 0, tableId: number = 0): AppThunk => {
  return async (dispatch) => {
    dispatch(hotkeyLoading());
    return fetchAddHotkey(key, actionId, hallId, tableId).then(res => {
      if (res && 'data' in res && res.data.code === 200) {
        dispatch(addHotkeySuccess(res.data.data));
      } else {
        dispatch(addHotkeyFailure(!res || !('data' in res) ? ['Ошибка'] : (typeof res.data.data.length !== 'number' ? [res.data.data] : res.data.data)));
      }
    }).catch(err => dispatch(addHotkeyFailure(err)));
  }
};

export const removeHotkey = (hotkeyId: number): AppThunk => {
  return async (dispatch) => {
    dispatch(hotkeyLoading());
    return fetchRemoveHotkey(hotkeyId).then(res => {
      if (res && 'data' in res && res.data.code === 200) {
        dispatch(removeHotkeySuccess(res.data.data));
      } else {
        dispatch(removeHotkeyFailure(!res || !('data' in res) ? ['Ошибка'] : (typeof res.data.data.length !== 'number' ? [res.data.data] : res.data.data)));
      }
    }).catch(err => dispatch(removeHotkeyFailure(err)));
  }
};

export const loadHotkeys = (hallId: number = 0, tableId: number = 0): AppThunk => {
  return async (dispatch) => {
    dispatch(hotkeyLoading());
    dispatch(clearHotkeys({ hallId, tableId }));
    return fetchHotkeys(hallId, tableId).then(res => {
      if (res && 'data' in res && res.data.code === 200) {
        dispatch(hotkeysSuccess(res.data.data));
      } else {
        dispatch(hotkeysFailure(!res || !('data' in res) ? ['Ошибка'] : (typeof res.data.data.length !== 'number' ? [res.data.data] : res.data.data)));
      }
    }).catch(err => dispatch(addHotkeyFailure(err)));
  }
};

export const {
  hotkeyLoading,
  addHotkeySuccess,
  addHotkeyFailure,
  hotkeysSuccess,
  hotkeysFailure,
  removeHotkeySuccess,
  removeHotkeyFailure,
  clearHotkeys
} = slice.actions;

interface IState { hotkeys: hotkeyState; };

export const hotkeysSelector = (state: IState) => state.hotkeys;

export default slice.reducer;
