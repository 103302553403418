import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    color: '#666',
  },
  textFields: {
    width: '100%',
  },
  root: {
    '& .MuiPopover-root': {
      margin: '1vh',
      width: 200,
    },
  },
  permissions: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%',
  },
  submitBtn: {
    margin: '1vh',
  },
})

export const FormWrapper = styled.form`
  position: relative;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const SubForm = styled.div`
  position: absolute;
  background: #fff;
  padding: 5vh;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 999;
  border-radius: 1vh;
`
