import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { IGame, ISpin } from '../../../interfaces';
import { GameItem } from './styled';
import WinLines from './WinLines';
import WinScatters from './WinScatters';

type ReelProps = {
  spin: ISpin;
  game: IGame | null;
  linesPosition: any;
  parseWins: any;
};

const Reels: React.FC<ReelProps> = React.memo(({ spin, game, linesPosition, parseWins }) => {
  const [lineId, setLineId] = React.useState<null | number>(null);
  const [scatterId, setScatterId] = React.useState<null | number>(null);
  const isSelected: boolean = !!linesPosition && !!parseWins;
  return <>
    <Grid item xs={12} sm={12}>
      {spin?.reels[0]?.map((_: any, columnId: number) => {
        return <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" key={columnId}>
          {spin?.reels.map((item: number[], rowId: number) => {
            return <GameItem key={rowId} isLineSelected={lineId !== null} isScatterSelected={scatterId !== null}
              showline={isSelected && lineId !== null && linesPosition[String(parseWins.lines[lineId]?.line + 1)][rowId] === columnId + 1}
              highlights={isSelected &&
                (lineId !== null
                  ? linesPosition[String(parseWins.lines[lineId]?.line + 1)][rowId] === columnId + 1 && item[columnId] === parseWins.lines[lineId].template[rowId]
                  : scatterId !== null && parseWins.scatters[scatterId].symbol === item[columnId])}>
              {game?.icons.length === 0
                ? item[columnId]
                : <img className={'icon'} src={game?.icons[item[columnId]]} alt="" style={{ width: '100%', height: '100%', zIndex: 0 }} />}
            </GameItem>
          })}
        </Box>
      })}
    </Grid>
    {isSelected && !!parseWins.lines && !!parseWins.lines.length && <WinLines linesPosition={linesPosition} parseWins={parseWins} setLineId={setLineId}/>}
    {isSelected && !!parseWins.scatters && !!parseWins.scatters.length && <WinScatters parseWins={parseWins} setScatterId={setScatterId}/>}
  </>;
});

export default Reels;
