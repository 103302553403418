import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, TextField } from '@material-ui/core';

type FieldPollIncProps = {
    error: boolean;
    onChange: (object: any) => void;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldPollIncProps, cur: FieldPollIncProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldPollInc: FunctionComponent<FieldPollIncProps> = React.memo(({ error, onChange, value, formHelperText }) => {
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <TextField type="search" name="min_cashback_deposit" value={value} onChange={onChange} size="small" />
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldPollInc;