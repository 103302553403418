import React, { FunctionComponent } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Action } from '../../styles';
import trashPng from '../../../../../assets/images/trash.png';

type isConfirmType = {
    id: number | undefined;
    name: string;
    open: boolean;
};

type DeleteButtonProps = {
    id: number;
    setIsConfirm: (item: isConfirmType) => void;
    login: string;
};

function cmp(prev: DeleteButtonProps, next: DeleteButtonProps): boolean {
    return prev.id === next.id;
}

const DeleteButton: FunctionComponent<DeleteButtonProps> = React.memo(({ id, setIsConfirm, login }) => {
    const [t] = useTranslation();
    return <Tooltip title={String(t('ru.common.delete_btn'))} placement="top">
        <Action bg={'#919191'} onClick={() => setIsConfirm({ id: id, name: login, open: true })}>
            <IconButton>
                <img src={trashPng} alt="icon" />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default DeleteButton;