import React, { FunctionComponent } from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import { Action } from '../../styles';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

type isConfirmType = {
    id: number | undefined;
    name: string;
    open: boolean;
};

type ReloginButtonProps = {
    id: number;
    login: string;
    setIsReLogin: (item: isConfirmType) => void;
};

function cmp(prev: ReloginButtonProps, next: ReloginButtonProps): boolean {
    return prev.id === next.id && prev.login === next.login;
}

const ReloginButton: FunctionComponent<ReloginButtonProps> = React.memo(({ id, setIsReLogin, login }) => {
    return <MenuItem>
        <Action bg={'#919191'} onClick={() => setIsReLogin({ id: id, name: login, open: true })} >
            <IconButton>
                <TransferWithinAStationIcon style={{ width: '15px', color: '#fff' }} />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default ReloginButton;