export const prettyNum = (balance: number | undefined) => {
  if (balance === 0) {
    return '0.00'
  }

  function numberWithSpaces(x: string) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  if (balance) {
    const num: number = balance / 100
    if ((num ^ 0) === num) {
      return numberWithSpaces(String(num + '.00'))
    } else {
      return numberWithSpaces(String(num.toFixed(2)))
    }
  }
}
