import React from 'react'
import { withRouter } from 'react-router-dom'

import { Box, makeStyles, Grid, IconButton } from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { authSelector } from '../redux/authReducer'
import Tree from '../components/Tree'
import Info from '../components/Info'
import { useShowBlock } from '../hooks/useShowBlock'
import Overlay from '../components/Common/Overlay/index'
import ChangeUser from '../components/Users/Change'
import ChangeTable from '../components/Tables/Change'
import ChangeHall from '../components/Halls/Change'
import { getTreeInfo, treeInfoSelector, treeInfoSuccess } from '../redux/treeInfoReducer'
import Partners from '../components/Widgets/Partners'
import TerminalsInGame from '../components/Widgets/TerminalsInGame'
import TerminalsStatistic from '../components/Widgets/TerminalsStatistic'
import { roleSelector, getRolesList } from '../redux/roleReducer'
import { permissionSelector, getAllPermissions } from '../redux/permissionReducer'
import { JackpotsWrapper } from '../components/Jackpots/styles'
import { handlerAddUser, handlerChangeUser } from '../redux/userReducer'
import { handlerChangeHall, hallSelector } from '../redux/hallReducer'
import {
  fetchTableSessions,
  fetchTableSpins,
  handlerAddTable,
  handlerChangeTable,
  handlerPutTableBalance,
  handlerTakeTableBalance,
  tableSelector,
} from '../redux/tableReducer'
import ErrorBoundary from '../utils/errorBoundary'
import { findPermission } from '../utils/find'
import AddJackpot from '../components/Jackpots/Add'
import ChangeJackpot from '../components/Jackpots/Change'
import AddTable from '../components/Tables/Add'
import Jackpot from '../components/Jackpots/View'
import CloseIcon from '@material-ui/icons/Close'
import PaymentForm from '../components/Payment'
import { ITable, TableData, UserData } from '../interfaces'
import { Wrapper } from '../components/Operations/styles'
import SessionsList from '../components/ApplicationSessions/List'
import {
  addJackpotItem,
  changeJackpotItem,
  jackpotSelector,
  getAllJackpots,
} from '../redux/jackpotReducer'
import SpinsList from '../components/Spins/List'
import AddUser from '../components/Users/Add'
import ReportWidget from '../components/Widgets/Reports'
import JackpotsList from '../components/Jackpots/List'
import { useTranslation } from 'react-i18next'
import Preloader from '../components/Common/Preloader'
import { citySelector, getCityList } from '../redux/cityReducer'
import { countrySelector, getCountryList } from '../redux/countryReducer'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: '10vh',
  },
  nav: {
    paddingTop: '2vh',
    marginTop: '-5vh',
    marginBottom: '3vh',
  },
}))

const Home: React.FC = () => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const [t] = useTranslation()

  const { data: user } = useSelector(authSelector)
  const { data: item, component, loading } = useSelector(treeInfoSelector)
  const {
    roles: { items: roles },
  } = useSelector(roleSelector)
  const { items: permissionsList } = useSelector(permissionSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const {
    status,
    assigments: { items },
  } = useSelector(authSelector)

  const {
    tables: { data: tables },
    spins: { data: spins, loading: spinsLoading },
  } = useSelector(tableSelector)

  const {
    jackpots: { items: jackpots },
  } = useSelector(jackpotSelector)

  const { items: cityList } = useSelector(citySelector)

  const { items: countryList } = useSelector(countrySelector)


  React.useEffect(() => {
    !jackpots.length && dispatch(getAllJackpots(1, 49))
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    user && !item && dispatch(getTreeInfo(user.id, 'user'))
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (status === 200) {
      !roles.length && dispatch(getRolesList())
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !permissionsList.length && dispatch(getAllPermissions(1, 50))
    }
    // eslint-disable-next-line
  }, [dispatch, status])

  React.useEffect(() => {
    if (status === 200) {
      !cityList.length && dispatch(getCityList())
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  React.useEffect(() => {
    if (status === 200) {
      !countryList.length && dispatch(getCountryList())
    }
    // eslint-disable-next-line
  }, [status, dispatch])

  const [tableId] = React.useState<number>(-1)
  const [jackpotId, setJackpotId] = React.useState<number>(-1)
  const [sessionId, setSessionId] = React.useState<number>(-1)

  const {
    showBlock: showChangeUser,
    isBlockShown: isChangeUser,
    hideBlock: hideChangeUser,
  } = useShowBlock()

  const {
    showBlock: showChangeHall,
    isBlockShown: isChangeHall,
    hideBlock: hideChangeHall,
  } = useShowBlock()

  const {
    showBlock: showChangeTable,
    isBlockShown: isChangeTable,
    hideBlock: hideChangeTable,
  } = useShowBlock()

  const { isBlockShown: isAddTable, hideBlock: hideAddTable } = useShowBlock()

  const { showBlock: showJackpot, isBlockShown: isJackpot, hideBlock: hideJackpot } = useShowBlock()

  const {
    showBlock: showAddJackpot,
    isBlockShown: isAddJackpot,
    hideBlock: hideAddJackpot,
  } = useShowBlock()

  const {
    showBlock: showChangeJackpot,
    isBlockShown: isChangeJackpot,
    hideBlock: hideChangeJackpot,
  } = useShowBlock()

  const { isBlockShown: isTableSessions, hideBlock: hideTableSessions } = useShowBlock()

  const { isBlockShown: isTablePayment, hideBlock: hideTablePayment } = useShowBlock()

  const { showBlock: showPayment, isBlockShown: isPayment, hideBlock: hidePayment } = useShowBlock()

  const {
    showBlock: showSessionSpins,
    isBlockShown: isSessionSpins,
    hideBlock: hideSessionSpins,
  } = useShowBlock()

  const { showBlock: showAddUser, isBlockShown: isAddUser, hideBlock: hideAddUser } = useShowBlock()



  const tableCurrency = tables?.find((i: ITable) => i.id === tableId)?.hall.currency_id
  const tableBalance =
    tables?.find((i: ITable) => i.id === tableId)?.balance &&
    tableCurrency &&
    tables?.find((i: ITable) => i.id === tableId)?.balance[tableCurrency]
  const renderJackpotList = () => {
    switch (component) {
      case 'table':
        return <JackpotsList change table_id={item?.id} />
      case 'hall':
        return <JackpotsList change hall_id={item?.id} />
      case 'user':
        return <JackpotsList change user_id={item?.id} />
      default:
        return <span>no data</span>
    }
  }

  React.useEffect(() => {
    return () => {
      dispatch(treeInfoSuccess(null))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <ErrorBoundary>
            <Partners />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ErrorBoundary>
            <TerminalsInGame />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ErrorBoundary>
            <TerminalsStatistic />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <ErrorBoundary>
            <Info
              showChangeUser={showChangeUser}
              showChangeHall={showChangeHall}
              showChangeTable={showChangeTable}
              showPayment={showPayment}
              isPayment={isPayment}
              hidePayment={hidePayment}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <Tree showAddUser={showAddUser} />
          </ErrorBoundary>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={8}>
          {findPermission(items, 'hallReportAmount') && <ErrorBoundary>
            <ReportWidget />
          </ErrorBoundary>}
          {!!findPermission(items, 'jackpotView') && (
            <JackpotsWrapper>
              <Box display="flex" justifyContent="flex-start">
                <h3>{t('ru.jackpots.title')}:</h3>
              </Box>
              <ErrorBoundary>{loading ? <Preloader /> : renderJackpotList()}</ErrorBoundary>
            </JackpotsWrapper>
          )}
        </Grid>
      </Grid>
      {isChangeUser && (
        <Overlay>
          <ChangeUser
            user_id={item.id}
            hideChangeUser={hideChangeUser}
            handlerChangeUser={(data, assignmentsData, hideForm, id) =>
              dispatch(handlerChangeUser(data, assignmentsData, hideForm, id, true))
            }
          />
        </Overlay>
      )}
      {isChangeHall && (
        <Overlay>
          <ChangeHall
            hideChangeHall={hideChangeHall}
            handlerChangeHall={(data, packages, math, hideForm, id) =>
              dispatch(handlerChangeHall(data, packages, math, hideForm, id, true))
            }
            hallId={item.id}
          />
        </Overlay>
      )}
      {isTablePayment && (
        <Overlay>
          <PaymentForm
            id={tables?.find((i: ITable) => i.id === tableId)}
            hidePayment={hideTablePayment}
            take={(data, table) => dispatch(handlerTakeTableBalance(data, table))}
            putEvent={(data, table) => dispatch(handlerPutTableBalance(data, table))}
            currency_id={tableCurrency}
            balance={tableBalance}
            info={false}
          />
        </Overlay>
      )}
      {isAddTable && (
        <Overlay>
          <AddTable
            hideAddTable={hideAddTable}
            handlerAddTable={(data: TableData, packages: string[], hideForm: () => void) =>
              dispatch(handlerAddTable(data, packages, hideForm))
            }
            halls={halls}
            defaultHall={item.id}
          />
        </Overlay>
      )}
      {isChangeTable && (
        <Overlay>
          <ChangeTable
            hideChangeTable={hideChangeTable}
            handlerChangeTable={(data, packages, hideForm, id, info) =>
              dispatch(handlerChangeTable(data, packages, hideForm, id, info))
            }
            halls={halls}
            tableId={isChangeHall ? tableId : item.id}
            defaultHall={item.hall_id}
            setJackpotId={setJackpotId}
            showJackpot={showJackpot}
            showAddJackpot={showAddJackpot}
            showChangeJackpot={showChangeJackpot}
          />
        </Overlay>
      )}
      {/* {isTable && (
        <Overlay>
          <Table
            hideTable={hideTable}
            tableId={tableId}
            showTableSessions={showTableSessions}
            showBank={() => {}}
            showOperations={showOperations}
            showPayment={showOperations}
            showMathHistory={() => {}}
            setIsChangeMode={() => {}}
            setIsCancel={() => {}}
          />
        </Overlay>
      )} */}
      {isAddJackpot && (
        <Overlay>
          <AddJackpot
            hall_id={item.id}
            hideAddJackpot={hideAddJackpot}
            addJackpotItem={(data, hideForm) => dispatch(addJackpotItem(data, hideForm))}
          />
        </Overlay>
      )}
      {isChangeJackpot && (
        <Overlay>
          <ChangeJackpot
            jackpotId={jackpotId}
            hall_id={item.id}
            hideChangeJackpot={hideChangeJackpot}
            changeJackpotItem={(data, hideForm) =>
              dispatch(changeJackpotItem(data, hideForm, jackpotId))
            }
          />
        </Overlay>
      )}
      {isJackpot && (
        <Overlay>
          <Jackpot isChangeMode={false} jackpotId={jackpotId} hideJackpot={hideJackpot} />
        </Overlay>
      )}
      {isTableSessions && (
        <Overlay>
          <Wrapper>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h3>История сессий</h3>
              <IconButton onClick={hideTableSessions}>
                <CloseIcon />
              </IconButton>
            </Box>
            <SessionsList
              table_id={tableId}
              title={''}
              scroll
              striped
              fetch={(id: number, page: number, limit: number) =>
                dispatch(fetchTableSessions(id, page, limit))
              }
              itemOnClick={(id: number) => {
                showSessionSpins()
                setSessionId(id)
              }}
            />
          </Wrapper>
        </Overlay>
      )}
      {isSessionSpins && (
        <Overlay>
          <Wrapper>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h3>Сессия {sessionId}</h3>
              <IconButton onClick={hideSessionSpins}>
                <CloseIcon />
              </IconButton>
            </Box>
            <SpinsList
              session_id={sessionId}
              spins={spins}
              loading={spinsLoading}
              fetch={(id: number, page: number, limit: number) =>
                dispatch(fetchTableSpins(id, page, limit))
              }
            />
          </Wrapper>
        </Overlay>
      )}
      {isAddUser && (
        <Overlay>
          <AddUser
            hideAddUser={hideAddUser}
            handlerAddUser={(data: UserData, assignmentsData: any, hideForm: () => void) =>
              dispatch(handlerAddUser(data, assignmentsData, hideForm))
            }
          />
        </Overlay>
      )}
    </Box>
  )
}

export default withRouter(Home)
