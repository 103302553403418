import React, { FunctionComponent } from 'react';
import { Box, Grid, GridSize } from '@material-ui/core';

type FieldProps = {
    xs: GridSize;
    sm: GridSize;
    label: string;
    value: string;
};

function cmp(prev: FieldProps, cur: FieldProps): boolean {
    return prev.xs === cur.xs && prev.sm === cur.sm && prev.value === cur.value && prev.label === cur.label;
}

const Field: FunctionComponent<FieldProps> = React.memo(({ xs, sm, label, value }) => {
    return <Grid item xs={xs} sm={sm}>
        <Box>
            <b>{label}:</b>{' '}
            {value}
        </Box>
    </Grid>;
}, cmp);

export default Field;