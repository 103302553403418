import { createSlice } from '@reduxjs/toolkit'
import { fetchRolesList, addRole, deleteRole, fetchRole, changeRole } from '../services/Role'
import { AppThunk } from './store'
import { RoleData, isConfirmType, IRole, ErrorType } from '../interfaces'

interface RoleInitialState {
  role: IRole | null
  loading: boolean
  error: ErrorType[]
  roles: {
    items: IRole[]
    loading: boolean
    error: ErrorType[]
  }
  form: {
    loading: boolean
    error: ErrorType[]
  }
}

export const initialState: RoleInitialState = {
  role: null,
  loading: false,
  error: [],
  roles: {
    items: [],
    loading: false,
    error: [],
  },
  form: {
    loading: false,
    error: [],
  },
}
export const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    rolesListIsLoading: (state) => {
      state.roles.loading = true
    },
    rolesListSuccess: (state, { payload }) => {
      state.roles.loading = false
      state.roles.error = []
      state.roles.items = payload
    },
    rolesListFailure: (state, { payload }) => {
      state.roles.loading = false
      state.roles.error = payload
    },
    roleIsLoading: (state) => {
      state.loading = true
    },
    roleSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.role = payload
    },
    roleFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    roleItemDelete: (state, { payload }) => {
      state.roles.items = [...state.roles.items.filter((i) => i.name !== payload)]
    },
    roleItemAdd: (state, { payload }) => {
      state.roles.items = [...state.roles.items, payload]
    },
    roleItemChange: (state, { payload }) => {
      const itemId = state.roles.items.findIndex((i) => i.name === payload.name)
      state.roles.items = [
        ...state.roles.items.slice(0, itemId),
        payload,
        ...state.roles.items.slice(itemId + 1, state.roles.items.length),
      ]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getRole = (name: string): AppThunk => {
  return async (dispatch) => {
    dispatch(roleIsLoading())

    return fetchRole(name)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(roleSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(roleFailure([res.data.data]))
          } else {
            dispatch(roleFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(roleFailure(err)))
  }
}

export const getRolesList = (search?: any): AppThunk => {
  return async (dispatch) => {
    dispatch(rolesListIsLoading())

    return fetchRolesList(search)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(rolesListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(rolesListFailure([res.data.data]))
          } else {
            dispatch(rolesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(rolesListFailure(err)))
  }
}

export const addRoleItem = (data: RoleData): AppThunk => {
  return async (dispatch) => {
    return addRole(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(roleItemAdd(res.data.data))
        } else {
          dispatch(roleFailure(res.data.data))
        }
      })
      .catch((err) => dispatch(roleFailure(err)))
  }
}

export const deleteRoleItem = (
  name: string,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return deleteRole(name)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(roleItemDelete(name))
          dispatch(formSucces())
          setIsConfirm({ open: false })
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerAddRole = (data: RoleData, hideForm: (role_name: string) => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addRole(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(roleItemAdd(res.data.data))
          hideForm(res.data.data.id)
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerChangeRole = (data: RoleData, hideForm: () => void, name: string): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return changeRole(data, name)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(roleItemChange(res.data.data))
          dispatch(roleSuccess(res.data.data))
          hideForm()
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  rolesListIsLoading,
  rolesListSuccess,
  rolesListFailure,
  roleIsLoading,
  roleSuccess,
  roleFailure,
  roleItemAdd,
  roleItemDelete,
  roleItemChange,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  role: RoleInitialState
}

export const roleSelector = (state: IState) => state.role

export default slice.reducer
