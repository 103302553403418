import React, { FunctionComponent } from 'react';
import { Button, FormControl, FormHelperText, Grid, NativeSelect, Tooltip } from '@material-ui/core';
import { ICurrency } from '../../../../../interfaces';
import { useSelector } from 'react-redux';
import { currencySelector } from '../../../../../redux/currencyReducer';
import AddCircleIcon from '@material-ui/icons/AddCircle';

type FieldCurrencyProps = {
    error: boolean;
    title: string;
    className: string;
    onClick: () => void;
    buttonText: string;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    currencyId: string;
    formHelperText: string;
};

function cmp(prev: FieldCurrencyProps, cur: FieldCurrencyProps): boolean {
    return prev.error === cur.error && prev.title === cur.title && prev.className === cur.className && prev.buttonText === cur.buttonText && prev.formHelperText === cur.formHelperText && prev.currencyId === cur.currencyId;
}

const FieldCurrency: FunctionComponent<FieldCurrencyProps> = React.memo(({ error, title, className, onClick, buttonText, onChange, currencyId, formHelperText }) => {
    const { items: currencyList } = useSelector(currencySelector);
    return <Grid item xs={12} sm={4}>
        <FormControl error={error} fullWidth size="small">
            <Tooltip title={title} arrow>
                <Button className={className} onClick={onClick}> {buttonText} <AddCircleIcon /> </Button>
            </Tooltip>
            <NativeSelect onChange={onChange} value={currencyId} >
                <option aria-label="None" value="" />
                {currencyList && currencyList.map((item: ICurrency) => <option value={item.id} key={item.id}> {item.id} </option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldCurrency;