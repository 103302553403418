import React from 'react'

import Item from './Item'
import Preloader from '../../Common/Preloader'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/userReducer'
import { hallSelector } from '../../../redux/hallReducer';
import { Box } from '@material-ui/core'
import { authSelector } from '../../../redux/authReducer'
import { useTranslation } from 'react-i18next';
import AddButton from './add-button';
import TableHeader from './table-header';
import { isConfirmType, IUser } from '../../../interfaces';
import { findPermission } from '../../../utils/find';

function makeTree(users: Map<number, IUser>, halls: Map<number, number>, tree: Map<number, any> | any = new Map(), r: number = 0) {
  if (!users.size || r > 10) {
    return tree;
  }
  if (tree.children) {
    users.forEach(u => {
      if (u.parent_id === tree.u.id) {
        tree.children.set(u.id, { u, children: new Map(), users: 0, halls: halls.get(u.id) || 0 });
        tree.users += 1;
        users.delete(u.id)
      }
    });
    tree.children.forEach((u: any, i: number) => {
      makeTree(users, halls, u, r + 1);
      tree.users += u.users;
      tree.halls += u.halls;
    });
  } else {
    users.forEach(u => {
      if (!u.parent_id || !users.has(u.parent_id)) {
        tree.set(u.id, { u, children: new Map(), users: 0, halls: halls.get(u.id) || 0 });
      }
    });
    if (!tree.size) {
      return tree;
    }
    tree.forEach((u: any, i: number) => {
      makeTree(users, halls, u, r + 1);
    });
  }
  return tree;
}

interface UsersListBoxProps {
  setUserId: (id: number | null) => void;
  setIsPaymentShown: (show: boolean) => void;
  setChange: (show: boolean) => void;
  showAddUser: (show: boolean) => void;
  setIsConfirm: (confirm: isConfirmType) => void;
  setIsBlock: (confirm: isConfirmType) => void;
  setIsReLogin: (confirm: isConfirmType) => void;
}

const UsersListBox: React.FC<UsersListBoxProps> = React.memo(({ setUserId, setIsPaymentShown, setChange, showAddUser, setIsConfirm, setIsBlock, setIsReLogin }) => {
  const [t] = useTranslation();

  const { data, assigments: { items } } = useSelector(authSelector);
  const { users: { data: users, loading } } = useSelector(userSelector);
  const { halls: { data: halls } } = useSelector(hallSelector);
  const userTree: Map<number, any> = makeTree(users.reduce((prev, cur) => {
    prev.set(cur.id, cur);
    return prev;
  }, new Map()), halls.reduce((prev, cur) => {
    if (prev.has(cur.owner_id)) {
      prev.set(cur.owner_id, prev.get(cur.owner_id) + 1);
    } else {
      prev.set(cur.owner_id, 1);
    }
    return prev;
  }, new Map()));

  return <Box component="div" style={{ minWidth: '760px' }}>
    <h2>{t('ru.users.title')}</h2>
    <AddButton showAddUser={() => showAddUser(true)} />
    <div>
      <TableHeader />
      <ul className="list">
        {loading ? <Preloader absolute={false} /> : (
          Array.from(userTree.values()).map((item: any) => <Item key={item.u.id} bg={'#f9f9f9'} user={item}
            balance={data?.id === item.u.id ? data?.balance : item.u.balance} users={users}
            setUserId={setUserId} setIsPaymentShown={setIsPaymentShown} setIsConfirm={setIsConfirm}
            setIsBlock={setIsBlock} setIsReLogin={setIsReLogin} nesting={1} setChange={setChange}
            viewUser={findPermission(items, 'viewUser')} updateUser={findPermission(items, 'updateUser')} deleteUser={findPermission(items, 'deleteUser')} />)
        )}
      </ul>
    </div>
  </Box>
});

export default UsersListBox;
