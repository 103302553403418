import React from 'react'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'

const PasswordField: React.FC<any> = ({ ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false)
  return (
    <TextField
      InputProps={{
        endAdornment: props.type ? <InputAdornment position="end">
          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment> : null
      }}
      className={props.className}
      key={props.key}
      label={props.label}
      variant={props.variant}
      value={props.value}
      type={showPassword ? 'text' : props.type}
      onChange={props.onChange}
      name={props.name}
      error={props.error}
      helperText={props.helperText}
      placeholder={props.placeholder}
    />
  )
}

export default PasswordField
