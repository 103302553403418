import React from 'react'
import { Wrapper } from './styles'
import ProfileForm from '../Form'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Box } from '@material-ui/core'
import { ProfileData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'

interface AddProfileProps {
  handlerAddProfile: (data: ProfileData, hideForm: () => void) => void
  hideAddProfile: () => void
}

const AddProfile: React.FC<AddProfileProps> = ({ handlerAddProfile, hideAddProfile }) => {
  const [t] = useTranslation()
  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>{t('ru.profiles.add_form')}</h3>
        <IconButton onClick={hideAddProfile}>
          <CloseIcon />
        </IconButton>
      </Box>
      <ProfileForm
        onSubmit={handlerAddProfile}
        cancelBtnEvent={hideAddProfile}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
      />
    </Wrapper>
  )
}

export default AddProfile
