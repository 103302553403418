import React, { FunctionComponent } from 'react';
import { ITable, IHall, isConfirmType } from '../../../interfaces';
import Preloader from '../../Common/Preloader';
import TableContentAll from './table-content-all';
import TableContentGroup from './table-content-group';

type TableContentProps = {
    preloader: boolean,
    tables: ITable[],
    halls: IHall[],
    showTable: () => void
    showChangeTable: () => void
    setTableId: (id: number) => void
    setIsConfirm: (isConfirm: isConfirmType) => void
    setIsBlock: (isConfirm: isConfirmType) => void
    setIsSession: (isConfirm: isConfirmType) => void
    setIsReload: (isConfirm: isConfirmType) => void
    setIsHome: (isConfirm: isConfirmType) => void
    showPayment: () => void
    showHighscore: () => void
    showPollscore: () => void
    showBind: () => void
    setIsChangeMode: (isChangeMode: boolean) => void
    isModal: boolean,
    hallsGroup: number,
    mathType?: number
};

const TableContent: FunctionComponent<TableContentProps> = ({ preloader, tables, halls, setTableId,
    showTable, showChangeTable, setIsConfirm, setIsBlock, setIsSession, setIsReload, setIsHome, showPayment,
    showHighscore, showPollscore, showBind, setIsChangeMode, isModal, hallsGroup, mathType = 0 }) => {
    if (preloader) {
        return <Preloader absolute={false} />;
    }
    if (hallsGroup) {
        return <TableContentGroup tables={tables} halls={halls} setTableId={setTableId} showTable={showTable} showChangeTable={showChangeTable}
            setIsConfirm={setIsConfirm} setIsBlock={setIsBlock} setIsSession={setIsSession} setIsReload={setIsReload} setIsHome={setIsHome} showPayment={showPayment}
            showHighscore={showHighscore} showPollscore={showPollscore} showBind={showBind} setIsChangeMode={setIsChangeMode} isModal={isModal} mathType={mathType} />;
    }
    return <TableContentAll tables={tables} halls={halls} setTableId={setTableId} showTable={showTable} showChangeTable={showChangeTable}
        setIsConfirm={setIsConfirm} setIsBlock={setIsBlock} setIsSession={setIsSession} setIsReload={setIsReload} setIsHome={setIsHome} showPayment={showPayment}
        showHighscore={showHighscore} showPollscore={showPollscore} showBind={showBind} setIsChangeMode={setIsChangeMode} isModal={isModal} mathType={mathType} />;
};

export default TableContent;