import axios from 'axios'
import { ProfileData, ImagesData } from '../interfaces'

export type QueryType = {
    [query: string]: string
}

export const fetchProfilesList = async (query?: QueryType) => {
    const search = query
        ? (query && '?') +
          (query.name && `f[name]=${query.name}`) +
          (query.surname && `&f[surname]=${query.surname}`) +
          (query.patronymic && `&f[patronymic]=${query.patronymic}`)
        : ''

    return axios.get(`profile${search}`)
}

export const addProfile = async (data: ProfileData) => {
    return axios.post('profile', data)
}

export const fetchProfile = async (id: number) => {
    return axios.get(`profile/${id}`)
}

export const changeProfile = async (data: ProfileData, id: number) => {
    return axios.put(`profile/${id}`, data)
}

export const deleteProfile = async (id: number) => {
    return axios.delete(`profile/${id}`)
}

export const addProfileImage = async (data: ImagesData, id: number) => {
    return axios.post(`profile/${id}/image/attach`, data)
}

export const deleteProfileImage = async (id: number, image_id: number) => {
    return axios.post(`profile/${id}/image/${image_id}/delete`)
}
