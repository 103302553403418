import React, { FunctionComponent } from 'react';

type NestingLineProps = {
    nesting: number;
};

function cmp(prev: NestingLineProps, next: NestingLineProps): boolean {
    return prev.nesting === next.nesting;
}

const NestingLine: FunctionComponent<NestingLineProps> = React.memo(({ nesting }) => {
    const style = { width: '10px', height: '45px', background: '#3039ea', opacity: nesting > 2 ? 0.6 : 1 };
    return nesting && nesting > 1 ? <div style={style} /> : null;
}, cmp);

export default NestingLine;