import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Home from './pages/Home';
import Auth from './pages/Auth';
import UsersPage from './pages/Users';
import HallsPage from './pages/Halls';
import Players from './pages/Players';
import Admin from './pages/Admin';
import Jackpots from './pages/Jackpots';
import Page from './components/Layouts/Page';

import { useDispatch, useSelector } from 'react-redux';
import { authSelector, clearState, getUserInfo, getUserStatus } from './redux/authReducer';

import axios from 'axios';
import { API_BASE } from './settings';
import Tables from './components/Tables';
import ReportsPage from './pages/Reports';
import CashboxPage from './pages/Cashbox';
import Me from './components/Me';

// import Faq from './components/Faq';
// import Issues from './components/Issues';
import TerminalsPage from './pages/Terminals';
import Preloader from './components/Common/Preloader';
import { useCallback } from 'react';

axios.defaults.baseURL = API_BASE;

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { status, token, data: info, loading } = useSelector(authSelector);

  React.useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, [token]);

  React.useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        if (response.data && response.data.code === 401) {
          history.push('/login')
          dispatch(clearState())
          dispatch(getUserStatus(401))
          localStorage.removeItem('token')
        }
        return response;
      },
      (error) => {
        // dispatch(getUserStatus(400))
        return error;
      }
    )
    // eslint-disable-next-line
  }, []);

  const jwt = localStorage.getItem('token')
  React.useEffect(() => {
    !info && jwt && dispatch(getUserInfo())
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    !status && !jwt && dispatch(getUserStatus(401))
    // eslint-disable-next-line
  }, [])

  const privateRender = useCallback(
    (component: any) => {
      return !!status && !loading ? (
        status !== 200 ? (
          <Redirect to="/login" />
        ) : (
          <Page>{component}</Page>
        )
      ) : (
        <Preloader absolute />
      )
    },
    [status, loading]
  )

  return (
    <>
      <Switch>
        <Route exact path="/" render={() => privateRender(<Home />)} />
        <Route path="/login" render={() => (status === 200 ? <Redirect to="/" /> : <Auth />)} />
        <Route path="/me" render={() => privateRender(<Me />)} />
        <Route path="/users" render={() => privateRender(<UsersPage />)} />
        <Route path="/halls" render={() => privateRender(<HallsPage />)} />
        <Route path="/players" render={() => privateRender(<Players />)} />
        <Route path="/terminals" render={() => privateRender(<TerminalsPage />)} />
        <Route path="/tables" render={() => privateRender(<Tables />)} />
        <Route path="/cashbox" render={() => privateRender(<CashboxPage />)} />
        <Route path="/reports" render={() => privateRender(<ReportsPage />)} />
        {/* <Route path="/faq" render={() => privateRender(<Faq />)} />
        <Route path="/issues" render={() => privateRender(<Issues />)} /> */}
        <Route path="/admin" render={() => privateRender(<Admin />)} />
        <Route path="/jackpots" render={() => privateRender(<Jackpots />)} />
        <Redirect to="/" />
      </Switch>
    </>
  )
}

export default App
