import React from 'react'
import { Status, Action, ListItem } from '../styles'

import { IconButton, makeStyles, Menu, MenuItem, MenuList, Tooltip } from '@material-ui/core'
import editPng from '../../../../assets/images/edit.png'
import visionPng from '../../../../assets/images/vision.png'
import trashPng from '../../../../assets/images/trash.png'
import BlockIcon from '@material-ui/icons/Block'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { convertUTCDateToLocalDate } from '../../../../utils/date'
import { StatusMobile } from '../../../Common/Status/styles'
import { prettyNum } from '../../../../utils/prettyNum'
import { isConfirmType, IHall } from '../../../../interfaces'
import { findPermission } from '../../../../utils/find'
import { authSelector } from '../../../../redux/authReducer'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EnhanceLink from '../../../Common/EnhanceLink'
import { userSelector } from '../../../../redux/userReducer'

const useStyles = makeStyles(() => ({
  balance: {
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#919191',
    padding: '3px',
    fontWeight: 'bold',
    fontSize: '1em',
  },
  root: {
    '& .MuiList-padding': {
      padding: 0,
    },
  },
}))

interface HallsListItemProps {
  item: IHall
  setHallId: (id: number) => void
  showHall: () => void
  showPayment: () => void
  setIsConfirm: (isConfirm: isConfirmType) => void
  setIsBlock: (isConfirm: isConfirmType) => void
  setIsChangeMode: (isChangeMode: boolean) => void
  isModal: boolean
}

const Item: React.FC<HallsListItemProps> = React.memo(({
  item,
  setHallId,
  showPayment,
  setIsConfirm,
  setIsBlock,
  setIsChangeMode,
  isModal,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location: any = useLocation()
  const [t] = useTranslation()

  const { id, name, owner_id, created_at, currency, out_percent, status, balance, real_month_payout } = item

  const {
    assigments: { items },
    role: { item: userRole },
  } = useSelector(authSelector)

  const {
    users: { data: users },
  } = useSelector(userSelector)
  const owner = users.find(e => e.id === owner_id);

  const [open, setOpen] = React.useState(false)
  const anchorRef: any = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const isViewHall = findPermission(items, 'viewHall');
  const isUpdateHall = isViewHall && findPermission(items, 'updateHall');
  const itemMenu = () => {
    return (
      <>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className={classes.root}
          autoFocus={false}
          open={open}
          elevation={0}
          anchorEl={anchorRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          <MenuList
            autoFocus={false}
            onKeyDown={handleListKeyDown}
            onClick={handleClose}
            style={{
              background: '#fff',
              border: '1px solid #efeff0',
              display: 'flex',
            }}
          >
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `halls`,
                  search: `hall_id=${id}`,
                  state: {
                    prevPaths: isModal
                      ? !!location.state &&
                        !!location.state.prevPaths &&
                        !!location.state.prevPaths.length
                        ? [
                          ...location.state.prevPaths,
                          window.location.pathname + window.location.search,
                        ]
                        : [window.location.pathname + window.location.search]
                      : location.state && location.state.prevPaths,
                  },
                })
              }}
            >
              <Action bg={'#7467ec'}>
                <IconButton>
                  <img src={visionPng} alt="icon" />
                </IconButton>
              </Action>
            </MenuItem>
            {isUpdateHall && (
              <MenuItem onClick={() => setIsBlock({ id: id, name: name, open: true })}>
                <Action bg={'#919191'}>
                  <IconButton>
                    <BlockIcon fontSize="small" style={{ color: '#fff' }} />
                  </IconButton>
                </Action>
              </MenuItem>
            )}
            {isUpdateHall && (
              <MenuItem
                onClick={() => {
                  // setHallId(id)
                  setIsChangeMode(true)
                  history.push({
                    pathname: `halls`,
                    search: `hall_id=${id}`,
                    state: { prevPath: window.location.href },
                  })
                  // showHall()
                }}
              >
                <Action bg={'#919191'}>
                  <IconButton>
                    <img src={editPng} alt="icon" />
                  </IconButton>
                </Action>
              </MenuItem>
            )}
            {findPermission(items, 'deleteHall') && (
              <MenuItem onClick={() => setIsConfirm({ id: id, name: name, open: true })}>
                <Action bg={'#919191'}>
                  <IconButton>
                    <img src={trashPng} alt="icon" />
                  </IconButton>
                </Action>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </>
    )
  }
  const payout = real_month_payout ? (real_month_payout['in'] > 0 ? ` (${(100 * real_month_payout['out'] / real_month_payout['in']).toFixed(1)})` : ' (∞)') : '';
  return (
    <ListItem>
      <div>{id}</div>
      <div>{name}</div>
      <div>
        {owner && users.findIndex((u) => u.id === owner.id) !== -1 ?
          <EnhanceLink pathname={`/users`} search={`?id=${owner.id}`}>
            {owner.login}
          </EnhanceLink> : '-'}
      </div>
      <div onClick={() => { setHallId(id); showPayment(); }}>
        <span className={classes.balance}>
          {prettyNum(balance) ? prettyNum(balance) : '-'}({currency.id})
        </span>
      </div>
      <div>
        <span>{convertUTCDateToLocalDate(created_at)}</span>
      </div>
      {userRole !== 'cashier' && <div>{out_percent}% {payout}</div>}
      <div>
        {window.innerWidth < 1200 ? <StatusMobile status={!!status} /> : <Status active={!!status}>
          {status ? t('ru.common.status.active') : t('ru.common.status.inactive')}
        </Status>}
      </div>
      <div>
        {window.innerWidth < 1200 ? itemMenu() : (
          <>
            {' '}
            {isViewHall && <Tooltip title={String(t('ru.common.view_tooltip'))} placement="top">
              <Action
                bg={'#7467ec'}
                onClick={() => {
                  history.push({
                    pathname: `halls`,
                    search: `hall_id=${id}`,
                    state: {
                      prevPaths: isModal
                        ? !!location.state &&
                          !!location.state.prevPaths &&
                          !!location.state.prevPaths.length
                          ? [
                            ...location.state.prevPaths,
                            window.location.pathname + window.location.search,
                          ]
                          : [window.location.pathname + window.location.search]
                        : location.state && location.state.prevPaths,
                    },
                  })
                }}
              >
                <IconButton>
                  <img src={visionPng} alt="icon" />
                </IconButton>
              </Action>
            </Tooltip>}
            {isUpdateHall && <Tooltip title={String(t('ru.common.change_btn'))} placement="top">
              <Action bg={'#919191'}
                onClick={() => {
                  setIsChangeMode(true);
                  history.push({ pathname: `halls`, search: `hall_id=${id}`, state: { prevPath: window.location.href } });
                }}
              >
                <IconButton>
                  <img src={editPng} alt="icon" />
                </IconButton>
              </Action>
            </Tooltip>}
            {findPermission(items, 'deleteHall') && <Tooltip title={String(t('ru.common.delete_btn'))} placement="top">
              <Action bg={'#919191'} onClick={() => setIsConfirm({ id: id, name: name, open: true })}>
                <IconButton>
                  <img src={trashPng} alt="icon" />
                </IconButton>
              </Action>
            </Tooltip>}
            {isUpdateHall && <Tooltip title={String(t(`ru.common.confirm.${status ? '' : 'un'}block`))} placement="top">
              <Action bg={'#919191'} onClick={() => setIsBlock({ id: id, name: name, open: true })}>
                <IconButton>
                  <BlockIcon fontSize="small" style={{ color: '#fff' }} />
                </IconButton>
              </Action>
            </Tooltip>}
          </>
        )}
      </div>
    </ListItem>
  )
})

export default Item
