import React, { ReactNode } from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import Preloader from '../../Common/Preloader'

import { ListItem, Box } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { cashboxSelector, getCashboxList } from '../../../redux/cashboxReducer'
import { getUsersList, userSelector } from '../../../redux/userReducer'
import { useTranslation } from 'react-i18next'

interface CashboxListProps {}

const CashboxList: React.FC<CashboxListProps> = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const { items: cashboxList, loading, pageCount } = useSelector(cashboxSelector)
  const {
    users: { data: users },
  } = useSelector(userSelector)

  React.useEffect(() => {
    !users.length && dispatch(getUsersList())
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    !cashboxList.length && dispatch(getCashboxList(1, 20))
    // eslint-disable-next-line
  }, [])

  const renderList = () => {
    return cashboxList.map((item: any, id: number) => {
      return (
        <React.Fragment key={id}>
          <Item item={item} />
        </React.Fragment>
      )
    })
  }
  interface TcProps {
    children: ReactNode
  }
  const Tc: React.FC<TcProps> = ({ children }) => {
    return (
      <ListItem component="span" style={{ justifyContent: 'center', textAlign: 'center' }}>
        {children}
      </ListItem>
    )
  }

  // const {
  //   cashbox: { countPage },
  // } = useSelector(tableSelector)

  const [page, setPage] = React.useState(1)
  React.useEffect(() => {
    getCashboxList(page, 20)
    // eslint-disable-next-line
  }, [page])

  return (
    <>
      <Wrapper>
        <Box display="flex" justifyContent="space-between">
          <h3>{t('ru.cashboxes.title')}</h3>
        </Box>
        <div style={{ minWidth: '850px' }}>
          <TableHeader style={{ fontSize: '75%' }}>
            <Tc>#</Tc>
            <Tc>{t('ru.cashboxes.fields.credit')}</Tc>
            <Tc>{t('ru.cashboxes.fields.debit')}</Tc>
            <Tc>{t('ru.cashboxes.fields.balance_start')}</Tc>
            <Tc>{t('ru.cashboxes.fields.balance_end')}</Tc>
            <Tc>{t('Выручка')}</Tc>
            <Tc>{t('ru.cashboxes.fields.date_start')}</Tc>
            <Tc>{t('ru.cashboxes.fields.date_end')}</Tc>
            <Tc>{t('ru.cashboxes.fields.cashier')}</Tc>
            <Tc>{t('ru.cashboxes.fields.hall')}</Tc>
            {/* <Tc>Кол-во операций</Tc> */}
          </TableHeader>
          <ul className={'cashbox'}>{loading ? <Preloader absolute={false} /> : renderList()}</ul>
          {pageCount && (
            <Box
              display="flex"
              justifyContent="center"
              style={{ margin: '10px auto', width: '100%' }}
            >
              <Pagination
                variant="outlined"
                shape="rounded"
                siblingCount={0}
                count={pageCount}
                page={page}
                onChange={(e, value) => {
                  setPage(value)
                }}
              />
            </Box>
          )}
        </div>
      </Wrapper>
    </>
  )
}

export default CashboxList
