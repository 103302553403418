import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { hallSelector } from '../../../../redux/hallReducer'
import { userSelector } from '../../../../redux/userReducer'
import { prettyNum } from '../../../../utils/prettyNum'
import EnhanceLink from '../../../Common/EnhanceLink'
import { ListItem } from '../styles'
import { convertUTCDateToLocalDate } from '../../../../utils/date'

interface ReportsItemProps {
  item: any
}

const Item: React.FC<ReportsItemProps> = ({ item }) => {
  const { credit, debit, type, from, to, balance_start, balance_end } = item
  const {
    users: { data: users },
  } = useSelector(userSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  // (HallTake - вывод из зала, HallPut - перевод на зал, Transfer - перевод между пользователями)
  const getHall = (id: number) => halls.find((h) => h.id === id)
  const getUser = (id: number) => users.find((u) => u.id === id)
  const getType = (type: string) => {
    switch (type) {
      case `HallTake`:
        return (
          <Box style={{ width: '90%' }} display="flex" justifyContent="center" alignItems="center">
            вывод из зала{' '}
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${to}`}>
              {getHall(to)?.name}
            </EnhanceLink>
            на агента
            <EnhanceLink pathname={`/users`} search={`?id=${from}`}>
              {getUser(from)?.login}
            </EnhanceLink>
          </Box>
        )
      case `HallPut`:
        return (
          <Box style={{ width: '90%' }} display="flex" justifyContent="center" alignItems="center">
            перевод с агента
            <EnhanceLink pathname={`/users`} search={`?id=${from}`}>
              {getUser(from)?.login}
            </EnhanceLink>
            на зал
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${to}`}>
              {getHall(to)?.name}
            </EnhanceLink>
          </Box>
        )
      case `Transfer`:
        return (
          <Box style={{ width: '90%' }} display="flex" justifyContent="center" alignItems="center">
            {!!credit ? 'перевод с агента' : 'снятие c агента'}
            <EnhanceLink pathname={`/users`} search={`?id=${credit ? from : to}`}>
              {getUser(credit ? from : to)?.login}
            </EnhanceLink>
            на агента
            <EnhanceLink pathname={`/users`} search={`?id=${credit ? to : from}`}>
              {getUser(credit ? to : from)?.login}
            </EnhanceLink>
          </Box>
        )
      default:
        break
    }
  }
  return (
    <ListItem bg={'#fff'}>
      <div>
        <span style={{ width: '90%', textAlign: 'center' }}>{convertUTCDateToLocalDate(item.created_at)}</span>
      </div>
      <div>
        <EnhanceLink pathname={`/halls`} search={`?hall_id=${from}`}>
          {getUser(from)?.login}
        </EnhanceLink>
      </div>
      <div style={{ color: !!credit ? '#e27575' : '#87b384' }}>
        {!!credit ? '-' + prettyNum(Number(credit)) : prettyNum(Number(debit))}
      </div>
      <div style={{ fontSize: '.6em' }}>{prettyNum(Number(balance_start))}</div>
      <div style={{ fontSize: '.6em' }}>{prettyNum(Number(balance_end))}</div>
      <div>{getType(type)}</div>
    </ListItem>
  )
}

export default Item
