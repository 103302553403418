import { Box, ListItem, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import Chart from 'react-google-charts'
import { useDispatch, useSelector } from 'react-redux'
import { getTableReportsList, reportSelector } from '../../../redux/reportReducer'

import { getAlltables, tableSelector } from '../../../redux/tableReducer'
import Preloader from '../../Common/Preloader'
import { useTranslation } from 'react-i18next'
import { TableHeader } from '../List/styles'
import Item from '../List/Item'
import { authSelector } from '../../../redux/authReducer'
import { useCallback } from 'react'

export const useStyles = makeStyles({
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
    fontSize: '90%',
  },
  reload: {
    color: 'white',
    background: '#a9c477',
    borderRadius: 0,
    width: 'max-content',
  },
})

interface TablesReportProps {
  hall_id: number
  from: string
  to: string
  setSelectedTable: (table_id: number) => void
}

const TablesReport: React.FC<TablesReportProps> = ({ hall_id, from, to, setSelectedTable }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const {
    role: { item: userRole },
  } = useSelector(authSelector)

  const {
    reports: {
      tables: { data: tablesReport, loading },
    },
  } = useSelector(reportSelector)

  const {
    tables: { data: tables, loading: tablesLoading },
  } = useSelector(tableSelector)

  React.useEffect(() => {
    !tables.length && dispatch(getAlltables(1, 50))
    // eslint-disable-next-line
  }, [])

  const hallTables = tables.filter((i) => i.hall_id === hall_id)
  const getHallTablesId = () => {
    let tablesId: number[] = []
    hallTables.forEach((item) => {
      tablesId = [...tablesId, item.id]
    })
    return tablesId
  }

  const getRandomColors = React.useCallback(() => {
    const randomBetween = (min: number, max: number) =>
      min + Math.floor(Math.random() * (max - min + 1))
    let colors: string[] = []
    for (let i = 0; i < getHallTablesId().length; i++) {
      const r = randomBetween(0, 255)
      const g = randomBetween(0, 255)
      const b = randomBetween(0, 255)
      colors = [...colors, `rgb(${r},${g},${b})`]
    }

    return colors
    // eslint-disable-next-line
  }, [])
  const colors = [
    '#856ff7',
    '#64b0a6',
    '#704a34',
    '#c55656',
    '#86c380',
    '#f0d965',
    ...getRandomColors(),
  ]

  const getBenefitForTheTables = useCallback(() => {
    let pieItems: any = []
    !!tablesReport?.gambling_tables?.length &&
      tablesReport.gambling_tables.forEach((item: any, id: number) => {
        const tableName: any = tables && tables.find((i: any) => i.id === item.gambling_table_id)
        const pieItem: any = [tableName?.name, (item.debit - item.credit) / 100, colors[id]]
        pieItems = [...pieItems, pieItem]
      })

    return pieItems
    // eslint-disable-next-line
  }, [tablesReport])

  const hallsBenefitChart = () => {
    return (
      <div style={{ display: 'flex', width: '50%' }}>
        <Chart
          width={'100%'}
          height={'100%'}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={[['Зал', '', { role: 'style' }], ...getBenefitForTheTables()]}
          options={{ colors: ['#64b0a6'], legend: 'none' }}
        />
      </div>
    )
  }

  React.useEffect(() => {
    !!getHallTablesId().length && dispatch(getTableReportsList(getHallTablesId(), from, to))
    // eslint-disable-next-line
  }, [tablesLoading])

  return (
    <Box
      style={{ width: '100%', paddingTop: '25px' }}
      display="flex"
      flexDirection="column"
      alignContent="center"
      flexWrap="wrap"
    >
      {tablesReport && (
        <Box
          style={{ width: '100%' }}
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexWrap="wrap"
        >
          {loading || tablesLoading ? (
            <Preloader />
          ) : (
            <>
              {!!(
                tablesReport.total_credit +
                tablesReport.total_debit +
                tablesReport?.end_balance
              ) && hallsBenefitChart()}
              {!(
                tablesReport.total_credit +
                tablesReport.total_debit +
                tablesReport?.end_balance
              ) && <Typography style={{ margin: 'auto' }}>{t('ru.common.is_absent')}</Typography>}
            </>
          )}
        </Box>
      )}
      <Box style={{ width: '100%', overflowX: 'auto' }}>
        <div style={{ minWidth: '500px', background: '#f9f9f9', padding: '2px' }}>
          <TableHeader>
            <ListItem component="span" style={{ justifyContent: 'center' }}>
              id
            </ListItem>
            <ListItem component="span" style={{ justifyContent: 'center' }}>
              {t('ru.reports.fields.hall')}
            </ListItem>
            <ListItem component="span" style={{ justifyContent: 'center' }}>
              {t('ru.reports.fields.balance')}
            </ListItem>
            <ListItem component="span" style={{ justifyContent: 'center' }}>
              {t('ru.reports.fields.debit')}
            </ListItem>
            <ListItem component="span" style={{ justifyContent: 'center' }}>
              {t('ru.reports.fields.credit')}
            </ListItem>
            <ListItem component="span" style={{ justifyContent: 'center' }}>
              {t('ru.reports.fields.benefit')}
            </ListItem>
            <ListItem component="span" style={{ justifyContent: 'center' }}>
              {t('ru.reports.fields.percent')}
            </ListItem>
            <ListItem component="span" style={{ justifyContent: 'center' }}></ListItem>
          </TableHeader>
          <ul>
            {loading || tablesLoading ? (
              <Preloader absolute={false} />
            ) : (
              tablesReport &&
              tablesReport.gambling_tables &&
              tablesReport.gambling_tables.map((table: any) => {
                return (
                  <Item
                    cursor="pointer"
                    key={table.gambling_table_id}
                    id={table.gambling_table_id}
                    name={tables?.find((i) => +i.id === +table.gambling_table_id)?.name}
                    table_id={table.gambling_table_id}
                    balance={table.end_balance}
                    credit={table.credit}
                    debit={table.debit}
                    setSelectedHall={setSelectedTable}
                    reportBtnLock={userRole === 'cashier'}
                    // income={prettyNum(report.credit - report.debit)}
                    // payout={(
                    //   ((report.credit - report.debit) / report.credit) *
                    //   100
                    // ).toFixed(2)}
                    total={false}
                  />
                )
              })
            )}
            {tablesReport && tablesReport.gambling_tables && !tablesReport.gambling_tables.length && (
              <Box
                style={{ width: '100%' }}
                display="flex"
                justifyContent="center"
                alignContent="flex"
              >
                {t('ru.common.no_data')}
              </Box>
            )}
            {!loading &&
              tablesReport &&
              tablesReport.gambling_tables &&
              !!tablesReport.gambling_tables.length && (
                <Item
                  name={'Итог:'}
                  balance={tablesReport?.end_balance}
                  credit={tablesReport?.total_credit}
                  debit={tablesReport?.total_debit}
                  total
                />
              )}
          </ul>
        </div>
      </Box>
    </Box>
  )
}

export default TablesReport
