import React from 'react'

import List from '@material-ui/core/List'
import { Box, Grid, makeStyles, IconButton, ListItem, Divider } from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'

import { Wrapper } from './styles'
import Profile from '../../Profiles/View'
import { useShowBlock } from '../../../hooks/useShowBlock'
import { IProfile, PlayerData } from '../../../interfaces'
import PlayerForm from '../Form'
import { handlerChangePlayer } from '../../../redux/playerReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GoBackBtn from '../../Common/GoBackBtn'
import { hallSelector } from '../../../redux/hallReducer'
import EnhanceLink from '../../Common/EnhanceLink'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))
interface PlayerProps {
  player: Player | undefined
  profile: IProfile | undefined
  hidePlayer: () => void
  setCurrentProfile: (id: number) => void
  showProfiles: () => void
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
}
const PlayerView: React.FC<PlayerProps> = ({
  player,
  profile,
  hidePlayer,
  showProfiles,
  isChangeMode,
  setIsChangeMode,
}) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const location: any = useLocation()
  const [t] = useTranslation()

  const {
    halls: { data: halls },
  } = useSelector(hallSelector)

  const { isBlockShown: isProfile, hideBlock: hideProfile } = useShowBlock()

  const [isChange, setIsChange] = React.useState(isChangeMode)
  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  const rootRender = (
    <>
      <Box className={classes.closeBtn}>
        <IconButton onClick={hidePlayer}>
          <CloseIcon />
        </IconButton>
        {!!location.state && !!location.state.prevPaths && !!location.state.prevPaths.length && (
          <GoBackBtn />
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <AccountCircleIcon fontSize="large" />
        <h2>{player?.name}</h2>
      </Box>
      <br />
      <Divider />
      {isChange ? (
        <PlayerForm
          onSubmit={(data: PlayerData, hideForm: () => void, id: number | undefined) =>
            dispatch(handlerChangePlayer(data, hideForm, id))
          }
          showProfiles={showProfiles}
          profile={profile}
          id={player?.id}
          name={player?.name}
          hall_id={player?.hall_id}
          cancelBtnEvent={() => setIsChange(false)}
          cancelBtnText={t('ru.common.back_btn')}
          submitBtnText={t('ru.common.change_btn')}
          hideForm={() => setIsChange(false)}
        />
      ) : (
        <List>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <b>{t('ru.players.fields.name')}:</b> {player?.name ? player?.name : 'Отсуствует'}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex">
                  <b>{t('ru.players.fields.hall')}:</b>{' '}
                  <EnhanceLink pathname={`halls`} search={`?hall_id=${player?.hall_id}`}>
                    {halls && halls?.find((hall) => hall.id === player?.hall_id)?.name}
                  </EnhanceLink>
                </Box>
              </Grid>
            </Grid>
          </div>
          <br />
          <span>
            <b>{t('ru.players.fields.profile')} #{player?.profile?.id}:</b>
          </span>
          {player?.profile ? (
            <ListItem className={classes.item} button>
              <Grid container style={{ textAlign: "center" }}>
                <Grid item xs={4}>
                  <span className={classes.span}>{player.profile.name}</span>
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.span}>{player.profile.surname}</span>
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.span}>{player.profile.patronymic}</span>
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.span}>{["Паспорт"][player.profile.document_type]}</span>
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.span}>{player.profile.document_series}</span>
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.span}>{player.profile.document_number}</span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.span}>{player.profile.registration_address}</span>
                </Grid>
              </Grid>
            </ListItem>
          ) : (
            <span>{t('ru.common.is_absent')}</span>
          )}
        </List>
      )}
    </>
  )

  return (
    <Wrapper>
      {rootRender}
      {isProfile && <Profile profile={player?.profile} hideProfile={hideProfile} />}
    </Wrapper>
  )
}

export default PlayerView
