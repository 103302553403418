import React, { FunctionComponent } from 'react';
import { MenuItem, Box, Select, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type TitleProps = {
    canCreate: boolean,
    hallsGroup: number,
    setHallsGroup: (data: number) => void,
    showAddTable: () => void
};

const Title: FunctionComponent<TitleProps> = React.memo(({ hallsGroup, setHallsGroup, canCreate, showAddTable }) => {
    const [t] = useTranslation();
    return <Box display="flex" justifyContent="space-between" alignItems="center">
        <h2>{t('ru.tables.title')}</h2>
        <Select value={hallsGroup} onChange={(e: any) => setHallsGroup(e.target.value)}>
            <MenuItem value={0}>{t('ru.common.selectors.all')}</MenuItem>
            <MenuItem value={1}>{t('ru.common.selectors.hall')}</MenuItem>
        </Select>
        {canCreate && <Button style={{ margin: '10px' }} onClick={showAddTable} variant="contained"> {t('ru.common.add_btn')} </Button>}
    </Box>;
});

export default Title;