import React, { FunctionComponent } from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import HomeIcon from '@material-ui/icons/Home';
import { isConfirmType } from '../../../../interfaces'

type HomeMenuItemProps = {
    id: number,
    name: string,
    setIsHome: (isConfirm: isConfirmType) => void
}

function cmp(prevProps: HomeMenuItemProps, nextProps: HomeMenuItemProps) {
    return prevProps.id === nextProps.id;
};

const HomeMenuItem: FunctionComponent<HomeMenuItemProps> = React.memo(({ id, name, setIsHome }) => {
    return <MenuItem onClick={() => { setIsHome({ id: id, name: name, open: true }) }} >
        <Action bg={'#919191'}>
            <IconButton>
                <HomeIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default HomeMenuItem;