import React from 'react'
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import Games from '../components/Games/index'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Roles from '../components/Roles'
import DefaultPackages from '../components/DefaultPackages'
import AdminMenu from '../components/AdminMenu'
import Packages from '../components/Packages'
import Hotkeys from '../components/Hotkeys'
import Limits from '../components/Limits'
import Logs from '../components/Logs'

const Admin = () => {
    return (
        <Switch>
            <Route exact path="/admin">
                <AdminMenu />
            </Route>
            <Route path="/admin/games">
                <div style={{ width: '100%' }}>
                    <Link to="/admin">
                        <ArrowBackIcon />
                    </Link>
                    <Games />
                </div>
            </Route>
            <Route path="/admin/roles">
                <div style={{ width: '100%' }}>
                    <Link to="/admin">
                        <ArrowBackIcon />
                    </Link>
                    <Roles />
                </div>
            </Route>
            <Route path="/admin/game-packages">
                <div style={{ width: '100%' }}>
                    <Link to="/admin">
                        <ArrowBackIcon />
                    </Link>
                    <Packages />
                </div>
            </Route>
            <Route path="/admin/game-default-packages">
                <div style={{ width: '100%' }}>
                    <Link to="/admin">
                        <ArrowBackIcon />
                    </Link>
                    <DefaultPackages />
                </div>
            </Route>
            <Route path="/admin/hotkeys">
                <div style={{ width: '100%' }}>
                    <Link to="/admin">
                        <ArrowBackIcon />
                    </Link>
                    <Hotkeys />
                </div>
            </Route>
            <Route path="/admin/limits">
                <div style={{ width: '100%' }}>
                    <Link to="/admin">
                        <ArrowBackIcon />
                    </Link>
                    <Limits />
                </div>
            </Route>
            <Route path="/admin/log">
                <div style={{ width: '100%' }}>
                    <Link to="/admin">
                        <ArrowBackIcon />
                    </Link>
                    <Logs />
                </div>
            </Route>
            <Redirect to='/admin' />
        </Switch>
    )
}

export default Admin
