import React from 'react'
import { Wrapper } from './styles'
import ProfileForm from '../Form'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Box } from '@material-ui/core'
import { IProfile, ProfileData } from '../../../interfaces'

interface ChangeProfileProps {
  hideChangeProfile: () => void
  handlerChangeProfile: (data: ProfileData, hideForm: () => void, id: number | undefined) => void
  profile: IProfile | undefined
}

const ChangeProfile: React.FC<ChangeProfileProps> = ({
  hideChangeProfile,
  handlerChangeProfile,
  profile,
}) => {
  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>Изменить профиль</h3>
        <IconButton onClick={hideChangeProfile}>
          <CloseIcon />
        </IconButton>
      </Box>
      <ProfileForm
        onSubmit={handlerChangeProfile}
        cancelBtnEvent={hideChangeProfile}
        cancelBtnText={'Отмена'}
        submitBtnText={'Изменить'}
        id={profile?.id}
        name={profile?.name}
        patronymic={profile?.patronymic}
        surname={profile?.surname}
      />
    </Wrapper>
  )
}

export default ChangeProfile
