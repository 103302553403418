import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'

export const Wrapper = styled.div`
  position: relative;
  padding: 3vh;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 0.05);

  h3 {
    font-size: 95%;
    margin: 0 5px;
  }

  b {
    font-size: 90%;
    line-height: 25x;
  }

  span {
    font-size: 84%;
  }

  .tabs {
    position: relative;
  }

  .tabs:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ddd;
    z-index: 1;
  }
`

type StyledPropsTabItem = {
  active: boolean
}
export const TabItem = styled.span`
  background: #fff;
  color: ${(props: StyledPropsTabItem) => (props.active ? '#666' : '#ddd')};
  border: ${(props: StyledPropsTabItem) => (props.active ? '1px solid #ddd' : 'none')};
  border-bottom: ${(props: StyledPropsTabItem) => (props.active ? 'none' : '1px solid #ddd')};
  cursor: pointer;
  padding: 10px;
  z-index: 10;
`

export const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%',
  },
  chip: {
    margin: '.3vh',
  },
  box: {
    margin: '6px 0'
  }
}))
