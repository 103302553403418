import React from 'react'
import { Wrapper } from './styles'
import { makeStyles, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector, useDispatch } from 'react-redux'
import { tableSelector, getTable, tableSuccess } from '../../../redux/tableReducer'
import Preloader from '../../Common/Preloader'
import { IHall, TableData } from '../../../interfaces'
import TableForm from '../Form'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface ChangeUserProps {
  halls: IHall[]
  hideChangeTable: () => void
  handlerChangeTable: (
    data: TableData,
    packages: any,
    hideForm: () => void,
    id?: any,
    info?: boolean
  ) => void
  tableId: number
  defaultHall?: number
  setJackpotId?: (id: number) => void
  showJackpot?: () => void
  showAddJackpot?: () => void
  showChangeJackpot?: () => void
}

const ChangeUser: React.FC<ChangeUserProps> = ({
  halls,
  hideChangeTable,
  handlerChangeTable,
  tableId,
  defaultHall,
  setJackpotId,
  showJackpot,
  showAddJackpot,
  showChangeJackpot,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data: table, loading } = useSelector(tableSelector)
  React.useEffect(() => {
    tableId && dispatch(getTable(tableId))
    return () => {
      dispatch(tableSuccess(null))
    }
  }, [dispatch, tableId])
  const [tab, setTab] = React.useState(0)
  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideChangeTable}>
        <CloseIcon />
      </IconButton>
      <h3>Изменение стола</h3>
      {!table || loading ? (
        <Preloader absolute={false} />
      ) : (
        <>
          <TableForm
            onSubmit={handlerChangeTable}
            id={table.id}
            name={table.name}
            hall_id={table.hall_id}
            packets={table.packages}
            status={table.status}
            cancelBtnText={'Назад'}
            cancelBtnEvent={hideChangeTable}
            submitBtnText={'Подтвердить'}
            hideForm={hideChangeTable}
            defaultHall={defaultHall}
            setTab={setTab}
            tab={tab}
            denomination={table.denomination}
            denominationList={table.denominationList}
            api={table.api ?? undefined}
          />
        </>
      )}
    </Wrapper>
  )
}

export default ChangeUser
