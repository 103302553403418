import React, { FunctionComponent } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Action } from '../../styles';
import visionPng from '../../../../../assets/images/vision.png';

type ViewButtonProps = {
    id: number;
};

function cmp(prev: ViewButtonProps, next: ViewButtonProps): boolean {
    return prev.id === next.id;
}

const ViewButton: FunctionComponent<ViewButtonProps> = React.memo(({ id }) => {
    const [t] = useTranslation();
    const history = useHistory();
    return <Tooltip title={String(t('ru.common.view_tooltip'))} placement="top">
        <Action
            bg={'#7467ec'}
            onClick={() => {
                history.push({
                    pathname: `users`,
                    search: `?id=${id}`,
                    state: { prevPath: window.location.href },
                })
            }}
        >
            <IconButton>
                <img src={visionPng} alt="icon" />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default ViewButton;