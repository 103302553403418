import React from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import Preloader from '../../Common/Preloader'

import { useSelector } from 'react-redux'
import { Box, ListItem } from '@material-ui/core'
import { reportSelector } from '../../../redux/reportReducer'
import { hallSelector } from '../../../redux/hallReducer'
import { useTranslation } from 'react-i18next'
import { authSelector } from '../../../redux/authReducer'

interface ReportsListProps {
  setSelectedHall: (id: number | null) => void
}

const ReportsList: React.FC<ReportsListProps> = ({ setSelectedHall }) => {
  const [t] = useTranslation()

  const {
    reports: {
      halls: { data: hallReports, loading },
    },
  } = useSelector(reportSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  
  const {
    role: { item: userRole },
  } = useSelector(authSelector)

  const sortButton = (name: string, label: string) => {
    return (
      <ListItem component="span" style={{ cursor: 'default', justifyContent: 'center' }}>
        {label}
      </ListItem>
    )
  }

  return (
    <Wrapper>
      <div style={{ minWidth: '800px' }}>
        <TableHeader>
          {sortButton('id', 'id')}
          {sortButton('name', t('ru.reports.fields.hall'))}
          {sortButton('package_id', t('ru.reports.fields.balance'))}
          {sortButton('package_id', t('ru.reports.fields.debit'))}
          {sortButton('package_id', t('ru.reports.fields.credit'))}
          {sortButton('package_id', t('ru.reports.fields.benefit'))}
          {userRole &&
            userRole !== 'cashier' &&
            sortButton('package_id', t('ru.reports.fields.percent'))}
          <ListItem component="span"></ListItem>
        </TableHeader>
        <ul>
          {loading ? (
            <Preloader absolute={false} />
          ) : (
            hallReports &&
            hallReports.halls &&
            hallReports.halls.map((hall: any) => {
              return (
                <Item
                  cursor="pointer"
                  key={hall.hall_id}
                  id={hall.hall_id}
                  name={halls?.find((i) => i.id === hall.hall_id)?.name}
                  hall_id={hall.hall_id}
                  balance={hall.end_balance}
                  tables={hall.gamble_table_balance}
                  credit={hall.credit}
                  debit={hall.debit}
                  // income={prettyNum(report.credit - report.debit)}
                  // payout={(
                  //   ((report.credit - report.debit) / report.credit) *
                  //   100
                  // ).toFixed(2)}
                  total={false}
                  setSelectedHall={setSelectedHall}
                />
              )
            })
          )}
          {!halls.length && (
            <Box
              style={{ width: '100%' }}
              display="flex"
              justifyContent="center"
              alignContent="flex"
            >
              {t('ru.common.no_data')}
            </Box>
          )}
          {!loading && !!halls.length && <Item
            name={'Итог:'}
            balance={hallReports?.end_balance}
            tables={hallReports?.total_gamble_table_balance}
            credit={hallReports?.total_credit}
            debit={hallReports?.total_debit}
            setSelectedHall={setSelectedHall}
            // income={prettyNum(total(reports, 'credit') - total(reports, 'debit'))}
            // payout={(
            //   (total(reports, 'debit') / total(reports, 'credit')) *
            //   100
            // ).toFixed(2)}
            total
          />}
        </ul>
      </div>
    </Wrapper>
  )
}

export default ReportsList
