import React, { FunctionComponent } from 'react';
import { Button, FormControl, FormHelperText, Grid, GridSize, NativeSelect, Tooltip } from '@material-ui/core';
import { ICity } from '../../../../../interfaces';
import { useSelector } from 'react-redux';
import { citySelector } from '../../../../../redux/cityReducer';
import AddCircleIcon from '@material-ui/icons/AddCircle';

type FieldCityProps = {
    sm: GridSize;
    error: boolean;
    title: string;
    className: string;
    onClick: () => void;
    buttonText: string;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    cityId: number;
    formHelperText: string;
};

function cmp(prev: FieldCityProps, cur: FieldCityProps): boolean {
    return prev.sm === cur.sm && prev.error === cur.error && prev.title === cur.title && prev.className === cur.className && prev.buttonText === cur.buttonText && prev.formHelperText === cur.formHelperText && prev.cityId === cur.cityId;
}

const FieldCity: FunctionComponent<FieldCityProps> = React.memo(({ sm, error, title, className, onClick, buttonText, onChange, cityId, formHelperText }) => {
    const { items: cityList } = useSelector(citySelector);
    return <Grid item xs={12} sm={sm}>
        <FormControl fullWidth size="small" error={error}>
            <Tooltip title={title} arrow>
                <Button className={className} onClick={onClick}>
                    {buttonText} <AddCircleIcon />
                </Button>
            </Tooltip>
            <NativeSelect onChange={onChange} value={cityId} >
                <option aria-label="None" value="" />
                {cityList && cityList.map((item: ICity) => <option value={item.id} key={item.id}> {item.name} </option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldCity;