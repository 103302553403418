import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    h2 {
        margin: 1vh 0;
        font-size: 2.9vh;
    }

    .operations {
        overflow-y: auto;
        height: 60vh;
        width: 100%;
        overflow-x: hidden;
    }
`
export const TableHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    span {
        width: 20%;
        height: 4.9vh;
        color: #010e2a;
        font-size: 1.6vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`
type ListItemType = {
    bg: string
}
export const ListItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: ${(props: ListItemType) => props.bg};
    margin: 0.5vh;
    div {
        position: relative;
        width: 20%;
        height: 5.9vh;
        color: #010e2a;
        font-size: 1.3vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.5;
        img {
            width: 1.5vh;
            cursor: pointer;
        }
    }
`
type StatusType = {
    active: boolean
}
export const Status = styled.span`
    height: 3vh;
    background: ${(props: StatusType) => (props.active ? '#87b03b' : '#b03c3c')};
    color: #fff;
    border-radius: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 3vh;
    width: 15vh;
`
type ActionType = {
    bg: string
}
export const Action = styled.span`
    width: 3vh;
    height: 3vh;
    margin: 0.3vh;
    background: ${(props: ActionType) => props.bg};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
`
