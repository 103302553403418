import { Box } from '@material-ui/core'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CashboxList from '../components/Cashboxes/List'

import Reports from '../components/Reports'
import GamesReportsList from '../components/Reports/GamesReportsList'
import { useForm } from '../hooks/useForm'
import { convertToUtc, date } from '../utils/date'
import { getHallGameReportsList } from '../redux/reportReducer'
import { useDispatch, useSelector } from 'react-redux'
import { hallSelector } from '../redux/hallReducer'
import { useTranslation } from 'react-i18next'
import { authSelector } from '../redux/authReducer'
import DateTimeRangePicker from '../components/Common/DateTimeRangePicker'
import UserReports from '../components/Reports/UserReports'

const ReportsPage = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const { form } = useForm({
    from: date(),
    to: date(),
  })
  const {
    role: { item: userRole },
  } = useSelector(authSelector)

  const {
    halls: { data: halls },
  } = useSelector(hallSelector)

  const getUserHalls = () => {
    let hallsId: number[] = []
    halls &&
      halls.forEach((item) => {
        hallsId.push(item.id)
      })
    return hallsId
  }

  return (
    <Switch>
      <Route path="/reports/main">
        <Reports />
      </Route>
      <Route path="/reports/cashboxes">
        <CashboxList />
      </Route>
      {userRole && userRole !== 'cashier' && (
        <Route path="/reports/games">
          <Box
            style={{ width: '100%' }}
            display="flex"
            alignContent="flex-start"
            flexDirection="column"
          >
            <h3>{t('ru.reports.games_title')}</h3>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap"
              style={{ marginTop: '15px' }}
            >
              <DateTimeRangePicker
                onApply={(e: any, picker: any) => {
                  dispatch(
                    getHallGameReportsList(
                      getUserHalls(),
                      convertToUtc(picker.startDate._d),
                      convertToUtc(picker.endDate._d)
                    )
                  )
                }}
              />
            </Box>
            <div style={{ overflowX: 'auto' }}>
              <GamesReportsList
                setTab={() => {}}
                from={convertToUtc(form.from)}
                to={convertToUtc(form.to)}
                hall_id={getUserHalls()}
              />
            </div>
          </Box>
        </Route>
      )}
      <Route path="/reports/user">
        <UserReports />
      </Route>
    </Switch>
  )
}

export default ReportsPage
