import React from 'react';
import { Box, Chip, Collapse, Grid, ListItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';
import { IPackage } from '../../../interfaces';

const useStyles = makeStyles(() => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: '100%'
    },
    chip: {
        margin: '.5vh',
        padding: '0 1vh'
    },
}));

interface ItGamePackagesProps {
    packages?: IPackage[];
};

const GamePackages: React.FC<ItGamePackagesProps> = ({ packages }) => {
    console.log(packages)
    const [t] = useTranslation();
    const classes: any = useStyles();
    return <Grid item xs={12}>
        <Box>
            <b>{t('ru.halls.fields.packets')}:</b>
            <br />
        </Box>
        {packages?.map((item, id) => <React.Fragment key={id}>
            <ListItem>
                <Box display="flex" alignItems="center">
                    <BorderOuterIcon className={classes.icon} />
                    {(item.package ?? item).name}
                </Box>
            </ListItem>
            <Collapse in={true}>
                <Box className={classes.chips}>
                    {item.games.map((game) => <Chip
                        className={classes.chip}
                        key={game.id}
                        label={
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <span>{game.name}</span>
                            </Box>
                        }
                    />)}
                </Box>
            </Collapse>
        </React.Fragment>)}
    </Grid>;
};

export default GamePackages;