import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import Item from '../Item';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { tableSelector } from '../../../redux/tableReducer';
import { useTranslation } from 'react-i18next';

const TerminalsInGame = () => {
  const [t] = useTranslation();
  const { tables: { count: { game } }, loading } = useSelector(tableSelector);
  return <Item
      title={t('ru.widgets.terminals')}
      icon={<PersonIcon fontSize="large" />}
      value={
        <Box display="flex" alignItems="center" margin="2vh 0">
          <h2>{game}</h2>
        </Box>
      }
      loading={ loading}
    />;
}

export default TerminalsInGame;
