import React, { FunctionComponent } from 'react';
import { IconButton, Menu, MenuList } from '@material-ui/core';
import ViewMenuItem from './view-menu-item';
import UpdateMenuItem from './update-menu-item';
import DeleteMenuItem from './delete-menu-item';
import BlockMenuItem from './block-menu-item';
import CloseSessionMenuItem from './close-session-menu-item';
import BindMenuItem from './bind-menu-item';
import HomeMenuItem from './home-menu-item';
import ReloadMenuItem from './reload-menu-item';
import { authSelector } from '../../../../redux/authReducer';
import { useSelector } from 'react-redux';
import { isConfirmType, CurrentGameType } from '../../../../interfaces';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiList-padding': {
            padding: 0
        }
    }
}));

type BigScreenButtonsProps = {
    id: number,
    name: string,
    isModal: boolean,
    status: boolean,
    currentGame: CurrentGameType | null,
    playerConnection: any,
    showBind: () => void,
    setTableId: (id: number) => void,
    setIsChangeMode: (isChangeMode: boolean) => void,
    setIsConfirm: (isConfirm: isConfirmType) => void,
    setIsBlock: (isConfirm: isConfirmType) => void,
    setIsSession: (isConfirm: isConfirmType) => void,
    setIsReload: (isConfirm: isConfirmType) => void,
    setIsHome: (isConfirm: isConfirmType) => void
};

const SmallScreenButtons: FunctionComponent<BigScreenButtonsProps> = ({ id, name, isModal, status, currentGame, playerConnection,
    showBind, setTableId, setIsChangeMode, setIsConfirm, setIsBlock, setIsSession, setIsReload, setIsHome }) => {

    //используем права
    const { assigments: { items }, role: { item: userRole } } = useSelector(authSelector);

    let canView: boolean = false;
    let canUpdate: boolean = false;
    let canDelete: boolean = false;
    let canBind: boolean = !currentGame && userRole === 'administrator';
    items.forEach((item: string) => {
        if (item === 'viewGamblingTable') {
            canView = true;
        } else if (['updateGamblingTable', 'updateGamblingTableChildren'].includes(item)) {
            canUpdate = true;
        } else if (item === 'deleteGamblingTable') {
            canDelete = true;
        }
    });

    //открытие меню
    const anchorRef: any = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const classes = useStyles();

    return <>
        <IconButton ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} >
            <MoreVertIcon />
        </IconButton>
        <Menu
            className={classes.root}
            autoFocus={false}
            open={open}
            anchorEl={anchorRef.current}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
            onClose={handleClose}
        >
            <MenuList autoFocus={false} onClick={handleClose} style={{ background: '#fff', border: '1px solid #efeff0', padding: 0, display: 'flex' }} >
                {canView && <ViewMenuItem id={id} isModal={isModal} />}
                {canUpdate && <BlockMenuItem id={id} name={name} status={status} setIsBlock={setIsBlock} />}
                {canUpdate && <UpdateMenuItem id={id} setIsChangeMode={setIsChangeMode} />}
                {canDelete && <DeleteMenuItem id={id} setIsConfirm={setIsConfirm} name={name} />}
                {!!currentGame && <CloseSessionMenuItem id={id} name={name} setIsSession={setIsSession} />}
                {canBind && <BindMenuItem id={id} playerConnection={playerConnection} showBind={showBind} setTableId={setTableId} />}
                <HomeMenuItem id={id} name={name} setIsHome={setIsHome} />
                <ReloadMenuItem id={id} name={name} setIsReload={setIsReload} />
            </MenuList>
        </Menu>
    </>;
}

export default SmallScreenButtons;