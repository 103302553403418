import React, { FunctionComponent } from 'react';
import { Box, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const EmptyTable: FunctionComponent<{}> = React.memo(() => {
    const [t] = useTranslation();
    return <Box style={{ width: '100%' }} display="flex" justifyContent="center">
        <FormHelperText>{t('ru.common.no_data')}</FormHelperText>
    </Box>;
});

export default EmptyTable;