import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, IconButton, makeStyles } from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  backBtn: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
}))

const GoBackBtn: React.FC = () => {
  const classes = useStyles()
  const history: any = useHistory()
  const location: any = useLocation()
  const [t] = useTranslation()

  const enhanceGoBack = () => {
    history.push(location.state.prevPaths[location.state.prevPaths.length - 1], {
      prevPaths: [...location.state.prevPaths.slice(0, location.state.prevPaths.length - 1)]
    })
  }

  return (
    <Box className={classes.backBtn} onClick={() => enhanceGoBack()}>
      <IconButton>
        <ArrowBackIosIcon fontSize="small" />
        <span style={{ fontSize: '60%' }}>{t('ru.common.back_btn')}</span>
      </IconButton>
    </Box>
  )
}
export default GoBackBtn
