import styled from 'styled-components'
import { down } from 'styled-breakpoints'
export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: max-content;
  min-width: 16%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 13vh 0 2vh 0;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.06));
  z-index: 99;
  ${down('md')} {
    display: none;
  }
  /* @media (max-width: 957px) {
    display: none;
  } */

  .icon {
    fill: pink;
    stroke: #666;
  }

  .link {
    height: max-content;
  }
`
type MenuItemType = {
  active: boolean
}
export const MenuItem = styled.div`
  position: relative;
  height: 6vh;
  min-height: 45px;
  max-height: 65px;
  width: 100%;
  color: ${(props: MenuItemType) => (props.active ? 'rgb(116, 103, 236)' : '#666')};
  font-size: 85%;
  background: rgb(245, 246, 249);
  background-color: ${(props: MenuItemType) => (props.active ? 'rgb(245,246,249)' : 'white')};
  padding: 1% 5%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-left: ${(props: MenuItemType) => (props.active ? '.7vh solid #7467ec' : '0')};
  ${down('lg')} {
    height: 6vh;
    font-size: 80%;
  }
`
