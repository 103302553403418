import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import HomeIcon from '@material-ui/icons/Home';
import { useTranslation } from 'react-i18next';
import { isConfirmType } from '../../../../interfaces'

type HomeBtnProps = {
    id: number,
    name: string,
    setIsHome: (isConfirm: isConfirmType) => void
}

const HomeBtn: FunctionComponent<HomeBtnProps> = React.memo(({ id, name, setIsHome }) => {
    const [t] = useTranslation();
    return <Tooltip title={String(t('Домой'))} placement="top">
        <Action bg={'#919191'} onClick={() => { setIsHome({ id: id, name: name, open: true }) }} >
            <IconButton>
                <HomeIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </Tooltip>;
});

export default HomeBtn;