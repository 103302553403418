import React, { ReactNode } from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import Preloader from '../../../Common/Preloader'

import { ListItem, Box, FormLabel, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useShowBlock } from '../../../../hooks/useShowBlock'
import Overlay from '../../../Common/Overlay'
import Terminal from '../View'

interface TerminalListProps {}

const TerminalList: React.FC<TerminalListProps> = () => {
  const [t] = useTranslation()

  interface TcProps {
    children: ReactNode
  }
  const Tc: React.FC<TcProps> = ({ children }) => {
    return (
      <ListItem component="span" style={{ justifyContent: 'center', textAlign: 'center' }}>
        {children}
      </ListItem>
    )
  }

  const {
    showBlock: showTerminal,
    isBlockShown: isTerminal,
    hideBlock: hideTerminal,
  } = useShowBlock()

  const renderList = () => {
    return [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }].map((item: any, id: number) => {
      return (
        <React.Fragment key={id}>
          <Item item={item} showTerminal={showTerminal} />
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <Wrapper>
        <div style={{ minWidth: '550px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined">{`Добавить`}</Button>
          </Box>
          <TableHeader style={{ fontSize: '75%' }}>
            <Tc>#</Tc>
            <Tc>{t('Название')}</Tc>
            <Tc>{t('ru.terminals.fields.last_connection')}</Tc>
            <Tc>{t('Дата платежа')}</Tc>
            <Tc>{t('Состояние купюрника')}</Tc>
            <Tc>{t('Наличных принято')}</Tc>
            <Tc>{t('Остаток в диспенсере ')}</Tc>
            <Tc>{t('Прибыль с даты изъятия')}</Tc>
            <Tc>{''}</Tc>
          </TableHeader>
          <Box display="flex" justifyContent="center">
            <FormLabel>{`admin`}</FormLabel>
          </Box>
          <ul className={'Terminal'}>{false ? <Preloader absolute={false} /> : renderList()}</ul>
          <Box display="flex" justifyContent="center">
            <FormLabel>{`user_112`}</FormLabel>
          </Box>
          <Item item={{ id: 5 }} showTerminal={showTerminal} />
          <Item item={{ id: 6 }} showTerminal={showTerminal} />
          {/* {pageCount && <Box
            display="flex"
            justifyContent="center"
            style={{ margin: '10px auto', width: '100%' }}
          >
            <Pagination
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              count={pageCount}
              page={page}
              onChange={(e, value) => {
                setPage(value)
              }}
            />
          </Box>} */}
        </div>
        {isTerminal && (
          <Overlay>
            <Terminal terminal hideTerminal={hideTerminal} />
          </Overlay>
        )}
      </Wrapper>
    </>
  )
}

export default TerminalList
