import React from 'react'
import { Icon } from './styles'

export const Users: React.FC = () => {
    return (
        <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 58" width="40" height="58">
                    <image
                        width="40"
                        height="58"
                        id="img3"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAA6CAYAAADC4Bv9AAAFSklEQVRogcWafWhVdRjHP7qtWoU1cU5r5Swb2XpZ/0QWvYATikqSklwgRKCmCSaYFJQ5e9FSzEiiMntTl9ay0jQKkpQyqBSmlZVBNkNbL+S2FtnLTn88z8/f755+595z7zn33i8cds/zPL/v+e7s9/I8zy4kQyVwK/A20AcEeh0B3gQmJ+RPhLuAvxxRR4FOYC/wr2PvBaaVUtgg5O0YAfcCTZ64ZmChE7emRPrYoQ9cD5wSI74OmQIBsLmIugB5CwGwqoCxHTr28VQVOWjSB+xKwPGtctSnoiiEz5R8TAKOS5RjSyqKHDQo8fYUuL5QrqEpcB3DHCW9Oc9xM4ClwEmO7U7lmpKONMEqJT03Zvx4YBt2i/kaGKy+K9W2JE2B65S0McJfAYwFZgH7HGErgDb9vFNj6/T+qWIIBLgQ2W7WAxuBPY4gc3UAFzvjV6j9Eb0PgJVpCnxGSR8A+j2CDgOvAK3AiAiO3Rr7vP58NE2BT4QEbUTmYyPxThOAl0Mci9MSNwzoBnqAu5V8dQE825AkYjqSQBwGhqQhcK6KatH7AHi9AJ6d2Hk8RT9PTawOWKBkZ+m9WQT54kOswKv184yk4gDOUbJ9wDj9/GoBPNuBf4BLkT9vQPSCyhv3kznBny6AY0OIY3Za4gyux+6HWwsY/yl2m2nJEZsIfcifKl8EwHcpa/FipT5sQh5jWnXMgqIoCqEBWwydHCO+FhjQMcOKJysTs/SB+5FVHoUm4KDGlrwEfRC7Il8CrgVGI2/4Gmz9EgDzSi3OYBeZW4fv6iqXOJBEtAe4HHgYScE2IInAOOBL4O9yCKsD7kDeUF+WuP0aMx84swS6aCEzbTKr05d83oNtibhp2g3AcWmKGgUsAv5wHvQ5cBtwGvCR2qY7Y65T2/caMxGbLLjlwNgkwlpDpANAO3bVGlRj31I9UOWMGenE3ae2duBnJ6ZTf7nquMIWIhPbELyPtNiq1G9Wbp0zphnpdHUhCyNAth0DI/qgY5sEvEHmW10OHB8lrAZ7oPcCj+HvWl2mMW+F7CbbDpASwcUytd/i4WtAsqUfNOYAcLZP4Dca8GTUb+DAVHLD9X4yNscLgN/JnJMB8EsMXpMc9xBaSEvIr9Jqxs5FQ9qNJKPnYZtEy5FuVgDcGJN7NqGsvVYNh2ISGJgy0l2VBvNCvh/z5P5Ex10AUhcEwMw8SWqRenku0pgcQGrmo8CfyPYzE8m+ozoSUZigmtpAWhABmZ2AfDEVu7jMXGxLwDcCZztbjfM6QxgNXBSD8DnlMBM7QPbQXGjE35AyAtvBtjV8b3Cv+gZ7fC66sCUlwG+h+ygcQfbPMOp1/JpcAs1qzHZ2VmCTVwPTNK/JIdCcQAUL7FRflcdncLrGvOjYHiJ62rj4FTnjiyrQnCzzHdvtxEvzowSekaZAs025Yq5QW64uapTAUdg8ILFA0x4+37GdqrYdBQqsRhpU09IQaM7wypC9H/8KjSMwA0kEDiK6MHpPfbXlFDhG/Ws9PtM8H1+AwCHAV0humkjgTeqf4/FNyuLLJbBBx76TVKBpy13l8Zm3my2/LPo+aCq84R5fpfo+KKfAA2QvzLuRVl3UWV50gUEOgYc05oRyCTT/ZNzt8W1R39KIsbEFPkv0wW4EVmR5yGKNedexvcD/E4h8BJp8cC3YYnykJ9DUBrmwCHv2mvIzztcGfkJOHB8CpFVyrIeyB9gUukzRsxmpgyfq4BORZvgm4DXkTQWhqwPpdoU5ffxh+8dqXwayHazzPCBAztJe597Mp6Ge2D6kHu4Pjcl29ZP5hSD32grU/AfTWDwr/eZpTgAAAABJRU5ErkJggg=="
                    />
                <g id="Left Menu">
                    <g id="Menu">
                        <use id="users" href="#img3" transform="matrix(1,0,0,1,0,0)" />
                    </g>
                </g>
            </svg>
        </Icon>
    )
}

export default Users
