import React from 'react'

import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { useSelector, useDispatch } from 'react-redux'

import { FormWrapper } from './styles'
import { gameSelector } from '../../../redux/gameReducer'
import { packageSelector } from '../../../redux/packageReducer'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import { formIsError } from '../../../redux/packageReducer'
import Preloader from '../../Common/Preloader'
import { useForm } from '../../../hooks/useForm'
import ErrorText from '../../Common/ErrorText'
import { IGame, PackageData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'

export const useStyles = makeStyles({
  submitBtn: {
    margin: '1vh',
  },
})

interface PackageFormProps {
  onSubmit: (data: PackageData, hideForm: () => void, packageId?: number | undefined) => void
  cancelBtnEvent: () => void
  cancelBtnText: string
  submitBtnText: string
  id?: number
  name?: string
  games?: IGame[]
  hideForm: () => void
}

const PackageForm: React.FC<PackageFormProps> = ({
  onSubmit,
  cancelBtnEvent,
  cancelBtnText,
  submitBtnText,
  id,
  name = '',
  games = [],
  hideForm,
}) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading: formLoading },
  } = useSelector(packageSelector)
  const {
    games: { items: gamesList },
  } = useSelector(gameSelector)

  const getGamesId = (arr: IGame[]) => {
    let newArr: number[] = []
    arr.forEach((item: IGame) => {
      newArr = [...newArr, item.id]
    })
    return newArr
  }

  const [gamesSelected, setGamesSelected] = React.useState<number[]>(getGamesId(games))

  const { form, handleInputChange } = useForm({
    name: name,
  })

  const data: PackageData = {
    name: form.name,
    games: gamesSelected,
  }

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      cancelBtnEvent()
    }
    if (e.code === 'Enter') {
      id && onSubmit(data, cancelBtnEvent, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  return formLoading ? (
    <Preloader />
  ) : (
    <FormWrapper
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(data, hideForm, id)
      }}
    >
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.textFields}
            label={t('ru.packages.fields.name')}
            type="text"
            value={form.name}
            name="name"
            onChange={handleInputChange}
            size="small"
            error={!!error && !!trowError(error, 'name')}
            helperText={!!error && trowErrorMessage(error, 'name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <div className={classes.permissions}>
              <Grid container>
                {gamesList &&
                  gamesList.map((game) => (
                    <Grid key={game.name} item xs={12} sm={6} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!gamesSelected.find((i: number) => i === game.id)}
                            color="default"
                            onChange={() => {
                              if (gamesSelected.includes(game.id)) {
                                let index = gamesSelected.indexOf(game.id)
                                setGamesSelected([
                                  ...gamesSelected.slice(0, index),
                                  ...gamesSelected.slice(index + 1, gamesSelected.length),
                                ])
                              } else {
                                setGamesSelected([...gamesSelected, game.id])
                              }
                            }}
                            name={game.name}
                          />
                        }
                        label={
                          <span style={{ fontSize: '70%', lineHeight: '1' }}>
                            {gamesList.find((i: IGame) => i.name === game.name)?.name}
                          </span>
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              className={classes.submitBtn}
              variant="contained"
              onClick={cancelBtnEvent}
            >
              {cancelBtnText}
            </Button>
            <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>
    </FormWrapper>
  )
}

export default PackageForm
