import React, { FunctionComponent } from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import editPng from '../../../../assets/images/edit.png';
import { useHistory } from 'react-router-dom';

type UpdateMenuItemProps = {
    id: number,
    setIsChangeMode: (isChangeMode: boolean) => void
};

function cmp(prevProps: UpdateMenuItemProps, nextProps: UpdateMenuItemProps) {
    return prevProps.id === nextProps.id;
};

const UpdateMenuItem: FunctionComponent<UpdateMenuItemProps> = React.memo(({ id, setIsChangeMode }) => {
    const history = useHistory();
    return <MenuItem onClick={() => { setIsChangeMode(true); history.push({ pathname: `tables`, search: `?table_id=${id}` }); }} >
        <Action bg={'#919191'}>
            <IconButton>
                <img src={editPng} alt="icon" />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default UpdateMenuItem;