import React from 'react'

import { useForm } from '../../../hooks/useForm'

import { Box, FormControl, Grid, Checkbox, FormControlLabel } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { useSelector, useDispatch } from 'react-redux'

import { FormWrapper, useStyles } from './styles'
import { permissionSelector } from '../../../redux/permissionReducer'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import { roleSelector, formIsError } from '../../../redux/roleReducer'
import Preloader from '../../Common/Preloader'
import ErrorText from '../../Common/ErrorText'
import { IPermission, RoleData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'

interface RoleFormProps {
  onSubmit: (data: RoleData, hideForm: () => void, name: string) => void
  hideForm: () => void
  cancelBtnEvent: () => void
  cancelBtnText: string
  submitBtnText: string
  name?: string
  description?: string
  permissions?: string[]
}

const RoleForm: React.FC<RoleFormProps> = ({
  onSubmit,
  cancelBtnEvent,
  cancelBtnText,
  submitBtnText,
  name = '',
  description = '',
  permissions = [],
  hideForm,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading: formLoading },
  } = useSelector(roleSelector)
  const { items: permissionsList } = useSelector(permissionSelector)

  const { form, handleInputChange } = useForm({
    name: name,
    description: description,
  })

  const [permissionsSelected, setPermissionsSelected] = React.useState<string[]>(permissions)

  const data: RoleData = {
    name: form.name,
    description: form.description,
    permissions: permissionsSelected,
  }

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
      // dispatch(roleSuccess(null))
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      cancelBtnEvent()
    }
    if (e.code === 'Enter') {
      onSubmit(data, cancelBtnEvent, name)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  return formLoading ? (
    <Preloader absolute={false} />
  ) : (
    <FormWrapper
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(data, hideForm, name)
      }}
    >
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.textFields}
            label={t('ru.roles.fields.name')}
            type="text"
            value={form.name}
            name="name"
            onChange={handleInputChange}
            size="small"
            error={!!trowError(error, 'name')}
            helperText={trowErrorMessage(error, 'name')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.textFields}
            label={t('ru.roles.fields.description')}
            type="text"
            value={form.description}
            name="description"
            onChange={handleInputChange}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <div className={classes.permissions}>
              <Grid container>
                {permissionsList.map((permission) => (
                  <Grid key={permission.name} item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!permissionsSelected.find((i: string) => i === permission.name)}
                          color="default"
                          onChange={() => {
                            if (permissionsSelected.includes(permission.name)) {
                              let index = permissionsSelected.indexOf(permission.name)
                              setPermissionsSelected([
                                ...permissionsSelected.slice(0, index),
                                ...permissionsSelected.slice(index + 1, permissionsSelected.length),
                              ])
                            } else {
                              setPermissionsSelected([...permissionsSelected, permission.name])
                            }
                          }}
                          name={permission.name}
                        />
                      }
                      label={
                        <span style={{fontSize: '70%', lineHeight: '1'}}>
                        {permissionsList.find((i: IPermission) => i.name === permission.name)
                          ? permissionsList?.find((i: IPermission) => i.name === permission.name)
                              ?.description
                          : (permission.name as string)}</span>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              className={classes.submitBtn}
              variant="contained"
              onClick={cancelBtnEvent}
            >
              {cancelBtnText}
            </Button>
            <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>
    </FormWrapper>
  )
}

export default RoleForm
