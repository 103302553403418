import React from 'react'
import CashboxList from '../components/Cashboxes/List'

const CashboxPage = () => {
    return (
        <>
            <CashboxList />
        </>
    )
}

export default CashboxPage
