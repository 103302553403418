import axios from 'axios'
import { LoginData } from '../interfaces'

export const signIn = async (data: LoginData) => {
    return axios.post('auth/sign-in', data)
}

export const signOut = async () => {
    return axios.post('auth/sign-out')
}

export const checkUser = async () => {
    return axios.get('user',
    {
        params: { expand: 'cashbox,pusher'},
    })
}

export const reLogin = async (user_id: number) => {
    return axios.get(`user/${user_id}/login`)
}

export const alertBlock = async () => {
    return axios.get(`block`)
}