import { createSlice } from '@reduxjs/toolkit'
import { fetchLimitList, addLimit, changeLimit, deleteLimit } from '../services/Limit'
import { ILimit, ErrorType } from '../interfaces'
import { AppThunk } from './store'
import { changeArr } from '../utils/changeArr'

interface limitInitialState {
  items: ILimit[]
  loading: boolean
  error: ErrorType[] | null
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: limitInitialState = {
  items: [],
  loading: false,
  error: null,
  form: {
    loading: false,
    error: null,
  },
}

export const slice = createSlice({
  name: 'limit',
  initialState,
  reducers: {
    limitListIsLoading: (state) => {
      state.loading = true
    },
    limitListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    limitListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    limitItemDelete: (state, { payload }) => {
      const index = state.items.findIndex((i) => i.id === payload)
      state.items = [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1, state.items.length),
      ]
    },
    limitItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    limitItemChange: (state, { payload }) => {
      state.items = [...changeArr(state.items, payload.id, payload)]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSuccess: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getLimitList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(limitListIsLoading())

    return fetchLimitList()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(limitListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(limitListFailure([res.data.data]))
          } else {
            dispatch(limitListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(limitListFailure(err)))
  }
}

export const addLimitItem = (data: ILimit, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return addLimit(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(limitItemAdd(res.data.data))
          dispatch(formSuccess())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const changeLimitItem = (data: ILimit, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return changeLimit(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(limitItemChange(res.data.data))
          dispatch(formSuccess())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const deleteLimitItem = (id: number, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return deleteLimit(id)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data)
          dispatch(limitItemDelete(id))
          dispatch(formSuccess())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  limitListIsLoading,
  limitListSuccess,
  limitListFailure,
  limitItemDelete,
  limitItemAdd,
  limitItemChange,
  formIsLoading,
  formSuccess,
  formIsError,
} = slice.actions

interface IState {
  limit: limitInitialState
}

export const limitSelector = (state: IState) => state.limit

export default slice.reducer
