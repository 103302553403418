import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import TreeItem from '@material-ui/lab/TreeItem'

export const Wrapper = styled.div`
  position: relative;
  padding: 3vh;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 0.05);
  margin: 8px 0 4px 0;
  h3 {
    font-size: 95%;
  }

`

// function TransitionComponent(props: any) {
//   const style = useSpring({
//     from: { opacity: 0 },
//     to: { opacity: 1 },
//   })

//   return (
//     <animated.div style={style}>
//       <Collapse {...props} />
//     </animated.div>
//   )
// }

export const FadeIn = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  animation-name: fadeIn;
`
type TreeItemType = {
  showline?: any
}
export const StyledTreeItem = withStyles(() => ({
  iconContainer: {
    position: 'relative',
    '& .close': {
      opacity: 0.3,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '-18px',
      width: '28px',
      height: 0,
      borderTop: (props: TreeItemType) => !props.showline ? '2px solid #eaeaea' : 'none',
    },

  },
  group: {
    position: 'relative',
    marginLeft: 7,
    paddingLeft: 18,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '100%',
      top: '-12.5px',
      left: 0,
      bottom: 0,
      width: 0,
      borderLeft: '2px solid #eaeaea',
    },


    '& .MuiCollapse-wrapper > div > div:last-child ul:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '100%',
      left: '-25px',
      bottom: '10px',
      width: 0,
      border: '2px solid #fff',
    },

  },
}))((props: any) => (
  <FadeIn>
    <TreeItem {...props} />
  </FadeIn>
))

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
    minРeight: '80vh',
    height: 'max-content',
    marginTop: '3vh',




  },
  treeItem: {
    fontSize: '85%',
    color: '#3d3d3d',
    '& span': {
      margin: '0 2px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
})
