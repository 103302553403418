import React from 'react'
import { Wrapper } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, IconButton } from '@material-ui/core'
import RoleForm from '../Form'
import { RoleData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddRoleProps {
  hideAddRole: () => void
  handlerAddRole: (data: RoleData, hideForm: () => void) => void
}

const AddRole: React.FC<AddRoleProps> = ({ hideAddRole, handlerAddRole }) => {
  const classes = useStyles()
  const history = useHistory()
  const [t] = useTranslation()
  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddRole}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.roles.add_form')}</h3>
      <RoleForm
        onSubmit={handlerAddRole}
        cancelBtnEvent={hideAddRole}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
        hideForm={(role?: string) => {
          hideAddRole()
          history.push(`/admin/roles?role=${role}`)
        }}
      />
    </Wrapper>
  )
}

export default AddRole
