import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';

type FieldJackpotMigrationProps = {
    error: boolean;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldJackpotMigrationProps, cur: FieldJackpotMigrationProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const OPTIONS = [0, 5, 10, 15, 20, 25, 30];

const FieldJackpotMigration: FunctionComponent<FieldJackpotMigrationProps> = React.memo(({ error, onChange, value, formHelperText }) => {
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <NativeSelect onChange={onChange} value={value}>
                {OPTIONS.map(sum => <option key={sum} value={sum}>{sum}%</option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldJackpotMigration;