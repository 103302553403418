import React from 'react'
import { Wrapper, LoginForm, LoginInput, LoginSubmit } from './styles'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { useForm } from '../../hooks/useForm'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector, loginHandler } from '../../redux/authReducer'

import userIcon from '../../assets/images/user.png'
import passIcon from '../../assets/images/pass.png'

import { FormHelperText, CircularProgress, makeStyles } from '@material-ui/core'
import { trowErrorMessage } from '../../utils/errors'
import PasswordField from '../Common/PasswordField'

import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaProvider,
  ReCaptchaV2,
  TReCaptchaV2Callback
} from 'react-recaptcha-x';

const useStyles = makeStyles(() => ({
  password: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: 'none'
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: 'none'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 'none'
    }
  }
}));

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { form: formLoading } = useSelector(authSelector);

  const { form, handleInputChange, setFormItem } = useForm({
    login: '',
    password: '',
    recaptcha: '',
  });

  const v2Callback: TReCaptchaV2Callback = ( token: string | false | Error ): void => {
    if (typeof token === 'string') {
      console.log('this is the token', token);
      setFormItem('recaptcha', token);
    } else if (typeof token === 'boolean' && !token) {
      console.log('token has expired, user must check the checkbox again');
    } else if (token instanceof Error) {
      console.log('error. please check your network connection');
    }
  };

  return <Wrapper>
    <ReCaptchaProvider siteKeyV2="6LeAHQ8kAAAAAH3KCQbNgRIL_5fsumT4Hg10dCB9" langCode="ru" hideV3Badge={false}>
      <LoginForm onSubmit={(e) => { e.preventDefault(); if(true || !form.recaptcha || form.recaptcha === 1) {form.recaptcha = 1;} !formLoading.loading && dispatch(loginHandler(form, () => history.push('/'))); }}>
        <LoginInput>
          <img src={userIcon} alt="icon" />
          <input name="login" onChange={handleInputChange} placeholder="Username" />
        </LoginInput>
        <LoginInput spacing={0.1}>
          <img src={passIcon} alt="icon" />
          <PasswordField name="password" onChange={handleInputChange} type="password" placeholder="*********" className={classes.password} variant="outlined" />
        </LoginInput>
        {!!formLoading.error && trowErrorMessage(formLoading.error, 'password') ? <FormHelperText>{trowErrorMessage(formLoading.error, 'password')}</FormHelperText> : ''}
        {!!formLoading.error && trowErrorMessage(formLoading.error, 'login') ? <FormHelperText>{trowErrorMessage(formLoading.error, 'login')}</FormHelperText> : ''}
        <div style={{display: formLoading.error ? 'none' : 'none'}}>
          <span>Подтвердите, что вы не робот:</span>
          <ReCaptchaV2 callback={v2Callback} theme={EReCaptchaV2Theme.Light} size={EReCaptchaV2Size.Normal} id="recaptcha" tabindex={0} />
        </div>
        <LoginSubmit type="submit">
          {formLoading.loading ? <CircularProgress style={{ color: '#fff' }} size={17} /> : 'LOGIN'}
        </LoginSubmit>
      </LoginForm>
    </ReCaptchaProvider>
  </Wrapper>
}

export default withRouter(Login)
