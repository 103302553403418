import React, { FunctionComponent } from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import BlockIcon from '@material-ui/icons/Block';
import { isConfirmType } from '../../../../interfaces';

type BlockMenuItemProps = {
    id: number,
    name: string,
    status: boolean,
    setIsBlock: (isConfirm: isConfirmType) => void
};

function cmp(prevProps: BlockMenuItemProps, nextProps: BlockMenuItemProps) {
    return prevProps.id === nextProps.id;
};

const BlockMenuItem: FunctionComponent<BlockMenuItemProps> = React.memo(({ id, name, status, setIsBlock }) => {
    return <MenuItem onClick={() => setIsBlock({ id: id, name: name, open: true })} >
        <Action bg={'#919191'}>
            <IconButton>
                <BlockIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default BlockMenuItem;