import { Box, FormControl, FormHelperText, FormLabel, NativeSelect } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { trowError, trowErrorMessage } from "../../../utils/errors";
import { playerSelector } from "../../../redux/playerReducer";
import { useSelector } from "react-redux";

type RenderSelectProps = {
    form: any;
    setFormItem: (name: string, value: any) => void;
    field: string;
    options: {
        id: string | number,
        value: string | number
    }[];
};

const RenderSelect: React.FC<RenderSelectProps> = ({ field, options, form, setFormItem }) => {
    const [t] = useTranslation();
    const { form: { error } } = useSelector(playerSelector);
    return <Box display="flex" alignItems="center" justifyContent="center" margin="2vh">
        <FormLabel>{t("ru.players.fields." + field)}: </FormLabel>
        <FormControl error={!!trowError(error, field)}>
            <NativeSelect value={form[field]} onChange={(e) => setFormItem(field, e.target.value)}>
                {!form[field] && <option value=""></option>}
                {options.map(opt => <option value={opt.id} key={opt.id}>{opt.value}</option>)}
            </NativeSelect>
            <FormHelperText>{trowErrorMessage(error, field)}</FormHelperText>
        </FormControl>
    </Box>;
}

export default RenderSelect;