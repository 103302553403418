import { IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import EnhanceLink from '../../../Common/EnhanceLink'
import { ListItem } from '../styles'
import visionPng from '../../../../assets/images/vision.png'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import { Action } from '../../../Users/List/styles'
import { useTranslation } from 'react-i18next'
import { authSelector } from '../../../../redux/authReducer'
import { useSelector } from 'react-redux'
import { prettyNum } from '../../../../utils/prettyNum'

interface ReportsListItemProps {
  cursor?: string
  id?: number | undefined
  name: string | undefined
  balance?: string | undefined
  tables?: string | undefined
  credit?: string | undefined
  debit?: string | undefined
  income?: string | undefined
  payout?: string | undefined
  total?: boolean | undefined
  hall_id?: number
  table_id?: number
  setSelectedHall?: (id: number) => void
  isModal?: boolean
  reportBtnLock?: boolean
}

const Item: React.FC<ReportsListItemProps> = ({
  id,
  name,
  balance,
  tables,
  credit,
  debit,
  setSelectedHall,
  hall_id,
  table_id,
  isModal,
  reportBtnLock,
}) => {
  const [t] = useTranslation()

  const {
    role: { item: userRole },
  } = useSelector(authSelector)

  const benefit = Number(debit) - Number(credit)
  const outPercent =
    credit && debit && ((Number(credit) / 100 / (Number(debit) / 100)) * 100).toFixed(2)

  const payout =
    String(outPercent) === 'Infinity'
      ? '∞'
      : (String(outPercent) !== 'NaN' ? outPercent : '0') + '%'

  return (
    <ListItem bg={'#fff'} cursor={'default'}>
      <div>{id ? id : ''}</div>
      <div>{name}</div>
      <div>{prettyNum(Number(balance))}</div>
      <div>{prettyNum(Number(debit))}</div>
      <div>{prettyNum(Number(credit))}</div>
      <div>{benefit !== 0 && String(benefit) !== 'NaN' ? prettyNum(Number(benefit)) : '0.00'}</div>
      {userRole && userRole !== 'cashier' && (
        <div>{Number(credit) > 0 && Number(debit) === 0 ? '∞' : payout}</div>
      )}
      {!isModal && (
        <div>
          {(table_id || hall_id) && (
            <>
              <Tooltip title={String(t('ru.common.view_tooltip'))} placement="top">
                <div>
                  <EnhanceLink
                    pathname={hall_id ? `/halls` : `/tables`}
                    search={hall_id ? `?hall_id=${hall_id}` : `?table_id=${table_id}`}
                  >
                    <Action bg={'#7467ec'}>
                      <IconButton>
                        <img src={visionPng} alt="icon" />
                      </IconButton>
                    </Action>
                  </EnhanceLink>
                </div>
              </Tooltip>
              {!reportBtnLock && (
                <Tooltip title={String(t('Отчет'))} placement="top">
                  <Action
                    bg={'#919191'}
                    onClick={() => id && setSelectedHall && setSelectedHall(id)}
                  >
                    <IconButton>
                      <EqualizerIcon fontSize="small" style={{ color: '#fff', fontSize: '60%' }} />
                    </IconButton>
                  </Action>
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}
    </ListItem>
  )
}

export default Item
