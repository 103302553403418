import { Box, ListItem, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from '../../../hooks/useForm'
import { getUserReport, reportSelector } from '../../../redux/reportReducer'
import { convertToUtc, date } from '../../../utils/date'
import DateTimeRangePicker from '../../Common/DateTimeRangePicker'
import Preloader from '../../Common/Preloader'
import { Wrapper, TableHeader } from './styles'
import Item from './Item'
import { getUsersList, userSelector } from '../../../redux/userReducer'

const UserReports: React.FC = () => {
  const dispatch = useDispatch()

  const {
    reports: {
      user: { data: userReport, loading },
    },
  } = useSelector(reportSelector)
  const {
    users: { data: users },
  } = useSelector(userSelector)

  React.useEffect(() => {
    !users.length && dispatch(getUsersList())
    // eslint-disable-next-line
  }, [])

  const { form, setFormItem } = useForm({
    from: convertToUtc(date() + ' 00:00:00'),
    to: convertToUtc(date() + ' 23:59:59'),
  })

  React.useEffect(() => {
    dispatch(getUserReport(form.from, form.to))
    // eslint-disable-next-line
  }, [])

  console.log(userReport)
  return (
    <Wrapper>
      <Box>
        <DateTimeRangePicker
          onApply={(e: any, picker: any) => {
            dispatch(
              getUserReport(convertToUtc(picker.startDate._d), convertToUtc(picker.endDate._d))
            )
            setFormItem('from', convertToUtc(picker.startDate._d))
            setFormItem('to', convertToUtc(picker.endDate._d))
          }}
        />
      </Box>
      <div style={{ minWidth: '900px' }}>
        <TableHeader>
          <ListItem component="span" style={{ justifyContent: 'center' }}>
            {'Создано'}
          </ListItem>
          <ListItem component="span" style={{ justifyContent: 'center' }}>
            {'Вдаделец'}
          </ListItem>
          <ListItem component="span" style={{ justifyContent: 'center' }}>
            {'Сумма'}
          </ListItem>
          <ListItem component="span" style={{ justifyContent: 'center' }}>
            {'Баланс до'}
          </ListItem>
          <ListItem component="span" style={{ justifyContent: 'center' }}>
            {'Баланс после'}
          </ListItem>
          <ListItem component="span" style={{ justifyContent: 'center' }}>
            {'Тип'}
          </ListItem>
        </TableHeader>
        <ul>
          {loading ? (
            <Preloader absolute={false} />
          ) : (
            userReport?.map((user: any, id: number) => {
              return <Item key={id} item={user} />
            })
          )}
          {!userReport?.length &&
            <Box style={{ width: '100%' }} display='flex' justifyContent='center'>
              <Typography>Нет данных</Typography>
            </Box>
          }
        </ul>
      </div>
    </Wrapper>
  )
}

export default UserReports
