import { Box, ListItem } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInfoReports, reportSelector } from '../../../redux/reportReducer'
import { Wrapper } from './styles'
import Preloader from '../../Common/Preloader'
import { convertToUtc, date } from '../../../utils/date'
import { useForm } from '../../../hooks/useForm'
import { hallSelector } from '../../../redux/hallReducer'
import { treeInfoSelector } from '../../../redux/treeInfoReducer'
import { IHall } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import Item from '../../Reports/List/Item'
import { TableHeader } from '../../Reports/List/styles'
import { tableSelector } from '../../../redux/tableReducer'
import { userSelector } from '../../../redux/userReducer'
import { authSelector } from '../../../redux/authReducer'
import DateTimeRangePicker from '../../Common/DateTimeRangePicker'

const ReportWidget: React.FC = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const { form, setFormItem } = useForm({
    from: date(),
    to: date(),
  })

  const {
    role: { item: userRole },
  } = useSelector(authSelector)

  const {
    reports: {
      info: { data: report, loading },
    },
  } = useSelector(reportSelector)

  const {
    halls: { data: hallsList },
  } = useSelector(hallSelector)

  const { data: admin } = useSelector(authSelector)

  const {
    users: { data: users },
  } = useSelector(userSelector)

  const {
    tables: { data: tables },
  } = useSelector(tableSelector)

  const { component, data } = useSelector(treeInfoSelector)

  const getUserHalls = (user_id: number) => {
    let hallsId: number[] = []
    if (!!hallsList.length) {
      const userHalls = hallsList.filter((i: IHall) => i.owner_id === user_id)
      userHalls.forEach((hall: IHall) => {
        hallsId = [...hallsId, hall.id]
      })
    }
    return hallsId
  }

  const rootParent = (user_id: number): any => {
    if (!!users) {
      const currentUser = users.find((u) => u.id === user_id)
      if (!!currentUser) {
        if (currentUser.parent_id === null || !users.find((u) => u.id === currentUser.parent_id)) {
          return currentUser.id
        } else {
          return currentUser && currentUser.parent_id && rootParent(currentUser.parent_id)
        }
      }
    }
  }
  const getPartners = () => {
    let items: number[] = []
    users.forEach((item) => {
      if (admin && admin.id === rootParent(item.id)) {
        items = [...items, item.id]
      }
    })
    return items
  }

  const getPartnersHallsId = () => {
    let hallsId: number[] = []
    getPartners().forEach((item) => {
      hallsId = [...hallsId, ...getUserHalls(item)]
    })
    return hallsId
  }

  const getReportsElements = (component: string) => {
    switch (component) {
      case 'user':
        return data.id !== admin?.id ? getUserHalls(data.id) : getPartnersHallsId()
      case 'hall':
        return [data.id]
      case 'table':
        return [data.id]
      default:
        break
    }
  }

  React.useEffect(() => {
    hallsList.length &&
      data &&
      component &&
      dispatch(
        getInfoReports(
          component,
          getReportsElements(component),
          convertToUtc(`${form.from} 00:00:00`),
          convertToUtc(`${form.to} 23:59:59`)
        )
      )
    // eslint-disable-next-line
  }, [data, hallsList])

  React.useEffect(() => {
    if (Date.parse(form.to) < Date.parse(form.from)) {
      setFormItem('date_for_drawing', form.from)
    }
    // eslint-disable-next-line
  }, [form.date_end_draw])

  React.useEffect(() => {
    if (Date.parse(form.from) > Date.parse(form.date_end_draw)) {
      setFormItem('date_end_draw', form.from)
    }
    // eslint-disable-next-line
  }, [form.date_for_drawing])

  const getReportList = () => {
    if (report && report.gambling_tables && !!report.gambling_tables.length) {
      return report.gambling_tables.map((table: any) => {
        return (
          <Item
            key={table.gambling_table_id}
            id={table.gambling_table_id}
            name={tables?.find((i) => i.id === table.gambling_table_id)?.name}
            table_id={table.gambling_table_id}
            balance={table.end_balance}
            credit={table.credit}
            debit={table.debit}
            setSelectedHall={() => {}}
            total={false}
            isModal
          />
        )
      })
    }
    if (report && report.halls && !!report.halls.length) {
      return report.halls.map((hall: any) => {
        return (
          <Item
            key={hall.hall_id}
            id={hall.hall_id}
            name={hallsList?.find((i) => i.id === hall.hall_id)?.name}
            hall_id={hall.hall_id}
            balance={hall.end_balance}
            credit={hall.credit}
            debit={hall.debit}
            setSelectedHall={() => {}}
            total={false}
            isModal
          />
        )
      })
    }
  }

  return (
    <Wrapper>
      <h3>{t('ru.reports.main_title')}</h3>
      <br />
      <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap">
        <DateTimeRangePicker
          onApply={(e: any, picker: any) => {
            component &&
              dispatch(
                getInfoReports(
                  component,
                  getReportsElements(component),
                  convertToUtc(picker.startDate._d),
                  convertToUtc(picker.endDate._d)
                )
              )
          }}
        />
      </Box>

      <Box
        style={{ width: '100%', paddingTop: '25px' }}
        display="flex"
        flexDirection="column"
        alignContent="center"
        flexWrap="wrap"
      >
        <Box style={{ width: '100%', overflowX: 'auto' }}>
          <div style={{ minWidth: '700px', background: '#f9f9f9' }}>
            <TableHeader>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                id
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.hall')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.balance')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.debit')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.credit')}
              </ListItem>
              <ListItem component="span" style={{ justifyContent: 'center' }}>
                {t('ru.reports.fields.benefit')}
              </ListItem>
              {userRole && userRole !== 'cashier' && (
                <ListItem component="span" style={{ justifyContent: 'center' }}>
                  {t('ru.reports.fields.percent')}
                </ListItem>
              )}
            </TableHeader>
            <ul>
              {loading ? <Preloader absolute={false} /> : getReportList()}
              {!report?.gambling_tables?.length && !report?.halls?.length && (
                <Box style={{ width: '100%' }} display="flex" justifyContent="center">
                  Нет данных
                </Box>
              )}
              {!!report?.gambling_tables?.length ||
                (!!report?.halls?.length && (
                  <Item
                    name={'Итог:'}
                    balance={report?.end_balance}
                    credit={report?.total_credit}
                    debit={report?.total_debit}
                    total
                    isModal
                  />
                ))}
            </ul>
          </div>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default ReportWidget
