import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useTranslation } from 'react-i18next';
import { isConfirmType } from '../../../../interfaces'

type CloseSessionBtnProps = {
    id: number,
    name: string,
    setIsSession: (isConfirm: isConfirmType) => void
}

function cmp(prevProps: CloseSessionBtnProps, nextProps: CloseSessionBtnProps) {
    return prevProps.id === nextProps.id;
}

const CloseSessionBtn: FunctionComponent<CloseSessionBtnProps> = React.memo(({ id, name, setIsSession }) => {
    const [t] = useTranslation();
    return <Tooltip title={String(t('ru.common.close_session'))} placement="top">
        <Action bg={'#919191'} onClick={() => { setIsSession({ id: id, name: name, open: true }) }} >
            <IconButton>
                <DoneAllIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default CloseSessionBtn;