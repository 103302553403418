import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  fetchProfilesList,
  deleteProfile,
  addProfileImage,
  deleteProfileImage,
  changeProfile,
  addProfile,
  QueryType,
} from '../services/Profile'
import { changeArr } from '../utils/changeArr'
import { AppThunk } from './store'
import { ProfileData, IProfile, ImagesData, ImageType, ErrorType } from '../interfaces'

interface ProfileState {
  items: IProfile[]
  loading: boolean
  error: ErrorType[]
  form: {
    loading: boolean
    error: ErrorType[]
  }
  imageUpload: {
    loading: boolean
    error: ErrorType[]
  }
}

export const initialState: ProfileState = {
  items: [],
  loading: false,
  error: [],
  form: {
    loading: false,
    error: [],
  },
  imageUpload: {
    loading: false,
    error: [],
  },
}
type AddImagePayloadType = {
  id: number
  images: ImageType[]
}

export const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileListIsLoading: (state) => {
      state.loading = true
    },
    profileListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.items = payload
    },
    profileListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    profileItemDelete: (state, { payload }) => {
      state.items = [...state.items.filter((i) => i.id !== payload)]
    },
    profileItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    profileItemChange: (state, { payload }) => {
      state.items = [...changeArr(state.items, payload.id, payload)]
    },
    profileAddImage: (state, action: PayloadAction<AddImagePayloadType>) => {
      const itemId = state.items.findIndex((i) => i.id === action.payload.id)
      state.items = [
        ...state.items.slice(0, itemId),
        {
          ...state.items.find((i) => i.id === action.payload.id),
          images: [action.payload.images],
        },
        ...state.items.slice(itemId + 1, state.items.length),
      ] as IProfile[]
    },
    profileDeleteImage: (state, action: PayloadAction<number>) => {
      const itemId = state.items.findIndex((i) => i.id === action.payload)
      state.items = [
        ...state.items.slice(0, itemId),
        {
          ...state.items.find((i: IProfile) => i.id === action.payload),
          images: [],
        },
        ...state.items.slice(itemId + 1, state.items.length),
      ] as IProfile[]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
    imageUploadIsLoading: (state) => {
      state.imageUpload.loading = true
    },
    imageUploadSucces: (state) => {
      state.imageUpload.loading = false
      state.imageUpload.error = []
    },
    imageUploadIsError: (state, { payload }) => {
      state.imageUpload.loading = false
      state.imageUpload.error = payload
    },
  },
})

export const getProfilesList = (query?: QueryType): AppThunk => {
  return async (dispatch) => {
    dispatch(profileListIsLoading())

    return fetchProfilesList(query)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(profileListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(profileListFailure([res.data.data]))
          } else {
            dispatch(profileListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(profileListFailure(err)))
  }
}

export const handlerDeleteProfile = (id: number): AppThunk => {
  return async (dispatch) => {
    return (
      id &&
      deleteProfile(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(profileItemDelete(id))
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(profileListFailure([res.data.data]))
            } else {
              dispatch(profileListFailure(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(profileListFailure(err)))
    )
  }
}

export const handlerAddImage = (
  data: ImagesData,
  profile_id: number | undefined,
  setImg: (img: string) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(imageUploadIsLoading())
    return (
      data &&
      profile_id &&
      addProfileImage(data, profile_id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(
              profileAddImage({
                id: profile_id,
                images: res.data.data[0],
              })
            )
            setImg('')
            dispatch(imageUploadSucces())
          } else if (res.data.code !== 200) {
            dispatch(imageUploadIsError(res.data.data))
          }
        })
        .catch((err) => dispatch(imageUploadIsError(err)))
    )
  }
}

export const handlerDeleteImage = (profile_id: number, image_id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(imageUploadIsLoading())
    return deleteProfileImage(profile_id, image_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(profileDeleteImage(profile_id))
          dispatch(imageUploadSucces())
        } else if (res.data.code !== 200) {
          dispatch(imageUploadIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(imageUploadIsError(err)))
  }
}

export const handlerAddProfile = (data: ProfileData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addProfile(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(profileItemAdd(res.data.data))
          hideForm()
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerChangeProfile = (
  data: ProfileData,
  hideForm: () => void,
  id: number
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return changeProfile(data, id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(profileItemChange(res.data.data))
          hideForm()
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  profileListIsLoading,
  profileListSuccess,
  profileListFailure,
  profileItemAdd,
  profileItemChange,
  profileItemDelete,
  profileAddImage,
  profileDeleteImage,
  formIsLoading,
  formSucces,
  formIsError,
  imageUploadIsLoading,
  imageUploadSucces,
  imageUploadIsError,
} = slice.actions

interface IState {
  profile: ProfileState
}

export const profileSelector = (state: IState) => state.profile

export default slice.reducer
