import React, { ChangeEvent } from 'react'

import { useForm } from '../../../hooks/useForm'
import { useShowBlock } from '../../../hooks/useShowBlock'

import {
  Tooltip,
  Box,
  FormControl,
  NativeSelect,
  FormHelperText,
  Grid,
  Select,
  MenuItem,
  Input,
  InputLabel,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { useSelector, useDispatch } from 'react-redux'

import { FormWrapper, useStyles } from './styles'
import Overlay from '../../Common/Overlay/index'
import { manufacturerSelector } from '../../../redux/manufacturerReducer'
import { packageSelector } from '../../../redux/packageReducer'
import ManufacturerForm from './Manufacturer'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import { formIsError, gameSelector } from '../../../redux/gameReducer'
import Preloader from '../../Common/Preloader'
import { IPackage, GameData, IManufacturer } from '../../../interfaces'
import ErrorText from '../../Common/ErrorText'
import SwitchStatus from '../../Common/SwitchStatus/index'
import { useTranslation } from 'react-i18next'

interface GameFormProps {
  onSubmit: (data: GameData, hideForm: () => void, id: number | undefined) => void
  cancelBtnEvent: () => void
  cancelBtnText: string
  submitBtnText: string
  id?: number | undefined
  name?: string
  packages?: IPackage[]
  status?: number
  manufacturer_id?: number
  hideForm: (game_id?: number) => void
}

const GameForm: React.FC<GameFormProps> = ({
  onSubmit,
  cancelBtnEvent,
  cancelBtnText,
  submitBtnText,
  id,
  name,
  packages,
  manufacturer_id,
  status,
  hideForm,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading: formLoading },
  } = useSelector(gameSelector)
  const { items: ManufacturerList } = useSelector(manufacturerSelector)
  const {
    packages: { items: PackageList },
  } = useSelector(packageSelector)

  const { form, handleInputChange, clearForm, setFormItem } = useForm({
    name: name,
    manufacturer_id: manufacturer_id,
    status: status,
  })

  const getPackagesId = (arr: IPackage[]) => {
    let newArr: number[] = []
    arr.forEach((item: IPackage) => {
      newArr = [...newArr, Number(item.id)]
    })
    return newArr
  }

  const [packagesSelected, setPackagesSelected] = React.useState<number[]>(
    getPackagesId(packages ? packages : [])
  )
  const [manufacturer, setManufacturer] = React.useState<number>(
    manufacturer_id ? manufacturer_id : -1
  )

  const data: GameData = {
    name: form.name,
    package: packagesSelected,
    manufacturer_id: manufacturer,
    status: !!form.status ? 10 : 0,
  }

  React.useEffect(() => {
    return () => clearForm()
    // eslint-disable-next-line
  }, [])

  const {
    showBlock: showManufacturer,
    isBlockShown: isManufacturer,
    hideBlock: hideManufacturer,
  } = useShowBlock()

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      hideForm()
    }
    if (e.code === 'Enter') {
      onSubmit(data, hideForm, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  return formLoading ? (
    <Preloader absolute={false} />
  ) : (
    <FormWrapper
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(data, hideForm, id)
      }}
    >
      <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            className={classes.textFields}
            label={t('ru.games.fields.name')}
            value={form.name}
            name="name"
            onChange={handleInputChange}
            size="small"
            error={!!trowError(error, 'name')}
            helperText={trowErrorMessage(error, 'name')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            className={classes.textFields}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            onClick={() => setFormItem('status', !form.status)}
            style={{ cursor: 'pointer' }}
          >
            <SwitchStatus checked={!!form.status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error={!!trowError(error, 'package')} fullWidth size="small">
            <InputLabel>{t('ru.games.fields.packet')}:</InputLabel>
            <Select multiple value={packagesSelected} input={<Input />}>
              {PackageList &&
                PackageList.map((item: IPackage) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    onClick={() => {
                      if (packagesSelected.includes(item.id)) {
                        let index = packagesSelected.indexOf(item.id)
                        setPackagesSelected([
                          ...packagesSelected.slice(0, index),
                          ...packagesSelected.slice(index + 1, packagesSelected.length),
                        ])
                      } else {
                        setPackagesSelected([...packagesSelected, item.id])
                      }
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {trowErrorMessage(error, 'package')
                ? trowErrorMessage(error, 'package')
                : t('ru.games.form_helpers.packet')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl error={!!trowError(error, 'manufacturer_id')} fullWidth size="small">
            <Tooltip title="Add" arrow>
              <Button className={classes.buttons} onClick={showManufacturer}>
              {t('ru.games.fields.manufacturer')} <AddCircleIcon />
              </Button>
            </Tooltip>
            <NativeSelect
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setManufacturer(Number(e.target.value))
              }}
              value={manufacturer}
            >
              <option aria-label="None" value="" />
              {ManufacturerList &&
                ManufacturerList.map((item: IManufacturer) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  )
                })}
            </NativeSelect>
            <FormHelperText>
              {trowErrorMessage(error, 'manufacturer_id')
                ? trowErrorMessage(error, 'manufacturer_id')
                : t('ru.games.form_helpers.manufacturer')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              className={classes.submitBtn}
              variant="contained"
              onClick={cancelBtnEvent}
            >
              {cancelBtnText}
            </Button>
            <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>

      {isManufacturer && (
        <Overlay>
          <ManufacturerForm hideManufacturer={hideManufacturer} />
        </Overlay>
      )}
    </FormWrapper>
  )
}

export default GameForm
