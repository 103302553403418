import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';

type FieldKeyboardTypeProps = {
    error: boolean;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldKeyboardTypeProps, cur: FieldKeyboardTypeProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldKeyboardType: FunctionComponent<FieldKeyboardTypeProps> = React.memo(({ error, onChange, value, formHelperText }) => {
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <NativeSelect onChange={onChange} value={value}>
                <option value={1}>сиреневая</option>
                <option value={2}>серая</option>
                <option value={3}>Таджикистан</option>
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldKeyboardType;