const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles(() => ({
    textFields: {
      width: '100%',
    },
    select: {
      width: '100%',
      marginTop: '2vh',
    },
    submitBtn: {
      margin: '1vh',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: '100%',
    },
    chip: {
      margin: '.5vh',
    },
    icon: {
      margin: '.5vh',
    },
    packet: {
      background: '#f3f7f9',
      padding: '0 1vh',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '16px',
    },
  }))
