import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from '../../Common/Overlay/index';
import Confirm from '../../Common/Confirm';

type ReloginFormProps = {
    cancel: () => void;
    name?: string;
    loading: boolean;
    relogin: () => void
};

function cmp(prev: ReloginFormProps, next: ReloginFormProps): boolean {
    return prev.name===next.name && prev.loading===next.loading;
}

const ReloginForm: FunctionComponent<ReloginFormProps> = React.memo(({ cancel, name, loading, relogin }) => {
    const [t] = useTranslation();
    return <Overlay>
        <Confirm text={`${t('ru.common.confirm.relogin')} ${name}?`} loading={loading} cancel={cancel} submit={relogin} />
    </Overlay>;
}, cmp);

export default ReloginForm;