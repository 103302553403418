import React, { FunctionComponent } from 'react';
import { MenuItem, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import visionPng from '../../../../assets/images/vision.png';
import { useHistory, useLocation } from 'react-router-dom';

type ViewMenuItemProps = {
    id: number,
    isModal: boolean
};

const ViewMenuItem: FunctionComponent<ViewMenuItemProps> = React.memo(({ id, isModal }) => {
    const history = useHistory();
    const location: any = useLocation();
    return <MenuItem onClick={() => {
        history.push({
            pathname: `tables`,
            search: `?table_id=${id}`,
            state: {
                prevPaths: isModal
                    ? !!location.state &&
                        !!location.state.prevPaths &&
                        !!location.state.prevPaths.length
                        ? [
                            ...location.state.prevPaths,
                            window.location.pathname + window.location.search,
                        ]
                        : [window.location.pathname + window.location.search]
                    : location.state && location.state.prevPaths,
            },
        })
    }} >
        <Action bg={'#7467ec'}>
            <IconButton>
                <img src={visionPng} alt="icon" />
            </IconButton>
        </Action>
    </MenuItem>;
});

export default ViewMenuItem;