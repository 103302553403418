import React from 'react'
import { ListItem } from '../styles'
import { ISpin } from '../../../../interfaces'
import { useHistory, useLocation } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { convertUTCDateToLocalDate } from '../../../../utils/date'

interface SpinsListItemProps {
  item: ISpin
  bg: string
}

function getLines(item: ISpin) {
  if ("balls" in item) {
    if (item.balls) {
      console.log(item.round_balls)
      return <p>
        {item.balls.map((e, i) => <>{i ? ' ' : ''}<span key={e} style={{ backgroundColor: item.round_balls?.includes(e) ? "yellow" : "inherit" }}>{e}</span></>)}
      </p>
    }
    return '';
  }
  return item.lines;
}

const Item: React.FC<SpinsListItemProps> = ({ item, bg }) => {
  const history = useHistory()
  const location = useLocation()
  const getType = (type: number) => {
    switch (type) {
      case 1:
        return 'пополнение'
      case 3:
        return 'спин'
      case 4:
        return 'фриспин'
      case 5:
        return 'риск'
      case 6:
        return 'бонус'
      case 7:
        return 'супербонус'
      case 8:
        return 'cashback'
      case 9:
        return 'джекпот'
      case 10:
        return 'ставка';
      default:
        return type
    }
  }
  return (
    item && (
      <>
        <ListItem
          bg={bg}
          onClick={() => {
            let query = new URLSearchParams(location.search)
            query.append('spin_id', String(item.id))
            history.push({
              pathname: location.pathname,
              search: query.toString(),
            })
          }}
        >
          <div>{item.id}</div>
          <div>{getType(item.type)}</div>
          <div>{item.balance_start / 100}</div>
          <div>{item.balance_end / 100}</div>
          <div>{getLines(item)}</div>
          <div>{item.total_bet}</div>
          <div>{item.win}</div>
          <div>
            {item.created_at && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                component="section"
              >
                <span>{convertUTCDateToLocalDate(item.created_at)}</span>
              </Box>
            )}
          </div>
        </ListItem>
      </>
    )
  )
}

export default Item
