import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import CachedIcon from '@material-ui/icons/Cached';
import { useTranslation } from 'react-i18next';
import { isConfirmType } from '../../../../interfaces';

type ReloadBtnProps = {
    id: number,
    name: string,
    setIsReload: (isConfirm: isConfirmType) => void
}

function cmp(prevProps: ReloadBtnProps, nextProps: ReloadBtnProps) {
    return prevProps.id === nextProps.id;
};

const ReloadBtn: FunctionComponent<ReloadBtnProps> = React.memo(({ id, name, setIsReload }) => {
    const [t] = useTranslation();
    return <Tooltip title={String(t('Перезагрузка'))} placement="top">
        <Action bg={'#919191'} onClick={() => { setIsReload({ id: id, name: name, open: true }) }} >
            <IconButton>
                <CachedIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
        </Action>
    </Tooltip>;
}, cmp);

export default ReloadBtn;