import React, { FunctionComponent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Action } from '../styles';
import visionPng from '../../../../assets/images/vision.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ViewBtnProps = {
    id: number,
    isModal: boolean
}

const ViewBtn: FunctionComponent<ViewBtnProps> = React.memo(({ id, isModal }) => {
    const history = useHistory();
    const location: any = useLocation();
    const [t] = useTranslation();
    return <Tooltip title={String(t('ru.common.view_tooltip'))} placement="top">
        <Action
            bg={'#7467ec'}
            onClick={() => {
                history.push({
                    pathname: `tables`,
                    search: `?table_id=${id}`,
                    state: {
                        prevPaths: isModal
                            ? !!location.state &&
                                !!location.state.prevPaths &&
                                !!location.state.prevPaths.length
                                ? [
                                    ...location.state.prevPaths,
                                    window.location.pathname + window.location.search,
                                ]
                                : [window.location.pathname + window.location.search]
                            : location.state && location.state.prevPaths,
                    }
                })
            }}
        >
            <IconButton>
                <img src={visionPng} alt="icon" />
            </IconButton>
        </Action>
    </Tooltip>;
});

export default ViewBtn;