import moment from 'moment';
import 'fix-date';

export const date = () => {
  // eslint-disable-next-line
  const date = new Date()
  const currentDate = (days = 0) => {
    var mm = date.getMonth() + 1
    var dd = date.getDate() + days

    return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-')
  }

  return currentDate()
}

export const enhanceDateItem = (value: number) => {
  return value < 10 ? '0' + value : value
}

export const getTime = () => {
  const date = new Date()
  return `${enhanceDateItem(date.getHours())}:${enhanceDateItem(
    date.getMinutes()
  )}:${enhanceDateItem(date.getSeconds())}`
}

export function convertUTCDateToLocalDate(date: string): string {
  if (date) {
    const timezone = localStorage.getItem('timezone');
    const offset = timezone ? +timezone : 10800;
    return moment(date).add(offset, 'seconds').format('YYYY-MM-DD HH:mm:ss');
  }
  return 'Нет данных';
}

export const convertToUtc = (date: any) => {
  const timezone = localStorage.getItem('timezone');
  const offset = timezone ? +timezone : 10800;
  let isoDate = moment(date).subtract(offset, 'seconds').format('YYYY-MM-DD HH:mm:ss');
  return isoDate;
}
