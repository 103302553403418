import { makeStyles } from '@material-ui/core'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'

export const useStyles = makeStyles({
  item: {
    width: '100%',
    padding: '1vh 1vh',
  },
  process: {
    height: '2vh',
    width: '100%',
  },
  percent: {
    margin: '1vh',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
    fontSize: '90%',
    width: '100%',
  },
  span: {
    color: '#555',
    minHeight: '100%',
    background: '#eee',
    padding: '0 3%',
  },
  reload: {
    color: 'white',
    background: '#a9c477',
    borderRadius: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
})

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 0.05);
  margin: auto;

  .report-list {
    width: 50%;
  }



  ${down('sm')} {
    .report-list {
      width: 100%;
    }
  }
`
