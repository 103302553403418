import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import visionPng from '../../../assets/images/vision.png'
import editPng from '../../../assets/images/edit.png'
import trashPng from '../../../assets/images/trash.png'

import { jackpotSelector, deleteJackpotItem, addJackpotItem, getAllJackpots } from '../../../redux/jackpotReducer'
import Preloader from '../../Common/Preloader'
import { Action } from '../../Users/List/styles'
import { IJackpot, isConfirmType } from '../../../interfaces'
import Overlay from '../../Common/Overlay/index'
import Confirm from '../../Common/Confirm'
import AddJackpot from '../Add'
import Jackpot from '../View'
import { useShowBlock } from '../../../hooks/useShowBlock'
import { useTranslation } from 'react-i18next'
import { authSelector } from '../../../redux/authReducer'
import { trowErrorMessage } from '../../../utils/errors'
import { Alert } from '@material-ui/lab'
import { prettyNum } from '../../../utils/prettyNum'
import { userSelector } from '../../../redux/userReducer'
import { convertUTCDateToLocalDate } from '../../../utils/date'
import { Link } from 'react-router-dom'
import { hallSelector } from '../../../redux/hallReducer'
import { tableSelector } from '../../../redux/tableReducer'
import { findPermission, findPermissionOr } from '../../../utils/find'

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: '75%',
    lineHeight: 1.6,
    padding: '10px',
  },
  body: {
    fontSize: '75%',
    lineHeight: 1.6,
    padding: '5px',
    textAlign: 'center',
  },
}))(TableCell)

const useStyles = makeStyles({
  list: {
    overflow: 'auto',
    height: '10vh',
    width: '100%',
    scrollbarWidth: 'none',
    '& ::-webkit-scrollbar': {
      width: '0 !important',
    },
  },
  container: {
    maxHeight: 320,
  },
  item: {
    width: '100%',
    padding: '1vh 1vh',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
    fontSize: '90%',
  },
  span: {
    color: '#555',
    minHeight: '100%',
    background: '#eee',
    padding: '0 3%',
  },
  reload: {
    color: 'white',
    background: '#a9c477',
    borderRadius: 0,
    width: 'max-content',
  },
})

// 6: {field: "type", message: "Типы могут быть: 0-бронза, 1-серебро, 2-золото, 3-бриллиант"}
// 7: {field: "status", message: "Статусы могут быть: 0-Активен, 1-Накопление, 2-Не активен, 3-Отключен"}
interface JackpotsListProps {
  hall_id?: null | number
  table_id?: null | number
  user_id?: null | number
  change?: boolean
}
const JackpotsList: React.FC<JackpotsListProps> = ({ hall_id, table_id, user_id, change }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const typeConvert = (type: number) => {
    switch (type) {
      case 0:
        return t('ru.jackpots.selectors.bronze')
      case 1:
        return t('ru.jackpots.selectors.silver')
      case 2:
        return t('ru.jackpots.selectors.gold')
      case 3:
        return t('ru.jackpots.selectors.diamond')
      default:
        break
    }
  }

  const statusConvert = (status: number) => {
    switch (status) {
      case 0:
        return t('ru.jackpots.selectors.active')
      case 1:
        return t('ru.jackpots.selectors.accumulation')
      case 2:
        return t('ru.jackpots.selectors.inactive')
      case 3:
        return t('ru.jackpots.selectors.disabled')
      default:
        break
    }
  }

  const {
    jackpots: { items: jackpots, loading },
    form: { loading: formLoading, error },
  } = useSelector(jackpotSelector)

  const {
    data,
    assigments: { items: assigments }
  } = useSelector(authSelector)

  const {
    users: { data: users },
  } = useSelector(userSelector)

  const {
    halls: { data: halls },
  } = useSelector(hallSelector)

  const {
    tables: { data: tables },
  } = useSelector(tableSelector)

  React.useEffect(() => {
    !jackpots.length && dispatch(getAllJackpots(1, 50))
    // eslint-disable-next-line
  }, [status, dispatch])

  const [jackpotId, setJackpotId] = React.useState(-1)
  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })

  const { showBlock: showJackpot, isBlockShown: isJackpot, hideBlock: hideJackpot } = useShowBlock()

  const {
    showBlock: showAddJackpot,
    isBlockShown: isAddJackpot,
    hideBlock: hideAddJackpot,
  } = useShowBlock()

  // const {
  //   showBlock: showChangeJackpot,
  //   isBlockShown: isChangeJackpot,
  //   hideBlock: hideChangeJackpot,
  // } = useShowBlock()

  const rootParent = (user_id: number): any => {
    if (!!users) {
      const currentUser = users.find((u) => u.id === user_id)
      if (!!currentUser) {
        if (currentUser.parent_id === null || !users.find((u) => u.id === currentUser.parent_id)) {
          return currentUser.id
        } else {
          return currentUser && currentUser.parent_id && rootParent(currentUser.parent_id)
        }
      }
    }
  }
  const getPartners = () => {
    let items: number[] = []
    users.forEach((item) => {
      if (data && data.id === rootParent(item.id)) {
        items = [...items, item.id]
      }
    })
    return items
  }

  const getAdminJackpots = () => {
    let items: any = []
    getPartners().forEach((item) => {
      const userHalls = halls.filter((h) => h.owner_id === item)
      userHalls.forEach((item) => {
        const hallTables = tables.filter((t) => t.hall_id === item.id)
        hallTables.forEach((table) => {
          checkTableJackpots(table.id).forEach((j) => {
            if (items.findIndex((jackpot: any) => jackpot.id === j.id) === -1) {
              items = [...items, j]
            }
          })
        })
        checkHallJackpots(item.id).forEach((j) => {
          if (items.findIndex((jackpot: any) => jackpot.id === j.id) === -1) {
            items = [...items, j]
          }
        })
      })

      jackpots
        .filter((i) => i.parent_id === item)
        .forEach((j) => {
          if (items.findIndex((jackpot: any) => jackpot.id === j.id) === -1) {
            items = [...items, j]
          }
        })
    })

    return items
  }

  const checkHallJackpots = (hall_id: number) => {
    const filteredJackpots = jackpots.filter((i) => {
      let checkHall = false
      i.halls.forEach((item) => {
        if (item === hall_id) {
          checkHall = true
        }
      })
      return checkHall
    })
    return filteredJackpots ? filteredJackpots : []
  }
  const checkTableJackpots = (table_id: number) => {
    const filteredJackpots = jackpots.filter((i) => {
      let checkTable = false
      i.gambling_tables.forEach((item) => {
        if (item === table_id) {
          checkTable = true
        }
      })
      return checkTable
    })
    return filteredJackpots ? filteredJackpots : []
  }
  const filteredJackpots = () => {
    if (hall_id) {
      return checkHallJackpots(hall_id)
    } else if (table_id) {
      return checkTableJackpots(table_id)
    } else if (user_id) {
      return user_id !== data?.id
        ? jackpots.filter((i) => i.parent_id === user_id)
        : getAdminJackpots()
    } else {
      return jackpots.filter((i) => i.parent_id === data?.id)
    }
  }

  const [isChangeMode, setIsChangeMode] = React.useState(false)
  const sortedDate = (arr: any) => {
    const sortedArr = [...arr]
    return sortedArr.sort((a: any, b: any) => {
      return Date.parse(b.result.time) - Date.parse(a.result.time)
    })
  }

  // console.log(Date.parse('2021-06-18 22:46:52') < Date.parse('2021-06-29 11:36:28'), 'TEST')
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ width: '100%' }}
    >
      {loading ? (
        <Preloader />
      ) : (
        <TableContainer component={Paper} className={classes.container}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('ru.jackpots.fields.type')}</StyledTableCell>
                <StyledTableCell align="center">{t('ru.jackpots.fields.status')}</StyledTableCell>
                <StyledTableCell align="center">{t('ru.jackpots.fields.balance')}</StyledTableCell>
                <StyledTableCell align="center">{''}</StyledTableCell>
                <StyledTableCell align="right">
                  {findPermission(assigments, 'jackpotCreate') && change && (
                    <Button variant="contained" onClick={showAddJackpot}>
                      {t('ru.common.add_btn')}
                    </Button>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.list}>
              {!!filteredJackpots().length &&
                filteredJackpots().map(
                  (jackpot: IJackpot) =>
                    true && (
                      <TableRow
                        key={jackpot.id}
                        style={{ background: !jackpot.status ? '#fff' : '#efefef' }}
                      >
                        <StyledTableCell>{typeConvert(jackpot.type)}</StyledTableCell>
                        <StyledTableCell>{statusConvert(jackpot.status)}</StyledTableCell>
                        <StyledTableCell>{prettyNum(jackpot.balance)}</StyledTableCell>
                        <StyledTableCell>
                          <Box display="flex" flexDirection="column">
                            {jackpot?.result?.time && (
                              <span>{convertUTCDateToLocalDate(jackpot.result.time)}</span>
                            )}
                            {jackpot?.result?.session_id && jackpot?.result?.gambling_table_id && (
                              <Link
                                to={`/tables?table_id=${jackpot.result.gambling_table_id}&sessions_page=1&session_id=${jackpot.result.session_id}&spins_page=1`}
                              >
                                <span style={{ textDecoration: 'underline' }}>
                                  Сессия #{jackpot.result.session_id}
                                </span>
                              </Link>
                            )}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box display="flex" justifyContent="flex-end">
                            <Action bg={'#7467ec'} onClick={() => { showJackpot(); setJackpotId(jackpot.id) }}>
                              <IconButton>
                                <img src={visionPng} alt="icon" style={{ width: '30%' }} />
                              </IconButton>
                            </Action>
                            {findPermissionOr(assigments, ['jackpotUpdate', 'jackpotUpdateChildren']) && <Action bg={'#919191'} onClick={() => { showJackpot(); setIsChangeMode(true); setJackpotId(jackpot.id) }}>
                              <IconButton>
                                <img src={editPng} alt="icon" style={{ width: '30%' }} />
                              </IconButton>
                            </Action>}
                            {findPermissionOr(assigments, ['jackpotDelete', 'jackpotDeleteChildren']) && <Action bg={'#919191'} onClick={() => { setIsConfirm({ id: jackpot.id, name: String(jackpot.id), open: true }) }}>
                              <IconButton>
                                <img src={trashPng} alt="icon" style={{ width: '35%' }} />
                              </IconButton>
                            </Action>}
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    )
                )}
              {!!filteredJackpots().length &&
                sortedDate(filteredJackpots().filter((j: any) => j.result !== null)).map(
                  (jackpot: IJackpot) =>
                    !!jackpot.status && (
                      <TableRow
                        key={jackpot.id}
                        style={{ background: !jackpot.status ? '#fff' : '#efefef' }}
                      >
                        <StyledTableCell>{typeConvert(jackpot.type)}</StyledTableCell>
                        <StyledTableCell>{statusConvert(jackpot.status)}</StyledTableCell>
                        <StyledTableCell>{prettyNum(jackpot.balance)}</StyledTableCell>
                        <StyledTableCell>
                          <Box display="flex" flexDirection="column">
                            {jackpot?.result?.time && <span>{convertUTCDateToLocalDate(jackpot.result.time)}</span>}
                            {jackpot?.result?.session_id && jackpot?.result?.gambling_table_id && (
                              <Link
                                to={`/tables?table_id=${jackpot.result.gambling_table_id}&sessions_page=1&session_id=${jackpot.result.session_id}&spins_page=1`}
                              >
                                <span style={{ textDecoration: 'underline' }}>
                                  Сессия #{jackpot.result.session_id}
                                </span>
                              </Link>
                            )}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box display="flex" justifyContent="flex-end">
                            <Action bg={'#7467ec'} onClick={() => { showJackpot(); setJackpotId(jackpot.id); }}>
                              <IconButton>
                                <img src={visionPng} alt="icon" style={{ width: '30%' }} />
                              </IconButton>
                            </Action>
                            {findPermissionOr(assigments, ['jackpotUpdate', 'jackpotUpdateChildren']) && <Action bg={'#919191'} onClick={() => { showJackpot(); setIsChangeMode(true); setJackpotId(jackpot.id) }}>
                              <IconButton>
                                <img src={editPng} alt="icon" style={{ width: '30%' }} />
                              </IconButton>
                            </Action>}
                            {findPermissionOr(assigments, ['jackpotDelete', 'jackpotDeleteChildren']) && <Action bg={'#919191'} onClick={() => { setIsConfirm({ id: jackpot.id, name: String(jackpot.id), open: true }) }}>
                              <IconButton>
                                <img src={trashPng} alt="icon" style={{ width: '35%' }} />
                              </IconButton>
                            </Action>}
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {trowErrorMessage(error, 'gambling_tables') && (
        <Alert severity="error">{trowErrorMessage(error, 'gambling_tables')}</Alert>
      )}
      {filteredJackpots().length === 0 && (
        <Box style={{ width: '100%', padding: '25px 0' }} display="flex" justifyContent="center">
          <Typography>{t('ru.common.is_absent')}</Typography>
        </Box>
      )}
      {isAddJackpot && (
        <Overlay>
          <AddJackpot
            table_id={table_id}
            hall_id={hall_id ? hall_id : null}
            hideAddJackpot={() => hideAddJackpot()}
            addJackpotItem={(data, hideForm) => dispatch(addJackpotItem(data, hideForm))}
          />
        </Overlay>
      )}
      {isJackpot && (
        <Overlay>
          <Jackpot
            table_id={table_id}
            hall_id={hall_id}
            isChangeMode={isChangeMode}
            jackpotId={jackpotId}
            hideJackpot={hideJackpot}
          />
        </Overlay>
      )}
      {isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`}
            loading={formLoading}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              isConfirm.id && dispatch(deleteJackpotItem(isConfirm.id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
    </Box>
  )
}

export default JackpotsList
