import React from 'react'

import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  ListItem,
  makeStyles,
  TextField,
} from '@material-ui/core'
import BorderOuterIcon from '@material-ui/icons/BorderOuter'

import { useTranslation } from 'react-i18next'
import { TabItem } from '../../../Halls/View/styles'
import JackpotsList from '../../../Jackpots/List'
import { IGame } from '../../../../interfaces'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  textFields: {
    width: '100%',
  },
  submitBtn: {
    margin: '1vh',
  },
}))

interface TerminalFormProps {
  terminal: any
  cancelBtnEvent: () => void
}

const TerminalForm: React.FC<TerminalFormProps> = ({ terminal, cancelBtnEvent }) => {
  // const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const [tab, setTab] = React.useState<number>(0)

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return main()
      case 1:
        return packets()
      case 2:
        return <JackpotsList change table_id={235} />
      default:
        break
    }
  }

  const main = () => {
    return (
      <>
        {' '}
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textFields}
            label={t('ru.tables.fields.login')}
            type="text"
            name="login"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textFields}
            label={t('ru.tables.fields.password')}
            type="text"
            name="password"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            className={classes.textFields}
            label={t('ru.tables.fields.login')}
            type="text"
            name="login"
          />
        </Grid>
      </>
    )
  }

  const packets = () => {
    return (
      <Grid item xs={12}>
        <Box>
          <b>{t('ru.tables.tabs.packets')}:</b>
          <br />
        </Box>
        {[].map((item: any, id: number) => {
          return (
            <React.Fragment key={id}>
              <ListItem>
                <Box display="flex" alignItems="center">
                  <BorderOuterIcon />
                  {item.package.name}
                </Box>
              </ListItem>
              <Collapse in={true}>
                <Box>
                  {item.games.map((game: IGame) => {
                    return (
                      <Chip
                        key={game.id}
                        label={
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <span>{game.name}</span>
                          </Box>
                        }
                      />
                    )
                  })}
                </Box>
              </Collapse>
            </React.Fragment>
          )
        })}
      </Grid>
    )
  }

  const rootRender = (
    <>
      <Box className="tabs" display="flex" justifyContent="flex-start">
        <TabItem
          active={tab === 0}
          onClick={() => {
            setTab(0)
          }}
        >
          {t('ru.tables.tabs.info')}
        </TabItem>
        <TabItem
          active={tab === 1}
          onClick={() => {
            setTab(1)
          }}
        >
          {t('ru.tables.tabs.packets')}
        </TabItem>
        <TabItem
          active={tab === 2}
          onClick={() => {
            setTab(2)
          }}
        >
          {t('ru.tables.tabs.jackpots')}
        </TabItem>
      </Box>
      <br />
      <Grid container spacing={2}>
        {returnTab(tab)}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            fullWidth
            className={classes.submitBtn}
            variant="contained"
            onClick={cancelBtnEvent}
          >
            {'Отменить'}
          </Button>
          <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
            {'Применить'}
          </Button>
        </Box>
      </Grid>
    </>
  )

  return rootRender
}

export default TerminalForm
