import React, { FunctionComponent } from 'react';
import { IconButton, makeStyles, Menu, MenuList } from '@material-ui/core'
import ViewButton from './view-button';
import UpdateButton from './update-button';
import DeleteButton from './delete-button';
import BlockButton from './block-button';
import ReloginButton from './relogin-button';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiList-padding': {
            padding: 0,
        },
    }
}));

type isConfirmType = {
    id: number | undefined;
    name: string;
    open: boolean;
};

type ButtonsProps = {
    anchorRef: any;
    open: boolean;
    handleToggle: () => void;
    handleClose: (event: any) => void;
    handleListKeyDown: (event: any) => void;
    viewUser: boolean;
    updateUser: boolean;
    deleteUser: boolean;
    isMe: boolean;
    id: number;
    setChange: (x: boolean) => void;
    setIsConfirm: (item: isConfirmType) => void;
    login: string;
    status: boolean;
    setIsBlock: (item: isConfirmType) => void;
    setIsReLogin: (item: isConfirmType) => void;
};

function cmp(prev: ButtonsProps, next: ButtonsProps): boolean {
    return prev.viewUser === next.viewUser && prev.deleteUser === next.deleteUser && prev.updateUser === next.updateUser &&
        prev.isMe === next.isMe && prev.id === next.id && prev.login === next.login && prev.status === next.status && prev.open === next.open;
}

const Buttons: FunctionComponent<ButtonsProps> = React.memo(({ viewUser, updateUser, deleteUser, isMe, id, setChange, setIsConfirm, login, setIsBlock, status, setIsReLogin, anchorRef, open, handleToggle, handleClose, handleListKeyDown }) => {
    const classes = useStyles();
    return <>
        <IconButton ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} >
            <MoreVertIcon />
        </IconButton>
        <Menu className={classes.root} autoFocus={false} open={open} elevation={0} anchorEl={anchorRef.current} getContentAnchorEl={null} anchorOrigin={{ vertical: 'center', horizontal: 'left' }} transformOrigin={{ vertical: 'center', horizontal: 'left' }} onClose={handleClose} >
            <MenuList autoFocus={false} onKeyDown={handleListKeyDown} onClick={handleClose} style={{ background: '#fff', border: '1px solid #efeff0', padding: 0, display: 'flex' }} >
                {viewUser && <ViewButton id={id} />}
                {viewUser && updateUser && <BlockButton id={id} setIsBlock={setIsBlock} status={status} login={login} isMe={isMe} />}
                {viewUser && updateUser && <UpdateButton id={id} setChange={setChange} />}
                {deleteUser && <DeleteButton id={id} setIsConfirm={setIsConfirm} login={login} />}
                {!isMe && <ReloginButton id={id} setIsReLogin={setIsReLogin} login={login} />}
            </MenuList>
        </Menu>
    </>;
}, cmp);

export default Buttons;