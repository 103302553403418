import React from 'react'
import { ListItem } from '../styles'
import { prettyNum } from '../../../../utils/prettyNum'
import { userSelector } from '../../../../redux/userReducer'
import { hallSelector } from '../../../../redux/hallReducer'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { convertUTCDateToLocalDate } from '../../../../utils/date'
import EnhanceLink from '../../../Common/EnhanceLink'

interface SpinsListItemProps {
  item: any
  bg?: string
}

const Item: React.FC<SpinsListItemProps> = ({ item, bg = '#fff' }) => {
  const {
    users: { data: users },
  } = useSelector(userSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const benefit = item.credit - item.debit
  return (
    item && (
      <>
        <ListItem bg={bg}>
          <div>{item.id}</div>
          <div>{prettyNum(item.credit)}</div>
          <div>{prettyNum(item.debit)}</div>
          <div>{prettyNum(item.balance_start)}</div>
          <div>{prettyNum(item.balance_end)}</div>
          <div style={{ color: benefit === 0 ? 'gray' : benefit < 0 ? '#e27575' : '#87b384' }}>
            {prettyNum(benefit)}
          </div>
          <div>
            <Box
              style={{ width: '100%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <span>{convertUTCDateToLocalDate(item.created_at)}</span>
            </Box>
          </div>
          <div>
            <Box
              style={{ width: '100%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <span>{convertUTCDateToLocalDate(item.deleted_at)}</span>
            </Box>
          </div>
          <div>
            <EnhanceLink pathname={`/users`} search={`?id=${item.user_id}`}>
              {users.find((i) => i.id === Number(item.user_id))?.login}
            </EnhanceLink>
          </div>
          <div>
            <EnhanceLink pathname={`/halls`} search={`?hall_id=${item.hall_id}`}>
              {halls.find((i) => i.id === Number(item.hall_id))?.name}
            </EnhanceLink>
          </div>
        </ListItem>
      </>
    )
  )
}

export default Item
