import React, { ReactElement } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Box, Grid, makeStyles, IconButton, Button, Chip, Tooltip } from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import CloseIcon from '@material-ui/icons/Close'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import { Wrapper } from './styles'
import { prettyNum } from '../../../utils/prettyNum'
import { useSelector, useDispatch } from 'react-redux'
import { assignmentSelector, handlerFetchAssignments } from '../../../redux/assignmentReducer'
import { permissionSelector } from '../../../redux/permissionReducer'
import Preloader from '../../Common/Preloader'
import { IPermission, AssigmentType } from '../../../interfaces'
import { Status } from '../List/styles'
import { getUser, handlerChangeUser, userSelector, userSuccess } from '../../../redux/userReducer'
import { TabItem } from '../../Info/styles'
import HallsList from '../../Halls/List'
import { useShowBlock } from '../../../hooks/useShowBlock'
import AddHall from '../../Halls/Add'
import Overlay from '../../Common/Overlay/index'
import { handlerAddHall } from '../../../redux/hallReducer'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import UserForm from '../Form'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GoBackBtn from '../../Common/GoBackBtn'
import Field from './field'
import { getTimezoneName } from '../Form/field-timezone'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  backBtn: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  halls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  permissions: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%',
  },
  permission: {
    margin: '.5vh',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%',
  },
  chip: {
    margin: '.5vh',
  },
}))

interface UserProps {
  user_id: number
  hideUser?: () => void
  setUserId: (id: null | number) => void
  change: boolean
  setChange: (change: boolean) => void
  me?: boolean
}

const User: React.FC<UserProps> = ({
  user_id,
  hideUser,
  setUserId,
  change,
  setChange,
  me = false,
}) => {
  const classes: any = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location: any = useLocation()
  const [t] = useTranslation()

  const {
    data: user,
    loading,
    users: { data: users },
  } = useSelector(userSelector)
  const userData = users.find((u) => u.id === user_id)
  React.useEffect(() => {
    user_id && dispatch(getUser(user_id, userData))
    return () => {
      dispatch(userSuccess(null))
    }
    // eslint-disable-next-line
  }, [dispatch, user_id])

  React.useEffect(() => {
    dispatch(handlerFetchAssignments(user?.id))
    // eslint-disable-next-line
  }, [dispatch, user?.id])

  const { items: assignments } = useSelector(assignmentSelector)
  // const {
  //   roles: { items: roles },
  // } = useSelector(roleSelector)
  const { items: permissionsList } = useSelector(permissionSelector)

  const [isChange, setIsChange] = React.useState(change)

  const parentLogin = user && users!.find((i) => i.id === user.parent_id)

  const [role, setRole] = React.useState(null)

  const userRole = () => {
    let role = null
    for (let role in user?.roles) {
      setRole(user?.roles[role].description)
    }
    return role
  }

  React.useEffect(() => {
    userRole()
    // eslint-disable-next-line
  }, [user])

  const userPermissions = (assignments: AssigmentType | null) => {
    let permissions: string[] = []
    for (let assignment in assignments) {
      if (assignments[assignment] === 'permission') {
        if (permissionsList.find((i: IPermission) => i.name === assignment)) {
          permissions = [
            ...permissions,
            permissionsList.find((i: IPermission) => i.name === assignment)
              ? permissionsList?.find((i: IPermission) => i.name === assignment)?.description
              : assignment,
          ] as string[]
        }
      }
    }
    return permissions
  }

  const [isOpenPhones, setIsOpenPhones] = React.useState(true)
  const [isOpenEmails, setIsOpenEmails] = React.useState(true)

  const renderBalance = () => {
    let items: ReactElement[] = []
    for (let currency in user?.balance) {
      items = [
        ...items,
        <Chip
          key={currency}
          className={classes.chip}
          label={<b>{`${prettyNum(user?.balance[currency])} ${currency}`}</b>}
        />,
      ]
    }
    return !!items.length ? items : <span>-</span>
  }

  React.useEffect(() => {
    return () => {
      setUserId(null)
    }
    // eslint-disable-next-line
  }, [])

  const permissionAndRolesRender = () => {
    return (
      !!user?.roles && (
        <>
          <Grid item xs={12} sm={6}>
            <Box>
              <b>{t('ru.users.fields.role')}:</b>{' '}
              {role ? role : 'Отсутствует'}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="space-between" flexDirection="row">
              <b>{t('ru.users.fields.status')}:</b>
              <Box display="flex">
                <Status active={!!user!.status}>
                  {!!user!.status ? t('ru.common.status.active') : t('ru.common.status.inactive')}
                </Status>
              </Box>
            </Box>
          </Grid>
          {!!userPermissions(assignments && assignments.assigned).length && (
            <Grid item xs={12} sm={12}>
              <b>{t('ru.users.permissions')}:</b>
              <br />
              <Box className={classes.permissions}>
                {userPermissions(assignments && assignments.assigned).map((permission) => (
                  <Chip key={permission} label={permission} className={classes.permission} />
                ))}
              </Box>
            </Grid>
          )}
        </>
      )
    )
  }

  const [tab, setTab] = React.useState(0)

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return main()
      case 1:
        return renderHalls()
      default:
        break
    }
  }

  const main = () => {
    return (
      <>
        <br />
        <div className={classes.root}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={6}>
              <Box>
                <b>Имя:</b> {user?.first_name ? user?.first_name : t('ru.common.is_absent')}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <b>Второе имя:</b> {user?.middle_name ? user?.middle_name : t('ru.common.is_absent')}
              </Box>
            </Grid>{' '} */}
            <Field xs={12} sm={6} label={t('ru.users.fields.parent')} value={parentLogin && user!.parent_id ? parentLogin!.login : t('ru.common.is_absent')} />
            <Field xs={12} sm={6} label={t('ru.users.fields.country')} value={user!.country ? user!.country.name : t('ru.common.is_absent')} />
            <Field xs={12} sm={6} label={t('ru.users.fields.city')} value={user!.city ? user!.city.name : t('ru.common.is_absent')} />
            {permissionAndRolesRender()}
            <Grid item xs={12} sm={12}>
              <Box display="flex" flexDirection="column">
                <b>{t('ru.users.fields.balance')}:</b>
                <Box className={classes.chips}>{renderBalance()}</Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button color="default" variant="contained" onClick={() => history.push({ pathname: location.pathname, search: location.search + '&operations_page=1', state: { prevPaths: location.state && location.state.prevPaths ? location.state.prevPaths : [] } })} style={{ margin: '10px' }} >
                  {t('ru.common.history_operations_btn')}{' '}
                  <MenuOpenIcon style={{ margin: '0 10px' }} />
                </Button>
              </Box>
            </Grid>
            <Field xs={12} sm={6} label={t('ru.users.fields.timezone')} value={getTimezoneName(user!.timezone)} />
          </Grid>
        </div>
        {!!user?.phones?.length && <ListItem style={{ flexDirection: 'column' }}>
          <ListItem button onClick={() => setIsOpenPhones(!isOpenPhones)}>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary={t('ru.users.fields.phones')} />
            {isOpenPhones ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isOpenPhones} timeout="auto" unmountOnExit>
            <List disablePadding>
              {user?.phones &&
                user?.phones.map((item: string, id: number) => {
                  return (
                    <Box key={id} display="flex" justifyContent="flex-start" flexDirection="row">
                      <ListItemText secondary={item} />
                    </Box>
                  )
                })}
            </List>
          </Collapse>
        </ListItem>}
        {!!user?.emails?.length && <ListItem style={{ flexDirection: 'column' }}>
          <ListItem button onClick={() => setIsOpenEmails(!isOpenEmails)}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary={t('ru.users.fields.mails')} />
            {isOpenEmails ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isOpenEmails} timeout="auto" unmountOnExit>
            <List disablePadding>
              {user?.emails &&
                user?.emails.map((item: string, id: number) => {
                  return (
                    <Box key={id} display="flex" justifyContent="flex-start" flexDirection="row">
                      <ListItemText secondary={item} />
                    </Box>
                  )
                })}
            </List>
          </Collapse>
        </ListItem>}
      </>
    )
  }

  const { showBlock: showAddHall, isBlockShown: isAddHall, hideBlock: hideAddHall } = useShowBlock()
  const renderHalls = () => {
    return (
      <Box className={classes.halls}>
        <HallsList user_id={user!.id} isModal />
        <Box display="flex" justifyContent="flex-end" flexDirection="row" flexWrap="wrap">
          <Button variant="outlined" onClick={showAddHall}>
            {t('ru.common.add_btn')}
          </Button>
        </Box>
        {isAddHall && (
          <Overlay>
            <AddHall
              user_id={user!.id}
              hideAddHall={hideAddHall}
              handlerAddHall={(data, packages, math, hideForm) =>
                dispatch(handlerAddHall(data, packages, math, hideForm))
              }
            />
          </Overlay>
        )}
      </Box>
    )
  }

  React.useEffect(() => {
    return () => {
      setChange(false)
    }
    // eslint-disable-next-line
  }, [])

  // const prevPath = location.state?.prevPath ? new URL(location.state.prevPath).search : null
  const rootRender =
    !user || loading ? (
      <Preloader />
    ) : (
      <>
        {!!location.state && !!location.state.prevPaths && !!location.state.prevPaths.length && (
          <GoBackBtn />
        )}
        <Box className={classes.closeBtn}>
          {
            <Tooltip
              title={
                isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))
              }
              placement="top"
            >
              <IconButton onClick={() => setIsChange(!isChange)}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    background: '#b6b6b6b6',
                    width: '30px',
                    minHeight: '30px',
                    borderRadius: '50%',
                    padding: '6px',
                  }}
                >
                  <img
                    style={{
                      width: '20px',
                      minHeight: 'auto',
                    }}
                    src={!isChange ? editPng : visionPng}
                    alt="icon"
                  />
                </Box>
              </IconButton>
            </Tooltip>
          }
          <IconButton onClick={() => history.push(me ? '/' : '/users')}>
            <CloseIcon />
          </IconButton>
        </Box>

        {isChange ? (
          <UserForm
            onSubmit={(data, assignmentsData, hideForm, id) =>
              dispatch(handlerChangeUser(data, assignmentsData, hideForm, id))
            }
            id={user?.id}
            login={user?.login}
            parent_id={user?.parent_id}
            balance={user?.balance}
            status={user?.status}
            first_name={user?.first_name}
            last_name={user?.last_name}
            middle_name={user?.middle_name}
            country_id={Number(user?.country?.id)}
            city_id={Number(user?.city?.id)}
            timezone={user?.timezone}
            phones={user?.phones}
            emails={user?.emails}
            cancelBtnText={t('ru.common.back_btn')}
            cancelBtnEvent={() => setIsChange(false)}
            submitBtnText={t('ru.common.submit_btn')}
            hideForm={() => setIsChange(false)}
          />
        ) : (
          <>
            <Box display="flex" alignItems="center">
              <AccountCircleIcon fontSize="large" />
              <h2>{user?.login}</h2>
            </Box>
            <br />
            <Box
              className="tabs"
              display="flex"
              justifyContent="flex-start"
              style={{ overflowX: 'auto' }}
            >
              <TabItem
                active={tab === 0}
                onClick={() => {
                  setTab(0)
                }}
              >
                {t('ru.users.tabs.info')}
              </TabItem>
              <TabItem
                active={tab === 1}
                onClick={() => {
                  setTab(1)
                }}
              >
                {t('ru.users.tabs.halls')}
              </TabItem>
            </Box>
            {returnTab(tab)}
          </>
        )}
      </>
    )

  return <Wrapper me={me}>{rootRender}</Wrapper>
}

export default User
