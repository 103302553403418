import { createSlice } from '@reduxjs/toolkit'
import { fetchCurrencyList, addCurrency } from '../services/Currency'
import { ICurrency, ErrorType, CurrencyData } from '../interfaces'
import { AppThunk } from './store'
interface currencyInitialState {
  items: ICurrency[]
  loading: boolean
  error: ErrorType[] | null
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: currencyInitialState = {
  items: [],
  loading: false,
  error: null,
  form: {
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    currencyListIsLoading: (state) => {
      state.loading = true
    },
    currencyListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    currencyListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    currencyItemDelete: (state, { payload }) => {
      const index = state.items.findIndex((i) => i.id === payload)
      state.items = [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1, state.items.length),
      ]
    },
    currencyItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getCurrencyList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(currencyListIsLoading())

    return fetchCurrencyList()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(currencyListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(currencyListFailure([res.data.data]))
          } else {
            dispatch(currencyListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(currencyListFailure(err)))
  }
}

export const addCurrencyItem = (data: CurrencyData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addCurrency(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(currencyItemAdd(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  currencyListIsLoading,
  currencyListSuccess,
  currencyListFailure,
  currencyItemAdd,
  currencyItemDelete,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  currency: currencyInitialState
}
export const currencySelector = (state: IState) => state.currency

export default slice.reducer
