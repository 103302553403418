import React, { FunctionComponent } from 'react';
import { ListItem, Box, Input, makeStyles } from '@material-ui/core';
import { TableHeader } from './styles';

const useStyles = makeStyles(() => ({
    inputRoot: {
        '& .MuiInput-underline.Mui-disabled:before': {
            border: 'none'
        }
    }
}));

type SearchHeaderProps = {
    isModal: boolean,
    search: any,
    setSearch: (data: any) => void
};

const SearchHeader: FunctionComponent<SearchHeaderProps> = React.memo(({ isModal, search, setSearch }) => {
    const classes = useStyles();
    return <Box style={{ background: '#fff', padding: '5px 0' }}>
        <TableHeader isModal={isModal}>
            <ListItem component="span">
                <Input style={{ background: '#efeff0' }} onChange={(e) => setSearch({ ...search, id: e.target.value })} />
            </ListItem>
            <ListItem component="span">
                <Input style={{ background: '#efeff0' }} onChange={(e) => setSearch({ ...search, login: e.target.value })} />
            </ListItem>
            <ListItem component="span">
                <Input style={{ background: '#efeff0' }} onChange={(e) => setSearch({ ...search, name: e.target.value })} />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
            </ListItem>
            <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
            </ListItem>
            <ListItem component="span"></ListItem>
        </TableHeader>
    </Box>;
});

export default SearchHeader;