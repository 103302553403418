import { createSlice } from '@reduxjs/toolkit'
import { fetchManufacturerList, addManufacturer } from '../services/Manufacturer'
import { ErrorType, IManufacturer, ManufacturerData } from '../interfaces'
import { AppThunk } from './store'
interface ManufacturerInitialState {
  items: IManufacturer[]
  loading: boolean
  error: ErrorType[] | null
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: ManufacturerInitialState = {
  items: [],
  loading: false,
  error: null,
  form: {
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {
    manufacturerListIsLoading: (state) => {
      state.loading = true
    },
    manufacturerListSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.items = payload
    },
    manufacturerListFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    manufacturerItemDelete: (state, { payload }) => {
      const index = state.items.findIndex((i) => i.id === payload)
      state.items = [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1, state.items.length),
      ]
    },
    manufacturerItemAdd: (state, { payload }) => {
      state.items = [...state.items, payload]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getManufacturerList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(manufacturerListIsLoading())

    return fetchManufacturerList()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(manufacturerListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(manufacturerListFailure([res.data.data]))
          } else {
            dispatch(manufacturerListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(manufacturerListFailure(err)))
  }
}

export const addManufacturerItem = (data: ManufacturerData, hideForm: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return addManufacturer(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(manufacturerItemAdd(res.data.data))
          hideForm()
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  manufacturerListIsLoading,
  manufacturerListSuccess,
  manufacturerListFailure,
  manufacturerItemAdd,
  manufacturerItemDelete,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  manufacturer: ManufacturerInitialState
}

export const manufacturerSelector = (state: IState) => state.manufacturer

export default slice.reducer
