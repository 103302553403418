import React from 'react'
import { ListItem } from '../styles'
import { Button } from '@material-ui/core'

interface TerminalsListItemProps {
  item: any
  bg?: string
  disabled?: boolean
}

const Item: React.FC<TerminalsListItemProps> = ({ item, bg = '#fff', disabled = false }) => {
  return (
    item && (
      <>
        <ListItem bg={bg} disabled={disabled}>
          <div>{item.id}</div>
          <div>{'Nürnberg'}</div>
          <div>{'12-12-2021'}</div>
          <div>{'+7-999-999-00-00'}</div>
          <div>{'19300.10'}</div>
          <div>{'user_123'}</div>
          <div>{'21-12-2021'}</div>
          <div>
            {!disabled && (
              <Button variant="contained" color="default" size="small">
                Погасить
              </Button>
            )}
          </div>
        </ListItem>
      </>
    )
  )
}

export default Item
