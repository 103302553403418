import { useState } from 'react'

export const useForm = (initialValues: any) => {
    const [form, setForm] = useState(initialValues)
    const updateForm = (values: any) => {
        setForm((state: any) => {
            return {
                ...state,
                ...values,
            }
        })
    }

    const clearForm = () => setForm(initialValues)

    const setFormItem = (name: string, value: any) => {
        setForm((state: any) => ({
            ...state,
            [name]: value,
        }))
    }

    const handleInputChange = (object: any) => {
        if (object.persist) {
            object.persist()
        }
        const element = object.target ? object.target : object

        setForm((state: any) => ({
            ...state,
            [element.name]: element.type === 'checkbox' ? element.checked : element.value,
        }))
    }

    const handleChangeSelect = (selectedOption: any, object: any) => {
        const element = object.target ? object.target : object
        setForm((state: any) => ({
            ...state,
            [element.name]: selectedOption,
        }))
    }

    return {
        form,
        setForm,
        setFormItem,
        updateForm,
        handleInputChange,
        handleChangeSelect,
        clearForm,
    }
}
