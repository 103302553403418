import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Icon = styled.div`
    margin: 0 2vh;
    display: flex;
    align-items: center;
    width: 15px;
`

export const BtnWrapper = styled.div`
    margin: 0 6vh;
    cursor: pointer;
    display: none;

    ${down('md')} {
        display: block;
        margin: 0;
    }
`
