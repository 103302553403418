import React from 'react'
import { Wrapper, TableHeader } from './styles'
import Item from './Item'
import Preloader from '../../Common/Preloader'

import Button from '@material-ui/core/Button'
import {
  roleSelector,
  deleteRoleItem,
  handlerAddRole,
} from '../../../redux/roleReducer'
import { ListItem, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Confirm from '../../Common/Confirm'
import Overlay from '../../Common/Overlay/index'
import { isConfirmType, RoleData } from '../../../interfaces'
import { useShowBlock } from '../../../hooks/useShowBlock'
import AddRole from '../Add'
import Role from '../View'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const RolesList: React.FC = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const {
    roles: { items: roles, loading },
  } = useSelector(roleSelector)

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })
  const [isChangeMode, setIsChangeMode] = React.useState(false)

  const { form: formLoading } = useSelector(roleSelector)

  const [roleId, setRoleId] = React.useState<null | string>(null)

  const { showBlock: showRole, hideBlock: hideRole } = useShowBlock()

  const { showBlock: showAddRole, isBlockShown: isAddRole, hideBlock: hideAddRole } = useShowBlock()

  const sortButton = (name: string, label: string) => {
    return (
      <ListItem component="span" style={{ cursor: 'pointer', justifyContent: 'center' }}>
        {label}
      </ListItem>
    )
  }

  const location = useLocation()
  const id = new URLSearchParams(location.search).get('role')
  React.useEffect(() => {
    if (id) {
      setRoleId(id)
    } else {
      setRoleId(null)
    }
  }, [id])

  return (
    <Wrapper>
      <Box component="div" style={{ minWidth: '550px' }}>
        <Box display="flex" justifyContent="space-between">
          <h2>{t('ru.roles.title')}</h2>
          <Button onClick={showAddRole} variant="contained">
          {t('ru.common.add_btn')}
          </Button>
        </Box>
        <div>
          <TableHeader>
            {sortButton('type', t('ru.roles.fields.type'))}
            {sortButton('name', t('ru.roles.fields.name'))}
            {sortButton('description', t('ru.roles.fields.description'))}
            <ListItem component="span"></ListItem>
            <ListItem component="span"></ListItem>
          </TableHeader>
          <ul>
            {loading ? (
              <Preloader absolute={false} />
            ) : (
              roles &&
              roles.map((role, id) => {
                return (
                  <Item
                    key={role.name}
                    id={id}
                    type={role.type}
                    name={role.name}
                    description={role.description}
                    setRoleId={setRoleId}
                    showRole={showRole}
                    setIsConfirm={setIsConfirm}
                    setIsChangeMode={setIsChangeMode}
                  />
                )
              })
            )}
          </ul>
        </div>
      </Box>
      {isAddRole && (
        <Overlay>
          <AddRole
            hideAddRole={hideAddRole}
            handlerAddRole={(data: RoleData, hideForm: () => void) =>
              dispatch(handlerAddRole(data, hideForm))
            }
          />
        </Overlay>
      )}
      {id && roleId && (
        <Overlay>
          <Role roleId={roleId} hideRole={hideRole} isChangeMode={isChangeMode} setIsChangeMode={setIsChangeMode}/>
        </Overlay>
      )}
      {isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              dispatch(deleteRoleItem(String(isConfirm?.id), setIsConfirm))
            }}
          />
        </Overlay>
      )}
    </Wrapper>
  )
}

export default RolesList
