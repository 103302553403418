import React, { FunctionComponent, ReactText } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';

type FieldDenominationCheckboxesProps = {
    formHelperText: string;
    denomenationList: number[];
    denominationsSelected: number[];
    setDenominationsSelected: (x: ReactText[]) => void;
    denomination: number;
};

function denominationCheckbox(denom: number, onChangeDenominationCheckbox: (denom: number) => void, checked: Boolean) {
    return <Checkbox checked={Boolean(checked)} color="default" onChange={() => onChangeDenominationCheckbox(denom)} />;
}

function cmp(prev: FieldDenominationCheckboxesProps, cur: FieldDenominationCheckboxesProps): boolean {
    return prev.formHelperText === cur.formHelperText && cur.denomination === prev.denomination && prev.denomenationList.length === cur.denomenationList.length && prev.denomenationList.every((d, i) => cur.denomenationList[i] === d) && prev.denominationsSelected.length === cur.denominationsSelected.length && prev.denominationsSelected.every((d, i) => cur.denominationsSelected[i] === d);
}

const FieldDenominationCheckboxes: FunctionComponent<FieldDenominationCheckboxesProps> = React.memo(({ formHelperText, denomination, denomenationList, setDenominationsSelected, denominationsSelected }) => {
    const onChangeDenominationCheckbox = (denom: number) => {
        if (denominationsSelected.includes(denom)) {
            if (denom !== denomination) {
                const index = denominationsSelected.indexOf(denom);
                setDenominationsSelected([...denominationsSelected.slice(0, index), ...denominationsSelected.slice(index + 1, denominationsSelected.length)]);
            }
        } else {
            const tmp = [...denominationsSelected, denom];
            tmp.sort((a: React.ReactText, b: React.ReactText) => +a - +b);
            setDenominationsSelected(tmp);
        }
    };
    return <Grid item xs={12} sm={12}>
        <FormControl fullWidth size="small">
            <FormHelperText> {formHelperText} </FormHelperText>
            <Box display="flex" flexWrap="wrap">
                {denomenationList && denomenationList.map(denom => <FormControlLabel key={denom} control={denominationCheckbox(denom, onChangeDenominationCheckbox, denominationsSelected.indexOf(denom) !== -1)} label={<span style={{ fontSize: '70%', lineHeight: '1' }}>{denom / 100}</span>} />)}
            </Box>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldDenominationCheckboxes;