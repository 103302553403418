import React from 'react'
import { useForm } from '../../hooks/useForm'

import IconButton from '@material-ui/core/IconButton'
import { SubForm } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  Box,
  Input,
  Button,
  FormHelperText,
  NativeSelect,
  Grid,
  ButtonGroup,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { currencySelector } from '../../redux/currencyReducer'
import Preloader from '../Common/Preloader'
import { paymentSelector, paymentFormIsError, paymentFormSucces } from '../../redux/paymentReducer'
import { IPaymentData, ICurrency } from '../../interfaces'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

const antiDoubleSystem = {
  halls: JSON.parse(process.env.ANTI_DOUBLE_HALLS!) as number[],
  timeout: +process.env.ANTI_DOUBLE_TIMEOUT! * 1000 as number,
  lastActions: [] as number[]
};

interface PaymentFormProps {
  id: any
  hidePayment: () => void
  balance: any
  currency_id?: string
  take: (data: IPaymentData, id?: any, info?: boolean) => void
  putEvent: (data: IPaymentData, id?: any, info?: boolean) => void
  info?: boolean
  ownerBalance?: number | undefined
  hallLanguage?: string;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ id, hidePayment, currency_id, take, putEvent, info, balance, ownerBalance, hallLanguage }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const {
    form: { error, loading, payment },
  } = useSelector(paymentSelector)
  const { items: currencyList } = useSelector(currencySelector)

  const [currency, setCurrency] = React.useState(currency_id ? currency_id : '')
  const [paymentMessage, setPaymentMessage] = React.useState('')

  const { form, handleInputChange, setFormItem } = useForm({
    balance: 0,
  })

  const data: IPaymentData = {
    currency_id: currency,
    amount: form.balance * 100,
  }

  React.useEffect(() => {
    return () => {
      dispatch(paymentFormIsError(null))
      dispatch(paymentFormSucces(null))
    }
    // eslint-disable-next-line
  }, [])

  const subBalance = (sum: number) => {
    dispatch(paymentFormIsError(null))
    dispatch(paymentFormSucces(null))
    id ? take({
      currency_id: currency,
      amount: sum,
    }, id, info) : take(data)
    setFormItem('balance', 0)
    setPaymentMessage(`Баланс был cнят на ${sum / 100}(${currency})`)
  };
  const addBalance = (sum: number) => {
    let extra = '';
    if (id && antiDoubleSystem.halls.includes(id.hall_id)) {
      const time = Date.now();
      if (sum in antiDoubleSystem.lastActions && antiDoubleSystem.lastActions[sum] + antiDoubleSystem.timeout > time) {
        const x = Math.floor(Math.random() * 5) + 1;
        const y = Math.floor(Math.random() * 5) + 1;
        const z = +(window.prompt(`Введите сумму чисел ${x} + ${y}`) ?? '').trim();
        if (x + y !== z) {
          return alert("Неверный ответ, попробуйте ещё раз");
        }
        extra = `${x}+${y}=${z}`;
      } else {
        antiDoubleSystem.lastActions[sum] = time;
      }
    }
    dispatch(paymentFormIsError(null))
    dispatch(paymentFormSucces(null))
    id ? putEvent({
      currency_id: currency,
      amount: sum,
      extra
    }, id, info) : putEvent(data)
    setFormItem('balance', 0)
    setPaymentMessage(`Баланс был пополнен на ${sum / 100}(${currency})`)
  };
  const currentBalance = !currency_id ? balance[currency] : balance
  let fastActions = [50, 100, 500, 1000, 2000, 3000, 4000, 5000];
  if (id?.login) {
    if (hallLanguage === "TGK") {
      fastActions = [5, 10, 20, 50, 100, 200, 500, 1000];
    }
  }
  return (
    <SubForm>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>Изменение баланса <b>{id?.name}</b></h3>
        <IconButton onClick={hidePayment}>
          <CloseIcon />
        </IconButton>
      </Box>
      {!!payment && <Alert severity="success" style={{ marginBottom: '20px' }}>
        {paymentMessage}
        {/*  */}
      </Alert>}
      {!!error.length && <Alert severity="error" style={{ marginBottom: '20px' }}>
        {error[0].message}
      </Alert>}
      {loading ? <Preloader absolute={false} /> : (
        <Grid container>
          {id?.login ? <Grid item xs={12}>
            Быстрые действия: <ButtonGroup variant="contained">
              {fastActions.map(sum => <Button key={sum} style={{ backgroundColor: 'green' }} color="primary" size="small" disabled={!currency || (sum > (ownerBalance || 0) / 100)} onClick={() => addBalance(100 * sum)}> +{sum} </Button>)}
              <Button style={{ backgroundColor: 'red' }} color="primary" size="small" disabled={!currency || currentBalance <= 0} onClick={() => subBalance(currentBalance)}> Вывести {(currentBalance / 100).toFixed(2)} </Button>
            </ButtonGroup>
          </Grid> : ''}
          <Grid item xs={9}>
            <Input value={form.balance ? form.balance : 0} name="balance" onChange={handleInputChange} placeholder="Сначала выберите валюту" disabled={!currency} />
          </Grid>
          <Grid item xs={3}>
            <NativeSelect onChange={(e) => setCurrency(e.target.value)} value={currency} disabled={!!currency_id}>
              <option aria-label="None" value="" />
              {!!currencyList.length && currencyList.map((item: ICurrency) => <option value={item.id} key={item.id}> {item.id} </option>)}
            </NativeSelect>
            <FormHelperText>{t('ru.halls.form_helpers.currency')}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup variant="contained">
              <Button variant="outlined" color="secondary" disabled={!currency || !!(form.balance > currentBalance / 100)}
                onClick={() => {
                  dispatch(paymentFormIsError(null))
                  dispatch(paymentFormSucces(null))
                  id ? take(data, id, info) : take(data)
                  setFormItem('balance', 0)
                  setPaymentMessage(`Баланс был cнят на ${form.balance}(${currency})`)
                }}>
                {t('ru.common.balance.withdraw')}
              </Button>
              <Button variant="outlined" color="primary" disabled={!currency || (!!ownerBalance && !!(form.balance > ownerBalance / 100))}
                onClick={() => addBalance(100 * form.balance)}>
                {t('ru.common.balance.change')}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
    </SubForm>
  )
}

export default PaymentForm
