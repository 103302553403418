import React from 'react'
import { Wrapper } from './styles'
import { makeStyles, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { IHall, TableData } from '../../../interfaces'
import TableForm from '../Form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddUserProps {
  halls: IHall[]
  defaultHall?: number
  hideAddTable: () => void
  handlerAddTable: (data: TableData, packages: string[], hideForm: () => void) => void
}

const AddUser: React.FC<AddUserProps> = ({ hideAddTable, handlerAddTable, halls, defaultHall }) => {
  const classes = useStyles()
  const history = useHistory()
  const [t] = useTranslation()

  const [tab, setTab] = React.useState(0)

  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddTable}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.tables.add_form')}</h3>
      <TableForm
        onSubmit={handlerAddTable}
        cancelBtnEvent={hideAddTable}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
        hideForm={(table_id?: number) => {
          hideAddTable()
          history.push(`tables?table_id=${table_id}`)
        }}
        defaultHall={defaultHall}
        tab={tab}
        setTab={setTab}
      />
    </Wrapper>
  )
}

export default AddUser
