import React, { FunctionComponent } from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Action } from '../../styles';
import visionPng from '../../../../../assets/images/vision.png';

type ViewButtonProps = {
    id: number;
};

function cmp(prev: ViewButtonProps, next: ViewButtonProps): boolean {
    return prev.id === next.id;
}

const ViewButton: FunctionComponent<ViewButtonProps> = React.memo(({ id }) => {
    const history = useHistory();
    return <MenuItem onClick={() => history.push({ pathname: `users`, search: `?id=${id}`, state: { prevPath: window.location.href } })} >
        <Action bg={'#7467ec'}>
            <IconButton>
                <img src={visionPng} alt="icon" />
            </IconButton>
        </Action>
    </MenuItem>;
}, cmp);

export default ViewButton;