import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';

type FieldTimezoneProps = {
    error: boolean;
    onChange: (value: number) => void;
    value: number;
    formHelperText: string;
    label: string;
};

function cmp(prev: FieldTimezoneProps, cur: FieldTimezoneProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value && prev.label === cur.label;
}

export function getTimezoneName(value: number) {
    const h = Math.round(value / 3600);
    const absH = Math.abs(h);
    if (absH <= 12) {
        return 'UTC ' + (h >= 0 ? '+' : '-') + absH;
    }
    return '';
}

export function getTimezones(): number[] {
    const ret = [];
    for (let i = 12; i >= -12; --i) {
        ret.push(3600 * i);
    }
    return ret;
}

const FieldTimezone: FunctionComponent<FieldTimezoneProps> = React.memo(({ error, onChange, value, formHelperText, label }) => {
    return <Grid item xs={12} sm={6}>
        <FormControl error={error} fullWidth size="small">
            <FormHelperText>{label}</FormHelperText>
            <NativeSelect onChange={e => onChange(+e.target.value)} value={value}>
                {getTimezones().map(item => <option key={item} value={item}> {getTimezoneName(item)} </option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldTimezone;