export const findPermission = (items: string[], permission: string) => {
    return !!items.find((i: string) => i === permission)
}

export const findPermissionOr = (items: string[], permissions: string[]) => {
    return !!items.find((i: string) => permissions.includes(i))
}

export const binarySearch = (arr: any[], id: number): number => {
    let min = 0, max = arr.length - 1, mid = (max + min) / 2 | 0;
    while(max>=min) {
        if(arr[mid].id < id) {
            min = mid + 1;
        } else if(arr[mid].id > id) {
            max = mid - 1;
        } else {
            return mid;
        }
        mid = (max + min) / 2 | 0;
    }
    return -1;
}