import React from 'react'
import { useForm } from '../../../hooks/useForm'

import {
  Box,
  Button,
  Grid,
  NativeSelect,
  TextField,
  FormHelperText,
  FormControl,
  makeStyles,
  FormLabel,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { hallSelector } from '../../../redux/hallReducer'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import { formIsError, playerSelector } from '../../../redux/playerReducer'
import Preloader from '../../Common/Preloader'
import ErrorText from '../../Common/ErrorText'
import { IProfile, IHall, PlayerData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  submitBtn: {
    margin: '1vh',
  },
}))
interface PlayerFormProps {
  showProfiles: () => void
  cancelBtnText: string
  cancelBtnEvent: () => void
  submitBtnText: string
  onSubmit: (data: PlayerData, hideForm: () => void, id?: number | undefined) => void
  id?: number
  profile?: IProfile
  name?: string
  hall_id?: number
  hideForm: () => void
}
const PlayerForm: React.FC<PlayerFormProps> = ({
  showProfiles,
  cancelBtnText,
  cancelBtnEvent,
  submitBtnText,
  onSubmit,
  profile,
  id,
  name = '',
  hall_id = '',
  hideForm,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const {
    form: { error, loading: formLoading },
  } = useSelector(playerSelector)

  const { form, handleInputChange, setFormItem } = useForm({
    name: name,
    hall_id: hall_id,
  })

  const data: PlayerData = {
    name: form.name,
    hall_id: form.hall_id,
    profile_id: profile ? profile.id : null,
  }

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      hideForm()
    }
    if (e.code === 'Enter') {
      onSubmit(data, hideForm, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  return formLoading ? (
    <Preloader absolute={false} />
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(data, hideForm, id)
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <TextField
            value={form.name}
            name="name"
            label={t('ru.players.fields.name')}
            onChange={handleInputChange}
            size="small"
            error={!!trowError(error, 'name')}
            helperText={trowErrorMessage(error, 'name')}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center" justifyContent="center" margin="2vh">
            <FormLabel>{t('ru.players.fields.hall')}: </FormLabel>
            <FormControl error={!!trowError(error, 'hall_id')}>
              <NativeSelect
                value={form.hall_id}
                onChange={(e) => {
                  setFormItem('hall_id', Number(e.target.value))
                }}
              >
                <option value={''}></option>
                {halls &&
                  halls.map((item: IHall) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
              </NativeSelect>
              <FormHelperText>{trowErrorMessage(error, 'hall_id')}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <span>{t('ru.players.fields.profile')}:</span>
          {profile ? (
            <Button onClick={showProfiles}>
              <span>{`${profile.name}  ${profile.surname}  ${profile.patronymic}`}</span>
            </Button>
          ) : (
            <Button onClick={showProfiles}>{t('ru.players.form_helpers.profile')}</Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              className={classes.submitBtn}
              onClick={cancelBtnEvent}
              variant="contained"
            >
              {cancelBtnText}
            </Button>
            <Button fullWidth variant="contained" type="submit">
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>
    </form>
  )
}

export default PlayerForm
