import React from 'react'
import { ListItem, Action } from '../styles'

import visionPng from '../../../../assets/images/vision.png'
import editPng from '../../../../assets/images/edit.png'
import trashPng from '../../../../assets/images/trash.png'
import userIcon from '../../../../assets/images/user.png'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import InsertLinkIcon from '@material-ui/icons/InsertLink'
import LinkOffIcon from '@material-ui/icons/LinkOff'

import { IconButton, makeStyles, Menu, MenuItem, MenuList, Tooltip } from '@material-ui/core'
import { findPermission } from '../../../../utils/find'
import { useSelector } from 'react-redux'
import { authSelector } from '../../../../redux/authReducer'
import { IHall, IProfile, isConfirmType } from '../../../../interfaces'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EnhanceLink from '../../../Common/EnhanceLink'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiList-padding': {
      padding: 0,
    },
  },
}))

interface PlayerListItemProps {
  id: number
  login: string
  hall: IHall | undefined
  profile: IProfile
  tableConnection: any
  showPlayer: () => void
  showChangePlayer: () => void
  setCurrentPlayer: (id: number) => void
  setCurrentProfile: (id: number) => void
  setIsConfirm: (isConfirm: isConfirmType) => void
  setIsChangeMode: (isChangeMode: boolean) => void
  showBind: () => void
}

const Item: React.FC<PlayerListItemProps> = ({
  id,
  login,
  hall,
  profile,
  tableConnection,
  showPlayer,
  showChangePlayer,
  setCurrentPlayer,
  setCurrentProfile,
  setIsConfirm,
  setIsChangeMode,
  showBind,
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    assigments: { items },
  } = useSelector(authSelector)

  const [open, setOpen] = React.useState(false)
  const anchorRef: any = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const itemMenu = () => {
    return (
      <>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className={classes.root}
          autoFocus={false}
          open={open}
          elevation={0}
          anchorEl={anchorRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          <MenuList
            autoFocus={false}
            onKeyDown={handleListKeyDown}
            onClick={handleClose}
            style={{
              background: '#fff',
              border: '1px solid #efeff0',
              display: 'flex',
            }}
          >
            <Link to={`players?player_id=${id}`}>
              <MenuItem
                onClick={() => {
                  // setCurrentPlayer(id)
                  // showPlayer()
                }}
              >
                <Action bg={'#7467ec'}>
                  <IconButton>
                    <img src={visionPng} alt="icon" />
                  </IconButton>
                </Action>
              </MenuItem>
            </Link>
            <Link to={`players?player_id=${id}`}>
              <MenuItem
                onClick={() => {
                  // setCurrentPlayer(id)
                  profile && setCurrentProfile(profile.id)
                  setIsChangeMode(true)
                  // showPlayer()
                }}
              >
                <Action bg={'#919191'}>
                  <IconButton>
                    <img src={editPng} alt="icon" />
                  </IconButton>
                </Action>
              </MenuItem>
            </Link>
            <MenuItem
              onClick={() => {
                setIsConfirm({
                  id: id,
                  name: login,
                  open: true,
                })
              }}
            >
              <Action bg={'#919191'}>
                <IconButton>
                  <img src={trashPng} alt="icon" />
                </IconButton>
              </Action>
            </MenuItem>
            <MenuItem
              onClick={() => {
                showBind()
                setCurrentPlayer(id)
              }}
            >
              <Action bg={'#919191'}>
                <IconButton>
                  {tableConnection ? (
                    <LinkOffIcon fontSize="small" style={{ color: '#fff' }} />
                  ) : (
                    <InsertLinkIcon fontSize="small" style={{ color: '#fff' }} />
                  )}
                </IconButton>
              </Action>
            </MenuItem>
          </MenuList>
        </Menu>
      </>
    )
  }

  return (
    <>
      <ListItem bg={'#fff'}>
        <div>
          <span className="avatar">
            <img src={userIcon} alt={'img'} />
          </span>
          {id}
        </div>
        <div>{login}</div>
        <div>{profile ? profile.name + ' ' + profile.surname : '-'}</div>
        <div>
          <EnhanceLink pathname={`/halls`} search={`?hall_id=${hall?.id}`}>
            {hall?.name}
          </EnhanceLink>
        </div>
        <div>
          {window.innerWidth < 1200 ? (
            itemMenu()
          ) : (
            <>
              {findPermission(items, 'viewPlayer') && (
                <Tooltip title={String(t('ru.common.view_tooltip'))} placement="top">
                  <Link to={`players?player_id=${id}`}>
                    <Action
                      bg={'#7467ec'}
                      onClick={() => {
                        // setCurrentPlayer(id)
                        // showPlayer()
                      }}
                    >
                      <IconButton>
                        <img src={visionPng} alt="icon" />
                      </IconButton>
                    </Action>
                  </Link>
                </Tooltip>
              )}
              {findPermission(items, 'viewPlayer') && findPermission(items, 'updatePlayer') && (
                <Tooltip title={String(t('ru.common.change_btn'))} placement="top">
                  <Link to={`players?player_id=${id}`}>
                    <Action
                      bg={'#919191'}
                      onClick={() => {
                        // setCurrentPlayer(id)
                        profile && setCurrentProfile(profile.id)
                        setIsChangeMode(true)
                        // showPlayer()
                      }}
                    >
                      <IconButton>
                        <img src={editPng} alt="icon" />
                      </IconButton>
                    </Action>
                  </Link>
                </Tooltip>
              )}
              {findPermission(items, 'deletePlayer') && (
                <Tooltip title={String(t('ru.common.delete_btn'))} placement="top">
                  <Action
                    bg={'#919191'}
                    onClick={() => {
                      setIsConfirm({
                        id: id,
                        name: login,
                        open: true,
                      })
                    }}
                  >
                    <IconButton>
                      <img src={trashPng} alt="icon" />
                    </IconButton>
                  </Action>
                </Tooltip>
              )}
              <Tooltip title={String(t('Привязка'))} placement="top">
                <Action
                  bg={'#919191'}
                  onClick={() => {
                    showBind()
                    setCurrentPlayer(id)
                  }}
                >
                  <IconButton>
                    {tableConnection ? (
                      <LinkOffIcon fontSize="small" style={{ color: '#fff' }} />
                    ) : (
                      <InsertLinkIcon fontSize="small" style={{ color: '#fff' }} />
                    )}
                  </IconButton>
                </Action>
              </Tooltip>
            </>
          )}
        </div>
      </ListItem>
    </>
  )
}

export default Item
