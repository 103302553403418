import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hotkeyActionsSelector, getHotkeyActions } from '../../redux/hotkeyActionsReducer';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { authSelector } from '../../redux/authReducer';
import { addHotkey, loadHotkeys, hotkeysSelector } from '../../redux/hotkeysReducer';
import HotkeyRow from './hotkey-row';
import HotkeyModal from './hotkey-modal';

interface HotkeysProps {
    hallId?: number;
    tableId?: number;
    edit?: boolean;
};

const Hotkeys: React.FC<HotkeysProps> = React.memo(({ hallId = 0, tableId = 0, edit = true }) => {
    console.log('Hotkeys');
    const dispatch = useDispatch();
    const { status } = useSelector(authSelector);
    const hotkeyActions = useSelector(hotkeyActionsSelector);
    const hotkeys = useSelector(hotkeysSelector);
    React.useEffect(() => {
        if (status === 200 && hotkeyActions.items.length === 0 && !hotkeyActions.error && !hotkeyActions.loading) {
            dispatch(getHotkeyActions());
        }
    }, [status, hotkeyActions, dispatch]);
    React.useEffect(() => {
        if ((hotkeys.hallId !== hallId || hotkeys.tableId !== tableId) && hotkeyActions.items.length && !hotkeys.loading && !hotkeys.error) {
            dispatch(loadHotkeys(hallId, tableId));
        }
    }, [hotkeyActions, dispatch, hotkeys, hallId, tableId]);
    const [openActionId, showDialog] = React.useState<number>(0);
    const send = (e: React.KeyboardEvent) => {
        const code = e.nativeEvent.code;
        if (!(hotkeys.actionToIdxs[openActionId] || []).some((idx: number) => code === hotkeys.items[idx].key)) {
            dispatch(addHotkey(e.nativeEvent.code, openActionId, hallId, tableId));
        }
        showDialog(0);
    };
    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Функция</TableCell>
                    <TableCell>Тип</TableCell>
                    <TableCell>Клавиши</TableCell>
                    {edit && <TableCell>Действия</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {hotkeyActions.items.map(action => <HotkeyRow key={action.id} action={action} showDialog={showDialog} edit={edit} />)}
            </TableBody>
        </Table>
        <HotkeyModal isOpen={openActionId > 0} showDialog={showDialog} onKeyDown={send} />
    </>;
});

export default Hotkeys;
