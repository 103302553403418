import React from 'react'
import { Box, Chip, Collapse, ListItem } from '@material-ui/core'
import BorderOuterIcon from '@material-ui/icons/BorderOuter'
import { useStyles } from '../styles'
import { TabItem } from '../styles'
import { ITable, UserBalance } from '../../../interfaces'
import { prettyNum } from '../../../utils/prettyNum'
import { handlerTakeTableBalance, handlerPutTableBalance } from '../../../redux/tableReducer'
import Overlay from '../../Common/Overlay/index'
import PaymentForm from '../../Payment'
import { useDispatch, useSelector } from 'react-redux'
import { Status } from '../../Users/List/styles'
import { useTranslation } from 'react-i18next'
import { findPermission } from '../../../utils/find'
import { authSelector } from '../../../redux/authReducer'

interface TableInfoProps {
  item: ITable
  isPayment: Boolean
  hidePayment: () => void
}

type AllProps = TableInfoProps

const TableInfo: React.FC<AllProps> = ({ item, isPayment, hidePayment }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()
  const { assigments: { items: assigments } } = useSelector(authSelector);

  const tableCurrency: string | undefined = item?.hall?.currency_id
  const [tab, setTab] = React.useState<number>(0)
  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return <Main />
      case 1:
        return <Packets />
      default:
        break
    }
  }
  const checkBalance = (balance: UserBalance) => {
    let count = 0
    for (let i in balance) {
      if (i) count += 1
    }
    return count
  }
  const Main: React.FC = () => {
    return (
      <>
        <Box>
          <b>{t('ru.tables.fields.hall')}:</b> <span>{item.hall && item.hall.name}</span>
        </Box>
        <Box>
          <b>{t('ru.tables.fields.login')}:</b> <span>{item.login}</span>
        </Box>
        <Box>
          <b>{t('ru.tables.fields.password')}:</b> <span>{item.password}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <b>{t('ru.tables.fields.balance')}:</b>

          <span>
            {!!checkBalance(item.balance)
              ? item.balance && tableCurrency && prettyNum(item.balance[tableCurrency])
              : '0.00'}
            ({tableCurrency})
          </span>
        </Box>
        <Box
          className={classes.box}
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
        >
          <b>{t('ru.tables.fields.status')}:</b>
          <Box display="flex">
            <Status active={!!item.status}>{!!item.status ? 'Активен' : 'Неактивен'}</Status>
          </Box>
        </Box>
        {!!item!.currentGame && (
          <>
            <Box>
              <b>{t('ru.tables.fields.current_game')}:</b> {!!item && item.currentGame!.name}
            </Box>
            <Box display="flex" justifyContent="center">
              <img style={{width: '55%', margin: '5px auto'}} src={item!.currentGame!.logo} alt="" />
            </Box>
          </>
        )}
      </>
    )
  }

  const Packets: React.FC = () => {
    return (
      <>
        <Box>
          <b>{t('ru.tables.tabs.packets')}:</b>
          <br />
        </Box>
        {!!item.packages &&
          item.packages.map((item, id) => {
            return (
              <React.Fragment key={id}>
                <ListItem>
                  <Box display="flex" alignItems="center">
                    <BorderOuterIcon fontSize="small" />
                    <span>{item.package.name}</span>
                  </Box>
                </ListItem>
                <Collapse in={true}>
                  <Box className={classes.chips}>
                    {item.games.map((game) => {
                      return (
                        <Chip
                          key={game.id}
                          className={classes.chip}
                          label={
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <span>{game.name}</span>
                            </Box>
                          }
                        />
                      )
                    })}
                  </Box>
                </Collapse>
              </React.Fragment>
            )
          })}
      </>
    )
  }
  const canViewGamePackages = findPermission(assigments, 'viewGamePackageList');
  return (
    <>
      <Box className="tabs" display="flex" justifyContent="flex-start">
        <TabItem active={tab === 0} onClick={() => setTab(0)}>{t('ru.tables.tabs.info')}</TabItem>
        {canViewGamePackages && <TabItem active={tab === 1} onClick={() => setTab(1)}>{t('ru.tables.tabs.packets')}</TabItem>}
      </Box>
      {returnTab(tab)}
      {isPayment && (
        <Overlay>
          <PaymentForm
            id={item}
            hidePayment={hidePayment}
            take={(data, id, info) => dispatch(handlerTakeTableBalance(data, id, info))}
            putEvent={(data, id, info) => dispatch(handlerPutTableBalance(data, id, info))}
            currency_id={tableCurrency}
            balance={
              item.balance && !!checkBalance(item.balance)
                ? tableCurrency && item.balance[tableCurrency]
                : item.balance
            }
            info
            hallLanguage={item?.hall?.language}
          />
        </Overlay>
      )}
    </>
  )
}

export default TableInfo
