import React from 'react'
import { Wrapper, TableHeader } from './styles'
import Item from './Item/index'
import Preloader from '../../Common/Preloader'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import Button from '@material-ui/core/Button'
import {
  gameSelector,
  deleteGameItem,
  handleChangeStatusGame,
  handleAddGame,
} from '../../../redux/gameReducer'
import { ListItem, MenuItem, Select, Box, FormLabel, Input, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { manufacturerSelector } from '../../../redux/manufacturerReducer'
import Overlay from '../../Common/Overlay/index'
import Confirm from '../../Common/Confirm'
import { GameData, IGame, IManufacturer, isConfirmType } from '../../../interfaces'
import AddGame from '../Add'
import Game from '../View'
import { useShowBlock } from '../../../hooks/useShowBlock'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { sortedList } from '../../../utils/sort'

const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none',
    },
  },
}))

const GamesList: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    games: { items: games },
    loading,
    form: formLoading,
  } = useSelector(gameSelector)
  const { items: manufacturerList } = useSelector(manufacturerSelector)

  const [selectedSort, setSelectedSort] = React.useState('id')
  const [gamesGroup, setGamesGroup] = React.useState<number>(0)
  const [sortDir, setSortDir] = React.useState<boolean>(true)

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })
  const [isBlock, setIsBlock] = React.useState<isConfirmType>({ open: false })

  const [gameId, setGameId] = React.useState<null | number>(null)

  const [isChangeMode, setIsChangeMode] = React.useState(false)

  const { showBlock: showGame, hideBlock: hideGame } = useShowBlock()
  const {
    showBlock: showChangeGame,
    // isBlockShown: isChangeGame,
    // hideBlock: hideChangeGame,
  } = useShowBlock()

  const { showBlock: showAddGame, isBlockShown: isAddGame, hideBlock: hideAddGame } = useShowBlock()

  const sortButton = (name: string, label: string) => {
    return (
      <ListItem
        component="span"
        selected={name === selectedSort || `-${name}` === selectedSort}
        style={{ cursor: 'pointer', justifyContent: 'center' }}
        onClick={() => {
          if (selectedSort === name) {
            setSortDir(!sortDir)
          } else {
            setSelectedSort(name)
          }
        }}
      >
        {label}
        {selectedSort === `${name}` && (sortDir ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItem>
    )
  }

  const [search, setSearch] = React.useState<any>(null)

  const filteredGames = () => {
    if (search) {
      return games.filter((game) => {
        const idFilter = search?.id ? game.id === Number(search?.id) : true
        const nameFilter = !!search?.name
          ? game.name.toLowerCase().indexOf(search.name.toLowerCase()) > -1
          : true

        return idFilter && nameFilter
      })
    } else {
      return games
    }
  }

  const renderGroup =
    manufacturerList &&
    manufacturerList.map((item: IManufacturer) => {
      return (
        !!filteredGames().filter((i: IGame) => i.manufacturer_id === item.id).length && (
          <React.Fragment key={item.id}>
            <Box display="flex" justifyContent="center">
              <FormLabel>{item.name}</FormLabel>
            </Box>
            {sortedList(selectedSort, sortDir, filteredGames()).map((game: IGame) => {
              return (
                item.id === game.manufacturer.id && (
                  <Item
                    key={game.id}
                    id={game.id}
                    name={game.name}
                    manufacturer={game.manufacturer.name}
                    packages={game.packages}
                    status={game.status}
                    setGameId={setGameId}
                    showGame={showGame}
                    showChangeGame={showChangeGame}
                    setIsConfirm={setIsConfirm}
                    setIsBlock={setIsBlock}
                    setIsChangeMode={setIsChangeMode}
                  />
                )
              )
            })}
          </React.Fragment>
        )
      )
    })

  const renderAll = sortedList(selectedSort, sortDir, filteredGames()).map((game: IGame) => {
    return (
      <Item
        key={game.id}
        id={game.id}
        name={game.name}
        manufacturer={game.manufacturer.name}
        packages={game.packages}
        status={game.status}
        setGameId={setGameId}
        showGame={showGame}
        showChangeGame={showChangeGame}
        setIsConfirm={setIsConfirm}
        setIsBlock={setIsBlock}
        setIsChangeMode={setIsChangeMode}
      />
    )
  })

  const location = useLocation()
  const id = new URLSearchParams(location.search).get('game_id')
  React.useEffect(() => {
    if (id) {
      setGameId(Number(id))
    } else {
      setGameId(null)
    }
  }, [id])

  const renderGames = () => {
    return gamesGroup ? renderGroup : renderAll
  }

  return (
    <Wrapper>
      <Box component="div" style={{ minWidth: '550px' }}>
        <Box display="flex" justifyContent="space-between">
          <h2>{t('ru.games.title')}</h2>
          <Select value={gamesGroup} onChange={(e: any) => setGamesGroup(e.target.value)}>
            <MenuItem value={0}>{t('ru.common.selectors.all')}</MenuItem>
            <MenuItem value={1}>{t('ru.common.selectors.manufacturer')}</MenuItem>
          </Select>
          <Button onClick={showAddGame} variant="contained">
            {t('ru.common.add_btn')}
          </Button>
        </Box>
        <div>
          <TableHeader>
            {sortButton('id', 'id')}
            {sortButton('name', t('ru.games.fields.name'))}
            {sortButton('manufacturer_id', t('ru.games.fields.manufacturer'))}
            {sortButton('package_id', t('ru.games.fields.packet'))}
            {sortButton('status', t('ru.games.fields.status'))}
            <ListItem component="span"></ListItem>
          </TableHeader>
          <Box style={{ background: '#fff', padding: '5px 0' }}>
            <TableHeader>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      id: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      name: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem component="span"></ListItem>
            </TableHeader>
          </Box>
          <ul>{loading ? <Preloader absolute={false} /> : renderGames()}</ul>
        </div>
      </Box>
      {isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              dispatch(deleteGameItem(isConfirm.id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
      {isBlock.open && (
        <Overlay>
          <Confirm
            text={`${
              !!games && !!games?.find((i) => i.id === isBlock.id)?.status
                ? t('ru.common.confirm.block')
                : t('ru.common.confirm.unblock')
            } ${isBlock.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsBlock({ open: false })
            }}
            submit={() => {
              dispatch(
                handleChangeStatusGame(
                  games?.find((i) => i.id === isBlock.id),
                  setIsBlock
                )
              )
            }}
          />
        </Overlay>
      )}
      {isAddGame && (
        <Overlay>
          <AddGame
            hideAddGame={hideAddGame}
            handleAddGame={(data: GameData, hideForm: () => void) =>
              dispatch(handleAddGame(data, hideForm))
            }
          />
        </Overlay>
      )}
      {id && gameId && (
        <Overlay>
          <Game
            hideGame={hideGame}
            game={games && games.find((i: IGame) => i.id === gameId)}
            isChangeMode={isChangeMode}
            setIsChangeMode={setIsChangeMode}
          />
        </Overlay>
      )}
    </Wrapper>
  )
}

export default GamesList
