import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  h2 {
    margin: 1vh 0;
    font-size: 2.9vh;
  }
`
export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  span {
    width: 20%;
    height: 35px;
    color: #010e2a;
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  margin: 0.5vh auto;
  div {
    position: relative;
    width: 20%;
    height: 35px;
    color: #010e2a;
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.5vh;
      cursor: pointer;
    }
  }
`

type StatusType = {
  active: boolean
}
export const Status = styled.span`
  height: 3vh;
  background: ${(props: StatusType) => (props.active ? '#87b03b' : '#b03c3c')};
  color: #fff;
  border-radius: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh 3vh;
  width: 15vh;
`
type ActionType = {
  bg: string
}
export const Action = styled.span`
  width: 3vh;
  height: 3vh;
  max-width: 25px;
  max-height: 25px;
  margin: 0.3vh;
  background: ${(props: ActionType) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 13px;
    height: auto;
  }
`
