import { createSlice } from '@reduxjs/toolkit'
import {
  fetchDefaultGamePackagesList,
  addDefaultGamePackage,
  deleteDefaultGamePackage,
  changeDefaultGamePackage,
} from '../services/DefaultGamePackage'
import { changeArr } from '../utils/changeArr'
import { IDefaultPackage, ErrorType, DefaultPackageData, isConfirmType } from '../interfaces'
import { AppThunk } from './store'
interface defaultPackageinitialState {
  items: IDefaultPackage | null
  loading: boolean
  error: ErrorType[] | null
  defaultPackages: {
    items: IDefaultPackage[]
    loading: boolean
    error: ErrorType[] | null
  }
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
}
export const initialState: defaultPackageinitialState = {
  items: null,
  loading: false,
  error: null,
  defaultPackages: {
    items: [],
    loading: false,
    error: null,
  },
  form: {
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'defaultPackage',
  initialState,
  reducers: {
    defaultPackagesListIsLoading: (state) => {
      state.defaultPackages.loading = true
    },
    defaultPackagesListSuccess: (state, { payload }) => {
      state.defaultPackages.loading = false
      state.defaultPackages.error = null
      state.defaultPackages.items = payload
    },
    defaultPackagesListFailure: (state, { payload }) => {
      state.defaultPackages.loading = false
      state.defaultPackages.error = payload
    },
    defaultPackagesListItemDelete: (state, { payload }) => {
      state.defaultPackages.items = [...state.defaultPackages.items.filter((i) => i.id !== payload)]
    },
    defaultPackagesListItemAdd: (state, { payload }) => {
      state.defaultPackages.items = [...state.defaultPackages.items, payload]
    },
    defaultPackagesListItemChange: (state, { payload }) => {
      state.defaultPackages.items = [...changeArr(state.defaultPackages.items, payload.id, payload)]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getDefaultPackagesList = (search?: any): AppThunk => {
  return async (dispatch) => {
    dispatch(defaultPackagesListIsLoading())
    return fetchDefaultGamePackagesList(search)
      .then((res) => {
        if(res.data.code === 200) {
          dispatch(defaultPackagesListSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(defaultPackagesListFailure([res.data.data]))
          } else {
            dispatch(defaultPackagesListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(defaultPackagesListFailure(err)))
  }
}

export const addDefaultPackageItem = (data: DefaultPackageData): AppThunk => {
  return async (dispatch) => {
    return addDefaultGamePackage(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(defaultPackagesListItemAdd(res.data.data))
        }
      })
      .catch((err) => console.log(err))
  }
}

export const deleteDefaultPackageItem = (
  id: number | undefined,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return id &&
      deleteDefaultGamePackage(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(defaultPackagesListItemDelete(id))
            dispatch(formSucces())
            setIsConfirm({ open: false })
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerAddDefaultPackage = (
  data: DefaultPackageData,
  hideForm: (default_package_id: number) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addDefaultGamePackage(data).then((res) => {
      if (res.data.code === 200) {
        dispatch(defaultPackagesListItemAdd(res.data.data))
        dispatch(formSucces())
        hideForm(res.data.data.id)
      } else {
        if (typeof res.data.data.length !== 'number') {
          dispatch(formIsError([res.data.data]))
        } else {
          dispatch(formIsError(res.data.data))
        }
      }
    }).catch(err => dispatch(formIsError(err)))
  }
}

export const handlerChangeDefaultPackage = (
  data: DefaultPackageData,
  hideForm: () => void,
  id: number | undefined
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return id &&
      changeDefaultGamePackage(data, id).then((res) => {
        if (res.data.code === 200) {
          dispatch(defaultPackagesListItemChange(res.data.data))
          dispatch(formSucces())
          hideForm()
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      }).catch(err => dispatch(formIsError(err)))
  }
}

export const {
  defaultPackagesListIsLoading,
  defaultPackagesListSuccess,
  defaultPackagesListFailure,
  defaultPackagesListItemAdd,
  defaultPackagesListItemDelete,
  defaultPackagesListItemChange,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  defaultPackage: defaultPackageinitialState
}

export const defaultPackageSelector = (state: IState) => state.defaultPackage

export default slice.reducer
