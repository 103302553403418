import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';

type FieldDenominationProps = {
    error: boolean;
    onChange: (object: any) => void;
    value: number;
    formHelperText: string;
    values: number[];
};

function cmp(prev: FieldDenominationProps, cur: FieldDenominationProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value && cur.values.length === prev.values.length;
}

const FieldDenomination: FunctionComponent<FieldDenominationProps> = React.memo(({ error, onChange, value, formHelperText, values }) => {
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <NativeSelect onChange={onChange} value={value}>
                {values.map(e => <option key={e} value={e}>{e / 100}</option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldDenomination;