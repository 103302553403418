import { createSlice } from '@reduxjs/toolkit'
import { checkUser, signOut, signIn } from '../services/Auth'
import { putAdminBalance, takeAdminBalance } from '../services/User'
import { paymentFormIsLoading, paymentFormSucces, paymentFormIsError } from './paymentReducer'
import { AppThunk } from './store'
import { IPaymentData, IUser, ITransfer, ErrorType, LoginData, isConfirmType } from '../interfaces'
import { fetchUserAssignments } from '../services/Assignment'
import { fetchRole } from '../services/Role'
import { closeCashbox, openCashbox } from '../services/Cashbox'
import axios from 'axios'

interface AdminInitialState {
  status: number | null
  token: string | null
  data: IUser | null
  transfers: ITransfer[]
  loading: boolean
  error: ErrorType[] | null
  role: {
    item: any
    loading: boolean
    error: ErrorType[] | null
  }
  assigments: {
    items: any
    loading: boolean
    error: ErrorType[] | null
  }
  form: {
    loading: boolean
    error: ErrorType[] | null
  }
  cashbox: {
    data: any
    loading: boolean
    error: ErrorType[] | null
  }
}
const JWT =
  global.window !== undefined && localStorage.getItem('token') ? localStorage.getItem('token') : ''

export const initialState: AdminInitialState = {
  status: null,
  token: JWT,
  data: null,
  transfers: [],
  loading: false,
  error: null,
  role: {
    item: null,
    loading: false,
    error: null,
  },
  assigments: {
    items: [],
    loading: false,
    error: null,
  },
  form: {
    loading: false,
    error: null,
  },
  cashbox: {
    data: null,
    loading: false,
    error: null,
  },
}
export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userIsLoading: (state) => {
      state.loading = true
    },
    userSuccess: (state, { payload }) => {
      state.loading = false
      state.error = null
      state.data = payload
      if (payload && "timezone" in payload) {
        localStorage.setItem('timezone', payload.timezone);
      }
    },
    userFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    getUserToken: (state, { payload }) => {
      state.token = payload
    },
    getUserStatus: (state, { payload }) => {
      state.status = payload
    },
    changeUserBalance: (state, { payload }) => {
      if (!!state.data) state.data.balance = payload
    },
    changeUserCurrency: (state, { payload }) => {
      if (!!state.data && !!state.data.balance)
        state.data.balance[payload.currency] += payload.balance
    },
    changeUserInfo: (state, { payload }) => {
      state.data = payload
    },
    userAssigmentsIsLoading: (state) => {
      state.assigments.loading = true
    },
    userAssigmentsSuccess: (state, { payload }) => {
      state.assigments.loading = false
      state.assigments.error = null
      state.assigments.items = payload
    },
    userAssigmentsFailure: (state, { payload }) => {
      state.assigments.loading = false
      state.assigments.error = payload
    },
    userRoleIsLoading: (state) => {
      state.role.loading = true
    },
    userRoleSuccess: (state, { payload }) => {
      state.role.loading = false
      state.role.error = null
      state.role.item = payload
    },
    userRoleFailure: (state, { payload }) => {
      state.role.loading = false
      state.role.error = payload
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = null
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
    cashboxIsLoading: (state) => {
      state.cashbox.loading = true
    },
    cashboxSucces: (state, { payload }) => {
      state.cashbox.data = payload
      state.cashbox.loading = false
      state.cashbox.error = null
    },
    userAddCashbox: (state, { payload }) => {
      if (state.data) {
        console.log(state.data.id, payload.user_id)
        if (state.data.id === payload.user_id) {
          state.data.cashbox = [...state.data.cashbox, payload.cashbox]
        }
      }
    },
    userDeleteCashbox: (state, { payload }) => {
      if (state.data)
        if (state.data.id === payload.user_id) {
          state.data.cashbox = [
            ...state.data.cashbox.filter(
              (i: any) => i.hall_id !== payload.hall_id || i.user_id !== payload.user_id
            ),
          ]
        }
    },
    changeCashbox: (state, { payload }) => {
      const array = state.data && state.data.cashbox
      const cashboxIndex =
        state.data && state.data.cashbox.findIndex((i: any) => i.id === payload.id)
      if (!!state.data && !!state.data.cashbox) {
        state.data.cashbox = [
          ...array.slice(0, cashboxIndex),
          {
            ...array[cashboxIndex],
            cashbox: payload.cashbox,
          },
          ...array.slice(cashboxIndex + 1, array.length),
        ]
      }
    },
    closeAllCashbox: (state) => {
      state.data &&
        state.data.cashbox.forEach((i: any, id: number) => {
          state.data!.cashbox[id].cashbox = null
        })
    },
    cashboxIsError: (state, { payload }) => {
      state.cashbox.loading = false
      state.cashbox.error = payload
    },
    clearState: (state) => {
      state = initialState
    },
  },
})

export const loginHandler = (data: LoginData, redirect: () => void): AppThunk => {
  return async (dispatch) => {
    dispatch(clearState())
    dispatch(formIsLoading())
    return signIn(data)
      .then((res) => {
        if (res.data.code === 200) {
          redirect()
          dispatch(getUserToken(res.data.data.token))
          !!global.localStorage && localStorage.setItem('token', res.data.data.token)
          dispatch(formSucces())
          dispatch(getUserInfo())
        } else {
          dispatch(getUserStatus(401))
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
    // .catch((err) => {
    //   dispatch(formIsError(err))
    // })
  }
}

export const getUserAssigment = (id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(userAssigmentsIsLoading())
    fetchUserAssignments(id)
      .then((res) => {
        if (res.data.code === 200) {
          const assigned = res.data.data.items.assigned
          let permissions: string[] = []
          dispatch(userAssigmentsSuccess(permissions));
          for (let i in assigned) {
            if (assigned[i] === 'role') {
              dispatch(userRoleSuccess(i))
              // eslint-disable-next-line
              fetchRole(i).then((res) => {
                if (res.data.code === 200) {
                  permissions = [...permissions, ...res.data.data?.permissions]
                  dispatch(userAssigmentsSuccess(permissions))
                }
              })
            } else if (assigned[i] === 'permission') {
              permissions = [...permissions, i]
            }
          }
          dispatch(userAssigmentsSuccess(permissions))
        } else {
          dispatch(userAssigmentsFailure(res.data.data))
        }
      })
      .catch((err) => dispatch(userAssigmentsFailure(err)))
  }
}

export const getUserInfo = (): AppThunk => {
  return async (dispatch) => {
    dispatch(userIsLoading())

    return checkUser()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(userSuccess(res.data.data))
          dispatch(getUserAssigment(res.data.data.id))
          dispatch(getUserStatus(res.data.code))
        } else if (res.data.code !== 200) {
          dispatch(userFailure(res.data.data))
          dispatch(getUserStatus(res.data.code))
        }
      })
      .catch((err) => dispatch(userFailure(err)))
  }
}

export const handleLogout = (redirect: (path: string) => void): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())

    return signOut()
      .then((res) => {
        if (res.data.code === 200) {
          if (!!res.data.data) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.data}`
            localStorage.setItem('token', res.data.data)
            dispatch(clearState())
            dispatch(getUserInfo())
            redirect('/')
          } else {
            dispatch(getUserStatus(null))
            dispatch(formSucces())
            dispatch(clearState())
            !!global.localStorage && localStorage.removeItem('token')
            redirect('/login')
          }
        } else if (res.data.code !== 200) {
          if (typeof res.data.data.length !== 'number') {
            dispatch(userFailure([res.data.data]))
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(userFailure(res.data.data))
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(userFailure(err)))
  }
}

export const handlerPutAdminBalance = (data: IPaymentData): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return putAdminBalance(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(changeUserBalance(res.data.data.balance))
          dispatch(paymentFormSucces(res.data.data))
        } else if (res.data.code !== 200) {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const handlerTakeAdminBalance = (data: IPaymentData): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return takeAdminBalance(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(changeUserBalance(res.data.data.balance))
          dispatch(paymentFormSucces(res.data.data))
        } else if (res.data.code !== 200) {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const handleOpenCashbox = (hall_id: number, cashbox_id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(cashboxIsLoading())
    return openCashbox(hall_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(cashboxSucces(res.data.data))
          dispatch(
            changeCashbox({
              id: cashbox_id,
              cashbox: { id: cashbox_id },
            })
          )
        } else if (res.data.code !== 200) {
          if (typeof res.data.data.length !== 'number') {
            dispatch(cashboxIsError([res.data.data]))
          } else {
            dispatch(cashboxIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(cashboxIsError(err)))
  }
}

export const handleCloseCashbox = (setIsConfirm: (isConfirm: isConfirmType) => void): AppThunk => {
  return async (dispatch) => {
    dispatch(cashboxIsLoading())
    return closeCashbox()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(cashboxSucces(res.data.data))
          dispatch(closeAllCashbox())
          setIsConfirm({ open: false })
        } else if (res.data.code !== 200) {
          if (typeof res.data.data.length !== 'number') {
            dispatch(cashboxIsError([res.data.data]))
          } else {
            dispatch(cashboxIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(cashboxIsError(err)))
  }
}

export const {
  userIsLoading,
  userSuccess,
  userFailure,
  getUserToken,
  getUserStatus,
  changeUserBalance,
  changeUserCurrency,
  changeUserInfo,
  userRoleIsLoading,
  userRoleSuccess,
  userRoleFailure,
  userAssigmentsIsLoading,
  userAssigmentsSuccess,
  userAssigmentsFailure,
  formIsLoading,
  formSucces,
  formIsError,
  cashboxIsLoading,
  cashboxSucces,
  cashboxIsError,
  changeCashbox,
  closeAllCashbox,
  userAddCashbox,
  userDeleteCashbox,
  clearState,
} = slice.actions

interface IState {
  auth: AdminInitialState
}

export const authSelector = (state: IState) => state.auth

export default slice.reducer
