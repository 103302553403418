import React from 'react'
import DualBarChart from '../Common/DualBarChart'
import { Box, IconButton } from '@material-ui/core'

import { Wrapper } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  getDistributionReport,
  reportsDistributionSuccess,
  reportSelector,
} from '../../redux/reportReducer'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../Common/Preloader'

interface DistributionProps {
  hallId: number
  tableId?: number
  hideDistribution: () => void
}
const Distribution: React.FC<DistributionProps> = ({ hallId, tableId = 0, hideDistribution }) => {
  const dispatch = useDispatch()
  const {
    reports: {
      distribution: { data: report, loading },
    },
  } = useSelector(reportSelector)

  React.useEffect(() => {
    dispatch(getDistributionReport(hallId, tableId))

    return () => {
      dispatch(reportsDistributionSuccess([]))
    }
    // eslint-disable-next-line 
  }, [])
  const getData = () => {
    let data: any = []
    report?.forEach((item: any) => {
      data = [
        ...data,
        {
          name: Number(item.sum) / 100,
          sum: (Number(item.sum) * Number(item.count)) / 100,
          profit: (Number(item.profit) / 100) * -1,
        },
      ]
    })
    return data.sort((a: any,b: any) => Number(a.name) - Number(b.name))
  }
  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>Распраделение пополнений</h3>
        <IconButton onClick={hideDistribution}>
          <CloseIcon />
        </IconButton>
      </Box>
      {loading ? <Preloader /> : <DualBarChart data={getData()} />}
    </Wrapper>
  )
}

export default Distribution
