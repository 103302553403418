import axios from 'axios'
import { CityData } from '../interfaces'

export const fetchCityList = async () => {
    return axios.get(`city`)
}

export const addCity = async (data: CityData) => {
    return axios.post(`city`, data)
}

// export const fetchCity = async (id: number) => {
//     return axios.get(`city/${id}`)
// }

// export const changeCity = async (id: number) => {
//     return axios.put(`city/${id}`)
// }

// export const deleteCity = async (id: number) => {
//     return axios.delete(`city/${id}`)
// }
