import React from 'react'

import CollectionList from './List'


const Collections: React.FC = () => {

  return (
    <>
      <CollectionList />
    </>
  )
}

export default Collections
