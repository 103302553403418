import axios from 'axios'
import { ILimit } from '../interfaces'

function parseData(data: ILimit) {
    const d:any = {name: data.name};
    ["max_balance", "max_add_balance", "max_percent_balance", "max_bet_win", "max_bet_win_percent", "day_payout"].forEach(e=>{
        const val = data[e as "name"];
        if(val===null || val==="" || +val<0) {
            d[e] = null;
        } else {
            d[e] = +val;
        }
    });
    if(data.bet_tax && data.bet_tax.coef) {
        d.bet_tax = { min: +data.bet_tax.min, max: +data.bet_tax.max, coef: +data.bet_tax.coef };
    }
    if(data.after_win && data.after_win.win) {
        d.after_win = { win: +data.after_win.win, limit: +data.after_win.limit, time: +data.after_win.time };
    }
    return d;
}

export const fetchLimitList = async () => {
    return axios.get(`limit`)
}

export const addLimit = async (data: ILimit) => {
    return axios.post(`limit`, parseData(data));
}

// export const fetchCity = async (id: number) => {
//     return axios.get(`city/${id}`)
// }

export const changeLimit = async (data: ILimit) => {
    return axios.put(`limit/${data.id}`, parseData(data))
}

export const deleteLimit = async (id: number) => {
    return axios.delete(`limit/${id}`)
}
