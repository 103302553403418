import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    width: 'max-content',
    margin: '0 auto',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline-block'
  }
}))
interface IEnhanceLink {
  pathname: string
  search: string
}
const EnhanceLink: React.FC<IEnhanceLink> = ({ children, pathname, search }) => {
  const history: any = useHistory()
  const location: any = useLocation()
  const classes = useStyles()

  return (
    <section
      className={classes.root}
      onClick={() => {
        history.push({
          pathname: pathname,
          search: search,
          state: {
            prevPaths:
              !!location.state && !!location.state.prevPaths && !!location.state.prevPaths.length
                ? [...location.state.prevPaths, window.location.pathname + window.location.search]
                : [window.location.pathname + window.location.search],
          },
        })
      }}
    >
      {children}
    </section>
  )
}
export default EnhanceLink
