import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { activeFaces, defaultFace, facesNames } from '../../../../../settings/hall';

type FieldHallTemplateProps = {
    error: boolean;
    onChange: (object: any) => void;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldHallTemplateProps, cur: FieldHallTemplateProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldHallTemplate: FunctionComponent<FieldHallTemplateProps> = React.memo(({ error, onChange, value, formHelperText }) => {
    const [t] = useTranslation();
    value = value ?? defaultFace;
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <NativeSelect onChange={onChange} value={value}>
                {activeFaces.map((idx) => <option key={idx} value={idx}>{t(`ru.halls.selectors.${facesNames[idx]}`)}</option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldHallTemplate;