import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box'
import ViewBtn from './view-btn';
import UpdateBtn from './update-btn';
import DeleteBtn from './delete-btn';
import BlockBtn from './block-btn';
import CloseSessionBtn from './close-session-btn';
import BindBtn from './bind-btn';
import HomeBtn from './home-btn';
import ReloadBtn from './reload-btn';
import { authSelector } from '../../../../redux/authReducer';
import { useSelector } from 'react-redux';
import { isConfirmType, CurrentGameType } from '../../../../interfaces';

type BigScreenButtonsProps = {
    id: number,
    name: string,
    isModal: boolean,
    status: boolean,
    currentGame: CurrentGameType | null,
    playerConnection: any,
    showBind: () => void,
    setTableId: (id: number) => void,
    setIsChangeMode: (isChangeMode: boolean) => void,
    setIsConfirm: (isConfirm: isConfirmType) => void,
    setIsBlock: (isConfirm: isConfirmType) => void,
    setIsSession: (isConfirm: isConfirmType) => void,
    setIsReload: (isConfirm: isConfirmType) => void,
    setIsHome: (isConfirm: isConfirmType) => void
};

const BigScreenButtons: FunctionComponent<BigScreenButtonsProps> = React.memo(({ id, name, isModal, status, currentGame, playerConnection,
    showBind, setTableId, setIsChangeMode, setIsConfirm, setIsBlock, setIsSession, setIsReload, setIsHome }) => {

    //используем права
    const { assigments: { items }, role: { item: userRole } } = useSelector(authSelector);

    let canView: boolean = false;
    let canUpdate: boolean = false;
    let canDelete: boolean = false;
    let canBind: boolean = !currentGame && userRole === 'administrator';
    items.forEach((item: string) => {
        if (item === 'viewGamblingTable') {
            canView = true;
        } else if (['updateGamblingTable', 'updateGamblingTableChildren'].includes(item)) {
            canUpdate = true;
        } else if (item === 'deleteGamblingTable') {
            canDelete = true;
        }
    });

    return <Box component="section" display="flex" justifyContent="center" flexDirection='column'>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection='row'>
            {' '}
            {canView && <ViewBtn id={id} isModal={isModal} />}
            {canUpdate && <UpdateBtn id={id} setIsChangeMode={setIsChangeMode} />}
            {canDelete && <DeleteBtn id={id} setIsConfirm={setIsConfirm} name={name} />}
            {canUpdate && <BlockBtn id={id} name={name} status={status} setIsBlock={setIsBlock} />}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection='row'>
            {!!currentGame && <CloseSessionBtn id={id} name={name} setIsSession={setIsSession} />}
            {canBind && <BindBtn id={id} playerConnection={playerConnection} showBind={showBind} setTableId={setTableId} />}
            <HomeBtn id={id} name={name} setIsHome={setIsHome} />
            <ReloadBtn id={id} name={name} setIsReload={setIsReload} />
        </Box>
    </Box>;
});

export default BigScreenButtons;