import React, { Dispatch } from 'react';
import { Button, Grid, makeStyles, Modal, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ILimit } from '../../interfaces';
import { addLimitItem, changeLimitItem } from '../../redux/limitReducer';
import { useDispatch } from 'react-redux';

interface LimitModalProps {
    limitId: number;
    setLimitId: (v: React.SetStateAction<number>) => void;
    form: ILimit;
    setForm: (form: ILimit) => void;
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "80%",
        backgroundColor: theme.palette.background.paper,
        color: "#000",
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`
    },
    textFields: {
        width: '100%',
    },
}));

function send(dispatch: Dispatch<any>, data: ILimit, hideForm: () => void) {
    if (data.name.length === 0 || data.name.length > 255) {
        return;
    }
    if (data.bet_tax) {
        if (data.bet_tax.min >= data.bet_tax.max || data.bet_tax.coef > 50 || data.bet_tax.coef < -50) {
            return;
        }
    }
    if (data.after_win) {
        if (data.after_win.win < 1 || data.after_win.limit < 0 || data.after_win.time < 1) {
            return;
        }
    }
    if (!["max_balance", "max_add_balance", "max_percent_balance", "max_bet_win", "max_bet_win_percent", "day_payout"].every((name: string) => {
        const val = data[name as "name"];
        if (val === "") {
            return false;
        } else if (val === null) {
            return true;
        }
        return Boolean(val.toString().match(/^-?\d+$/));
    })) {
        return;
    }
    if (data.id) {
        dispatch(changeLimitItem(data, hideForm));
    } else {
        dispatch(addLimitItem(data, hideForm));
    }
}

const LimitModal: React.FC<LimitModalProps> = ({ limitId, setLimitId, form, setForm }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [t] = useTranslation();
    const setFormItem = (name: string) => ((e: any) => setForm({ ...form, [name]: e.target.value }));
    const setFormItemTax = (name: string) => ((e: any) => setForm({ ...form, bet_tax: { ...(form.bet_tax || { min: 1, max: 1000, coef: 0 }), [name]: e.target.value } }));
    const setFormItemAfterWin = (name: string) => ((e: any) => setForm({ ...form, after_win: { ...(form.after_win || { win: 1, limit: 0, time: 60 }), [name]: e.target.value } }));
    const h2 = `${limitId ? "Изменение" : "Добавление"} ограничителя`;
    const btn = limitId ? "Сохранить" : "Добавить";
    return <Modal open={limitId >= 0} onClose={() => setLimitId(-1)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className={classes.paper}>
            <h2>{h2}</h2>
            <hr style={{ margin: "3px 0" }} />
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                {limitId > 0 && <Grid item xs={12} sm={12}>
                    <TextField className={classes.textFields} label={t('ru.limits.id')} type="number" value={limitId} name="id" disabled />
                </Grid>}
                <Grid item xs={12} sm={12}>
                    <TextField className={classes.textFields} label={t('ru.limits.name')} type="text" name="name" value={form.name} onChange={setFormItem("name")} />
                </Grid>
                {["max_balance", "max_add_balance", "max_percent_balance", "max_bet_win", "max_bet_win_percent", "day_payout"].map((name: string) => <Grid item xs={12} sm={12} key={name}><TextField className={classes.textFields} label={t('ru.limits.' + name)} type="number" name={name} value={form[name as "name"] === null ? -1 : form[name as "name"]} onChange={setFormItem(name)} /></Grid>)}
                <Grid item xs={4} sm={4}>
                    <TextField className={classes.textFields} label={t('ru.limits.bet_tax_min')} type="number" name="bet_tax_min" value={form.bet_tax?.min || 1} onChange={setFormItemTax("min")} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField className={classes.textFields} label={t('ru.limits.bet_tax_max')} type="number" name="bet_tax_max" value={form.bet_tax?.max || 1000} onChange={setFormItemTax("max")} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField className={classes.textFields} label={t('ru.limits.bet_tax_coef')} type="number" name="bet_tax_coef" value={form.bet_tax?.coef || 0} onChange={setFormItemTax("coef")} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField className={classes.textFields} label={t('ru.limits.after_win_win')} type="number" name="after_win_win" value={form.after_win?.win || 1} onChange={setFormItemAfterWin("win")} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField className={classes.textFields} label={t('ru.limits.after_win_limit')} type="number" name="after_win_limit" value={form.after_win?.limit || 0} onChange={setFormItemAfterWin("limit")} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <TextField className={classes.textFields} label={t('ru.limits.after_win_time')} type="number" name="after_win_time" value={form.after_win?.time || 60} onChange={setFormItemAfterWin("time")} />
                </Grid>
                <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                    <Button variant="contained" color="primary" onClick={() => send(dispatch, form, () => setLimitId(-1))}>{btn}</Button>
                </Grid>
            </Grid>
        </div>
    </Modal>;
};

export default LimitModal;
