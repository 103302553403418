import React from 'react'
import { Box } from '@material-ui/core'
import { GreenCheckbox } from '../GreenCheckBox/styles'
import { useTranslation } from 'react-i18next'
interface SwitchStatusProps {
  checked: boolean
}
const SwitchStatus: React.FC<SwitchStatusProps> = ({ checked }) => {
  const [t] = useTranslation()
  return (
    <Box style={{ fontSize: '80%' }} display="flex" alignItems="center" flexWrap="nowrap">
      <span style={{ color: !checked ? '#666' : '#6666666b' }}>{t('ru.common.status.inactive')}</span>
      <GreenCheckbox checked={!!checked} />
      <span style={{ color: checked ? '#666' : '#6666666b' }}>{t('ru.common.status.active') }</span>
    </Box>
  )
}

export default SwitchStatus
