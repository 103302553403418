import React, { ReactNode } from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import Preloader from '../../Common/Preloader'

import { ListItem, Box } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { tableSelector } from '../../../redux/tableReducer'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface SessionsListProps {
  table_id: number
  title: string
  scroll?: boolean
  striped?: boolean
  fetch?: (id: number, page: number, limit: number) => void
  itemOnClick: (id: number) => void
}

const SessionsList: React.FC<SessionsListProps> = ({
  table_id,
  title,
  scroll,
  striped,
  fetch = () => { },
  itemOnClick,
}) => {
  const history = useHistory()
  const location = useLocation()
  const [t] = useTranslation()

  const {
    sessions: { countPage, data: sessions, loading: sessionsLoading },
  } = useSelector(tableSelector)

  const renderList = () => {
    return sessions.map((item, id) => {
      const operationColor = (id: number, balance_start: number, balance_end: number) => {
        const evenId = id % 2 === 0
        if (balance_start !== balance_end) {
          if (evenId) {
            return balance_start > balance_end ? '#a9c477' : '#de7d55'
          } else {
            return balance_start > balance_end ? '#87b03b57' : '#ffd8c7'
          }
        } else {
          if (evenId) {
            return '#eee'
          } else {
            return '#fafafa'
          }
        }
      }
      return (
        <React.Fragment key={id}>
          <Item
            item={item}
            onClick={itemOnClick}
            striped={striped}
            bg={
              striped
                ? operationColor(id, Number(item.input), Number(item.balance))
                : '#fff'
            }
          />
        </React.Fragment>
      )
    })
  }

  interface TableCellProps {
    children: ReactNode
  }
  const Tc: React.FC<TableCellProps> = ({ children }) => {
    return (
      <ListItem component="span" style={{ justifyContent: 'center', textAlign: 'center' }}>
        {children}
      </ListItem>
    )
  }

  const query = new URLSearchParams(location.search)
  const sessions_page = Number(query.get('sessions_page'))
  const [page, setPage] = React.useState(sessions_page ? sessions_page : 1)

  React.useEffect(() => {
    fetch(table_id, page, 20)
    // eslint-disable-next-line
  }, [page])

  React.useEffect(() => {
    setPage(Number(sessions_page))
    // eslint-disable-next-line
  }, [sessions_page])

  React.useEffect(() => {
    return () => {
      setPage(1)
    }
  }, [])

  return (
    <>
      <Wrapper>
        <Box display="flex" justifyContent="space-between">
          <h2>{title}</h2>
        </Box>
        <div style={{ minWidth: '700px' }}>
          <TableHeader style={{ fontSize: '75%' }}>
            <Tc>id</Tc>
            <Tc>{t('ru.sessions.fields.app')}</Tc>
            <Tc>{t('ru.sessions.fields.in')}</Tc>
            <Tc>{t('ru.sessions.fields.out')}</Tc>
            <Tc>{t('ru.sessions.fields.spins_count')}</Tc>
            <Tc>{t('ru.sessions.fields.denomination')}</Tc>
            <Tc>{t('ru.sessions.fields.created_at')}</Tc>
            <Tc>{t('ru.sessions.fields.closed')}</Tc>
          </TableHeader>
          <ul className={'sessions'}>{sessionsLoading ? <Preloader /> : renderList()}</ul>
        </div>
      </Wrapper>
      <Box display="flex" justifyContent="center" style={{ margin: '10px auto' }}>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={Number(countPage)}
          page={Number(page)}
          onChange={(e, value) => {
            query.set('sessions_page', String(value))
            history.push({
              pathname: location.pathname,
              search: query.toString(),
            })
            // !sessionsLoading && setPage(value)
          }}
        />
      </Box>
      <Box display="flex" justifyContent="center" style={{ margin: '5px 0', fontSize: '85%' }}>
        <span>Зеленым цветом выделены операции пополнения, красным - изъятия, а серым не изменившие баланс</span>
      </Box>
    </>
  )
}

export default SessionsList
