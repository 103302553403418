import { createSlice } from '@reduxjs/toolkit'
import {
  fetchUser,
  fetchUsersList,
  deleteUser,
  changeUser,
  addUser,
  transferUserBalance,
  withdrawTransferUserBalance,
} from '../services/User'
import { changeArr } from '../utils/changeArr'
import { addAssignments, revokeAssignments } from '../services/Assignment'
import { paymentFormIsLoading, paymentFormSucces, paymentFormIsError } from './paymentReducer'
import {
  treeInfoSuccess,
  treeInfoIsLoading,
  treeInfoFailure,
  treeInfoChangeBalance,
} from './treeInfoReducer'
import { changeUserCurrency } from './authReducer'
import { IUser, UserData, isConfirmType, IPaymentData, ErrorType } from '../interfaces'
import { AppThunk } from './store'
import { addCashbox, removeCashbox } from '../services/Cashbox'

interface UserInitialState {
  data: IUser | null
  loading: boolean
  error: ErrorType[]
  users: {
    data: IUser[]
    loading: boolean
    error: ErrorType[]
  }
  form: {
    loading: boolean
    error: ErrorType[]
  }
}

export const initialState: UserInitialState = {
  data: null,
  loading: false,
  error: [],
  users: {
    data: [],
    loading: false,
    error: [],
  },
  form: {
    loading: false,
    error: [],
  },
}

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userIsLoading: (state) => {
      state.loading = true
    },
    userSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.data = payload
    },
    userFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    usersListIsLoading: (state) => {
      state.users.loading = true
    },
    usersListSuccess: (state, { payload }) => {
      state.users.loading = false
      state.users.error = []
      state.users.data = payload
    },
    usersListFailure: (state, { payload }) => {
      state.users.loading = false
      state.users.error = payload
    },
    usersListChange: (state, { payload }) => {
      state.users.data = [...changeArr(state.users.data, payload.id, payload)]
    },
    usersListDeleteItem: (state, { payload }) => {
      state.users.data = [...state.users.data.filter((i) => i.id !== payload)]
    },
    usersListItemAdd: (state, { payload }) => {
      state.users.data = [...state.users.data, payload]
    },
    userChangeBalance: (state, { payload }) => {
      const array = state.users.data
      const id = array.findIndex((i) => i.id === payload.id)
      state.users.data = [
        ...array.slice(0, id),
        {
          ...array[id],
          balance: payload.balance,
        },
        ...array.slice(id + 1, array.length),
      ]
    },
    addUserCashbox: (state, { payload }) => {
      const array = state.users.data
      const id = array.findIndex((i) => i.id === payload.user_id)
      state.users.data = [
        ...array.slice(0, id),
        {
          ...array[id],
          cashbox: [...array[id].cashbox, payload.cashbox],
        },
        ...array.slice(id + 1, array.length),
      ]
    },
    deleteUserCashbox: (state, { payload }) => {
      const array = state.users.data
      const id = array.findIndex((i) => i.id === payload.user_id)
      state.users.data = [
        ...array.slice(0, id),
        {
          ...array[id],
          cashbox: [
            ...array[id].cashbox.filter(
              (i: any) => i.hall_id !== payload.hall_id || i.user_id !== payload.user_id
            ),
          ],
        },
        ...array.slice(id + 1, array.length),
      ]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
  },
})

export const getUser = (id: number, userData: any): AppThunk => {
  return async (dispatch) => {
    dispatch(userIsLoading())

    return fetchUser(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(userSuccess(res.data.data))
          res.data.data !== userData && dispatch(usersListChange(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(userFailure([res.data.data]))
          } else {
            dispatch(userFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(userFailure(err)))
  }
}
let users: IUser[] = []
export const getUsersList = (page = 1, limit = 50): AppThunk => {
  return async (dispatch) => {
    dispatch(usersListIsLoading())

    return fetchUsersList(page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          users = [...users, ...res.data.data];
          dispatch(usersListSuccess(users));
          if (res.headers['x-pagination-current-page'] < res.headers['x-pagination-page-count']) {
            dispatch(getUsersList(Number(res.headers['x-pagination-current-page']) + 1, limit));
          } else {
            users = [];
          }
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(usersListFailure([res.data.data]))
          } else {
            dispatch(usersListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(usersListFailure(err)))
  }
}

export const deleteUserListItem = (
  id: number | undefined,
  isConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      id &&
      deleteUser(id)
        .then((res) => {
          if (res.data.code === 200) {
            dispatch(formSucces())
            dispatch(usersListDeleteItem(id))
            isConfirm({ open: false })
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

export const handlerAddUser = (
  data: UserData,
  assignments: any,
  hideForm: (user_id: number) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addUser(data)
      .then((res) => {
        if (res.data.code === 200) {
          addAssignments({ items: assignments }, res.data.data.id)
          dispatch(usersListItemAdd(res.data.data))
          dispatch(userFailure(null))
          hideForm(res.data.data.id)
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerChangeUser = (
  data: UserData,
  assignments?: any,
  hideForm?: () => void,
  userId?: number | undefined,
  info?: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    info && dispatch(treeInfoIsLoading())
    assignments.prev && userId && revokeAssignments({ items: assignments.prev }, userId)
    assignments.new && userId && addAssignments({ items: assignments.new }, userId)
    return (
      userId &&
      changeUser(data, userId)
        .then((res) => {
          if (res.data.code === 200) {
            info && dispatch(treeInfoSuccess(res.data.data))
            dispatch(usersListChange(res.data.data))
            dispatch(userSuccess(res.data.data))
            !!hideForm && hideForm()
            dispatch(formSucces())
          } else {
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
            info && dispatch(treeInfoFailure(''))
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

export const handleChangeUserStatus = (
  user: IUser | undefined,
  setIsBlock: (isBlock: isConfirmType) => void,
  info?: boolean
): AppThunk => {
  const data: any = {
    login: user?.login,
    first_name: user?.first_name,
    middle_name: user?.middle_name,
    last_name: user?.last_name,
    phones: user?.phones,
    emails: user?.emails,
    status: !!user?.status ? 0 : 10,
  }
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      user &&
      changeUser(data, user.id)
        .then((res) => {
          if (res.data.code === 200) {
            info && dispatch(treeInfoSuccess(res.data.data))
            dispatch(usersListChange(res.data.data))
            dispatch(formSucces())
            setIsBlock({ open: false })
          } else {
            info && dispatch(treeInfoFailure(''))
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

export const handlerTranferUserBalance = (
  data: IPaymentData,
  userId: number,
  info: boolean | undefined
): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return transferUserBalance(data, userId)
      .then((res) => {
        if (res.data.code === 200) {
          info && dispatch(treeInfoChangeBalance(res.data.data.balance))
          dispatch(
            userChangeBalance({
              id: Number(res.data.data.id),
              balance: res.data.data.balance,
            })
          )
          dispatch(
            changeUserCurrency({
              currency: data.currency_id,
              balance: -data.amount,
            })
          )
          dispatch(paymentFormSucces(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const handlerWithdrawTranferUserBalance = (
  data: IPaymentData,
  userId: number,
  info: boolean | undefined
): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return withdrawTransferUserBalance(data, userId)
      .then((res) => {
        if (res.data.code === 200) {
          info && dispatch(treeInfoChangeBalance(res.data.data.balance))
          dispatch(
            userChangeBalance({
              id: Number(res.data.data.id),
              balance: res.data.data.balance,
            })
          )
          dispatch(
            changeUserCurrency({
              currency: data.currency_id,
              balance: data.amount,
            })
          )
          dispatch(paymentFormSucces(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const handleBindingToHall = (user_id: number, hall_id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addCashbox(user_id, hall_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(formSucces())
          dispatch(
            addUserCashbox({
              user_id: user_id,
              cashbox: res.data.data,
            })
          )
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleRemoveToHall = (
  user_id: number,
  hall_id: number,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return removeCashbox(user_id, hall_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(formSucces())
          dispatch(
            deleteUserCashbox({
              hall_id: hall_id,
              user_id: user_id,
            })
          )
          setIsConfirm({ open: false })
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const {
  userIsLoading,
  userSuccess,
  userFailure,
  usersListIsLoading,
  usersListSuccess,
  usersListFailure,
  usersListChange,
  usersListDeleteItem,
  usersListItemAdd,
  userChangeBalance,
  addUserCashbox,
  deleteUserCashbox,
  formIsLoading,
  formSucces,
  formIsError,
} = slice.actions

interface IState {
  user: UserInitialState
}
export const userSelector = (state: IState) => state.user

export default slice.reducer
