import { createSlice } from '@reduxjs/toolkit';
import { fetchHotkeyActions } from '../services/Hotkey';
import { ErrorType, IHotkeyActionData, IntToIntArray, StringToIntArray } from '../interfaces';
import { AppThunk } from './store';

interface hotkeyActionsState {
  items: IHotkeyActionData[];
  loading: boolean;
  error: ErrorType[] | null;
  idToIdx: IntToIntArray;
  actionToIdx: StringToIntArray;
};

export const initialState: hotkeyActionsState = {
  items: [],
  loading: false,
  error: null,
  idToIdx: [],
  actionToIdx: {}
};

export const slice = createSlice({
  name: 'hotkeyActions',
  initialState,
  reducers: {
    hotkeyActionsLoading: (state) => {
      state.loading = true;
    },
    hotkeyActionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.items = payload;
      state.idToIdx = [];
      state.actionToIdx = {};
      payload.forEach((value: IHotkeyActionData, idx: number) => {
        state.idToIdx[value.id] = idx;
        state.actionToIdx[value.action] = idx;
      });
    },
    hotkeyActionsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.items = [];
      state.idToIdx = [];
      state.actionToIdx = {};
    }
  }
});

export const getHotkeyActions = (): AppThunk => {
  return async (dispatch) => {
    dispatch(hotkeyActionsLoading());

    return fetchHotkeyActions().then(res => {
      if (res && 'data' in res && res.data.code === 200) {
        dispatch(hotkeyActionsSuccess(res.data.data));
      } else {
        dispatch(hotkeyActionsFailure(!res || !('data' in res) ? ['Ошибка'] : (typeof res.data.data.length !== 'number' ? [res.data.data] : res.data.data)));
      }
    }).catch(err => dispatch(hotkeyActionsFailure(err)));
  }
}

export const {
  hotkeyActionsLoading,
  hotkeyActionsSuccess,
  hotkeyActionsFailure
} = slice.actions;

interface IState {
  hotkeyActions: hotkeyActionsState;
};

export const hotkeyActionsSelector = (state: IState) => state.hotkeyActions;

export default slice.reducer;
