import React from 'react'
import UserForm from '../Form'
import { Wrapper } from './styles'
import { makeStyles, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { UserData } from '../../../interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, userSelector, userSuccess } from '../../../redux/userReducer'
import Preloader from '../../Common/Preloader'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface ChangeUserProps {
  user_id: number
  hideChangeUser: () => void
  handlerChangeUser: (
    data: UserData,
    assignmentsData:
      | string[]
      | {
        prev: string[]
        new: string[]
      },
    hideForm: () => void,
    id: number | undefined
  ) => void
}

const ChangeUser: React.FC<ChangeUserProps> = ({ user_id, hideChangeUser, handlerChangeUser }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { data: user, loading, users: { data: users } } = useSelector(userSelector)
  const userData = users.find(u => u.id === user_id)
  React.useEffect(() => {
    user_id && dispatch(getUser(user_id, userData))
    return () => {
      dispatch(userSuccess(null))
    }
    // eslint-disable-next-line
  }, [dispatch, user_id])
  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideChangeUser}>
        <CloseIcon />
      </IconButton>
      <h3>Изменение пользователя</h3>
      {!user || loading ? (
        <Preloader absolute={false} />
      ) : (
        <UserForm
          onSubmit={handlerChangeUser}
          id={user?.id}
          login={user?.login}
          parent_id={user?.parent_id}
          balance={user?.balance}
          status={user?.status}
          first_name={user?.first_name}
          last_name={user?.last_name}
          middle_name={user?.middle_name}
          country_id={Number(user?.country?.id)}
          city_id={Number(user?.city?.id)}
          timezone={user?.timezone}
          phones={user?.phones}
          emails={user?.emails}
          cancelBtnText={'Назад'}
          cancelBtnEvent={hideChangeUser}
          submitBtnText={'Подтвердить'}
          hideForm={hideChangeUser}
        />
      )}
    </Wrapper>
  )
}

export default ChangeUser
