import { Grid, Box } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ITable } from '../../../interfaces';

type DenominationsProps = {
    table: ITable;
};

function cmp(prevProps: DenominationsProps, nextProps: DenominationsProps) {
    return prevProps.table.denomination === nextProps.table.denomination;
};

const Denominations: FunctionComponent<DenominationsProps> = React.memo(({ table }) => {
    const [t] = useTranslation();
    return <>
        <Grid item xs={12} md={12}>
            <Box>
                <b>{t('ru.tables.fields.denomination')}:</b> {table && table.denomination / 100}
            </Box>
        </Grid>
        <Grid item xs={12} md={12}>
            <Box>
                <b>{t('ru.tables.fields.denominations')}:</b> {table && table.denominationList ? table.denominationList.join(', ') : t('ru.tables.inherit')}
            </Box>
        </Grid>
    </>;
}, cmp);

export default Denominations;