import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { BindWrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayersList, playerBindChange, playerSelector } from '../../../redux/playerReducer'

import CloseIcon from '@material-ui/icons/Close'
import { tableSelector } from '../../../redux/tableReducer'
import { connectPlayer, disconnectPlayer } from '../../../services/Player'
import Preloader from '../../Common/Preloader'

interface BindProps {
  hideBind: () => void
  player_id: number
}

const Bind: React.FC<BindProps> = ({ hideBind, player_id }) => {
  const dispatch = useDispatch()
  const { items: players } = useSelector(playerSelector)
  const {
    tables: { data: tables, loading: tablesLoading },
  } = useSelector(tableSelector)
  const player = players.find((p) => p.id === player_id)

  React.useEffect(() => {
    !players.length && dispatch(getPlayersList())
    // eslint-disable-next-line
  }, [dispatch])

  const [table, setTable] = React.useState(
    player?.tableConnection ? player?.tableConnection.table_id : 0
  )

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  const handleConnectPlayerToTable = () => {
    setLoading(true)
    connectPlayer(player_id, table).then((res) => {
      if (res.data.code === 200) {
        table && dispatch(playerBindChange({ id: player_id, table_id: { table_id: table } }))
        setLoading(false)
      } else {
        setError(res.data.data)
      }
    })
  }

  const handleDisconnectPlayer = () => {
    setLoading(true)
    disconnectPlayer(player_id, table).then((res) => {
      if (res.data.code === 200) {
        table && dispatch(playerBindChange({ id: player_id, table_id: null }))
        setLoading(false)
        setTable(0)
      } else {
        setError(res.data.data)
      }
    })
  }

  const isConnection = player?.tableConnection
  return (
    <BindWrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>Привязка стол</h3>
        <IconButton onClick={hideBind}>
          <CloseIcon />
        </IconButton>
      </Box>
      {tablesLoading ? (
        <Preloader />
      ) : (
        <Select
          value={table}
          disabled={player?.tableConnection}
          onChange={(e: any) => setTable(e.target.value)}
          style={{ margin: '1em', width: '100%' }}
        >
          {(isConnection ? tables : tables.filter((t) => !t.playerConnection)).map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>
          })}
        </Select>
      )}
      <Button
        disabled={loading || table === 0}
        onClick={player?.tableConnection ? handleDisconnectPlayer : handleConnectPlayerToTable}
        variant="contained"
        fullWidth
      >
        {loading ? (
          <CircularProgress style={{ color: '#b1b1b1' }} size={17} />
        ) : !!isConnection ? (
          'Отвязать'
        ) : (
          'Привязать'
        )}
      </Button>
      {error && <Typography>{error}</Typography>}
    </BindWrapper>
  )
}
export default Bind
