import axios from 'axios'

export const fetchPermissionsList = async (page: number, limit: number) => {
    return axios.get('auth/permission', {
        params: {
            page: page,
            'per-page': limit
        }
    })
}

export const addPermission = async () => {
    return axios.post('auth/permission')
}

export const fetchPermission = async (id: number) => {
    return axios.get(`auth/permission/${id}`)
}

export const changePermission = async (id: number) => {
    return axios.put(`auth/permission/${id}`)
}

export const deletePermission = async (id: number) => {
    return axios.delete(`auth/permission/${id}`)
}
