import React from 'react'
import { Wrapper } from './styles'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, IconButton } from '@material-ui/core'
import DefaultPackageForm from '../Form'
import { DefaultPackageData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddDefaultPackageProps {
  hideAddDefaultPackage: () => void
  handlerAddDefaultPackage: (data: DefaultPackageData, hideForm: () => void) => void
}

const AddDefaultPackage: React.FC<AddDefaultPackageProps> = ({
  hideAddDefaultPackage,
  handlerAddDefaultPackage,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [t] = useTranslation()
  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddDefaultPackage}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.default_packages.add_form')}</h3>
      <DefaultPackageForm
        onSubmit={handlerAddDefaultPackage}
        cancelBtnEvent={hideAddDefaultPackage}
        hideForm={(default_package_id?: number) => {
          hideAddDefaultPackage()
          history.push(`/admin/game-default-packages?default_package_id=${default_package_id}`)
        }}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
      />
    </Wrapper>
  )
}

export default AddDefaultPackage
