import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1vh;
  padding: 3vh;
  width: max-content;
  min-width: 30%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
`
