import axios from 'axios'
import { UserData, IPaymentData } from '../interfaces'

export const fetchUsersList = async (
  page: number, limit: number
) => {
  return axios.get(`users`, {
    params: {
      page: page,
      'per-page': limit,
      expand: 'cashbox,roles',
    },
  })
}

export const addUser = async (data: UserData) => {
  return axios.post('user', data, {
    params: {
      expand: 'halls,roles,cashbox',
    },
  })
}

export const fetchUser = async (id: number) => {
  return axios.get(`user/${id}`, {
    params: {
      expand: 'halls,roles,cashbox',
    },
  })
}

export const changeUser = async (data: UserData, id: number) => {
  return axios.put(`user/${id}`, data, {
    params: {
      expand: 'halls,roles,cashbox',
    },
  })
}

export const deleteUser = (id: number) => {
  return axios.delete(`user/${id}`)
}

export const putAdminBalance = (data: IPaymentData) => {
  return axios.post(`user/balance/put`, data)
}

export const takeAdminBalance = (data: IPaymentData) => {
  return axios.post(`user/balance/take`, data)
}

export const transferUserBalance = (data: IPaymentData, id: number) => {
  return axios.post(`user/${id}/balance/transfer`, data)
}

export const withdrawTransferUserBalance = (data: IPaymentData, id: number) => {
  return axios.post(`user/${id}/balance/withdraw-transfer`, data)
}

export const getUserTransfers = (id: number, page: number, limit: number) => {
  return axios.get(`user/${id}/balance/operations`, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}
