import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import LimitModal from './limit-modal';
import { ILimit } from '../../interfaces';
import { limitSelector, getLimitList } from '../../redux/limitReducer';
import { deleteLimitItem } from '../../redux/limitReducer';

function f(item: ILimit): string {
    const ret: string[] = [];
    ["max_balance", "max_add_balance", "max_percent_balance", "max_bet_win", "max_bet_win_percent", "day_payout"].forEach((name: string) => {
        const val = item[name as "name"];
        if (val !== null) {
            ret.push(`${name} => ${val}`);
        }
    });
    if (item.bet_tax) {
        ret.push(`С ${item.bet_tax.min} по ${item.bet_tax.max} delta = ${item.bet_tax.coef}`);
    }
    if (item.after_win) {
        ret.push(`При выигрыше ${item.after_win.win}% установить лимит в ${item.after_win.limit}% на ${item.after_win.time / 60} минут`);
    }
    return ret.join("\n");
}

const Limits: React.FC = React.memo(() => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const { items: limitList } = useSelector(limitSelector);
    React.useEffect(() => {
        !limitList.length && dispatch(getLimitList());
    }, [limitList, dispatch]);
    const [limitId, setLimitId] = React.useState<number>(-1);
    const defState = { id: 0, name: "", max_balance: -1, max_add_balance: -1, max_percent_balance: -1, max_bet_win: -1, max_bet_win_percent: -1, bet_tax: { min: 1, max: 1000, coef: 0 }, day_payout: -1, after_win: { win: 1, limit: 0, time: 60 } };
    const [form, setForm] = React.useState<ILimit>(defState);
    return <>
        <p style={{ textAlign: "right" }}>
            <Button onClick={() => { setForm(defState); setLimitId(0); }} variant="contained">{t('ru.common.add_btn')}</Button>
        </p>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Название</TableCell>
                    <TableCell>Ограничения</TableCell>
                    <TableCell>Действия</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {limitList.map((item: ILimit) => {
                    return <TableRow key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell><pre>{f(item)}</pre></TableCell>
                        <TableCell>
                            <Button size="small" variant="contained" color="primary" onClick={() => { setForm(item); setLimitId(item.id as number); }}>Измененить</Button>{" "}
                            <Button size="small" variant="contained" color="secondary" onClick={() => dispatch(deleteLimitItem(item.id as number, () => null))}>Удалить</Button>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
        <LimitModal limitId={limitId} setLimitId={setLimitId} form={form} setForm={setForm} />
    </>;
});

export default Limits;
