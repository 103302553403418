import { Box } from '@material-ui/core'
import React from 'react'
import { ListItem } from '../styles'

interface TerminalsListItemProps {
  item: any
  bg?: string
}

const Item: React.FC<TerminalsListItemProps> = ({ item, bg = '#fff' }) => {
  return (
    item && (
      <>
        <ListItem bg={bg}>
          <div>{item.id}</div>
          <div>{'12-12-2021'}</div>
          <div>{'19300.10'}</div>
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              flexWrap="wrap"
              component="section"
              style={{ width: '100%', maxHeight: '75px' }}
            >
              <span style={{ fontSize: '85%' }}>"50": 96</span>
              <span style={{ fontSize: '85%' }}>"100": 35</span>
              <span style={{ fontSize: '85%' }}>"200": 111</span>
              <span style={{ fontSize: '85%' }}>"500": 29</span>
              <span style={{ fontSize: '85%' }}>"1000": 30</span>
              <span style={{ fontSize: '85%' }}>"2000": 33</span>
              <span style={{ fontSize: '85%' }}>"5000": 10</span>
            </Box>
          </div>
          <div>{'test_123'}</div>

        </ListItem>
      </>
    )
  )
}

export default Item
