import React from 'react'
import { Icon } from './styles'

export const Main = () => {
    return (
        <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 38" width="42" height="38">
                <defs>
                    <image
                        width="42"
                        height="38"
                        id="img1"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAmCAYAAACyAQkgAAADc0lEQVRYhe2YTYgUVxDHO0YPGhJUFBSSGIJILkbRBPFicvCUg8QgxoOIxl2qXs8OO1LVsx+Cdm4hBwPJJRJzCBFC1gUPmugaoogaAoIoIirqQdEQqXrTu66DX4fOYbpnnvs1M9n5uGzBO0xP9b9+76te9fO8BhuBfstGDoVhPKvR2g2xvi59j0EvEkhMIDGj3iBfP2w310vGaDvLgCBPCHUk/U0o1G4+Lwzj2YR62BnFaz3dhbdDerSIQf92YI8xx6+1BTLIDK9h1BsVSPv9WB8G+3UFVu8FJvqotZBgu52pLjIWtk3uW/iEUR6WgcHuazpgmIvmE+jRCqT+tde3b1V7r9cML2DUP5wl8mc+a99sCiQb3Ugg/6bBApQDdWug3e+s29G8kU0NhQyM9jujUZhOAEb5mEHvOrPy1fQB/cdLCPVkBVJ+68uOLp6ubhjG8xjlF3cJ9fojy/+XGKNuZtBhZyT3ThdwXAxjc47+iwB1Z30CIN9Udqn8w0Y3NhqyHCuj6wjkprN2f6j6Ui9G7xDIOaeXR/J5eb1ZkKkNDMSvEuqPDuzlnq7Cygmd2eh2Qnla3tVGM80GnIiBUV9UZlP98p9hGM9mI4ec3twJ/Gh1qyFT68s8WkEol91ZDbP2DY9QbpUfGv3p4MF4TrsgXWPU7xzYBx6BCIM+Y2N3tRturJGvnzFIkUEeewf2xHPd3NifKy4l1AuBkS9bDRaA7WaU825ODfeMLAxz0fxxzox2a3oCtZTS8zwCvZ5s5GxV57zRT9PasgVsLxmDnC2B2o6qznkjmxLQq3UHMoX3A9SdbOwuMrK27vdRz5QKHvtFU0B7zfC7hHrKOc3S2uB8PamuqaCUiZYxSNFJJVfdPEggcQ9Eq9oOyihXktF7SJloQ1mnSz5gkNtpHozj+JW2gXLGri+P2gRnc3+uuJRAnxNI7Hai5aAE6ifH7qVJfdJ6tobP5uaNKGpXUvgOTeoD+nOSG/vbBhqgmsT3zBTBB0rBNT8DOgM6AzoD2ghQ0Is1+9YCCrqjKijI8TpAS/Uood4PjO0IjO1gtJ3jGtjd6d0og5ydAnQw6cwgg909oRbazlKc0jUmo+2sCkpgt4wt16q1qYpsAj1dr15Nn+p92dHFjPIrgw4R6qlqjUFOBL79fDK95H7099q0dIhRBykTLRur8x8jhWxLDVXT/AAAAABJRU5ErkJggg=="
                    />
                </defs>
                <g id="Left Menu">
                    <g id="Menu">
                        <use id="housing" href="#img1" transform="matrix(1,0,0,1,0,0)" />
                    </g>
                </g>
            </svg>
        </Icon>
    )
}

export default Main
