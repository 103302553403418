import React from 'react';
import { Box, Grid, GridSize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface ItemProps {
    xs?: GridSize;
    md?: GridSize;
    name: string;
    value: string;
};

const Item: React.FC<ItemProps> = ({ xs = 12, md = 6, name, value }) => {
    const [t] = useTranslation();
    return <Grid item xs={xs} md={md}>
        <Box>
            <b>{t(name)}:</b> {t(value)}
        </Box>
    </Grid>;
};

export default Item;
