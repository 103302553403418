import { Box, Card, CardContent, Divider, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';

interface MenuItemProps {
    icon: React.ReactElement;
    label: string;
    link: string;
};

const useStyles = makeStyles(() => ({
    card: {
        width: '100%',
        height: '100%',
        padding: '2vh 4vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 0,
        boxShadow: '2px 2px 5px 1px rgb(0 0 0 / 0.05)'
    }
}));

const MenuItem: React.FC<MenuItemProps> = ({ icon, label, link }) => {
    const classes: any = useStyles();
    return <Link to={link}>
        <Card className={classes.card}>
            <Box display="flex" justifyContent="center" alignItems="center" margin="3vh">
                {icon}
            </Box>
            <Divider orientation="vertical" flexItem />
            <CardContent>
                <Box display="flex" justifyContent="center" alignItems="center" margin="1vh">
                    <Typography gutterBottom variant="h5" component="h3">
                        {label}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    </Link>;
};

export default MenuItem;