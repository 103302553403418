import React from 'react'
import OperationsList from './List'
import { Wrapper } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import {
  transfersSelector,
  handlerUserTransfers,
  handlerHallTransfers,
  handlerTableTransfers,
} from '../../redux/transfersReducer'
import { useLocation } from 'react-router-dom'

interface OperationsProps {
  user_id?: number | undefined
  hall_id?: number | undefined
  table_id?: number | undefined
  hideOperations: () => void
}

const Operations: React.FC<OperationsProps> = ({ user_id, hall_id, table_id, hideOperations }) => {
  const dispatch = useDispatch()
  const { items, loading } = useSelector(transfersSelector)
  const location = useLocation()
  const operations_page = Number(new URLSearchParams(location.search).get('operations_page'))
  React.useEffect(() => {
    user_id && dispatch(handlerUserTransfers(user_id, operations_page, 20))
    hall_id && dispatch(handlerHallTransfers(hall_id, operations_page, 20))
    table_id && dispatch(handlerTableTransfers(table_id, operations_page, 20))
    // eslint-disable-next-line
  }, [dispatch, user_id, hall_id, table_id])

  return (
    <Wrapper>
      <OperationsList
        user_id={user_id}
        hall_id={hall_id}
        table_id={table_id}
        hideOperations={hideOperations}
        transfers={items}
        loading={loading}
      />
    </Wrapper>
  )
}

export default Operations
