export const sortedList = (sort: string, sortDir: boolean, list: any[]) => {
    const sortedArr = [...list]
    return sortedArr.sort((a: any, b: any) => {
      if (sortDir ? a[sort] > b[sort] : a[sort] < b[sort]) {
        return 1
      }
      if (sortDir ? a[sort] < b[sort] : a[sort] > b[sort]) {
        return -1
      }
      return 0
    })
  }