import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../Halls/List/styles';
import HallBox from '../Tables/List/hall-box';
import { hallSelector } from '../../redux/hallReducer';
import { useSelector } from 'react-redux';
import Preloader from '../Common/Preloader';
import { IJackpot } from '../../interfaces';
import { findJackpots } from '../../redux/jackpotReducer';
import CompleteTable from './complete-table';
import { Link } from "react-router-dom";
import { Pagination } from '@material-ui/lab';

interface CompleteJackpotsProps {
    isModal?: boolean;
    hallId: number;
    setHallId: (hallId: number) => void;
}

const CompleteJackpots: React.FC<CompleteJackpotsProps> = React.memo(({ isModal, hallId, setHallId }) => {
    const [t] = useTranslation();
    const { halls: { data: halls } } = useSelector(hallSelector);
    const [jackpots, setJackpotsNative] = React.useState<IJackpot[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [page, setPageNative] = React.useState<number>(1);
    const [lastPage, setLastPage] = React.useState<number>(1);
    const setJackpots = (data: IJackpot[], lastPage: number) => {
        setJackpotsNative(data);
        setLastPage(lastPage);
    };
    const setPage = (page: number) => {
        findJackpots([2, 3, 4], [hallId], [], page, 10, setLoading, setJackpots, () => console.log('error'));
        setPageNative(page);
    };
    React.useEffect(() => {
        if (!hallId && halls.length) {
            setHallId(halls[0].id);
        }
    }, [halls, hallId, setHallId]);
    React.useEffect(() => {
        if (hallId) {
            findJackpots([2, 3, 4], [hallId], [], 1, 10, setLoading, setJackpots, () => console.log('error'));
        }
    }, [hallId]);
    //Jackpot::STATUS_ACTIVE, Jackpot::STATUS_ACCUMULATION_ONLY, Jackpot::STATUS_PAUSED, Jackpot::STATUS_COMPLETED
    return <Wrapper isModal={isModal}>
        <Box style={{ minWidth: '968px' }}>
            {!isModal && <Box display="flex" justifyContent="space-between">
                <h2>{t('ru.jackpots.title')} <Link to="/jackpots"><Button variant="contained" color="primary" size="small">посмотреть текущие</Button></Link></h2>
            </Box>}
            {hallId > 0 && <div style={{ position: 'relative' }}>
                <HallBox hallId={hallId} setHallId={setHallId} halls={halls} />
                {loading ? <Preloader /> : <CompleteTable jackpots={jackpots} hall_id={hallId} />}
            </div>}
            {lastPage > 1 && <Pagination variant="outlined" shape="rounded" siblingCount={0} count={lastPage} page={page} onChange={(e, value) => setPage(value)} />}
        </Box>
    </Wrapper>;
});

export default CompleteJackpots;