import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';

type FieldReelAnimationProps = {
    error: boolean;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldReelAnimationProps, cur: FieldReelAnimationProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldReelAnimation: FunctionComponent<FieldReelAnimationProps> = React.memo(({ error, onChange, value, formHelperText }) => {
    return <Grid item xs={12} sm={3}>
        <FormControl error={error} fullWidth size="small">
            <NativeSelect onChange={onChange} value={value}>
                <option value={0}>классика</option>
                <option value={1}>лепестки</option>
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldReelAnimation;