import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, TextField } from '@material-ui/core';

type FieldTickerTextProps = {
    error: boolean;
    className: string;
    onChange: (object: any) => void;
    value: number;
    formHelperText: string;
};

function cmp(prev: FieldTickerTextProps, cur: FieldTickerTextProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.value === cur.value;
}

const FieldTickerText: FunctionComponent<FieldTickerTextProps> = React.memo(({ error, className, onChange, value, formHelperText }) => {
    return <Grid item xs={12} sm={12}>
        <FormControl error={error} fullWidth size="small">
            <TextField className={className} placeholder="Введите текст" type="text" value={value} name="denom" onChange={onChange} size="small" />
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldTickerText;