import React, { FunctionComponent } from 'react';
import ViewButton from './view-button';
import UpdateButton from './update-button';
import DeleteButton from './delete-button';
import BlockButton from './block-button';
import ReloginButton from './relogin-button';

type isConfirmType = {
    id: number | undefined;
    name: string;
    open: boolean;
};

type ButtonsProps = {
    viewUser: boolean;
    updateUser: boolean;
    deleteUser: boolean;
    isMe: boolean;
    id: number;
    setChange: (x: boolean) => void;
    setIsConfirm: (item: isConfirmType) => void;
    login: string;
    status: boolean;
    setIsBlock: (item: isConfirmType) => void;
    setIsReLogin: (item: isConfirmType) => void;
};

function cmp(prev: ButtonsProps, next: ButtonsProps): boolean {
    return prev.viewUser === next.viewUser && prev.deleteUser === next.deleteUser && prev.updateUser === next.updateUser &&
        prev.isMe === next.isMe && prev.id === next.id && prev.login === next.login && prev.status === next.status;
}

const Buttons: FunctionComponent<ButtonsProps> = React.memo(({ viewUser, updateUser, deleteUser, isMe, id, setChange, setIsConfirm, login, setIsBlock, status, setIsReLogin }) => {
    return <>
        {viewUser && <ViewButton id={id} />}
        {viewUser && updateUser && <UpdateButton id={id} setChange={setChange} />}
        {deleteUser && <DeleteButton id={id} setIsConfirm={setIsConfirm} login={login} />}
        {viewUser && updateUser && <BlockButton id={id} setIsBlock={setIsBlock} status={status} login={login} isMe={isMe} />}
        {!isMe && <ReloginButton id={id} setIsReLogin={setIsReLogin} login={login} />}
    </>;
}, cmp);

export default Buttons;