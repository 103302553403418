import React from 'react'
import { useForm } from '../../../../hooks/useForm'

import IconButton from '@material-ui/core/IconButton'
import { SubForm } from '../styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Input, Button, InputLabel } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { addCompanyItem, companySelector, formIsError } from '../../../../redux/companyReducer'
import { trowErrorMessage, trowError } from '../../../../utils/errors'
import Preloader from '../../../Common/Preloader'

interface CompanyFormProps {
    hideCompany: () => void
  }

const CompanyForm: React.FC<CompanyFormProps> = ({ hideCompany }) => {
    const dispatch = useDispatch()

    const {
        form: { loading, error },
    } = useSelector(companySelector)

    const { form, handleInputChange } = useForm({
        name: '',
    })

    React.useEffect(() => {
        return () => {
            dispatch(formIsError(null))
        }
        // eslint-disable-next-line
    }, [])

    return (
        <SubForm>
            {!!loading ? (
                <Preloader absolute={false}/>
            ) : (
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <h4>Добавить компанию</h4>
                        <IconButton onClick={hideCompany}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Input
                            type="text"
                            value={form.city}
                            name="name"
                            onChange={handleInputChange}
                            placeholder="Название"
                            fullWidth
                            error={!!error && !!trowError(error, 'name')}
                        />
                        <InputLabel style={{ margin: '1vh', color: '#f44336' }}>
                            {!!error && trowErrorMessage(error, 'name')}
                        </InputLabel>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                dispatch(
                                    addCompanyItem(
                                        {
                                            name: form.name,
                                        },
                                        hideCompany
                                    )
                                )
                            }}
                        >
                            Добавить
                            <AddCircleIcon />
                        </Button>
                    </Box>
                </>
            )}
        </SubForm>
    )
}

export default CompanyForm
