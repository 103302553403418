import React from 'react'

import { Modal } from './styles'
import {
  Box,
  Input,
  Button,
  ButtonGroup,
  IconButton,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
// import { Alert } from '@material-ui/lab'
import Preloader from '../../Common/Preloader'
import { useStyles } from './styles'
import { prettyNum } from '../../../utils/prettyNum'
import { handleTakeTablePollscore, tableSelector } from '../../../redux/tableReducer'
import { useTranslation } from 'react-i18next'

interface PollscoreProps {
  id?: any
  currency_id?: string
  pollscore?: number
  setTableId: (id: number) => void
  hidePollscore: () => void
}

const Pollscore: React.FC<PollscoreProps> = ({ id, currency_id, pollscore, setTableId, hidePollscore }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation()

  React.useEffect(() => {
    return () => {
      setTableId(-1)
    }
    // eslint-disable-next-line
  }, [])

  const {
    form: {
      // error,
      loading },
  } = useSelector(tableSelector)


  return (
    <Modal>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h3>{t('ru.common.balance.pollscore')}</h3>
        <IconButton onClick={hidePollscore}>
          <CloseIcon />
        </IconButton>
      </Box>

      {loading ? (
        <Preloader absolute={false} />
      ) : (
        <ButtonGroup variant="contained">
          <Input
            className={classes.input}
            endAdornment={`(${currency_id})`}
            value={prettyNum(pollscore)}
            name="amount"
            readOnly
          />
          <Button
            size="large"
            variant="outlined"
            onClick={() => {
              dispatch(handleTakeTablePollscore(id, hidePollscore, currency_id || '', pollscore || 0))
            }}
          >
            {t('ru.common.balance.withdraw')}
          </Button>

        </ButtonGroup>
      )}
    </Modal>
  )
}

export default Pollscore
