import React, { FunctionComponent } from 'react';
import { Box, Button, FormControl, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type FieldAddDenominationProps = {
    className: string;
    onChange: (object: any) => void;
    onClick: () => void;
    value: number;
};

function cmp(prev: FieldAddDenominationProps, cur: FieldAddDenominationProps): boolean {
    return prev.className === cur.className && prev.value === cur.value;
}

const FieldAddDenomination: FunctionComponent<FieldAddDenominationProps> = React.memo(({ className, onChange, value, onClick }) => {
    const [t] = useTranslation();
    return <Grid item xs={12} sm={6}>
        <FormControl>
            <FormHelperText>{t('ru.halls.form_helpers.custom_denom')}</FormHelperText>
            <Box display="flex" alignItems="center">
                <TextField className={className} placeholder={t('ru.halls.form_helpers.denom_placeholder')} type="text" value={value} name="denom" onChange={onChange} size="small" />
                <Button onClick={onClick}> {t('ru.common.add_btn')} </Button>
            </Box>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldAddDenomination;