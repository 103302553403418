import React from 'react'

import { Box, Grid, makeStyles, IconButton, Button } from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import CloseIcon from '@material-ui/icons/Close'

import { Wrapper } from './styles'
import {
    profileSelector,
    handlerAddImage,
} from '../../../redux/profileReducer'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../../Common/Preloader'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
    input: {
        display: 'none',
    },
    profileImage: {
        maxWidth: '60vh',
    },
}))

interface ProfileProps {
    profile: PlayerProfile | undefined
    hideProfile: () => void
}

const Profile: React.FC<ProfileProps> = ({ profile, hideProfile }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [t] = useTranslation()

    const { imageUpload } = useSelector(profileSelector)
    const [img, setImg] = React.useState<string>('')

    const handleUploadImage = (event: any) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(event.target.files[0])
        fileReader.onload = (e: any) => {
            setImg(e.target.result)
        }
    }

    const imageData = {
        files: [img],
    }
    const viewImage = <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUploadImage}
        />
        <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
                {t('ru.common.add_btn')}
            </Button>
        </label>
    </Box>;

    const renderImage = () => {
        return !img ? (
            viewImage
        ) : (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <img
                    className={classes.profileImage}
                    src={img}
                    alt="img"
                />
                <Button disabled={imageUpload.loading} onClick={() => dispatch(handlerAddImage(imageData, profile?.id, setImg))}>
                    {t('ru.common.save_btn')}
                </Button>
            </Box>
        )
    }


    const rootRender = (
        <>
            <IconButton className={classes.closeBtn} onClick={hideProfile}>
                <CloseIcon />
            </IconButton>
            <Box display="flex" alignItems="center">
                <AccountCircleIcon fontSize="large" />
                <h2>
                    {profile?.name} {profile?.surname}
                </h2>
            </Box>
            <br />
            <hr />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <b>{t('ru.profiles.fields.first_name')}:</b> {profile?.name}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <b>{t('ru.profiles.fields.sure_name')}:</b> {profile?.surname}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <b>{t('ru.profiles.fields.last_name')}:</b> {profile?.patronymic}
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <span>
                        <b>{t('ru.profiles.fields.photo')}:</b>
                    </span>
                    <br />
                    {renderImage()}
                    {imageUpload.loading && <Preloader absolute />}
                </Grid>
            </Grid>
        </>
    )

    return <Wrapper>{rootRender}</Wrapper>
}

export default Profile
